import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import {  Box, Box0 } from '../../Statistics/chart';
import ReactApexChart from 'react-apexcharts';

const Ordermonth = () => {
  const [viewMode, setViewMode] = useState('year'); // 'year' or 'week'

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  return (
    <Card>
      <CardHeader className='card-no-border'>
        <H5>Order</H5>
      </CardHeader>
      <CardBody className='pt-0'>
        <div className="text-center mb-3">
          <Button className='btn btn-primary' onClick={() => handleViewModeChange('year')} active={viewMode === 'year'}>Year</Button>{' '}
          <Button className='btn btn-primary' onClick={() => handleViewModeChange('week')} active={viewMode === 'week'}>Week</Button>
        </div>
        {viewMode === 'year' ? (
          <ReactApexChart className='overview-wrapper' type='line'  height={200} options={Box0().options} series={Box0().series} />
        ) : (
          <ReactApexChart className='overview-wrapper' type='line'  height={200} options={Box().options} series={Box().series} />
        )}
      </CardBody>
    </Card>
  );
};

export default Ordermonth;
