import React, { Fragment,useEffect,useState,useRef } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs ,Btn,Spinner } from '../../AbstractElements';
import { useNavigate, useParams } from 'react-router-dom';
//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// rsuite
import { Input, Whisper, Tooltip,SelectPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
// data table
import DataTable from 'react-data-table-component';
import { dummytabledata } from '../../Data/Table/Defaultdata';
// date  format
import dateFormat from "dateformat";
import 'react-datepicker/dist/react-datepicker.css';
import Details_Order from "./details"
import { useLocation } from 'react-router-dom';



import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const BaseApi=api_Routes.order.view;

  const style2 = { width: 60, fontSize: 14, padding: 4 };


function Orders(){



    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [ totalItems, setTotalItems ] = useState(0)
    const [vendors, setvendors] = useState([])
    const [users, setusers] = useState([])
    const [from_price, setfrom_price] = useState(0)
    const [to_price, setto_price] = useState(0)
    const [statuss, setstatuss] = useState([]);
    const [selectedfDate, setSelectedfDate] = useState(null)
    const [selectedtDate, setSelectedtDate] = useState(null)
    const location = useLocation();
     const searchParams = new URLSearchParams(location.search);
     const permissions = JSON.parse(localStorage.getItem('permission'));
     const controllerRef = useRef(null)


    useEffect(()=>{
      if (!permissions || !permissions.includes('order.get-all')) {
        navigate(`/Forbidden`)
    }else{

      get_status()
      get_vendor()
      get_user()
      get_order()
    }
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    },[values_Filter,page,perPage])

    const get_status = () => {
   const response = { data: [
      { name: "pending", id: 1 },
      { name: "delivery", id: 2 },
      { name: "delivered", id: 3 },
      { name: "canceled", id: 5 },
      { name: "rejected", id: 4 },
      { name: "sold", id: 6 }
    ]};
      setstatuss([]);
    response.data.forEach(ele => {
    
      setstatuss(prev => [...prev, {
        label: ele.name,
        value: ele.id
      }]);
     
    });}

      const get_vendor = async()=>{
    const {response, message} = await Helper.Get({
        url:api_Routes.user.view,
        hasToken:true,
    })
    if(response){
      console.log("ana");
        console.log(response.data);
        setvendors([])
        response.data.forEach(ele=>{
        setvendors(prev => [...prev, {
            label:ele.name, // Convert user_id to integer
            value: ele.id   // Convert user_id to integer
        }])
        })
    }else{
        console.log(message);
    }
    }



          const get_user = async()=>{
    const {response, message} = await Helper.Get({
        url:api_Routes.user.view,
        hasToken:true,
    })
    if(response){
      console.log("ana");
        console.log(response.data);
        setusers([])
        response.data.forEach(ele=>{
        // setusers(prev => [...prev, {
        //     label:ele.name, // Convert user_id to integer
        //     value: ele.id   // Convert user_id to integer
        // }])
        setusers(response.data)
        })
    }else{
        console.log(message);
    }
    }

   
    console.log(values_Filter.id);





    const get_order = async()=>{
    
   
    const userId = searchParams.get('user_id');
    
      let url = BaseApi + '?1=1';
      
      if(userId){
        url = url + `&user_id=${userId}`;
      }
      if (values_Filter.vendor_id  ) {
        url = url + `&vendor_id=${values_Filter.vendor_id}`;
      }
      if (values_Filter.user_id ) {
        url = url + `&user_id=${values_Filter.user_id}`;
      }
      if (values_Filter.from_price ) {
        url = url + `&from_price=${values_Filter.from_price}`;
      }
      if ( values_Filter.to_price  ) {
        url = url + `&to_price=${values_Filter.to_price}`;
      }
      if ( values_Filter.status  ) {
        url = url + `&status=${values_Filter.status}`;
      }
      if ( values_Filter.from_date  ) {
        url = url + `&from_date=${values_Filter.from_date}`;
      }
      if ( values_Filter.to_date  ) {
        url = url + `&to_date=${values_Filter.to_date}`;
      }
     
      
        
        setIsLoading(true)
        const {response, message} = await Helper.Get({
          
        url: url,
          hasToken:true,
            data:{results:perPage,page:page,
              keywords:values_Filter.keywords,
             
            
            }
        })

        if(response){

          setData_Table([])
            setTotalItems(response.meta.total)

            response.data.forEach(elem => {
                setData_Table(prev=>[...prev,{
                  id: <img className='theme-text user-photo ' src={elem.user_photo} alt='User Photo' />,

                    user_name:elem.user_name,
                    phone:elem.user_phone,
                    price: elem.total,
                    date:dateFormat(elem.created_at,"dddd, mmmm dS, yyyy"),
                    status: elem.status == 1 ? (
                      <span className={`badge badge-light-primary`} style={{
                        padding: '10px 16px',
                        fontSize: '12px',
                        fontWeight: "bold"
                      }}>Pending</span>
                    ) : elem.status == 2 ? (
                      <span className={`badge badge-light-primary`}  style={{
                        padding: '10px 14px',
                        fontSize: '13px',
                        fontWeight: "bold"
                      }}>Delivery</span>
                    ) : elem.status == 3 ? (
                      <span className={`badge badge-light-success`} style={{
                        padding: '10px',
                        fontSize: '13px',
                        fontWeight: "bold"
                      }}>Delivered</span>
                    ) : (
                      <span className={`badge badge-light-success`} style={{
                        padding: '8px 26px',
                        fontSize: '13px',
                        fontWeight: "bold"
                      }}>Sold</span>
                    ),

                             action: (
                                <div>
                                  <span>
                                    <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>navigate(`/orders/${elem.id}/order`)}}> Details </Btn>
                                  </span>
                                </div>
                              ),
                        }])
                
            });
            setCol_Table([{
                    name: 'Photo',
                    selector: row => row['id'],
                    sortable: true,
                    width:"12%",
                    center: false,
                },
                {
                    name: 'User',
                    selector: row => row['user_name'],
                    sortable: true,
                    width:"14%",
                    center: true,
                },
                {
                    name: 'Phone Number',
                    selector: row => row['phone'],
                    sortable: true,
                    width:"17%",
                    center: true,
                },
                {
                    name: 'Price',
                    selector: row => row['price'],
                    sortable: true,
                    width:"10%",
                    center: false,
                },
                {
                    name: 'Created Date',
                    selector: row => row['date'],
                    sortable: true,
                    width:"20%",
                    center: false,
                },
                {
                    name: 'Status',
                    selector: row => row['status'],
                    sortable: true,
                    width:"15%",
                    center: false,
                },
                {
                    name: 'Action',
                    selector: (row) => row.action,
                    sortable: true,
                    center: true,
                    width: '10%',
                  },
                
          ])
          setIsLoading(false)
        }else{
            console.log(message);
        }

    }

        /// Change Pages To Api
    const handleChangePage = (value)=>{
        console.log("per_pages",value);
        setPerPage(value)
    }
    const handlePage =(e)=>{
        setPage(e)
    }
   const handleChange = (key, value) => {
    setValues(prev => ({ ...prev, [key]:value }));
     }

     const handleChanged = (key, value) => {
        const formattedDate = value ? new Date(value)
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ') 
        : null;  
        setValues(prev => ({ ...prev, [key]: formattedDate }));
       }


  
    const handleFilter = ()=>{
    setFilter_Values(values)
    }

    const handlefromprice=(e)=>{
        setfrom_price(e.target.value)
    }
    const handletoprice=(e)=>{
        setto_price(e.target.value)
    }

 

const onSearch1 = async (e,key)=>{

if(controllerRef.current){
  controllerRef.current.abort()
}
controllerRef.current = new AbortController()
const signal = controllerRef.current.signal
const {response,message} = await Helper.Get_Abort({
  url:  api_Routes.user.view ,
  signal:signal,
  data:{keywords:e,results:20},
  hasToken:true
})
if(response){
      setusers(response.data)
}

}
  

    return(id==":id"?<>

        <Breadcrumbs parent="order" title="List" mainTitle="Order" />
        <Container className='my-2' fluid={true}>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardBody>
                            <Row>
              <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={4}>
                <Whisper trigger="focus" speaker={<Tooltip>Keywords</Tooltip>}>
                  <Input onChange={(e)=>{handleChange("keywords",e)}} 
                        style={{ width: "100%" }} placeholder="Search By Name" />
                </Whisper>
              </Col> 

              
              {/* <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                <SelectPicker placeholder="Search By user" 
                 onChange={(e)=>{handleChange("user_id",e)}}
                        style={{ width: "100%" }}
                        data={ users.map((ele)=>({label:ele.name?ele.name:"",value:ele.id}))} 
                          onSearch={(e)=>{
                            if(e!="")
                             onSearch1(e,"user")
                        }}
                         />
              </Col> */}

              <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={4}>
                <SelectPicker placeholder="Search By status" 
                 onChange={(e)=>{handleChange("status",e)}}
                        style={{ width: "100%" }}
                          data={statuss} 
                        //  loading={isLoading.boxes} 
                         />
              </Col>
              <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={4}>
                <Input placeholder="Search By From Price" 
                     onChange={(e)=>{handleChange("from_price",e)}}
                        style={{ width: "100%" , height:'35px' ,borderRadius : '5px', border:'1px solid #e5e7eb', paddingLeft:'11px' }}
                        //  loading={isLoading.boxes} 
                         />
              </Col>
              <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={4}>
                <Input placeholder="Search By To Price" 
                onChange={(e)=>{handleChange("to_price",e)}}
                        style={{ width: "100%", height : '35px', marginTop:'15px' , borderRadius : '5px' , border: ' 1px solid  #e5e7eb' , paddingLeft:'11px' }}
                          
                        //  loading={isLoading.boxes} 
                         />
              </Col>
             
              

              <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3} >
              <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DemoContainer components={['DatePicker']} >
                    <DatePicker
                        placeholderText="Search By From Date"
                        selected={selectedfDate}
                        onChange={(date) => handleChanged("from_date", date)}
                       // dateFormat="yyyy-M-d"
                        onSelect={(date) => setSelectedfDate(date)}
                        sx={{height:'70%' , width:'100%' , overFlow:'hidden' , WebkitFlexDirection: 'row', display: 'contents' , borderRadius : '5px' , border: ' 1px solid  #e5e7eb' }}
                    />
                </DemoContainer>
               </LocalizationProvider>

               </Col>


               <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
             <DatePicker
                placeholderText="Search By To Date"
                selected={selectedtDate}
                onChange={(date) => handleChanged("to_date", date)}
                dateFormat="yyyy-M-d"
                onSelect={(date) => setSelectedtDate(date)}
                sx={{height:'30%' , width:'100%' , overFlow:'hidden' , WebkitFlexDirection: 'row', display: 'contents' , borderRadius : '5px' , border: ' 1px solid  #e5e7eb' }}

              />
              </DemoContainer>
               </LocalizationProvider>
                </Col>
                
                <Col className='d-flex align-items-center justify-content-end p-2 mt-3' sm={2} xs={2} lg={2} md={2} >
                <div onClick={()=>{handleFilter()}}>
                  <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                </div>
              </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        <Container fluid={true}>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardBody>
                            <Fragment>
                                <DataTable
                                        data={data_table}
                                        columns={col_table}
                                        striped={true}
                                        center={true}
                                        progressPending={isLoading}
                                        progressComponent={<div className="loader-box">
                                                            <Spinner attrSpinner={{ className: "loader-26" }} />
                                                        </div>}
                                        pagination
                                        paginationServer
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[10,20,30,50,100]}
                                        responsive
                                        paginationTotalRows={totalItems}
                                        paginationServerOptions={()=>{}}
                                        onChangePage={(e)=>{handlePage(e)}}
                                        onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                                />
                            </Fragment>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    
    </>:<Details_Order id={id} />
    
    );
}
export default Orders;