import React, { Fragment,useEffect,useState ,useRef} from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs ,Btn,Spinner } from '../../AbstractElements';
import {  useNavigate, useParams } from 'react-router-dom';
//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// rsuite
import { Input, Whisper, Tooltip,SelectPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
// data table
import DataTable from 'react-data-table-component';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-lightbox/style.css';

const  BaseApi = api_Routes.address.view;
const style2 = { width: 60, fontSize: 14, padding: 4 };


function Address (){
    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [users, setusers] = useState([])
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [ totalItems, setTotalItems ] = useState(0)
    const permissions = JSON.parse(localStorage.getItem('permission'));
    const controllerRef = useRef(null)

    useEffect(()=>{
        if (!permissions || !permissions.includes('user_address.get-all')) {
           navigate(`/Forbidden`)
       } else {
        get_address()
        get_user()
      }
        },[values_Filter,page,perPage])

        const get_user = async()=>{
          const {response, message} = await Helper.Get({
              url:api_Routes.user.view,
              hasToken:true,
          })
          if(response){
            console.log("ana");
              console.log(response.data);
              // setusers([])
              // response.data.forEach(ele=>{
              // setusers(prev => [...prev, {
              //     label:ele.name, // Convert user_id to integer
              //     value: ele.id   // Convert user_id to integer
              // }])
              // })
              setusers(response.data)
          }else{
              console.log(message);
          }
          }

        const get_address = async () => {

          let url = BaseApi + '?1=1';

          if (values_Filter.user_id ) {
            url = url + `&user_id=${values_Filter.user_id}`;
          }
            setIsLoading(true)
            const {response, message} = await Helper.Get({
              
            url: url,
              hasToken:true,
                data:{results:perPage,
                      page:page,
                      keywords:values_Filter.keywords, }
            })

            if(response){

                setData_Table([])
                  setTotalItems(response.meta.total)
      
                  response.data.forEach(elem => {
                      setData_Table(prev=>[...prev,{
    
                        username : elem.user?.name,
                        address: `${elem.floor}, ${elem.zipcode}, ${elem.building}, ${elem.street}, ${elem.area}, ${elem.city}`,
                                   action: (
                                      <div>
                                        <span>
                                          <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>navigate(`/user-address/${elem.id}/order`)}}>
                                             Details
                                          </Btn>
                                        </span>
    
                                      </div>
                                    ),
                              }])
                      
                  });
                  setCol_Table([
                      {
                        name: 'UserName',
                        selector: row => row['username'],
                        sortable: true,
                        width:"250px",
                        center: false,
                    },
                      {
                        name: 'Address',
                        selector: row => row['address'],
                        sortable: true,
                        width:"600px",
                        center: false,
                    },
                      {
                          name: 'Action',
                          selector: (row) => row.action,
                          sortable: true,
                          center: true,
                          width: '120px',
                        },
                      
                ])
                setIsLoading(false)
              }else{
                
                  console.log(message);
              }
        } 

        const handleChangePage = (value)=>{
            console.log("per_pages",value);
            setPerPage(value)
        }
        const handlePage =(e)=>{
            setPage(e)
        }
        const handleFilter = ()=>{
            setFilter_Values(values)
            }

        const handleChange = (key, value) => {
                setValues(prev => ({ ...prev, [key]: value }));   
            }

            const onSearch = async (e,key)=>{
  
              if(controllerRef.current){
                  controllerRef.current.abort()
              }
              controllerRef.current = new AbortController()
              const signal = controllerRef.current.signal
              const {response,message} = await Helper.Get_Abort({
                  url:  api_Routes.user.view ,
                  signal:signal,
                  data:{keywords:e,results:20},
                  hasToken:true
              })
              if(response){
                     setusers(response.data)
              }
            
            }

            return ( 
            
                <Fragment>
                      <Breadcrumbs parent="Address" title="List" mainTitle="User Address" />
                      
                      <Container className='my-2' fluid={true}>
                      <Row>
                        <Col sm="12">
                          <Card>
                           
                            <CardBody>
                            <Row  >
                              <Col className='d-flex justify-content-center p-2' sm={12} lg={11} md={11} xl={11}>
                                <Whisper trigger="focus" speaker={<Tooltip>Keywords</Tooltip>}>
                                  <Input onChange={(e)=>{handleChange("keywords",e)}} 
                                         placeholder="Search By User Name" />
                                </Whisper>
                              </Col>  

                              {/* <Col className='d-flex justify-content-center p-2' sm={12} lg={5} md={5} xl={5}>
                                  <SelectPicker placeholder="Search By user" 
                                  onChange={(e)=>{handleChange("user_id",e)}}
                                          style={{ width: "100%" }}
                                          data={ users.map((ele)=>({label:ele.name?ele.name:"",value:ele.id}))} 
                                          onSearch={(e)=>{
                                            if(e!="")
                                             onSearch(e,"user_id")
                                        }}
                                          //  loading={isLoading.boxes} 
                                          />
                                </Col> */}
                                              
                              <Col className='d-flex align-items-center justify-content-end p-2' sm={12} xs={12} lg={1} md={1} >
                                <div onClick={()=>{handleFilter()}}>
                                  <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                                </div>
                              </Col>
                            </Row>
              
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Container>  
                      <Container fluid={true}>
                        <Row>
                          <Col sm="12">
                            <Card>
                              <CardBody>
                              <Fragment>
                                  
                                  <DataTable
                                      // data={data}
                                      // columns={tableColumns}
                                      data={data_table}
                                      columns={col_table}
                                      striped={true}
                                      center={true}
                                      progressPending={isLoading}
                                      progressComponent={<div className="loader-box">
                                                      <Spinner attrSpinner={{ className: "loader-26" }} />
                                                  </div>}
                                      pagination
                                      paginationServer
                                      paginationPerPage={perPage}
                                      paginationRowsPerPageOptions={[10,20,30,50,100]}
                                      responsive
                                      paginationTotalRows={totalItems}
                                      paginationServerOptions={()=>{}}
                                      onChangePage={(e)=>{handlePage(e)}}
                                      onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                                      onSelectedRowsChange={(e)=>{
                                        setSelectedRows([])
                                        e.selectedRows.forEach(ele =>{
                                          setSelectedRows(prev=>[...prev,ele.id])
                                        })
                                      }}
                                      clearSelectedRows={toggleDelet}
                                      
                                  />
                              </Fragment>
                
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    
                  </Fragment>
                 
            );
}
export default Address;