import React, { Fragment, useState, useEffect, useContext } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P,Spinner ,Image} from "../AbstractElements";
import { EmailAddress, ForgotPassword, Password, RememberPassword, SignIn } from "../Constant";
import logoWhite from '../assets/images/logo/Group 8134.png';
import logoDark from '../assets/images/logo/Group 8134.png';
import { useNavigate ,Link} from "react-router-dom";
import man from "../assets/images/dashboard/profile.png";

import CustomizerContext from "../_helper/Customizer";
import OtherWay from "./OtherWay";
import { ToastContainer, toast } from "react-toastify";
//rsuite
import { Loader } from "rsuite";
//api request
import { api_Routes } from "api_Routes";
import { Helper } from "tools/helper";
import { fontWeight } from "@mui/system";
import image from "../assets/images/login/login_bg.jpg"


const Signin = ({ selected }) => {
  const [username, setUsername] = useState(localStorage.hasOwnProperty("verified")?JSON.parse(localStorage.getItem("verified")).username
  :"username");
  const [password, setPassword] = useState("test123");
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));
  const navigate = useNavigate() 
  useEffect(() => {
    
    localStorage.setItem("profileURL", man);
  }, [value, name]);

  const loginAuth = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const {response,message} = await Helper.Post({
      url:api_Routes.auth.login,
      data:{
        username:username,
        password:password
      }
   
    })
    if(response){
        console.log(response.data);
        localStorage.setItem("user",JSON.stringify({
          user_id:response.data.id,
          token:response.data.token,
          username:response.data.username,
          userphoto:response.data.photo
        }))
        localStorage.setItem("permission",JSON.stringify(response.data.permissions))
        toast.success(message);
        localStorage.setItem("authenticated", false);
        localStorage.setItem("login", true);
        navigate(`/dashboard/Shake`)
        setIsLoading(false)
        get_languages()
    }else{
      console.log(message);
      if(typeof message =="string"){
        toast.error(message);
      }else{
        Object.keys(message).forEach(msg=>{
          toast.error(`${msg} : ${message[msg]}`);
        })
      }
      setIsLoading(false)
      
    }
    // setValue(man);
    // setName("Emay Walter");
    // if (email === "test@gmail.com" && password === "test123") {
    //   localStorage.setItem("login", JSON.stringify(true));
    //   history(`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`);
    //   toast.success("Successfully logged in!..");
    // } else {
    //   toast.error("You enter wrong password or username!..");
    // }
  };
  const get_languages = async ()=>{
    const {response, message} = await Helper.Get({
      url:api_Routes.language.view,
      hasToken:true
    })
    if(response){
      const array_lang=[]
      response.data.forEach(ele=>{
          array_lang.push({
            id:ele.id,
            code:ele.code,
            name:ele.name
          })
      })
      localStorage.setItem("lang",JSON.stringify(array_lang))
    }else{
      if(typeof message == "string"){
        toast.error(message)
      }else {
        Object.keys(message).forEach(msg=>{
          toast.error(`${msg} : ${message[msg]} `)
        })
      }
      
    }
  }
  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12" style={{padding:"0"}}>
            <div className="login-card" style={{ 
              background:`url('${image}')`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
                  backgroundSize: "cover"}}>
              <div className="login-main login-tab">
                <Form className="theme-form">
                <div className='d-flex justify-content-center'>
                      <Link  target="_blank" to="https://ca.winshakes.com">
                        <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
                        <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} />
                      </Link>
                    </div>
                  <div className="d-flex justify-content-center">
                  <H4 >{selected === "simpleLogin" ? "" :
                   "Sign In With Shake Shake"}
                   </H4>
                  </div>
                 
                  {/* <P>{"Enter your email & password to login"}</P> */}
                  <FormGroup className="mt-3">
                    
                    {/* <Label className="col-form-label">User Name</Label> */}
                    <Input placeholder="User Name" className="form-control" type="text" onChange={(e) => setUsername(e.target.value)}  />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    {/* <Label className="col-form-label">{Password}</Label> */}
                    <div className="position-relative">
                      <Input  placeholder="Password" className="form-control" type={togglePassword ? "text" : "password"} onChange={(e) => setPassword(e.target.value)}  />
                      <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                        <span className={togglePassword ? "" : "show"}>
                          {togglePassword ?<svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                          <path d="M8.8725 4.75L11.25 7.12V7C11.25 6.40326 11.0129 5.83097 10.591 5.40901C10.169 4.98705 9.59674 4.75 9 4.75H8.8725ZM5.6475 5.35L6.81 6.5125C6.7725 6.67 6.75 6.8275 6.75 7C6.75 7.59674 6.98705 8.16903 7.40901 8.59099C7.83097 9.01295 8.40326 9.25 9 9.25C9.165 9.25 9.33 9.2275 9.4875 9.19L10.65 10.3525C10.1475 10.6 9.5925 10.75 9 10.75C8.00544 10.75 7.05161 10.3549 6.34835 9.65165C5.64509 8.94839 5.25 7.99456 5.25 7C5.25 6.4075 5.4 5.8525 5.6475 5.35ZM1.5 1.2025L3.21 2.9125L3.5475 3.25C2.31 4.225 1.335 5.5 0.75 7C2.0475 10.2925 5.25 12.625 9 12.625C10.1625 12.625 11.2725 12.4 12.285 11.995L12.6075 12.31L14.7975 14.5L15.75 13.5475L2.4525 0.25M9 3.25C9.99456 3.25 10.9484 3.64509 11.6517 4.34835C12.3549 5.05161 12.75 6.00544 12.75 7C12.75 7.48 12.6525 7.945 12.48 8.365L14.6775 10.5625C15.8025 9.625 16.7025 8.395 17.25 7C15.9525 3.7075 12.75 1.375 9 1.375C7.95 1.375 6.945 1.5625 6 1.9L7.6275 3.5125C8.055 3.3475 8.5125 3.25 9 3.25Z" fill="#7F7F7F"/>
                          </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
                          <path d="M9 3.75C8.40326 3.75 7.83097 3.98705 7.40901 4.40901C6.98705 4.83097 6.75 5.40326 6.75 6C6.75 6.59674 6.98705 7.16903 7.40901 7.59099C7.83097 8.01295 8.40326 8.25 9 8.25C9.59674 8.25 10.169 8.01295 10.591 7.59099C11.0129 7.16903 11.25 6.59674 11.25 6C11.25 5.40326 11.0129 4.83097 10.591 4.40901C10.169 3.98705 9.59674 3.75 9 3.75ZM9 9.75C8.00544 9.75 7.05161 9.35491 6.34835 8.65165C5.64509 7.94839 5.25 6.99456 5.25 6C5.25 5.00544 5.64509 4.05161 6.34835 3.34835C7.05161 2.64509 8.00544 2.25 9 2.25C9.99456 2.25 10.9484 2.64509 11.6517 3.34835C12.3549 4.05161 12.75 5.00544 12.75 6C12.75 6.99456 12.3549 7.94839 11.6517 8.65165C10.9484 9.35491 9.99456 9.75 9 9.75ZM9 0.375C5.25 0.375 2.0475 2.7075 0.75 6C2.0475 9.2925 5.25 11.625 9 11.625C12.75 11.625 15.9525 9.2925 17.25 6C15.9525 2.7075 12.75 0.375 9 0.375Z" fill="#7F7F7F"/>
                          </svg>}
                          
                        </span>
                      </div>
                    </div>
                  </FormGroup>
                  <div className="position-relative form-group mb-0">
                    <div className="checkbox">
                      <Input id="checkbox1" type="checkbox" />
                      {/* <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label> */}
                    </div>
                    {/* <a className="" href="/resetPassword">
                      {ForgotPassword}
                    </a> */}
                    
                    <Btn  attrBtn={{ color: "primary", className: "d-block w-100 mt-2",style:{height:"40px",fontWeight:"600"}, onClick: (e) => loginAuth(e) }}>
                      {isLoading?<Loader/>:"Sign in"}
                      </Btn>
                  </div>
                  <OtherWay />
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;
