import React, { Fragment,useEffect,useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs ,Btn,Spinner } from '../../AbstractElements';
import { Link, useNavigate, useParams } from 'react-router-dom';
//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// rsuite
import { Input, Whisper, Tooltip,SelectPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
// data table
import DataTable from 'react-data-table-component';
// date  format
import dateFormat from "dateformat";
import 'react-datepicker/dist/react-datepicker.css';
import Details_Redeem from './details';

const style2 = { width: 60, fontSize: 14, padding: 4 };
const BaseApi=api_Routes.redeemRequest.view;

function RedeemRequest (){

    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [ totalItems, setTotalItems ] = useState(0)
    const [statuss, setstatuss] = useState([]);
    const [pay, setpay] = useState([]);
    const permissions = JSON.parse(localStorage.getItem('permission'));



    useEffect(()=>{
      if (!permissions || !permissions.includes('redeem_request.get-all')) {
        navigate(`/Forbidden`)
    } else {
      get_pay()
      get_status()
      get_redeem()
    }
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    },[values_Filter,page,perPage])

    const get_status = () => {
      const response = { data: [
      { name: "pending", id: 1 },
      { name: "accepted ", id: 2 },
      { name: "rejected ", id: 3 },
    ]};
      setstatuss([]);
    response.data.forEach(ele => {
    
      setstatuss(prev => [...prev, {
        label: ele.name,
        value: ele.id
      }]);
     
    });}

    const get_pay = () => {
      const response = { data: [
      { name: "Paypal", id: 1 },
      { name: "Credit Card ", id: 2 },
      { name: "Bank Account ", id: 3 },
    ]};
      setpay([]);
    response.data.forEach(ele => {
    
      setpay(prev => [...prev, {
        label: ele.name,
        value: ele.id
      }]);
     
    });}

    const get_redeem = async()=>{
        let url = BaseApi + '?1=1';

        if ( values_Filter.status  ) {
        url = url + `&status=${values_Filter.status}`;
      }
        if ( values_Filter.payment  ) {
        url = url + `&payment_method_id=${values_Filter.payment}`;
      }
        setIsLoading(true)

     const {response, message} = await Helper.Get({
          
        url: url,
          hasToken:true,
            data:{results:perPage,page:page,
              keywords:values_Filter.keywords,
             
            }
        })

                if(response){

            setData_Table([])
            setTotalItems(response.meta.total)

            response.data.forEach(elem => {
                setData_Table(prev=>[...prev,{
                    id: <img className='theme-text user-photo ' src={elem.user.photo} alt='User Photo' />,
                    user_name:elem.user.username,  
                    amount: elem.amount,
                    balance: elem.balance,
                    date:dateFormat(elem.created_at,"dddd, mmmm dS, yyyy"),

                    status: elem.status == 1 ? (
                      <span className={`badge badge-light-primary `}  style={{padding:"8px 13px",fontSize: '12px' , fontWeight:'700' , fontFamily:'revert'}}>Pending</span>
                    ) : elem.status == 2 ? (
                      <span className={`badge badge-light-success `}  style={{padding:"8px 10px",fontSize: '12px' , fontWeight:'700' , fontFamily:'revert'}}>Accepted</span>
                    ) : (
                      <span className={`badge badge-light-danger `}  style={{padding:"8px 10px",fontSize: '12px' , fontWeight:'700' , fontFamily:'revert'}}>Rejected </span>
                    ),
                    paymentmethod: elem.payment_method_id == 1 ? (
                      <span className={`badge badge-light-info `} style={{padding:"8px 30px",fontSize: '12px' , fontWeight:'700' , fontFamily:'revert'}}>Paypal</span>
                    ) : elem.payment_method_id == 2 ? (
                      <span className={`badge badge-light-info `}  style={{padding:"8px 18px",fontSize: '12px' , fontWeight:'700' , fontFamily:'revert'}}>Credit Card</span>
                    ) : (
                      <span className={`badge badge-light-info `}  style={{padding:"8px 10px",fontSize: '12px' , fontWeight:'700' , fontFamily:'revert'}}>Bank Account </span>
                    ),
                     action: (
                          <div>
                            <span>
                              <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>navigate(`/redeem-request/${elem.id}/Shake`)}}> Details </Btn>
                            </span>
                          </div>
                              ),
                        }])
                
            });
            setCol_Table([{
                    name: 'Photo',
                    selector: row => row['id'],
                    sortable: true,
                    width:"10%",
                    center: false,
                },
                {
                    name: 'User',
                    selector: row => row['user_name'],
                    sortable: true,
                    width:"12%",
                    center: true,
                },
                {
                    name: 'Amount',
                    selector: row => row['amount'],
                    sortable: true,
                    width:"10%",
                    center: true,
                },
                {
                    name: 'Balance',
                    selector: row => row['balance'],
                    sortable: true,
                    width:"10%",
                    center: false,
                },
                {
                    name: 'Created Date',
                    selector: row => row['date'],
                    sortable: true,
                    width:"22%",
                    center: false,
                },
                {
                    name: 'Status',
                    selector: row => row['status'],
                    sortable: true,
                    width:"10%",
                    center: false,
                },
                {
                    name: 'Payment Method',
                    selector: row => row['paymentmethod'],
                    sortable: true,
                    width:"13%",
                    center: false,
                },
                {
                    name: 'Action',
                    selector: row => row['action'],
                    sortable: true,
                    center: true,
                    width: '10%',
                  },
               
                
          ])
          setIsLoading(false)
        }else{
            console.log(message);
        }

    }
            /// Change Pages To Api
    const handleChangePage = (value)=>{
        console.log("per_pages",value);
        setPerPage(value)
    }
    const handlePage =(e)=>{
        setPage(e)
    }
    const handleChange = (key, value) => {
    setValues(prev => ({ ...prev, [key]: value }));
     }

    const handleFilter = ()=>{
    setFilter_Values(values)
    }

        return( id==":id" ? <>

        <Breadcrumbs parent="Redeem Rquest" title="List" mainTitle="Redeem Request " />
        <Container className='my-2' fluid={true}>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardBody>
                            <Row>
              <Col className='d-flex justify-content-center p-2 ' sm={12} lg={4} md={4} xl={4}>
                <Whisper trigger="focus" speaker={<Tooltip>Keywords</Tooltip>}>
                  <Input onChange={(e)=>{handleChange("keywords",e)}} 
                        placeholder="Search by User Name , Name , Amount  " />
                </Whisper>

              </Col>  

             <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                <SelectPicker placeholder="Search By status" 
                 onChange={(e)=>{handleChange("status",e)}}
                        style={{ width: "100%" }}
                          data={statuss} 
                        //  loading={isLoading.boxes} 
                         />
              </Col>

             <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={4}>
                <SelectPicker placeholder="Search By Payment Method" 
                 onChange={(e)=>{handleChange("payment",e)}}
                        style={{ width: "100%" }}
                          data={pay} 
                        //  loading={isLoading.boxes} 
                         />
              </Col>
             
              <Col className='d-flex align-items-center justify-content-end p-2' sm={12} xs={12} lg={1} md={1} >
                <div onClick={()=>{handleFilter()}}>
                  <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                </div>
              </Col>

                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        <Container fluid={true}>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardBody>
                            <Fragment>
                                <DataTable
                                        data={data_table}
                                        columns={col_table}
                                        striped={true}
                                        center={true}
                                        progressPending={isLoading}
                                        progressComponent={<div className="loader-box">
                                                            <Spinner attrSpinner={{ className: "loader-26" }} />
                                                        </div>}
                                        pagination
                                        paginationServer
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[10,20,30,50,100]}
                                        responsive
                                        paginationTotalRows={totalItems}
                                        paginationServerOptions={()=>{}}
                                        onChangePage={(e)=>{handlePage(e)}}
                                        onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                                />
                            </Fragment>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    
   
    </>:<Details_Redeem  id={id} />
    );

}
export default RedeemRequest;