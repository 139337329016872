import React, { Fragment, useCallback, useState ,useEffect} from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 } from '../../../AbstractElements';
import { dummytabledata, tableColumns } from '../../../Data/Table/Defaultdata';
import { Media } from 'reactstrap';
import { Image } from '../../../AbstractElements';
const DataTableComponent = ({data_api}) => {
    console.log(data_api);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [data, setData] = useState(dummytabledata);
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    useEffect(()=>{
            data_api.forEach(elem=>{
                setData_Table(prev=>[...prev,{
                    name:<Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${elem.photo}`, alt:`${elem.name}` }} />
                        <Media body className="align-self-center">
                            <div>{elem.translations["en"].name}</div>
                        </Media>
                    </Media>,
                    category:elem.name,
                    brand:elem.brand.name,
                    sellprice:elem.sellprice,
                    is_stock:elem.is_stock,
                    quantity:elem.quantity,
                    ratings:elem.ratings
                }])
            })
            setCol_Table([{
                name: 'Name',
                selector: row => row['name'],
                sortable: true,
                center: false,
            },
            {
                name: 'Category',
                selector: row => `${row.category}`,
                sortable: true,
                center: true,
            },
            {
                name: 'Brand',
                selector: row => `${row.brand}`,
                sortable: true,
                center: true,
            },
            {
                name: 'Is Stock',
                selector: row => `${row.is_stock}`,
                sortable: true,
                center: true,
            },
            {
                name: 'Quantity',
                selector: row =>  `${row.quantity}`,
                sortable: true,
                center: true,
            },
            {
                name: 'Price',
                selector: row => `${row.sellprice}`,
                sortable: true,
                center: true,
            },
            // {
            //     name: 'Priority',
            //     selector: row => row['priority'],
            //     sortable: true,
            //     center: true,
            // },
            {
                name: 'Rating',
                selector: row =>  `${row.ratings}`,
                sortable: true,
                center: true,
            }])
    },[data_api])
    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
        console.log(state.selectedRows)
    }, []);

    const handleDelete = () => {
        if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
            setToggleDelet(!toggleDelet);

            setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
            setSelectedRows('');
        }
    };
    return (
        <Fragment>
            {(selectedRows.length !== 0) &&
                <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
                    <Btn attrBtn={{ color: 'danger', onClick: () => handleDelete() }}>Delete</Btn>
                </div>
            }
            <DataTable
                // data={data}
                // columns={tableColumns}
                data={data_table}
                columns={col_table}
                striped={true}
                center={true}
                pagination
                responsive
                selectableRows
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleDelet}
            />
        </Fragment>
    )
}
export default DataTableComponent