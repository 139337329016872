import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { H5, UL, LI } from '../../../AbstractElements';
import { Earning, Order, OrderOverviewTitle, Refunds } from '../../../Constant';
import { LightCardData2 } from '../../../Data/DefaultDashboard';
import { OptionsOverView, OptionsOverViewBarChart } from '../../../Data/Ecommerce/Chart';
import ReactApexChart from 'react-apexcharts';
import LightCardBox from '../Default/LightCardBox';
import {Box7} from '../../Statistics/chart'


const OrderOverview = () => {
  return (
    <Card>
    <CardHeader className='card-no-border'>
      <H5 >Order Per Status</H5>
    </CardHeader>
    <CardBody className='pt-0'>
      <Row className='m-0 overall-card'>
        <Col xl='9' md='8' sm='7' className='box-col-7 p-0'>
          <div className='chart-right'style={{marginTop:"40px",marginRight:"-130px"}}>
            <Row>
              <Col xl='12'>
                <CardBody className='p-0'>
                  <UL attrUL={{ horizontal: true, className: 'd-flex balance-data' }}>
                    <LI>
                      <span className='f-light ms-1'style={{color:"#54BA4A"}}>Pending</span>
                    </LI>
                    <LI>
                      <span className='f-light ms-1'style={{color:"#7064F5"}}>Delivery </span>
                    </LI>
                    <LI>
                      <span className='f-light ms-1'style={{color:"#FF5733"}}>Delivered</span>
                    </LI>
                    <LI>
                      <span className='f-light ms-1'style={{color:"#AB47BC"}}>Rejected</span>
                    </LI>
                    <LI>
                      <span className='f-light ms-1'style={{color:"#F9A825"}}>Canceled</span>
                    </LI>
                    <LI>
                      <span className='f-light ms-1'style={{color:"#0077FF"}}>Sold</span>
                    </LI>
                  </UL>
                  <div className='current-sale-container order-container'>
                    <ReactApexChart className='overview-wrapper' type='line' width={"110%"} height={300} options={Box7().options} series={Box7().series} />
                    <div className='back-bar-container'>
                      {/* <ReactApexChart type='bar' height={180} options={OptionsOverViewBarChart.options} series={OptionsOverViewBarChart.series} /> */}
                    </div>
                  </div>
                </CardBody>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </CardBody>
  </Card>
  );
};

export default OrderOverview;
