import React, { Fragment,useEffect,useState , useContext, useRef} from 'react';
import { Container, Row, Col, Card, CardBody,Media } from 'reactstrap';
import { Breadcrumbs ,Btn, H4,Image,Spinner } from '../../AbstractElements';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomizerContext from '../../_helper/Customizer';
//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// rsuite
import { CheckPicker,Input, Whisper, Tooltip,SelectPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
// data table
import DataTable from 'react-data-table-component';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-lightbox/style.css';
import ChatPage from './details';

const style2 = { width: 60, fontSize: 14, padding: 4 };
const BaseApi=api_Routes.converstaions.view;


function Converstaions (){

    const {id} = useParams()
    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);
    const [users, setusers] = useState([])
    const [toggleDelet, setToggleDelet] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [ totalItems, setTotalItems ] = useState(0)
    const permissions = JSON.parse(localStorage.getItem('permission'));
    const [isLoadingUser, setIsLoadingUser] = useState({
      userFirst:true,
      userSecond:true,
    })
    const [users_1 , setUser_1] = useState([])
    const [users_2 , setUser_2] = useState([])
    const controllerRef = useRef(null)
    useEffect(()=>{
      const controller = new AbortController()
      const signal = controller.signal
        if (!permissions || !permissions.includes('conversation.get-all')) {
           navigate(`/Forbidden`)
       } else {
        get_conv(signal)
        get_user(signal)
      }
      return()=>{
        controller.abort()
      }
   
    },[values_Filter,page,perPage])

    const get_user = async(signal )=>{

      const {response, message} = await Helper.Get_Abort({
          url:api_Routes.user.view,
          signal:signal,
          hasToken:true,
      })
      if(response){
          setUser_1(response.data)
          setUser_2(response.data)
          setIsLoadingUser(prev=>({
            userFirst:false,
            userSecond:false
          }))
      }else{
          console.log(message);
      }
      }

      const get_conv = async(signal)=>{

        let url = BaseApi + '?1+1';

        if (values_Filter.user_id ) {
          url = url + `&first_user=${values_Filter.user_id}`;
        }
        if (values_Filter.user_id1 ) {
          url = url + `&second_user=${values_Filter.user_id1}`;
        }

          setIsLoading(true)
          const {response, message} = await Helper.Get_Abort({
            
          url: url,
            hasToken:true,
            signal:signal,
            data:{results:perPage,
                  page:page,
                  keywords:values_Filter.keywords, }
          })

          if(response){

              setData_Table([])
                setTotalItems(response.meta.total)
    
                response.data.forEach(elem => {
                    setData_Table(prev=>[...prev,{
                      id:elem.id,
                      userReceiver : elem.second_name,
                      userSender : elem.first_name,
                                  action: (
                                    <div>
                                      <span>
                                        <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>navigate(`/Chat/${elem.id}/order`)}}>
                                            Preview
                                        </Btn>
                                      </span>
  
                                    </div>
                                  ),
                            }])
                    
                });
                setCol_Table([{
                        name: 'ID',
                        selector: row => row['id'],
                        sortable: true,
                        width:"20%",
                        center: false,
                    },
                    {
                      name: 'First User',
                      selector: row => row['userSender'],
                      sortable: true,
                      width:"30%",
                      center: true,
                  },
                {
                      name: 'Second User',
                      selector: row => row['userReceiver'],
                      sortable: true,
                      width:"33%",
                      center: true,
                  },
                    {
                        name: 'Action',
                        selector: (row) => row.action,
                        sortable: true,
                        width: '50%px',
                        center: true,
                      },
                    
              ])
              setIsLoading(false)
            }else{
              
                console.log(message);
            }
      }
      const handleChangePage = (value)=>{
          console.log("per_pages",value);
          setPerPage(value)
      }
      const handlePage =(e)=>{
          setPage(e)
      }
      const handleFilter = ()=>{
          setFilter_Values(values)
          }

      const handleChange = (key, value) => {
              setValues(prev => ({ ...prev, [key]: value }));   
          }
    const onSearch = async (e,key)=>{
      if(key == "first"){
        setIsLoadingUser(prev=>({...prev, userFirst:true}))
      }else {
        setIsLoadingUser(prev=>({...prev, userSecond:true}))
      }
        if(controllerRef.current){
            controllerRef.current.abort()
        }
        controllerRef.current = new AbortController()
        const signal = controllerRef.current.signal
        const {response,message} = await Helper.Get_Abort({
            url:  api_Routes.user.view ,
            signal:signal,
            data:{keywords:e,results:20},
            hasToken:true
        })
        if(response){
          if(key == "first"){
            setUser_1(response.data)
            setIsLoadingUser(prev=>({...prev, userFirst:false}))
          }else {
            setUser_2(response.data)
            setIsLoadingUser(prev=>({...prev, userSecond:false}))
          }
        }

    }
    return (
          
        <Fragment>
              <Breadcrumbs parent="Conversations" title="List" mainTitle="conversation" />
              
              <Container className='my-2' fluid={true}>
              <Row>
                <Col sm="12">
                  <Card>
                   
                    <CardBody>
                    <Row  >
                      <Col className='d-flex justify-content-center p-2' sm={12} lg={11} md={11} xl={11}>
                        <Whisper trigger="focus" speaker={<Tooltip>Name</Tooltip>}>
                          <Input onChange={(e)=>{handleChange("keywords",e)}} 
                                style={{ width: "100%" }} placeholder="Search By Name" />
                        </Whisper>
                      </Col>  
                      {/* <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                          <SelectPicker placeholder="Search By First User" 
                          onSearch={(e)=>{
                            if(e != ""){
                              onSearch(e,"first")
                            }
                          }} onChange={(e)=>{handleChange("user_id",e)}}
                                  style={{ width: "100%" }}
                                    data={users_1.map((ele)=>( {
                                      label:ele.name, // Convert user_id to integer
                                      value: ele.id   // Convert user_id to integer
                                  }))} 
                                    
                                   loading={isLoadingUser.userFirst} 
                                  />
                      </Col>
                      <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                          <SelectPicker placeholder="Search By Second User" 
                          onSearch={(e)=>{
                            if(e != ""){
                              onSearch(e,"second")
                            }
                          }} onChange={(e)=>{handleChange("user_id1",e)}}
                                  style={{ width: "100%" }}
                                    data={users_2.map((ele)=>( {
                                      label:ele.name, // Convert user_id to integer
                                      value: ele.id   // Convert user_id to integer
                                  }))} 
                                    
                                   loading={isLoadingUser.userSecond} 
                                  />
                      </Col> */}
                     
                      <Col className='d-flex align-items-center justify-content-center p-2' sm={12} xs={12} lg={1} md={1} >
                        <div onClick={()=>{handleFilter()}}>
                          <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                        </div>
                      </Col>
                    </Row>
      
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>  
              <Container fluid={true}>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                      <Fragment>
                          
                          <DataTable
                              // data={data}
                              // columns={tableColumns}
                              data={data_table}
                              columns={col_table}
                              striped={true}
                              center={true}
                              progressPending={isLoading}
                              progressComponent={<div className="loader-box">
                                              <Spinner attrSpinner={{ className: "loader-26" }} />
                                          </div>}
                              pagination
                              paginationServer
                              paginationPerPage={perPage}
                              paginationRowsPerPageOptions={[10,20,30,50,100]}
                              responsive
                              // selectableRows
                              paginationTotalRows={totalItems}
                              paginationServerOptions={()=>{}}
                              onChangePage={(e)=>{handlePage(e)}}
                              onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                              onSelectedRowsChange={(e)=>{
                                setSelectedRows([])
                                e.selectedRows.forEach(ele =>{
                                  setSelectedRows(prev=>[...prev,ele.id])
                                })
                              }}
                              clearSelectedRows={toggleDelet}
                              
                          />
                      </Fragment>
        
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            
          </Fragment>
             
            );
}
export default Converstaions;