import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { H4, Image } from '../../../AbstractElements';
import { MarginLeft, PaddingLeft, TapUpBalance, ThisMonth, TotalBalanceTitle } from '../../../Constant';
import { Helper } from '../../../tools/helper';
import { api_Routes } from '../../../api_Routes';
import CountUp from 'react-countup';
import WidgetImg from '../../../assets/images/dashboard-2/widget-img.png';
import MobileGif from '../../../assets/images/dashboard-2/mobile.gif';
import user from "../../../assets/images/logo/Userr.svg"
import order from "../../../assets/images/logo/order (2).svg"
import product from "../../../assets/images/logo/product.svg"
import shake from "../../../assets/images/logo/Shake user.svg"
import { Height } from '@mui/icons-material';
const TotalBalance = () => {

  const [detailsInfo, setDetailsInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
      getDetails();
    }, []);

    const getDetails = async () => {
   
      const { response, message } = await Helper.Get({
        url: api_Routes.statistics.view1,
        hasToken: true,
      });
      if (response) {
        console.log(response.data);
        setDetailsInfo(response.data);
        setIsLoading(false);
      } else {
        console.log(message);
      }
    };
  return (<>
   <Card className='o-hidden'>
  <CardBody className='balance-widget'>

    <Row >
      <Col  xs={12} sm={12} md={6} lg={6} xl={6} >
        <div style={{display:"flex"  , alignItems:"center"}}>
        <Image attrImage={{ className: 'me-3  ', style: { marginLeft: "10px", height:"40px",width:"40px" }, src: user, alt: '' }} />
        <span className='f-w-800 f-light f-18 '>Total Users</span>
          <CountUp style={{fontSize:"21px" , marginLeft:"15px"}} prefix='' duration={5} start={0} separator=',' end={detailsInfo.users_total} />
        </div>
      </Col>

      <Col   xs={12} sm={12} md={6} lg={6} xl={6}>
      <div style={{display:"flex"  , alignItems:"center"}}>
        <Image attrImage={{ className: 'me-3  ', style: { marginLeft: "10px",height:"40px",width:"40px"  }, src: product, alt: '' }} />
        <span className='f-w-800 f-light '>Total Products</span>
          <CountUp style={{fontSize:"21px" , marginLeft:"15px"}} prefix='' duration={5} start={0} separator=',' end={detailsInfo.products_total} />
          </div>
      </Col>
    </Row>
  </CardBody>
</Card>

  <Card className='o-hidden'>
   <CardBody className='balance-widget'>
    <Row>

      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
      <div style={{display:"flex"  , alignItems:"center"}}>
        <Image attrImage={{ className: 'me-3  ', style: { marginLeft: "10px", height:"40px",width:"40px"  }, src: shake, alt: '' }} />
        <span className='f-w-800 f-light '>Total Shakes</span>
      
          <CountUp style={{fontSize:"21px" , marginLeft:"15px"}} prefix='' duration={5} start={0} separator=',' end={detailsInfo.userShakes_total} />
          </div>
      </Col>

      <Col  xs={12} sm={12} md={6} lg={6} xl={6}>
      <div style={{display:"flex"  , alignItems:"center"}}>
        <Image attrImage={{ className: 'me-3  ', style: { marginLeft: "10px", height:"40px",width:"40px"  }, src: order, alt: '' }} />
        <span className='f-w-800 f-light '>Total Orders</span>
          <CountUp style={{fontSize:"21px" , marginLeft:"15px"}} prefix='' duration={5} start={0} separator=',' end={detailsInfo.orders_total} />
          </div>
      </Col>
      
    </Row>

  </CardBody>
</Card>

   
    </>
  );
};

export default TotalBalance;
