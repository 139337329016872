import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Breadcrumbs, Btn ,Spinner } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
//files

import { SelectPicker  , Loader} from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
// Editor Wyswigy 

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useParams } from 'react-router';
import { Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Box} from "@mui/material";




const Add_User = () => {
    const { id } = useParams()
    const [msgPasswordStatus,setMsgPasswordStatus] = useState("Password should be 8 characters and strong.")
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingDetails, setIsLoadingDetails] = useState(false)
    const permissions = JSON.parse(localStorage.getItem('permission'));
    const [selectedRole, setSelectedRole] = useState(null);
    const [formData, setFormData]  = useState(id == ":id"?{
        username:"",
        gender:"",
        first_name:"",
        second_name:"",
        phone:"",
        phone_code:"",
        password:"",
        role_id:"",
        last_login:"2023-11-20",
        verified:"1",
        active:"1",
        facebook_account:"",
        instagram_account:"",
        tiktok_account:"",
        twitter_account:"",

    }:{
        username:"",
        phone_code:"",
        gender:0,
        role_id:"",
        last_login:"2023-11-20",
        verified:true,
        active:true,
        facebook_account:"",
        instagram_account:"",
        tiktok_account:"",
        twitter_account:"", 
    })
    useEffect(()=>{
        if (id === ":id" && (!permissions || !permissions.includes('user.add'))) {
            navigate(`/Forbidden`);
        } else {
            if(id != ":id"){
                get_detailed()
            }
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    },[])
    const get_detailed = async()=>{
        setIsLoadingDetails(true)
        const {response, message} = await Helper.Post({
            url : api_Routes.user.getOne(id),
            hasToken :true
          })
          if(response){
            
           
            setFormData({username:response.data.username,
            first_name:response.data.first_name,
            gender:response.data.gender,
            second_name:response.data.second_name,
            phone:response.data.phone,
            phone_code:response.data.phone_code,
            role_id:response.data.role?.id,
            last_login:"2023-11-20",
            verified:response.data.verified,
            active:response.data.active,
            facebook_account:response.data.facebook_account,
            instagram_account:response.data.instagram_account,
            tiktok_account:response.data.tiktok_account,
            twitter_account:response.data.twitter_account,
        })
            

            console.log(formData)
            setIsLoadingDetails(false)
          }else{
            if(typeof(message) === "string"){
              toast.error(message)
            }else{
              Object.keys(message).map((i)=>{
                toast.error(`${i} : ${message[i][0]}`)
              })}
            setIsLoadingDetails(false)
          }
    }
    const handleChange = (key, value) => {
        let value_temp = ""
        if (key === "verified" || key === "active") {
            value_temp = value ? "1" : "0"
        } else {
            value_temp = value
        }
       
        setFormData(prev => ({ ...prev, [key]: value_temp }))
    }
    const handleChange1 = (e,key)=>{
        
        // if(key =="password"){
        //     const password_validation = e.target.value.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/) 
        //     const password_contain_lower = e.target.value.search(/[a-z]/) 
        //     const password_contain_uppercase = e.target.value.search(/[A-Z]/) 
        //     const password_contain_number = e.target.value.search(/[0-9]/)   
        //     if(e.target.value.length>0){
        //         if(e.target.value.length <7 || (password_validation<0 && password_contain_lower<0 && password_contain_uppercase<0 &&password_contain_number<0)){
        //             console.log("1");
        //             setMsgPasswordStatus("Weak")
        //         }else if(password_contain_lower<0 || password_contain_uppercase<0 || password_contain_number<0){
        //             console.log("2");
        //             setMsgPasswordStatus("Meduim") 
        //         }else if(e.target.value.length >7 &&password_validation==0){
        //             console.log("3");
        //             setMsgPasswordStatus("Strong") 
        //         }
        //     }else{
        //         setMsgPasswordStatus("Password should be 8 characters and strong.")
        //     }
         
        // }
        if(key =="password"){
            const password_validation = e.target.value.search(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/) 
            const password_contain_lower = e.target.value.search(/[a-z]/) 
            const password_contain_uppercase = e.target.value.search(/[A-Z]/) 
            const password_contain_number = e.target.value.search(/[0-9]/)   
            if(e.target.value.length>0){
                console.log("value",password_validation)
                console.log("upper",password_contain_lower)
                console.log("low",password_contain_uppercase)
                console.log("num", password_contain_number)
                // if(e.target.value.length <7 || (password_validation<0 && password_contain_lower<0 && password_contain_uppercase<0 && password_contain_number<0)){
                //     setMsgPasswordStatus("Weak")
                // }else if(password_contain_lower<0 || password_contain_uppercase<0 || password_contain_number<0){
                //     setMsgPasswordStatus("Meduim") 
                // }else if(e.target.value.length >7 &&password_validation==0){
                //     setMsgPasswordStatus("Strong") 
                // }
            }else{
                setMsgPasswordStatus("Password should be 8 characters and strong.")
            }
         
        }
        setFormData(prev=>({...prev,[key]:e.target.value}))
    }
    const handleSubmit = async () =>{
        if(id==":id"){
            if (!formData.username || !formData.first_name || !formData.second_name   || !formData.role_id || (!formData.gender && formData.gender!=0 )) {
                toast.error("Please fill in all required fields (UserName, First Name, Second Name, Role, Gender,Password ).");
                setIsLoading(false);
                return;
            }
        }else{
            if (!formData.username  || !formData.role_id || (!formData.gender && formData.gender!=0 )  ) {
                toast.error("Please fill in all required fields (UserName, Role, Gender ).");
                setIsLoading(false);
                return;
            }
        }
        const phone = formData.phone.split("")
        if(formData.phone_code == "+963"){
         
                if(formData.phone.length == 10 && formData.phone_code  == "+963" && phone[0] == "0" && phone[1]=="9" && formData.phone.length == 10){
                    sendAfterValidation()
                }else if(formData.phone.length == 9  && formData.phone_code  == "+963" && phone[0]=="9" && formData.phone.length == 9){
                    sendAfterValidation()
                }else{
                    toast.error("The phone number must be like (09 xx xxx xxx) or (9xx xxx xxx).");
                    setIsLoading(false);
                    return;
    
                }
        }else if(formData.phone_code  == "+1"){
            if(formData.phone.length == 10 || formData.phone.length == 11){
                    sendAfterValidation()
                
            }else{
                toast.error("The phone number must be like (0 xxx xxx xxxx) or (xxx xxx xxxx).");
                    setIsLoading(false);
                    return;
                
            }
        }
       
    }
    const sendAfterValidation = async()=>{
        setIsLoading(true)
        var form_data = new FormData();
        Object.keys(formData).forEach((key)=>{
            if(key === "file"){
                form_data.append("file", formData.file);
            }else {
                form_data.append(key,formData[key])
            }
            
          })
          if(id ==":id"){
            form_data.append("password_confirmation",formData.password)
            form_data.append("_method","PUT")
          }
          
          const {response, message} = await Helper.Post({
            url : id==":id"? api_Routes.user.add:api_Routes.user.update(id),
            data :form_data,
            hasToken :true
          })
          if(response){

            if (id === ":id") {
                toast.success("Successfully added !!");
                navigate(`/user-list/order`);
              } else {
                toast.success("Successfully edited !!");
                navigate(`/user-list/order`);
              }
                setIsLoading(false)
          }else{
            if(typeof(message) === "string"){
              toast.error(message)
            }else{
              Object.keys(message).map((i)=>{
                toast.error(`${i} : ${message[i][0]}`)
              })}
            setIsLoading(false)
          }

    }
    const [files, setFiles] = useState([]);

    function deleteFile(e) {
        setFiles([]);
        return files

    }
    const onFilesChange = (files) => {
        setFiles(files)
        setFormData(prev=>({...prev,["file"]:files[0]}))
        console.log(files);
    }
    const onFilesError = (error, file) => {
        setFormData(prev=>({...prev,["file"]:""}))
        setFiles(file)
    }
    
    
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Add User" parent="User" title="Add" />
            {/* Basic Info */}
            {isLoadingDetails?<div className="loader-box">
                            <Spinner attrSpinner={{ className: "loader-26" }} />
                        </div>:<>
                <Container  className='mb-3' fluid={true}>
                
                <Row>
                    <Col sm="12">
                        <Card>
                            <Row>
                                <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                                    <HeaderCard title="basic Info" />
                                </Col>
                           {(permissions.includes('user.edit')) && (
                               <Col className='d-flex justify-content-center align-items-center ' lg={2} xl={2}  md={2} sm={2} xs={2}>
                               <Btn attrBtn={{  className: "h-50", color: 'danger', type: "button", onClick: () => !isLoading && handleSubmit() }} 
                               disabled={isLoading}
                               >
                               {isLoading ? <Loader /> : <i className='fa fa-save'></i>}
                               </Btn>
                              </Col>
                             )}
                            </Row>
                            
                            <CardBody className="add-post">

                                {/* Basic Info */}
                                <Form className="row needs-validation">
                                        <Col sm="12">
                                            <Row>
                                                <Col>
                                              <FormGroup>
                                                    <Label className='me-3' htmlFor="validationCustom01">First Name</Label>
                                                    <Input value={formData.first_name} onChange={(e) => { handleChange("first_name", e.target.value) }} className="form-control" id="validationCustom01" type="text" placeholder="first_name" required="" />
                                                </FormGroup>
                                                </Col>

                                                <Col>
                                              <FormGroup>
                                                    <Label className='me-3' htmlFor="validationCustom01">Second Name</Label>
                                                    <Input value={formData.second_name} onChange={(e) => { handleChange("second_name", e.target.value) }} className="form-control" id="validationCustom01" type="text" placeholder="second_name" required="" />
                                                </FormGroup>
                                                </Col>
                                                
                                                <Col>
                                                <FormGroup>
                                                    <Label className='me-3' htmlFor="validationCustom01">User Name</Label>
                                                    <Input value={formData.username} onChange={(e) => { handleChange("username", e.target.value) }} className="form-control" id="validationCustom01" type="text" placeholder="username" required="" />
                                                </FormGroup>
                                                </Col>
                                                </Row>
                                                
                                                <Row>
                                                    <Col lg="4" sm="12" md="4" xs="12">
                                              
                                                        <FormGroup>
                                                            <div>
                                                             <Label  className='me-3' htmlFor="validationCustom01"> Phone Code</Label>
                                                            </div>
                                                         
                                                            <SelectPicker style={{width:"100%"}}
                                                                value={formData.phone_code}
                                                                data={[
                                                                    { label: "Canada +1", value: "+1" },
                                                                    { label: "Syria +963", value: "+963" },
                                                                ]}
                                                                onChange={(value) => handleChange("phone_code", value)}
                                                                placeholder="Phone Code"
                                                                id="validationCustom03"
                                                            />
                                                        </FormGroup>
                                                   
                                                    </Col>
                                                    <Col lg="8" sm="12" md="8" xs="12">
                                                        <FormGroup>
                                                            <Label className='me-3' htmlFor="validationCustom01">Phone</Label>
                                                            <Input value={formData.phone} onChange={(e) => { handleChange("phone", e.target.value) }} className="form-control" id="validationCustom01" type="text" placeholder="phone" required="" />
                                                        </FormGroup>
                                                    </Col>
                                                
                                                </Row>
                                              
                                               
                                                    <Row>
                                                       
                                                    <Col>
                                                        <FormGroup>
                                                                    <div>
                                                                    <Label className='me-3' htmlFor="validationCustom01">Role</Label>
                                                                    </div>
                                                            
                                                            <SelectPicker 
                                                                style={{ width: "100%" }}
                                                                value={formData.role_id ? formData.role_id.toString() : "Please Enter Role"}
                                                                data={[
                                                                    { label: "admin", value: "1" },
                                                                    { label: "supporter", value: "31" },
                                                                    { label: "user", value: "4" },
                                                                ]} 
                                                                onChange={(value) => handleChange("role_id", value)}
                                                                placeholder="role"
                                                                />
                                                            {selectedRole && <span>{selectedRole.label}</span>}
                                                        </FormGroup>
                                                    </Col>
                                            <Col className='d-flex align-items-end' >
                                                <FormGroup>
                                                    <Label className='me-3' htmlFor="validationCustom01">Active</Label>
                                                    <Switch sx={{color:"#D80621"}} checked={formData.active == "1" ? true : false} onChange={(e) => { handleChange("active", e.target.checked) }} />
                                                </FormGroup>
                                                </Col>
                                                <Col className='d-flex align-items-end' >
                                                <FormGroup>
                                                    <Label className='me-3' htmlFor="validationCustom01">Verified</Label>
                                                    <Switch checked={formData.verified == "1" ? true : false} onChange={(e) => { handleChange("verified", e.target.checked) }} />
                                                </FormGroup>
                                                </Col>
                                                </Row>
                                                
                                                <Row>
                                                    {id==':id' && <Col lg="4" sm="12" md="4" xs="12">
                                                   
                                                        <FormGroup>
                                                            <Label className='me-3' htmlFor="validationCustom01">password</Label>
                                                          
                                                            <Input  onChange={(e) => {  handleChange1(e , "password" ) }} className="form-control" id="validationCustom01" type="text" placeholder="password" required="" />
                                                            <Box style={msgPasswordStatus=="Password should be 8 characters and strong."?{fontSize:"13px",padding:"0 10px",color:"grey"}:{fontSize:"13px",padding:"0 10px"}}>
                                                                {msgPasswordStatus!="Password should be 8 characters and strong." ?
                                                                    <Box sx={{display:"flex",gap:"10px",justifyContent:"center",marginTop:"10px",fontSize:"10px"}}>
                                                                        <Box>
                                                                            <Box sx={msgPasswordStatus=="Weak"?{width:"50px",margin:"auto",height:"5px",borderRadius:"8px",color:"red",background:"red"}:{width:"50px",margin:"auto",height:"5px",borderRadius:"8px",color:"grey",background:"#bdbdbd"}}>
                                                                            </Box>
                                                                            {msgPasswordStatus=="Weak" && 
                                                                                <Box sx={{display:"flex",justifyContent:"center",paddingTop:"5px"}}>
                                                                                    Weak
                                                                                </Box>
                                                                            }
                                                                        </Box>
                                                                        <Box>
                                                                            <Box sx={msgPasswordStatus=="Medium"?{width:"50px",margin:"auto",borderRadius:"8px",height:"5px",color:"orange",background:"orange"}:{width:"50px",margin:"auto",height:"5px",borderRadius:"8px",color:"grey",background:"#bdbdbd"}}>
                                                                            </Box>
                                                                            {msgPasswordStatus=="Medium" && 
                                                                                <Box sx={{display:"flex",justifyContent:"center",paddingTop:"5px"}}>
                                                                                    Medium
                                                                                </Box>
                                                                            }
                                                                        </Box>
                                                                        <Box>
                                                                            <Box sx={msgPasswordStatus=="Strong"?{width:"50px",margin:"auto",height:"5px",borderRadius:"8px",color:"green",background:"green"}:{width:"50px",margin:"auto",height:"5px",borderRadius:"8px",color:"grey",background:"#bdbdbd"}}>
                                                                            </Box>
                                                                            {msgPasswordStatus=="Strong" && 
                                                                                <Box sx={{display:"flex",justifyContent:"center",paddingTop:"5px"}}>
                                                                                    Strong
                                                                                </Box>
                                                                            }
                                                                        </Box>
                                                                    </Box>
                                                                :
                                                                    <Box sx={{paddingTop:"5px"}}>
                                                                        {msgPasswordStatus}
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </FormGroup>
                                                      
                                                    </Col>}
                                                    <Col lg={id==':id'?"8":"12"} sm="12" md={id==':id'?"8":"12"} xs="12">
                                                    <FormControl>
                                                        <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                                            name="controlled-radio-buttons-group"
                                                            value={formData.gender.toString()} // Convert value to string
                                                            onChange={(e) => handleChange("gender", parseInt(e.target.value))}
                                                        >
                                                            <FormControlLabel value="0" control={<Radio />} label="Male" />
                                                            <FormControlLabel value="1" control={<Radio />} label="Female" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                    </Col>

                                                    <Col>
                                                    <FormGroup>
                                                            <Label className='me-3' htmlFor="validationCustom01">Feacebook Account</Label>
                                                            <Input value={formData.facebook_account} onChange={(e) => { handleChange("facebook_account", e.target.value) }} className="form-control" id="validationCustom01" type="text" placeholder="Feacebook Account" required="" />
                                                        </FormGroup>
                                                     </Col>
                                                    <Col>
                                                    <FormGroup>
                                                            <Label className='me-3' htmlFor="validationCustom01">Instagram Account</Label>
                                                            <Input value={formData.instagram_account} onChange={(e) => { handleChange("instagram_account", e.target.value) }} className="form-control" id="validationCustom01" type="text" placeholder="Instagram Accoun" required="" />
                                                        </FormGroup>
                                                     </Col>
                                                    <Col>
                                                    <FormGroup>
                                                            <Label className='me-3' htmlFor="validationCustom01">Tiktok Account</Label>
                                                            <Input value={formData.tiktok_account} onChange={(e) => { handleChange("tiktok_account", e.target.value) }} className="form-control" id="validationCustom01" type="text" placeholder="Tiktok Account" required="" />
                                                        </FormGroup>
                                                     </Col>
                                                    <Col>
                                                    <FormGroup>
                                                            <Label className='me-3' htmlFor="validationCustom01">Twitter Account</Label>
                                                            <Input value={formData.twitter_account} onChange={(e) => { handleChange("twitter_account", e.target.value) }} className="form-control" id="validationCustom01" type="text" placeholder="Twitter Account" required="" />
                                                        </FormGroup>
                                                     </Col>
                                                </Row>
                                            

                                     
                                       
                                        </Col>
                                    </Form>
                                    
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
          
            
            </>}
            
        </Fragment>
    );
};

export default Add_User;