import React from "react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { classes } from "../Data/Layouts";
import { api_Routes } from "api_Routes";
import { Helper } from "tools/helper";
import { toast,ToastContainer } from "react-toastify";
// setup fake backend
import { Helmet } from 'react-helmet-async';
import logo from "../assets/logo.png"

const Routers = () => {
  // const login = useState(JSON.parse(localStorage.getItem("login")))[0];
  const [login] = useState(localStorage.getItem("login"));
  const [authenticated] = useState(localStorage.getItem("authenticated"));
  const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === "compact-wrapper");
  const layout = localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  useEffect(() => {
    let abortController = new AbortController();
    const signal = abortController.signal
    // setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    get_languages(signal)
    return () => {
      abortController.abort();
    };
    
  }, []);
  const get_languages = async (signal)=>{
    const {response, message} = await Helper.Get_Abort({
      url:api_Routes.language.view,
      signal:signal,
      hasToken:true
    })
    if(response){
      const array_lang=[]
      response.data.forEach(ele=>{
          array_lang.push({
            id:ele.id,
            code:ele.code,
            name:ele.name
          })
      })
      localStorage.setItem("lang",JSON.stringify(array_lang))
    }
  }
  return (
    <BrowserRouter basename={"/"}>
      <Helmet >
        <title>Dashboard |Shake Shake  </title>
      </Helmet>
      
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path={"/"} element={<PrivateRoute />}>
            {login || authenticated ? (
              <>
                <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/${layout}`} />} />
                <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/${layout}`} />} />
              </>
             ) : (
              ""
            )} 
            
            <Route path={`/*`} element={<LayoutRoutes />} />
          </Route>

          <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
        </Routes> 
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
