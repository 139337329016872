import React from 'react';
import photo from "./404.b6956104f94c99fa58e2.png"
import { Box, Button, Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";


const photoStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '35%',
    maxHeight: '90%',
};



const Forbidden = () => {
    const navigate = useNavigate() 
    return (<>
        <div style={{ position: 'relative', width: 'auto', height: '78vh', overflow: 'hidden' }}>
                  <img src={photo} alt="Forbidden" style={photoStyle} />
        </div>
        <div>
            <p style={{fontFamily : "Montserrat" , fontSize:'35px' , textTransform: "capitalize" , textAlign:"center" , fontWeight:"800" , color:"#D80621"}}>Page Not Found !</p>
        </div>
        
        <Box sx={{display:"flex",justifyContent:"center"}}>
        <Button onClick={()=>{navigate("/login")}}  sx={{  width:'350px',
                                background:"#d80621",
                                borderRadius:"4px",
                                marginTop:"10px",
                                border:"1px solid  #d80621",
                                color:"white",
                                transition:"0.5s",
                                textTransform:"capitalize !important",
                                fontSize:"16px !important",
                                padding:"5px 40px",
                                "& span path":{
                                    fill:"white",
                                },
                                "&:hover":{
                                    background:"white",
                                    border:"1px solid  #d80621",
                                    color:"#d80621"
                                },
                                "&:hover span path ":{
                                    fill:"#D80621",
                                }
                            }}
                >
                   
                    Back to Login Page
                    </Button>
        </Box>
        
          </>
    );
}

export default Forbidden;