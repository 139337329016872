import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import { MonthlyProfitsGrowth, MonthlyProfitsTitle } from '../../../Constant';
import { MonthlyProfitsChartData } from '../../../Data/Ecommerce/Chart';
import { Box9} from '../../Statistics/chart'

const Fin = () => {
  return (
    <Card>
            <CardHeader className='card-no-border'>
                <H5>Financials</H5>
            </CardHeader>
            <CardBody className='pt-0'>
                <div className='monthly-profit'>
                <ReactApexChart type='donut' height={300} series={Box9().series} options={Box9().options} />
                </div>
            </CardBody>
     </Card>
  );
};

export default Fin;
