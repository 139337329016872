import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, H6, P, Image } from '../AbstractElements';
import logoWhite from '../assets/images/logo/Group 8134.png';
import logoDark from '../assets/images/logo/Group 8134.png';
import { Helper } from 'tools/helper';
import { api_Routes } from 'api_Routes';
import { toast ,ToastContainer } from 'react-toastify';
import { Loader } from 'rsuite';

const Reset_Password = ({ logoClassMain }) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [isLoading, setIsLoading] = useState({
    send_code:false,
    resend:false,
    confirm_code:false,
    reset_password:false,
    buttonDisable:true
  })
  const [isAppendInputs, setIsAppendInputs] = useState(false)
  const [isAppendInputs2, setIsAppendInputs2] = useState(false)
  const [ data, setData ] = useState({
    phone_Code:"+1",
    phone_number:"000-000-0000",
    field_1:"",
    
    password:"",
    password_confirmation:""
  })
  const navigate = useNavigate()
  const handleChange = (key,value) =>{
    setData(prev=>({...prev,[key]:value}))
  }
  const handleSubmitCode = async (isResend) =>{
    if(isResend){
      setIsLoading(prev=>({...prev,["resend"]:true}))
    }else{
      setIsLoading(prev=>({...prev,["send_code"]:true}))
    }

    const {response, message} = await Helper.Post({
      url:api_Routes.verified.by_phone,
      hasToken:true,
      data:{phone_code:data.phone_Code,
            phone :data.phone_number}
    })
    if(response){
      console.log(response.data);
      localStorage.setItem("verified",JSON.stringify({
        user_id:response.data.id,
        code:response.data.code,
        username:response.data.username,
        phone: response.data.phone
      }))
      if(isResend){
        setIsLoading(prev=>({...prev,["resend"]:false}))
      }else{
        setIsLoading(prev=>({...prev,["send_code"]:false}))
      } 
      setIsLoading(prev=>({...prev,["buttonDisable"]:false}))

      setIsAppendInputs2(true)

    }else{
      console.log(message);
      if(typeof message=="string"){
        toast.error(message)
      }else{
        Object.keys(message).forEach(msg=>{
          toast.error(` ${msg} : ${message[msg]} `)
        })
      }
      if(isResend){
        setIsLoading(prev=>({...prev,["resend"]:false}))
      }else{
        setIsLoading(prev=>({...prev,["send_code"]:false}))
      }
    }
  }
  const handleConfirmCode = async()=>{

      setIsLoading(prev=>({...prev,["confirm_code"]:true}))

    const {response, message} = await Helper.Post({
      url:api_Routes.verified.confirm_code,
      hasToken:true,
      data:{
        code:data.field_1,
        user_id:JSON.parse(localStorage.getItem("verified")).user_id,
      }
    })
    if(response){
      console.log(response.data);

      setIsLoading(prev=>({...prev,["confirm_code"]:false}))
      toast.success(response.data.message)
      setIsAppendInputs(true)
    }else{
      if(typeof message=="string"){
        toast.error(message)
      }else{
        Object.keys(message).forEach(msg=>{
          toast.error(`${message[msg]} : ${msg}`)
        })
      }
      setIsLoading(prev=>({...prev,["confirm_code"]:false}))
    }
  }
  const handleResetPassword = async ()=>{
    setIsLoading(prev=>({...prev,["reset_password"]:true}))

    const {response, message} = await Helper.Post({
      url:api_Routes.verified.reset_password,
      hasToken:true,
      data:{
        password:data.password,
        password_confirmation:data.password_confirmation,
        user_id:JSON.parse(localStorage.getItem("verified")).user_id,
      }
    })
    if(response){
      console.log(response.data);
      toast.success("Successfully Changed")

      setIsLoading(prev=>({...prev,["reset_password"]:false}))
      navigate("/login")
    }else{
      if(typeof message=="string"){
        toast.error(message,{position:"bottom-right"})
      }else{
        Object.keys(message).forEach(msg=>{
          toast.error(`${message[msg]} : ${msg}`)
        })
      }
      setIsLoading(prev=>({...prev,["reset_password"]:false}))
    }
  }
  return (
    <Fragment>
      <section>
        <Container className='p-0 login-page' fluid={true}>
          <Row className='m-0'>
            <Col className='p-0'>
              <div className='login-card'>
                <div>
                  
                  <div className='login-main'>
                    <Form className='theme-form login-form'>
                    <div className='d-flex justify-content-center'>
                      <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to="https://winshakes.com">
                        <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
                        <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} />
                      </Link>
                    </div>
                      <H4>Reset Your Password</H4>
                      <FormGroup>
                        <Label className='m-0 col-form-label'>Enter Your Mobile Number</Label>
                        <Row>
                          <Col xs='4' sm='3'>
                            <Input onChange={(e)=>{handleChange("phone_Code",e.target.value)}} className='form-control' type='text' value={data.phone_Code} />
                          </Col>
                          <Col xs='8' sm='9'>
                            <Input onChange={(e)=>{handleChange("phone_number",e.target.value)}} className='form-control' type='text' value={data.phone_number} />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup className='text-end'>
                        <Btn attrBtn={{ className: 'btn-block ', color: 'primary',onClick:()=> handleSubmitCode() }}>
                          {isLoading.send_code?<Loader/>:"Send"}
                          </Btn>
                      </FormGroup>
                    { isAppendInputs2 && <>
                      <FormGroup className='mb-4 mt-4'>
                        <span className='reset-password-link'>
                          If don't receive OTP?  
                          <a onClick={()=>{handleSubmitCode(true)}} className='btn-link text-danger'>
                            Resend {isLoading.resend?<Loader/>:"."}
                          </a>
                        </span>
                      </FormGroup>
                      <FormGroup>
                        <Label>Enter Code</Label>
                        <Row>
                          <Col>
                            <Input onChange={(e)=>{handleChange("field_1",e.target.value)}}  className='form-control text-center opt-text' type='text' value={data.field_1} maxlength='6' />
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup className='text-end'>
                        <Btn disable={isLoading.buttonDisable} attrBtn={{ className: 'btn-block ', color: 'primary', onClick:()=> handleConfirmCode() }}>
                          {isLoading.confirm_code?<Loader/>:"Continue"}
                          </Btn>
                      </FormGroup>
                     </> }
                      {isAppendInputs&&<>
                        <H6 attrH6={{ className: 'mt-4' }}>Create Your Password</H6>
                      <FormGroup className='position-relative'>
                        <Label className='col-form-label m-0'>New Password</Label>
                        <div className='position-relative'>
                          <Input onChange={(e)=>{handleChange("password",e.target.value)}} className='form-control' type={togglePassword ? 'text' : 'password'} name='login[password]' required placeholder='*********' />
                          <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                            <span className={togglePassword ? '' : 'show'}></span>
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label className='col-form-label m-0'>Retype Password</Label>
                        <Input onChange={(e)=>{handleChange("password_confirmation",e.target.value)}} className='form-control' type='password' name='login[password]' required='' placeholder='*********' />
                      </FormGroup>
                      <FormGroup>
                        <div className='checkbox'>
                          <Input id='checkbox1' type='checkbox' />
                          <Label className='text-muted' for='checkbox1'>
                            Remember password
                          </Label>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Btn   attrBtn={{ color: 'primary', className: 'btn d-block w-100', onClick:()=>handleResetPassword()}}>
                           {isLoading.reset_password?<Loader/>:"Done"}
                          </Btn>
                      </FormGroup>
                      </>}
                      <P attrPara={{ className: 'text-start' }}>
                        Already have an password?
                        <a className='ms-2' href='/login'>
                          Sign in
                        </a>
                      </P>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ToastContainer />
    </Fragment>
  );
};

export default Reset_Password;
