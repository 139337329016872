import Echo from "laravel-echo";
import { echo_info, echo_message } from "../redux/action";
import store from "../redux/store";
import { Helper } from "../tools/helper";

if(localStorage.hasOwnProperty("user") && (localStorage.getItem("user") != "") ){
    window.Echo = new Echo({
        // cluster: 'us2',
        // key:"45a9310e2373f31a2f51",
        cluster: 'eu',
        key:"675ccb29a3d3c3254ad7",
        broadcaster: 'pusher',
        authorizer: (channel, options) => {
            return {
                authorize: async (socketId, callback) => {
                    const {response, message} = await Helper.Post({
                        url:"https://api.winshakes.com/api/broadcastingAuth",
                        data:{
                            socket_id: socketId,
                            channel_name: channel.name
                        },
                        hasToken:true
                    })
                    if(response){
                        callback(null, response);
                    }else{
                        // callback(message);
                    }
                }
            };
        },
    });
}

export function listen_note (){
    window.Echo.channel("notification-channel").listen(".notification",(data)=>{
        store.dispatch(echo_info(data.notification,""))
    });
}

export function listen_message_channel (){
    window.Echo.channel("conversation-channel").listen(".conversation",(data)=>{  
        store.dispatch(echo_info(data.notification,data.notification.conversation_id))
    });
}

export function listen_message (id){
    window.Echo.private(`conv-${id}`).listen(".message",(data)=>{
        console.log(data);
        store.dispatch(echo_message(data))  
    });
}
