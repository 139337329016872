import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import {  Btn, Image} from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';


//files
import Files from 'react-files';
//Form Component
import { Loader} from 'rsuite';

//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
import {  useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';


function EditPhoto  ({ id }) {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [formData, setFormData] = useState({});
    const [records, setRecords] = useState([])
    const [photo, setphoto] = useState([])
    const [selectedType, setSelectedType] = useState(null);
    const [statuss, setstatuss] = useState([
        { label: "Category", id: "category" , value: "App\\Models\\Category","api" : api_Routes.category.view},
        { label: "Page", id: "page" , value: "App\\Models\\Page","api" : api_Routes.page.view},
        { label: "Product", id: "product" , value: "App\\Models\\Product","api" : api_Routes.product.view},
        { label: "Brand", id: "brand" , value: "App\\Models\\Brand","api" : api_Routes.brand.view},
     ]);
     const permissions = JSON.parse(localStorage.getItem('permission'));
     


    useEffect(() => {
        if (!permissions || !permissions.includes('photo.edit')) {
            navigate(`/Forbidden`)
        } else {
            if (id !== ":id")
              getPhoto();
        }
       
    }, []);

 

    const getPhoto = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.photos.getOne(id),
            hasToken: true,
        });

        if (response) {
            setFormData({

                type:response.data.type,
                type_id:response.data.type_id,
                visible:response.data.visible,
                file:response.data.url,

            })
        setphoto(response.data.url)
            setIsLoading(false);
        } else {
            console.log(message);
        }
    };

    const fill_records_value = async(api)=>{
        const {response, message} = await Helper.Get({
          
        url: api,
            hasToken:true,
            data:{}
        })
        if(response){
            setRecords([])
            console.log(records)
            
            response.data.forEach(ele=>{
                setRecords(prev => [...prev, {
                    label:ele.translations["en"].name, // Convert user_id to integer
                    value: ele.id   // Convert user_id to integer
                }])
            })
        }else{
            
            console.log(message);
        }

    }



    const handleSubmit = async () => {
        setIsLoading(true);
        // !formData.record ||
        // if (!formData.status ||  !formData.file) {
        //     toast.error("Please select type, record, and upload an image.");
        //     setIsLoading(false);
        //     return;
        // }

        var form_data = new FormData();
        var updatedFormData = { ...formData };

        // form_data.append("type", formData.status);
        // form_data.append("type_id", formData.record);
        // form_data.append("photo", formData.file);
        // form_data.append("visible", 1);


        Object.keys(updatedFormData).forEach((key) => {
            if (key === "file")
                form_data.append("photo", updatedFormData.file);
            
            else
                form_data.append(key, updatedFormData[key]);
        });

        // if (id === ":id") {
        //     form_data.append("_method", "PUT");
        // }

        const { response, message } = await Helper.Post({
            url: api_Routes.photos.update(id),
            data: form_data,
            hasToken: true
        });

        if (response) {
            if (id === ":id") {
                toast.success("Successfully added !!");
                navigate(`/photo-list/:id/order`);
              } else {
                toast.success("Successfully edited !!");
                navigate(`/photo-list/:id/order`);
              }  
                         setIsLoading(false);
        } else {
            if (typeof message === "string") {
                toast.error(message);
            } else {
                Object.keys(message).forEach((i) => {
                    const errorMessage = message[i][0] || "";
                    toast.error(`${i} : ${errorMessage}`);
                });
            }
            setIsLoading(false);
        }
        };  

        const handleChange = (key, value) => {
            setFormData(prev => ({ ...prev, [key]: value }));
        
            if (key === 'status') {
                const selectedStatus = statuss.find(status => status.value === value);
                if (selectedStatus) {
                    setSelectedType(selectedStatus.value);
                    fill_records_value(selectedStatus.api);
                }
            }
            
        }

        const deleteFile = () => {
            setFiles([]);
            setFormData({ ...formData, file: null });
        };
    
        const onFilesChange = (files) => {
             setFiles(files);
             setFormData(prev=>({...prev,["file"]:files[0]}))
             console.log(files);
        };
    
        const onFilesError = (error, file) => {
            console.log('File error:', error);
        };

      
       


    return (
        <Fragment>
            <Container fluid={true}>
                
           
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                            <div className="email-app-sidebar left-bookmark custom-scrollbar" style={{ display: 'flex', justifyContent: 'center' }}>
                                 <Image attrImage={{ style: { width: '150px', height: '150px' }, src: photo, alt: '' }} />
                            </div>

                            </CardBody>
                            <CardBody>
                    <Row  > 
                     
                      <Col className='d-flex align-items-center justify-content-center p-2' sm={12} xs={12} lg={2} md={2} >
                      <div>
                                            <Btn attrBtn={{ className: "mt-2", color: 'danger', type: "button",onClick: () =>!isLoading &&  handleSubmit() }} disabled={isLoading}>
                                                    {isLoading ? <Loader /> : <i className='fa fa-save'></i>}</Btn>
                                            </div> 
                      </Col>
                      
                      {/* <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                            <SelectPicker placeholder="Search By Type" 
                            onChange={(e)=>{handleChange("type",e)}}
                            style={{ width: "100%" }}
                            data={statuss} 
                            //  loading={isLoading.boxes} 
                         />
                      </Col>

                      <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                            <SelectPicker placeholder="Search By Record" 
                            onChange={(e)=>{handleChange("type_id",e)}}
                            style={{ width: "100%" }}
                            data={records} 
                            //  loading={isLoading.boxes} 
                         />
                      </Col> */}

                    </Row>
                    
      
                    </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                        <HeaderCard title="Upload Image" />

                            <CardBody className="fileUploader">
                                <Files
                                    className='files-dropzone fileContainer'
                                    onChange={onFilesChange}
                                    onError={onFilesError}
                                    accepts={['image/*']}
                                    multiple={false}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    {
                                        files.length > 0
                                            ? <div className='files-gallery'>
                                                {files.map((file, index) =>
                                                    <div key={index}>
                                                        <Image attrImage={{ className: 'files-gallery-item', alt: "img", src: `${file.preview.url}` }} />
                                                    </div>
                                                )}
                                            </div>

                                            : <div className="d-flex justify-content-center">
                                                <Btn attrBtn={{ className: "mt-2", type: "button", color: 'primary' }} >Edit Image</Btn>
                                            </div>
                                    }
                                </Files>
                                {files.length > 0 ?
                                    <div className="d-flex justify-content-center">
                                        <Btn attrBtn={{ className: "mt-2", color: 'danger', type: "button", onClick: () => deleteFile(files) }} >
                                            Delete
                                        </Btn></div> : ''}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default EditPhoto;
