import React, { Fragment,useEffect,useRef,useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../AbstractElements';
import DataTableComponent from './Table/DataTableComponent';
// rsuite
import { CheckPicker,Input, Whisper, Tooltip, SelectPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import {  useNavigate } from 'react-router-dom';

//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
import {useParams} from "react-router-dom"
import Details_product from './details';

const Product_Table = () => {
    const navigate = useNavigate()
    const {id} = useParams()
    const [isLoading, setIsLoading] = useState({
      categories:false,
      brands:false,
      is_box:null
    })
    const [values_Filter, setFilter_Values] = useState({})
    const [values,setValues] = useState({})
    const [boxes,setBoxes] = useState([])
    const [brands,setBrands] = useState([])
    const [categories,setcategories] = useState([])
    const permissions = JSON.parse(localStorage.getItem('permission'));
    const controllerRef = useRef(null)
    useEffect(()=>{
      const controller = new AbortController()
      const signal = controller.signal
      if (!permissions || !permissions.includes('product.get-all')) {
        navigate(`/Forbidden`)
      } else {
        get_categories(signal)
        // get_brands(signal)
        get_boxes(signal)
      }
      return()=>{
        controller.abort()
      }

    },[])
    const get_boxes = async()=>{
      setIsLoading(prev=>({...prev, boxes:true}))
      const {response, message} = await Helper.Get({
          url:api_Routes.box.view,
          hasToken:true,
      })
      if(response){
          response.data.forEach(ele=>{
            setBoxes(prev=>[...prev,{
              label:ele.translations["en"]?ele.translations["en"].name:"",
              value:ele.id
            }])
          })

          setIsLoading(prev=>({...prev, boxes:false}))
      }else{
          console.log(message);
      }
    }
  const get_categories = async(signal)=>{
      setIsLoading(prev=>({...prev, categories:true}))
      const {response, message} = await Helper.Get_Abort({
          url:api_Routes.category.view,
          hasToken:true,
          signal:signal,
          data:{parent:0,
          per_page:1000}
      })
      if(response){
          response.data.forEach(ele=>{
            setcategories(prev=>[...prev,{
              label:ele.translations["en"]?ele.translations["en"].name:"",
              value:ele.id
            }])
          })

          setIsLoading(prev=>({...prev, categories:false}))
      }else{
          console.log(message);
      }
  }
  const get_brands = async(signal)=>{
    setIsLoading(prev=>({...prev, brands:true}))
    const {response, message} = await Helper.Get_Abort({
        url:api_Routes.brand.view,
        hasToken:true,
        signal:signal
    })
    if(response){
        response.data.forEach(ele=>{
          setBrands(prev=>[...prev,{
            label:ele.translations["ar"]?ele.translations["ar"].name:"",
            value:ele.id
          }])
        })
      
        setIsLoading(prev=>({...prev, brands:false}))
    }else{
        console.log(message);
    }
  }
  const handleChange = (key,value)=>{
    setValues(prev=>({...prev,[key]:value}))
  }
  const handleFilter = ()=>{
    setFilter_Values(values)
  }
  const onSearch = async (e,key)=>{
    setcategories([])
    setIsLoading(prev=>({...prev, categories:true}))
    
    if(controllerRef.current){
        controllerRef.current.abort()
    }
    controllerRef.current = new AbortController()
    const signal = controllerRef.current.signal
    const {response,message} = await Helper.Get_Abort({
        url:api_Routes.category.view ,
        signal:signal,
        data:{keywords:e,results:20},
        hasToken:true
    })
    if(response){
      response.data.forEach(ele=>{
        setcategories(prev=>[...prev,{
          label:ele.translations["en"]?ele.translations["en"].name:"",
          value:ele.id
        }])
      })
      setIsLoading(prev=>({...prev, categories:false}))

    }

}
  return (
    id ==":id"?<Fragment>
      
    <Breadcrumbs parent="Product" title="List" mainTitle="Products" />
    <Container className='my-2' fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
           
            <CardBody>
            <Row  >
              <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={4}>
                <Whisper trigger="focus" speaker={<Tooltip>Name</Tooltip>}>
                  <Input onChange={(e)=>{handleChange("keywords",e)}} 
                        style={{ width: "100%" }} placeholder="Search By Product Name" />
                </Whisper>

              </Col>  
              <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                <SelectPicker placeholder="Search For Is Box Or Marketplace" onChange={(e)=>{handleChange("is_box",e)}}
                        style={{ width: "100%" }} data={[{
                          label:"Is Box",
                          value:"1"
                        },{label:"Marketplace",
                        value:"0"}]} loading={isLoading.boxes} />
              </Col>
              <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={4}>
                <CheckPicker  onSearch={(e)=>{
                            if( e != "")
                                onSearch(e,"category")
                        }} placeholder="Search By Category" onChange={(e)=>{handleChange("categories",e)}}
                        style={{ width: "100%" }} data={categories} loading={isLoading.categories} />
              </Col>
             
              <Col className='d-flex align-items-center justify-content-end p-2' sm={12} xs={12} lg={1} md={1} >
                <div onClick={()=>{handleFilter()}}>
                  <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                </div>
              </Col>
            </Row>
            

            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            
            <CardBody>
              <DataTableComponent values={values_Filter}  />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </Fragment>:<Details_product/>
  );

};

export default Product_Table;
