import React, { Fragment,useEffect,useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs, H5, Btn, Image, UL, LI, H6, Spinner } from '../../AbstractElements';
//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
//format date
import dateFormat from "dateformat";
import { ToastContainer } from 'react-toastify';
import {  useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';


function  Details_Balance({id}) {
    const navigate = useNavigate()
    const [detailsInfo, setDetailsInfo] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const permissions = JSON.parse(localStorage.getItem('permission'));

     useEffect(()=>{
        if (!permissions || !permissions.includes('balance.get-all')) {
            navigate(`/Forbidden`)
        }else{
        get_Details()
        }
    },[])
    const get_Details = async ()=>{

        const {response, message} = await Helper.Get({
            url:api_Routes.balance.getOne(id),
            hasToken:true,
        })
        if(response){
            console.log(response.data);
            setDetailsInfo(response.data)
               
           
            setIsLoading(false)
        }else{
            console.log(message);
            setIsLoading(false)
        }
    }
    const handleMenuItemClick = (path) => {
        navigate(path);
      };
      const handleGoBack = ()=>{
        navigate(`/balance-list/:id/Shake`)
    }

    return ( <Fragment>
        <Breadcrumbs mainTitle='Details' parent='details' title='Shake' />
        {isLoading?<div className="loader-box">
                                            <Spinner attrSpinner={{ className: "loader-26" }} />
                                        </div>:
        <Container fluid={true} className='email-wrap bookmark-wrap todo-wrap'>
             
          <Row>
            {/* User Info */}
            <Col xl='12' >
                <Card>
              
                    <CardBody>
                    <div className='email-app-sidebar left-bookmark custom-scrollbar'>
                        <div className='d-flex align-items-center'>
                        <div className='media-size-email'>
                            <Image attrImage={{ className: 'me-3 img-40 rounded-circle', src:detailsInfo.user?detailsInfo.user.photo:"" , alt: '' }} />
                        </div>
                        <div className='flex-grow-1'>
                            <H6 attrH6={{ className: 'f-w-600' }}>
                                 <a 
                                    href={`/user-list/Shake?id=${detailsInfo.user_id}`}>{`${detailsInfo.user.name} (${detailsInfo.user.username})`}
                                 </a> 
                            </H6> 
                         </div>
                         <div>
                        <Btn 
                            attrBtn={{
                                color: 'primary',
                                className: 'badge-light justify-content-start text-white d-flex align-items-center btn-mail w-40',
                                style: { padding: '10px 20px' },
                                onClick:()=>handleMenuItemClick(`/user-list/Shake?id=${detailsInfo.user_id}`)
                            }}
                        >
                            View
                        </Btn>
                        </div>
                        </div>
                        {/* <UL attrUL={{ className: 'simple-list nav main-menu' }}>
                        <LI attrLI={{ className: 'border-0 nav- pt-4' }}>
                            <i className={`fa fa-phone`}></i>
                            <span className='title ms-2'>{detailsInfo.user?detailsInfo.user.phone:""}</span>
                        </LI>
                      
                       

                        </UL> */}
                    </div>
                    </CardBody>
                </Card>
            </Col>
            {/* User To Info */}
            {/* {detailsInfo.to_user &&<Col xl='12' >
                <Card>
              
                    <CardBody>
                    <div className='email-app-sidebar left-bookmark custom-scrollbar'>
                        <div className='d-flex align-items-center'>
                        <div className='media-size-email'>
                            <Image attrImage={{ className: 'me-3 img-40 rounded-circle', src:detailsInfo.user_to?detailsInfo.user_to.photo:"" , alt: '' }} />
                        </div>
                        <div className='flex-grow-1'>
                            <H6 attrH6={{ className: 'f-w-600' }}>{detailsInfo.to_user?detailsInfo.to_user.name:""}</H6>
                        
                        </div>
                        </div>
                        <UL attrUL={{ className: 'simple-list nav main-menu' }}>
                        <LI attrLI={{ className: 'border-0 nav-item' }}>
                            <i className={`fa fa-phone`}></i>
                            <span className='title ms-2'>{detailsInfo.to_user?detailsInfo.to_user.phone:""}</span>
                        </LI>
                        
                        <LI attrLI={{ className: 'border-0 nav-item' }}>
                            <Btn attrBtn={{ color: 'primary', className: 'badge-light justify-content-start text-white d-flex align-items-center btn-mail w-100' }}>
                            <CheckCircle className='me-2' />
                                to
                            </Btn>
                        </LI>

                        </UL>
                    </div>
                    </CardBody>
                </Card>
            </Col>}
             */}
          
            
            {/* Details Shake */}
            <Col xl='12' >
                <Card>
                <CardHeader>
                        <H5>Details </H5>
                    </CardHeader>
                    <CardBody>
                        {detailsInfo.active == 1
                         ? 
                        <span className={`badge badge-light-success p-3`} style={{fontSize:'15px' , fontWeight:'700'}}>Active</span>
                        :
                        <span  className={`badge badge-dander p-3`} style={{fontSize:'15px' , fontWeight:'700'}}>Not Active</span>}
                        
                        {detailsInfo.to_user &&<div className='p-2 my-2 d-flex'>
                            <H6>User To  :  </H6>
                            <p>{detailsInfo.to_user.name}</p>
                        </div>}
                        <div className='p-2 my-2 d-flex'>
                            <H6>Type  :  </H6>
                            <p>{detailsInfo.type.name}</p>
                        </div>
                        <div className='p-2  d-flex'>
                            <H6> Amount  :  </H6>
                            <p>{detailsInfo.amount}</p>
                        </div>
                        <div className='p-2  d-flex'>
                            <H6> Date by create  :  </H6>
                            <p>{dateFormat(detailsInfo.created_at,"dddd, mmmm dS, yyyy")}</p>
                        </div>
                   
        
                    

                    </CardBody>
                </Card>
            </Col>
            
            
            
            
          </Row>
          <ToastContainer/>
        </Container>}
      </Fragment>);
}

export default  Details_Balance;