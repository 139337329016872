import React, { Fragment, useEffect, useState, useRef , useLayoutEffect  } from 'react';
import { Container, Row, Col, Card, CardBody, Media } from 'reactstrap';
import moment from 'moment';
import { api_Routes } from 'api_Routes';
import { Helper } from '../../tools/helper';
import { useParams } from 'react-router-dom';
import dateFormat from 'dateformat';
import styled, { keyframes } from 'styled-components';
import { Box, Grid , Avatar, IconButton, Skeleton, CircularProgress , LinearProgress , Typography  } from '@mui/material';
import { Bold } from 'react-feather';
import { Close } from '@mui/icons-material';
import Swiper_Images_With_Videos from './lightCallery';





// align-items: ${props => props.isSender ? 'flex-end' : 'flex-start'};

const spin = keyframes`
from { transform: rotate(0deg) }
to { transform: rotate(360deg)}
`

const BoxCustom = styled(Box)`
&  .content .grid_div{
    
    position: relative;
}
& .content .grid_div img{
    width:100%;
    object-fit: contain;
    max-height:200px;
    filter: grayscale(0.8);
}
& .content .grid_div .first-col {
    width: 50%;
}
& .content .grid_div .second-col {
    width: 50%
}
& .content .grid_div .container_loading{
    position: absolute;
    top: 0;
    z-index: 10;
    right: 0;
    left:0;
    bottom: 0;
    background: rgba(255, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
}
& .content .grid_div .one-col{
    width: 100%
}
& .content .grid_div .container_col{
    display: flex;
    background: #7c7575
}
& .content .grid_div .container_loading .loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #d80621;
    width: 50px;
    height: 50px;
    animation: ${spin} 2s linear infinite
    
  }
  & .message-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
     
}


& .message-box {
    background-color: #d80621;
    color: white;
    padding: 10px;
    max-width: 70%;
    width: fit-content;
}

& .message-footer {
    text-align: right;
    margin-top: 5px;
}
`



function ChatPage() {
    const { id } = useParams();
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState({ current: 1 });
    const [usersender, setusersender] = useState(null);
    const [perPage, setPerPage] = useState(30);
    const [isLoading_chat, setIsLoading_chat] = useState(true)
    const observerTarget  = useRef(null)
    const [pageHelp, setPageHelp] = useState(0)
    const [msg,setMsg] = useState("")
    const scrollControll = useRef(null)
    const [totalPages, setTotalPages] = useState(1)
    const [loadingOldMessages,setLoadingOldMessages] = useState(false)
    const divScroll = useRef(null)
    const [imagesBox, setImagesBox] = useState([])
    const [viewBox, setViewBox] = useState(false)


      
    var userreceiver = ''

    const scrollToBottom = () => {
        if (divScroll.current) {
            divScroll.current.scrollTop = divScroll.current.scrollHeight;
        }
    };

    useLayoutEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
       
        get_user();
        get_chat();
    }, [id, page, perPage]);




    const increase_page = () => {
        setPage((prevPage) => ({ current: prevPage.current + 1 }));
    };


    useEffect(() => {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            increase_page()
          }
        },
        { threshold: 1 }
      );
    
      if (observerTarget.current) {
        observer.observe(observerTarget.current);
      }
    
      return () => {
        if (observerTarget.current) {
          observer.unobserve(observerTarget.current);
        }
      };
    }, [observerTarget.current]);

    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        setMessages([])
        if(id != ""){
            page.current = 0
            setIsLoading_chat(true)
            setMessages([])
            get_chat(signal)



            scrollControll.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight });
            });
        }else{
            setIsLoading_chat(false)
        }
        return ()=>{
            controller.abort()
        }    
    },[id])

    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        if(id != "" && page.current > 0){
            setLoadingOldMessages(true)
            get_chat(signal)

            scrollControll.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight%2});
            });
            
        }
        return ()=>{
            controller.abort()
        }    
    },[pageHelp])
    // alert()



    const get_chat = async () => {
        console.log(page, totalPages)
        if ((page.current <= totalPages && page.current > 1) || page.current === 1 || page.current === 0) {
            console.log(1)
            const { response, message } = await Helper.Get({
                url: api_Routes.messages.getByConversation(id),
                hasToken: true,
                data: page.current === 0 ? { page: 1, results: 20 } : { page: page.current, results: 20 },
            });

            if (response) {
                if (page.current === 1 || page.current === 0) {
                    setMessages(response.data.reverse());
                    setTotalPages(response.meta.last_page);
                } else {
                    let array = []
                    response.data.forEach(e=>{
                        array.unshift(e)
                    })
                    setMessages(prev=>[...array,...prev])
                    
                
                    setIsLoading_chat(false)
                    setLoadingOldMessages(false)
                    scrollControll.current.addEventListener('DOMNodeInserted', event => {
                        const { currentTarget: target } = event;
                        target.scroll({ top: 200, behavior: 'smooth' });
                        // target.scrollTop = 100
                    });
             
                
            }
            setLoadingOldMessages(false) 
            setIsLoading_chat(false)
            }
        } else {
            setMsg("No old messages.");
        }
    };
    const get_user = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.converstaions.getOne(id),
            hasToken: true,
                });

        if (response) {
            console.log(response.data);
           //usersender = response.data.first_user
           setusersender(response.data.first_user)
            setIsLoading(false);
         //  debugger;
            console.log(usersender)
        } else {
            console.log(message);
        }
    };




    const handleOpenLightBox = (photos,id)=>{
        setViewBox(true)

        setImagesBox(photos)
    }

    return ( <>
       

        {viewBox&&
            <Box id="gallery_overlay" sx={{position:"fixed",top:0,left:0,right:0,bottom:0
            ,display:"flex",justifyContent:"center",alignItems:"center",background:"rgb(22 22 22 / 87%)",zIndex:"2000"}}>
                <IconButton onClick={()=>{setViewBox(false)}}>
                    <Close sx={{color:"white",position:"fixed",top:"10px",right:"10px",zIndex:"2500"}}/>
                </IconButton>
                <Swiper_Images_With_Videos array={imagesBox}/>
                 </Box>
                }
                
        <Box ref={scrollControll} className="scroll-div">   
        <Box ref={divScroll} sx={{padding:"0 5px" ,height: '630px', overflow: 'auto'}}>

             <div ref={observerTarget}  style={{height:"50px",display:"flex",justifyContent:"center",alignItems:"center"}} >
                     {msg}
             </div> 
        <div className="chat-container">
            {messages.map((e, index) => {
                const array = [];
                if (e.photos.length > 0)
                    e.photos.forEach((photo) => { array.push({ src: photo.url, type: "image" }) });
                if (e.videos.length > 0)
                    e.videos.forEach((video) => { array.push({ src: video.url, type: "video" }) });
    //debugger;   
                // Check if the userSender matches usersender
                var isUserSender = (e.sender == usersender);
           //   debugger;
                return (
                    <BoxCustom key={index} className={`message-container ${isUserSender ? 'right' : 'left'}`} isSender={isUserSender} style={{ paddingTop: '20px' ,display : isUserSender ? 'flex' : '' ,flexDirection : isUserSender ? 'column' : '', alignItems : isUserSender ? 'flex-end' : '', justifyContent : isUserSender ? 'end' : '' }}>
                     {/* , padding : isUserSender ? '5px 53px 0px 0px' : '' */}
                        <Box className="user-info" style={{ display :isUserSender ? 'grid' : '', justifyContent: isUserSender ? 'end' : '' }} >
                            {isUserSender ? 
                              (<Box className="user-details">
                                 <Typography variant="caption" className="message-time" style={{color:"#5e5e5e"}}>{dateFormat(e.created_at, "hh:mm TT")}</Typography>  
                                 <Typography fontSize={"16px"} fontWeight={"Bold"} fontFamily={"Montserrat"} color={"#5e5e5e"} variant="subtitle1">{e.sender_name ? e.sender_name : ""}</Typography>
                                 <Avatar alt={e.sender_name ? e.sender_name : ""} src={e.sender_photo ? e.sender_photo : ''} sx={{ width: 45, height: 45 }} />                               
                              </Box>) 
                            :
                              (<Box className="user-details" >
                                 <Avatar alt={e.sender_name ? e.sender_name : ""} src={e.sender_photo ? e.sender_photo : ''} sx={{ width: 45, height: 45 }} />                               
                                 <Typography fontSize={"16px"} fontWeight={"Bold"} fontFamily={"Montserrat"} color={"#5e5e5e"} variant="subtitle1">{e.sender_name ? e.sender_name : ""}</Typography>
                                 <Typography variant="caption" className="message-time" style={{color:"#5e5e5e"}}>{dateFormat(e.created_at, "hh:mm TT")}</Typography>  
                              </Box>)
                              }
                            
                        </Box>
                        <Box className="message-box" style={{marginLeft:isUserSender ? '' :'52px',marginRight:isUserSender ? '45px' :''  , borderRadius: isUserSender ? '16px 0px 16px 16px' : '0px 16px 16px 16px' }}>
                        <div className='content' >
                                    
                                    {e.message != "" && <div style={{textWrap:"br" , fontSize : "14px"}} dangerouslySetInnerHTML={{__html:e.message}}></div>}
                                    {array.length>0 && array.length > 4 &&  <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                               {array[0].type == "image"?<img alt={`img_${Math.random()*1230}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6}  xs={6}>
                                           <Box  onClick={()=>{handleOpenLightBox(array,1)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[0].type == "image"?<img alt={`img_${Math.random()*1200}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[1].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[1].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6}  xs={6}>
                                           <Box  onClick={()=>{handleOpenLightBox(array,2)}} sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                           {array[2].type == "image"?<img alt={`img_${Math.random()*1030}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[2].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[2].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid   sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           
                                           <Box onClick={()=>{handleOpenLightBox(array,3)}}  sx={{background: "white",borderRadius: "16px",overflow: "hidden",height:"200px",width:"100%",position:"relative"}}>
                                               <Box sx={{display:"flex",justifyContent:"center",cursor:"pointer",alignItems:"center" ,position:"absolute",left:0,right:0,bottom:0,top:0,zIndex:10,background:"rgb(33 32 32 / 30%)"}}>
                                               <span style={{color:"white",fontSize:"24px"}}>+{array.length-4}</span>
                                               </Box>
                                               {array[3].type == "image"?<img alt={`img_${Math.random()*1300}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[3].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[3].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    {array.length>0 && array.length == 4 && <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}   sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                           {array[0].type == "image"?<img alt={`img_${Math.random()*2230}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,1)}} sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[1].type == "image"?<img alt={`img_${Math.random()*4530}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[1].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[1].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,2)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                                {array[2].type == "image"?<img alt={`img_${Math.random()*1730}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[2].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[2].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,3)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[3].type == "image"?<img alt={`img_${Math.random()*10230}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[3].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[3].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    {array.length>0 && array.length == 3 && <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                           {array[0].type == "image"?<img alt={`img_${Math.random()*330}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,1)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                                {array[1].type == "image"?<img alt={`img_${Math.random()*2130}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[1].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[1].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,2)}}   sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                           {array[2].type == "image"?<img alt={`img_${Math.random()*5030}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[2].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    { array.length>0 && array.length == 2 &&  <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[0].type == "image"?<img alt={`img_${Math.random()*1237}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,1)}}   sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                                {array[1].type == "image"?<img alt={`img_${Math.random()*1210}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[1].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[1].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    {array.length>0 && array.length == 1 &&  <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={12} md={12}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[0].type == "image"?<img alt={`img_${Math.random()*1470}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    {e.id =="loading"  && <Box>
                                    <LinearProgress color="secondary"/>
                                        
                                    </Box>}
                                    {/* <Box sx={{display:"flex",justifyContent:"end"}}>
                                        <span style={{textTransform:"uppercase",fontSize:"10px",color:"#c2b5b5"}}>{dateFormat(e.created_at,"hh:mm TT")}</span>
                                    </Box> */}
                                </div>
                            <Box className="message-footer">
                                <Typography fontSize={"11px"} variant="caption" className="message-time">{dateFormat(e.created_at, "hh:mm TT")}</Typography>
                            </Box>
                        </Box>
                    </BoxCustom>
                );
            })}
            {isLoading && <p>Loading more messages...</p>}
        </div>
            </Box>
            </Box>
        
    
            </>  );
    
    

        
    
   
}

export default ChatPage;
