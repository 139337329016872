import React, { Fragment,useEffect,useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row,Table } from 'reactstrap';
import { Breadcrumbs,H3,H5, Btn, P, Image, UL, LI, H6, Spinner } from '../../AbstractElements';
//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
//format date

import { useNavigate } from 'react-router';
import { FaArrowLeft } from 'react-icons/fa';



function Details_Order ({id}){
    const [detailsInfo, setDetailsInfo] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()

   useEffect(()=>{
    const controller = new AbortController()
    const signal = controller.signal
        get_Details(signal)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        return()=>{
            controller.abort()
            }
    },[])

   const get_Details = async(signal)=>{
            const {response, message} = await Helper.Get_Abort({
            url:api_Routes.order.getOne(id),
            hasToken:true,
            signal:signal
        })
        if(response){
            console.log(response.data);
            setDetailsInfo(response.data)         
            setIsLoading(false)
        }
        else{
            console.log(message);
        }
    }
   
    const handleMenuItemClick = (path) => {
        navigate(path);
      };
      
    return(
        <Fragment>
          <Breadcrumbs mainTitle='Details' parent='details' title='Order' />
       { isLoading?
       <div className="loader-box">
          <Spinner attrSpinner={{ className: "loader-26" }} />
       </div>:
        <Container fluid={true} className='email-wrap bookmark-wrap todo-wrap'>
           
            <Row>
                {/* user_info */}
                <Col >
                    <Card>
                        <CardBody>
                            <div className='email-app-sidebar left-bookmark custom-scrollbar'>
                            <div className='d-flex align-items-center'>
                              <div className='media-size-email'>
                               <Image attrImage={{ className: 'me-3 img-40 rounded-circle', src:(detailsInfo?.user_photo)?(detailsInfo.user_photo):"" , alt: '' }} />
                              </div>
                            <div className='flex-grow-1'>
                            <H6 attrH6={{ className: 'f-w-600' }}>
                                 <a 
                                    href={`/user-list/Shake?id=${detailsInfo.user_id}`}>{`${detailsInfo.user_name} (${detailsInfo.user_username})`}
                                 </a> 
                            </H6> 
                                 </div>
                                 <div>
                        <Btn 
                            attrBtn={{
                                color: 'primary',
                                className: 'badge-light justify-content-start text-white d-flex align-items-center btn-mail w-40',
                                style: { padding: '10px 20px' },
                                onClick:()=>handleMenuItemClick(`/user-list/Shake?id=${detailsInfo.user_id}`)
                            }}
                        >
                            view
                        </Btn>
                        </div>
                            </div>
                        <UL attrUL={{ className: 'simple-list nav main-menu' }}>
                        <LI attrLI={{ className: 'border-0 nav-item pt-2' }}>
                            <i style={{color:"#D80621"}} className={`fa fa-phone`}></i>
                            <span className='title ms-2'>{detailsInfo.user_phone}</span>
                        </LI>
                        <LI attrLI={{ className: 'border-0 nav-item pt-2' }}>
                            <i style={{color:"#D80621"}} className={`fa fa-envelope`}></i>
                            <span className='title ms-2'>{detailsInfo.user_email}</span>
                        </LI>
                        <LI attrLI={{ className: 'border-0 nav-item pt-2' }}>
                            <i style={{color:"#D80621"}} className={`fa fa-calendar`}></i>
                            <span className='title ms-2'>{detailsInfo.created_at}</span>
                        </LI>
                        <LI attrLI={{ className: 'border-0 nav-item pt-2' }}>
                            <i style={{color:"#D80621"}} className={`fa fa-exclamation-circle`}></i>
                            <span className='title ms-2'>
                                {detailsInfo.status == 1 ? 'Pending'
                                : detailsInfo.status == 2 ? 'Delivery'
                                : detailsInfo.status == 3 ? 'Delivered'
                                :  detailsInfo.status == 4 ? 'Canceled' 
                                :  detailsInfo.status == 4 ? 'Rejected' 
                                : 'Sold'
                                }
                             </span>
                        </LI>
                              </UL>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                
            {(detailsInfo.status == 6 ) ? <div></div> :

             <Col xl='12' >
                <Card>
                  <CardHeader>
                        <H5>Address</H5>
                  </CardHeader>
                    <CardBody>
                        <Row>
                           {(detailsInfo.status == 1 && detailsInfo.user_address_city == null ) ?
                          
                          <Col>
                            <div>Wating for the user to select the shipping address</div>
                           </Col>
                           
                           :
                           <>
                                <Col lg={4} sm={6} xs={12} md={4}>
                                    <div className='p-2 d-flex'>
                                        
                                            <p>
                                                <P>City : {detailsInfo.user_address_city}</P>
                                            </p>
                                    </div>
                                </Col>
                                <Col lg={4} sm={6} xs={12} md={4}>
                                    <div className='p-2 d-flex'>
                                        
                                            <p>
                                                <P>Floor : {detailsInfo.user_address_floor}</P>
                                            </p>
                                    </div>
                                </Col>
                                <Col lg={4} sm={6} xs={12} md={4}>
                                    <div className='p-2 d-flex'>
                                        
                                            <p>
                                                <P>Zipcode : {detailsInfo.user_address_zipcode}</P>
                                            </p>
                                    </div>
                                </Col>
                                <Col lg={4} sm={6} xs={12} md={4}>
                                    <div className='p-2 d-flex'>
                                        
                                            <p>
                                                <P>Street : {detailsInfo.user_address_street}</P>
                                            </p>
                                    </div>
                                </Col>
                                <Col lg={4} sm={6} xs={12} md={4}>
                                    <div className='p-2 d-flex'>
                                        
                                            <p>
                                                <P>Area : {detailsInfo.user_address_area}</P>
                                            </p>
                                    </div>
                                </Col>
                                <Col lg={4} sm={6} xs={12} md={4}>
                                    <div className='p-2 d-flex'>
                                        
                                            <p>
                                                <P>Building : {detailsInfo.user_address_building}</P>
                                            </p>
                                    </div>
                                </Col>
                                </>
                        }                             
                        </Row>
                                                
                    </CardBody>
                 </Card>
              </Col>
}                            
                {/* Shipping Option */}
                  <Col xl='12' >
                <Card>
                    <CardHeader>
                        <H5>Shipping Option</H5>
                    </CardHeader>
                    <CardBody>
                    
                       <Row>
                       {(detailsInfo.status == 1 ) ?
                       
                       <Col>
                       <div>Wating for the user to select the shipping option</div>
                      </Col>
                      
                      :
                      <>

                       {
                            
                             <Col lg={4} sm={6} xs={12} md={4}>
                                <div className='p-2 d-flex'>
                                    <H6>Shipping Option : </H6>
                                    <p>{detailsInfo?.shipping_option_name}</p>
                                </div>

                            </Col>
                        }
                            {
                                 <Col lg={4} sm={6} xs={12} md={4}>
                                <div className='p-2 d-flex'>
                                    <H6> Company  : </H6>
                                    <p>{detailsInfo?.shipping_company_name}</p>
                                </div>
                            </Col>
                            }
                             {  <Col lg={4} sm={6} xs={12} md={4}>
                                <div className='p-2 d-flex'>
                                    <H6>price : </H6>
                                    <p>{detailsInfo.shipping_options_charge_price}</p>
                                </div>

                            </Col>
                            }
                        </>
                        }
                       </Row>      
                    </CardBody>
                </Card>
            </Col>

             {/* Activities */}
            {/* <Col xl='12' >
                <Card>
                    <CardHeader>
                        <H5>Activities</H5>
                    </CardHeader>
                    <CardBody>
                    
                       <Row>
                        
                       
                       {detailsInfo.activities.map((item) => (
                            <React.Fragment key={item.id}>
                                <div style={{borderBottom : "1px solid #e9e9e9"}}>
                                <Col lg={5} sm={12} xs={12} md={5}>
                                    <div className='p-2 d-flex'>
                                        <H6>Activity : </H6>
                                        <p>{item.translations["en"]}</p>
                                    </div>
                                </Col>

                                <Col lg={5} sm={12} xs={12} md={5}>
                                    <div className='p-2 d-flex'>
                                        <H6>Date : </H6>
                                        <p> {item.date}</p>
                                    </div>
                                </Col>

                                <Col lg={2} sm={12} xs={12} md={2}>
                                    <div className='p-2 d-flex'>
                                        <H6>Amount : </H6>
                                        <p>{item.amount}</p>
                                    </div>
                                </Col>
                                </div>
                            </React.Fragment>
                        ))}
                           
                       </Row>
                       
                    </CardBody>
                </Card>
            </Col>
 */}

             {/* products info */}
             <Col xl="12">
                <Card>
                    <CardHeader>
                        
                        <H3>Products</H3>
                        {/* <span>{"A"} <code>{".table-responsive"}</code>, <code>{'.table-light'}</code> {"inside table element."}</span> */}
                    </CardHeader>
                    <div className="card-block row">
                        <Col sm="12" lg="12" xl="12">
                            <div className="table-responsive">
                                <Table className='table-light'>
                                    <thead>
                                        <tr>
                                            <th scope="col">Photo</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Subtotal</th>
                                         
                                        </tr>
                                    </thead>
                                       <tbody>
                                            
                                                    {detailsInfo.items.map((item, index) => (
                                                    <tr key={index}>
                                                    <td>
                                                        <img className='me-3 img-40 rounded-circle' src={item.product_photo} alt='' />
                                                    </td>
                                                    <td>{item.product_translations["en"]?.name}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.subtotal}</td>
                                                    </tr>
                                                ))}
                                                                                   
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </div>
                </Card>
            </Col>


            </Row>
        </Container>
       }
       
        </Fragment>
    )

}
export default Details_Order
