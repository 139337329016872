import Reset_Password from "Auth/ForgetPwd";
import Add_Category from "Components/Categories/add_category";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet ,Route,Routes } from "react-router-dom";

const PrivateRoute = () => {
  const [login] = useState(localStorage.getItem("login"));
  const [authenticated] = useState(localStorage.getItem("authenticated"));

  useEffect(() => {
    
    
    // setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    
  },[]);
  return login || authenticated ? <Outlet /> : <Routes>
   <Route path={`/resetPassword`} element={<Reset_Password />} />
   <Route exact path={`/`} element={<Navigate exact to={`${process.env.PUBLIC_URL}/login`} />} />
  </Routes>
};

export default PrivateRoute;
