import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody,Form } from 'reactstrap';
import { Btn, Image } from '../../AbstractElements';
import { Video } from 'react-feather';
import { CheckCircle } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import HeaderCard from '../Common/Component/HeaderCard';
import Dropzone from 'react-dropzone-uploader';

//files
import Files from 'react-files';
//Form Component
import { Loader} from 'rsuite';

//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';

function EditVideo  ({ id }) {
    const [detailsInfo, setDetailsInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate()
    const [files, setFiles] = useState([]);
    const [formData, setFormData] = useState({});
    const [records, setRecords] = useState([])
    const [selectedType, setSelectedType] = useState(null);
    const [statuss, setstatuss] = useState([
        { label: "Category", id: "category" , value: "App\\Models\\Category","api" : api_Routes.category.view},
        { label: "Page", id: "page" , value: "App\\Models\\Page","api" : api_Routes.page.view},
        { label: "Product", id: "product" , value: "App\\Models\\Product","api" : api_Routes.product.view},
        { label: "Brand", id: "brand" , value: "App\\Models\\Brand","api" : api_Routes.brand.view},
     ]);
     const [ perPage, setPerPage ] = useState(10)
     const [ page, setPage ] = useState(1)
     const [values,setValues] = useState({})
     const [values_Filter, setFilter_Values] = useState({})
     const [data_table, setData_Table] = useState([])
     const [col_table, setCol_Table] = useState([])
     const permissions = JSON.parse(localStorage.getItem('permission'));

    useEffect(() => {
        if (!permissions || !permissions.includes('video.edit')) {
            navigate(`/Forbidden`)
        } else {
            getVideo();
        }
    }, []);

    const getVideo = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.videos.getOne(id),
            hasToken: true,
        });

        if (response) {
            setFormData({
                type:response.data.type,
                type_id:response.data.type_id,
                visible:response.data.visible,
                file:response.data.url,
            })

             setIsLoading(false);
        } else {
            console.log(message);
        }
    };

    const fill_records_value = async(api)=>{
        const {response, message} = await Helper.Get({
          
        url: api,
            hasToken:true,
            data:{}
        })
        if(response){
            setRecords([])
            console.log(records)
          
            
            response.data.forEach(ele=>{
                setRecords(prev => [...prev, {
                    label:ele.translations["en"].name, // Convert user_id to integer
                    value: ele.id   // Convert user_id to integer
                }])
            })
        }else{
            
            console.log(message);
        }

    }



    const handleSubmit = async () => {
        setIsLoading(true);
       

        var form_data = new FormData();
        var updatedFormData = { ...formData };


        
        Object.keys(updatedFormData).forEach((key) => {
            if (key === "file")
                form_data.append("video", updatedFormData.file);
            
            else
                form_data.append(key, updatedFormData[key]);
        });

       

        const { response, message } = await Helper.Post({
            url: api_Routes.videos.update(id),
            data: form_data,
            hasToken: true
        });

        if (response) {
            toast.success(id === ":id" ? "Successfully added !!" : "Successfully edited !!");
            navigate(`/video-list/:id/order`)
            setIsLoading(false);
        } else {
            if (typeof message === "string") {
                toast.error(message);
            } else {
                Object.keys(message).forEach((i) => {
                    const errorMessage = message[i][0] || "";
                    toast.error(`${i} : ${errorMessage}`);
                });
            }
            setIsLoading(false);
        }
        };  

        const handleChange = (key, value) => {
            setFormData(prev => ({ ...prev, [key]: value }));
        
            if (key === 'status') {
                const selectedStatus = statuss.find(status => status.value === value);
                if (selectedStatus) {
                    setSelectedType(selectedStatus.value);
                    fill_records_value(selectedStatus.api);
                }
            }
            
        }

        const deleteFile = () => {
            setFiles([]);
            setFormData({ ...formData, file: null });
        };
    
        const onFilesChange = (files) => {
            setFiles(files)
             setFormData(prev=>({...prev,["file"]:files[0]}))
             console.log(files);
        };
    
        const onFilesError = (error, file) => {
            console.log('File error:', error);
        };

        

        const handleChangeStatus = ({ meta, file }, status) => {

            if(status=="done"){
                setFiles(prev=>[...prev,file])
            }else if(status=="removed"){
                setFiles(prev=>{
                    let array = prev
                    let result =array.filter(i => i.name!=file.name)
                    return result
                })
            }
        };
        const getUploadParams = ({ meta,file }) => {
      
        
            return { url: 'https://httpbin.org/post' };
        };

    return (
        <Fragment>
            <Container fluid={true}>
            
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                            <div>
                                <video controls style={{ width: '100%', height: 'auto' ,'max-height' : '350px'}} src={formData.file}>
                                </video>
                            </div>

                            </CardBody>
                            <CardBody>
                    <Row  > 
                     
                      <Col className='d-flex align-items-center justify-content-center p-2' sm={12} xs={12} lg={2} md={2} >
                      <div>
                                            <Btn attrBtn={{ className: "mt-2", color: 'danger', type: "button",onClick: () =>!isLoading &&  handleSubmit() }} disabled={isLoading}>
                                                    {isLoading ? <Loader /> : <i className='fa fa-save'></i>}</Btn>
                                            </div> 
                      </Col>
                      
                      {/* <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                            <SelectPicker placeholder="Search By Type" 
                            onChange={(e)=>{handleChange("type",e)}}
                            style={{ width: "100%" }}
                            data={statuss} 
                            //  loading={isLoading.boxes} 
                         />
                      </Col>

                      <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                            <SelectPicker placeholder="Search By Record" 
                            onChange={(e)=>{handleChange("type_id",e)}}
                            style={{ width: "100%" }}
                            data={records} 
                            //  loading={isLoading.boxes} 
                         />
                      </Col> */}

                    </Row>
                    
      
                    </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                        <HeaderCard title="Upload Video" />

                            <CardBody className="fileUploader">
                                <Files
                                    className='files-dropzone fileContainer'
                                    onChange={onFilesChange}
                                    onError={onFilesError}
                                    accepts={['video/*']}
                                    multiple={false}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    {
                                        files.length > 0
                                            ? <div className='files-gallery'>
                                                {files.map((file, index) =>
                                                    <div key={index}>
                                                            <video className="files-gallery-item" controls>
                                                                    <source src={file.preview.url} />
                                                            </video>
                                                            
                                                    </div>
                                                )}
                                            </div>

                                            : <div className="d-flex justify-content-center">
                                                <Btn attrBtn={{ className: "mt-2", type: "button", color: 'primary' }} >Edit Video</Btn>
                                            </div>
                                    }
                                </Files>
                                {files.length > 0 ?
                                    <div className="d-flex justify-content-center">
                                        <Btn attrBtn={{ className: "mt-2", color: 'danger', type: "button", onClick: () => deleteFile(files) }} >
                                            Delete
                                        </Btn></div> : ''}
                            </CardBody>
                        </Card>
                        
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default EditVideo;
