import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import { MonthlyProfitsGrowth, MonthlyProfitsTitle } from '../../../Constant';
import { MonthlyProfitsChartData } from '../../../Data/Ecommerce/Chart';
import {Box8} from '../../Statistics/chart'

const Orderperstatus = () => {
  return (
    // <Card>
    //   <CardHeader className='card-no-border'>
    //     <H5>Shakes Per Category</H5>
    //     <span className='f-light f-w-500 f-14'>({MonthlyProfitsGrowth})</span>
    //   </CardHeader>
    //   <CardBody className='pt-0'>
    //     <div className='monthly-profit'>
    //       <ReactApexChart type='donut' height={300} series={MonthlyProfitsChartData.series} options={MonthlyProfitsChartData.options} />
    //     </div>
    //   </CardBody>
    // </Card>
    <Card>
            <CardHeader className='card-no-border'>
                <H5>Order Per Status</H5>
            </CardHeader>
            <CardBody className='pt-0'>
                <div className='monthly-profit'>
                <ReactApexChart type='donut' height={300} series={Box8().series} options={Box8().options} />
                </div>
            </CardBody>
     </Card>
  );
};

export default Orderperstatus;
