import React, { Fragment } from 'react';
import CountdownComponent from './CountdownData';
import { Container } from 'reactstrap';
import { WE_ARE_COMING_SOON } from '../../../Constant';
import { H5, } from '../../../AbstractElements';

const ComingsoonVideo = (props) => {
  return (
    <Fragment>
      <div className='page-wrapper'>
        <Container fluid={true} className='p-0'>
          <div className='comingsoon auth-bg-video'>
            
            <div className='comingsoon-inner text-center'>
              <H5>{WE_ARE_COMING_SOON}</H5>
              <div className='countdown' id='clockdiv'>
                <CountdownComponent />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default ComingsoonVideo;
