import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Breadcrumbs, Btn,Spinner } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
//files
//Form Component

import { SelectPicker, Loader} from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
// Editor Wyswigy 
import { EditorState, convertToRaw,ContentState ,convertFromHTML} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useParams} from "react-router-dom"


import {  useNavigate } from 'react-router-dom';
import { Switch } from '@mui/material';

import { FaArrowLeft } from 'react-icons/fa';


const Add_Country = ()=>{
    const navigate = useNavigate()
    const {id} = useParams()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [langauges, setLangauges] = useState([])
    const [formData, setFormData]  = useState({
        name:"",
        code:"",
        phone_code:"",
        is_active: "1", 
    })
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [editors_keys, setEditorsKeys] = useState(['description','short_description','specifications']);
    const permissions = JSON.parse(localStorage.getItem('permission'));

    useEffect(() => {
        if (id === ":id" && (!permissions || !permissions.includes('country.add'))) {
            navigate(`/Forbidden`);
        }else{
        if (id !== ":id") {
            get_details();
        }
    }
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);
            if (id === ":id") {
                setDataToEditorState(prev => {
                    array.forEach((e) => {
                        prev[e.id] = {
                            name: { id: 0, value: "" },
                        };
                    });
                    return prev;
                });
            }
        }
    }, []);

    const get_details = async ()=>{
        setIsLoadingDetailes(true)
        const {response,message} = await Helper.Get({
            url:api_Routes.country.getOne(id),
            hasToken:true,
            data:{detailed:1}
        })
        if(response){
            var details_languages = {}
            console.log(response.data)
            setPage(response.data)    
            setFormData({
                name:response.data.name,
                phone_code:response.data.phone_code,
                code:response.data.code, 
                is_active:response.data.is_active,  
            })
              
            response.data.detailed_translations.forEach(ele=>{
                ele.forEach(dt=>{
                    if (!details_languages[dt.language_id])
                        details_languages[dt.language_id] = {}

                    if(editors_keys.includes(dt.text_type))
                    {
                        details_languages[dt.language_id][dt.text_type] = {
                            value:dt.value ?
                                EditorState.createWithContent(
                                    ContentState.createFromBlockArray(
                                        convertFromHTML(dt.value)
                                    )
                                ) : EditorState.createEmpty(),
                            id:dt.id
                        }

                    }else
                        details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                })
            })
           
    
            
            setDataToEditorState(details_languages)

            setIsLoadingDetailes(false)
        }else{
            if(typeof message =="string"){
                toast.error(message)
            }else{
                Object.keys(message).forEach(msg=>{
                    toast.error(`${message[msg]}:${msg}`)
                })
            }
        }
    }
    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
                if(editors_keys.includes(key))
                    valueTemp = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value)))
                else
                    valueTemp  = value
                
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };

        const handleSubmit = async () => {
            let exit = false

            if (!formData.name || !formData.phone_code || !formData.code  ) {
        toast.error("Please fill in all required fields (Name, Phone Code , Code).");
        setIsLoading(false);
        return;
    }
            console.log(formData);
            setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''
            console.log(updatedFormData)
    
         // form_data.append("country_ids", JSON.stringify(formData.country_ids));


            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                    if(editors_keys.includes(subKey))
                        valueTemp = draftToHtml(convertToRaw(dataToEditorState[key][subKey].value.getCurrentContent()))
                        else {
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            } else {
                                 exit = true
                            }
                        }
                    if(id === ":id"){
                        form_data.append(`languages[${key}][${subKey}]`, valueTemp);
                    }
                    else
                        form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
                })
               // const editorContent = draftToHtml(convertToRaw(dataToEditorState[key].description.value.getCurrentContent()));

                // Append the editor content to the form data
                // form_data.append('editor_content', editorContent);
            })
            Object.keys(updatedFormData).forEach((key) => {
                if (key === "file")
                    form_data.append("file", updatedFormData.file);
                else
                    form_data.append(key, updatedFormData[key]);
            });

            if (id === ":id") {
                form_data.append("_method", "PUT");
            }
            if(exit){
                toast.error("You should add the name ");
                setIsLoading(false)
                return;
            }

            const { response, message } = await Helper.Post({
                url: id === ":id" ? api_Routes.country.add : api_Routes.country.update(id),
                data: form_data,
                hasToken: true
            });

            if (response) {
                if (id === ":id") {
                    toast.success("Successfully added !!");
                    navigate(`/country-list/:id/order`);
                  } else {
                    toast.success("Successfully edited !!");
                    navigate(`/country-list/:id/order`);
                  }
                setIsLoading(false);
            } else {
                if (typeof message === "string") {
                    toast.error(message);
                } else {
                    Object.keys(message).forEach((i) => {
                        const errorMessage = message[i][0] || "";
                        toast.error(`${i} : ${errorMessage}`);
                    });
                }
                setIsLoading(false);
            }
            };  
            const handleGoBack = ()=>{
                navigate(`/country-list/:id/order`)
            }
            return (
                <Fragment>
                    <Breadcrumbs mainTitle={id==":id"?"Add Country":"Edit Country"} parent="Country" title={id==":id"?"Add":"Edit"} />
                    {
                        isLoadingDetiales?<div className="loader-box">
                        <Spinner attrSpinner={{ className: "loader-26" }} />
                    </div>:<>
                    {/* Basic Info */}
                    <Container  className='mb-3' fluid={true}>
                    <Btn 
            attrBtn={{
                className: 'btn btn-primary justify-content-start text-white d-flex align-items-center   btn-mail w-40',
                style: { padding: '10px 20px', width:"fit-content",position:"fixed" , zIndex:"2" , bottom:"30px", right:"30px"},
                onClick: handleGoBack
            }}
            >
            <FaArrowLeft /> Back
            </Btn>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <Row>
                                        <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                                            <HeaderCard title="basic Info" />
                                        </Col>

                                        {( permissions.includes('country.edit')) && ( 
                                       <Col className='d-flex justify-content-center align-items-center ' lg={2} xl={2}  md={2} sm={2} xs={2}>
                                       <Btn attrBtn={{  className: "h-50", color: 'danger', type: "button", onClick: () => !isLoading && handleSubmit() }} 
                                        disabled={isLoading}
                                       >
                                       {isLoading ? <Loader /> : <i className='fa fa-save'></i>}
                                       </Btn>
                                      </Col>
                                            )}
                                    </Row>
                                    
                                    <CardBody className="add-post">
        
                                        {/* Basic Info */}
                                        <Form className="needs-validation">
                <Col>
                    <FormGroup>
                        <Label for="validationCustom01">Name</Label>
                        <Input style={{width:"100%"}}
                            value={formData.name}
                            onChange={(e) => handleChange("name", e.target.value, null)}
                            className="form-control"
                            id="validationCustom01"
                            type="text"
                            placeholder="Name"
                            required
                        />
                    </FormGroup>
                </Col>
            <Row>
                <Col>
                    <FormGroup>
                        <Input
                        style={{width:"100%"}}
                            value={formData.code}
                            onChange={(e) => handleChange("code", e.target.value, null)}
                            className="form-control"
                            
                            id="validationCustom02"
                            type="text"
                            placeholder="Code"
                            required
                        />
                    </FormGroup>
                </Col>
                <Col >
                    <FormGroup>
                    <Input
                        style={{width:"100%"}}
                            value={formData.phone_code}
                            onChange={(e) => handleChange("phone_code", e.target.value, null)}
                            className="form-control"
                            
                            id="validationCustom02"
                            type="text"
                            placeholder="Phone Code"
                            required
                        />
                    </FormGroup>
                </Col>
            </Row>
                <Col>
                    <FormGroup>
                        <Label for="validationCustom04">Is_Active</Label>
                        <div>
                            <Switch
                                checked={formData.is_active == "1"  ? true : false}
                                onChange={(e) => handleChange("is_active", e.target.checked?"1":"0")}
                            />
                        </div>
                    </FormGroup>
                </Col>
        </Form>                 
                                    </CardBody>
                                </Card>
                            </Col>
                            
                            
                        </Row>
                        
                        
                    </Container>
                    {/* languages */}
                    {
                        Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang,i)=>(<Container className='mb-3' fluid={true}>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <HeaderCard title={`${lang.name} information`} />
                                    <CardBody className="add-post">
                                        <Form className="row needs-validation">
                                                <Col sm="12">
                                                    <FormGroup>
                                                       <Label for="validationCustom01">Name</Label>
                                                      <Input value={id==":id"?dataToEditorState[JSON.stringify(lang.id)].name.value:dataToEditorState[JSON.stringify(lang.id)].name.value} 
                                                            onChange={(e)=>{handleChange("name",e.target.value,lang.id,id==":id"?null:dataToEditorState[JSON.stringify(lang.id)].name.id,0)}} className="form-control" id="validationCustom01" type="text" placeholder={`${lang.name} name`} required="" />
                                                             <div className="valid-feedback">{"Looks good!"}</div>
                                                   </FormGroup>
                                                </Col>
                                            </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>))
                    }
                    </>
                    }
                </Fragment>
            );

} 
export default Add_Country;
