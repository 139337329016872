const Host = "https://api.winshakes.com/api"
// const Host = "https://staging-api.winshakes.com/api";

export const api_Routes = {
    auth:{
        login:`${Host}/auth/dashboard`
    },
    verified:{
        by_phone:`${Host}/verification/sendByPhone`,
        confirm_code:`${Host}/verification/confirm`,
        reset_password:`${Host}/auth/forgottenPassword`
    },
    address:{
        view:`${Host}/userAddresses`,
        getOne:(id)=>(`${Host}/userAddresses/${id}`),
    },
    financials:{
        view:`${Host}/financials`,
    },
    statistics:{
        view:`${Host}/statistics/weeklyOrder`,
        view0:`${Host}/statistics/monthlyOrders`,
        view1:`${Host}/statistics/counts`,
        view2:`${Host}/statistics/weeklyVendors`,
        view3:`${Host}/statistics/monthlyVendors`,
        view4:`${Host}/statistics/weeklyUserShakes`,
        view5:`${Host}/statistics/monthlyUserShakes`,
        view6:`${Host}/statistics/shakesPerCategory`,
        view7:`${Host}/statistics/monthlyShakesPerResultType`,
        view8:`${Host}/statistics/monthlyOrdersPerStatus`,
        view9:`${Host}/statistics/ordersPerStatus`,
    },
    maillist:{
        view:`${Host}/mailLists`,
        getOne:(id)=>(`${Host}/mailLists/${id}`),
        add:`${Host}/mailLists`,
        update:(id)=>(`${Host}/mailLists/${id}`),
        bulkDelete:(id)=>(`${Host}/mailLists/${id}`),
    },
    notification:{
        view:`${Host}/notificationTexts`,
        getOne:(id)=>(`${Host}/notificationTexts/${id}`),
        add:`${Host}/notificationTexts`,
        update:(id)=>(`${Host}/notificationTexts/${id}`),
        bulkDelete:(id)=>(`${Host}/notificationTexts/${id}`),
    },
    messages:{
        view:`${Host}/messages`,
        message_getOne:(id)=>(`${Host}/messages/${id}`),
        getByConversation:(id)=>(`${Host}/messages/byConversationId/${id}`),
        messages_by_conversation:(id)=>(`${Host}/messages/byConversationId/${id}`),
        sendMessage:`${Host}/messages/send`,
    },
    converstaions:{
        view:`${Host}/conversations`,
        getOne:(id)=>(`${Host}/conversations/${id}`),
        getByAthunticated:`${Host}/conversations/ByAuthenticatedUser`,

    },
    photos:{
        bulk:`${Host}/photos/bulk`,
        view:`${Host}/photos`,
        update:(id)=>(`${Host}/photos/${id}`),
        getOne:(id)=>(`${Host}/photos/${id}`),
        add:`${Host}/photos`,
    },
    videos:{
        bulk:`${Host}/videos/bulk`,
        view:`${Host}/videos`,
        update:(id)=>(`${Host}/videos/${id}`),
        getOne:(id)=>(`${Host}/videos/${id}`),
        add:`${Host}/videos`,
    },
    shakes:{
        view:`${Host}/userShakes`,
        update:(id)=>(`${Host}/userShakes/${id}`),
        getOne:(id)=>(`${Host}/userShakes/${id}`)

    },
    order:{
        view:`${Host}/orders`,
        getOne:(id)=>(`${Host}/orders/${id}`),
        update:(id)=>(`${Host}/orders/${id}`),
    },
    setting:{
        view:`${Host}/settings/list`,
        update:`${Host}/settings`,
    },
    role:{
        view:`${Host}/roles`,
        add:`${Host}/roles`,
        update:(id)=>(`${Host}/roles/${id}`),
        getOne:(id)=>(`${Host}/roles/${id}`),
        bulkDelete:(id)=>(`${Host}/roles/${id}`),

    },
    permission:{
        add:`${Host}/permissions/assign`,
        getpermissionsbyrole:(id)=>(`${Host}/permissions/${id}`),
    },
    country:{
        view:`${Host}/countries`,
        getOne:(id)=>(`${Host}/countries/${id}`),
        add:`${Host}/countries`,
        update:(id)=>(`${Host}/countries/${id}`),
        bulkDelete:(id)=>(`${Host}/countries/${id}`),

    },
    shipping:{
        view:`${Host}/shippingCompanies`,
        getOne:(id)=>(`${Host}/shippingCompanies/${id}`),
        update:(id)=>(`${Host}/shippingCompanies/${id}`),
        bulk:(id)=>(`${Host}/shippingCompanies/${id}`),
        add:`${Host}/shippingCompanies/addShippingCompany`,
    },
    shippingoption:{
        view:`${Host}/shippingOptions`,
        getOne:(id)=>(`${Host}/shippingOptions/${id}`),
        update:(id)=>(`${Host}/shippingOptions/${id}`),
        bulk:(id)=>(`${Host}/shippingOptions/${id}`),
        add:`${Host}/shippingOptions`,
        },
    balance:{
        view_type:`${Host}/balances/types/`,
        getOne:(id)=>(`${Host}/balances/${id}`),
        view:`${Host}/balances`,
        store:`${Host}/balances`,
        bulk:`${Host}/balances/bulkDelete`,
        update:(id)=>(`${Host}/balances/${id}`),
        // getOne:(id)=>(`${Host}/userShakes/${id}`)
    },
    redeemRequest:{
        view:`${Host}/redeemRequests`,
        getOne:(id)=>(`${Host}/redeemRequests/${id}`),
        accept:(id)=>(`${Host}/redeemRequests/accept/${id}`),
        reject:(id)=>(`${Host}/redeemRequests/reject/${id}`),
    },
    language:{
        view:`${Host}/languages`,
        all:`${Host}/languages/all`,
        add:`${Host}/languages`,
        getOne:(id)=>(`${Host}/languages/${id}`)
    },
    user:{
        view:`${Host}/users`,
        show:`${Host}/users/topVendors`,
        all:`${Host}/users/all`,
        add:`${Host}/users`,
        bulkDelete:`${Host}/users/bulkDelete`,
        update:(id)=>(`${Host}/users/${id}`),
        updateVeified:(id)=>(`${Host}/verify/${id}`),
        getOne:(id)=>(`${Host}/users/${id}`)
    },
    box:{
        view:`${Host}/boxes`,
        all:`${Host}/boxes/all`,
        add:`${Host}/boxes`,
        bulk:`${Host}/boxes/bulkDelete`,
        update:(id)=>(`${Host}/boxes/${id}`),
        getOne:(id)=>(`${Host}/boxes/${id}`)
    },
    category_box:{
        view:`${Host}/boxCategories`,
        all:`${Host}/boxCategories/all`,
        add:`${Host}/boxCategories`,
        bulk:`${Host}/boxCategories/bulkDelete`,
        update:(id)=>(`${Host}/boxCategories/${id}`),
        getOne:(id)=>(`${Host}/boxCategories/${id}`)
    },
    product:{
        view:`${Host}/products`,
        viewbox:`${Host}/products/boxes`,
        all:`${Host}/products/all`,
        add:`${Host}/products`,
        bulkDelete:`${Host}/products/bulkDelete`,
        update:(id)=>(`${Host}/products/${id}`),
        getOne:(id)=>(`${Host}/products/${id}`),
        availableBoxesForBoxes:`${Host}/products/AvailableProductsForBoxes`
        
    },
    category:{
        view:`${Host}/categories`,
        all:`${Host}/categories/all`,
        add:`${Host}/categories`,
        bulk:`${Host}/categories/bulkDelete`,
        update:(id)=>(`${Host}/categories/${id}`),
        getOne:(id)=>(`${Host}/categories/${id}`)
    },
    brand:{
        view:`${Host}/brands`,
        all:`${Host}/brands/all`,
        add:`${Host}/brands`,
        bulk:`${Host}/brands/bulkDelete`,
        update:(id)=>(`${Host}/brands/${id}`),
        getOne:(id)=>(`${Host}/brands/${id}`)
    },
    page:{
        view:`${Host}/pages`,
        all:`${Host}/pages/all`,
        add:`${Host}/pages`,
        bulk:`${Host}/pages/bulkDelete`,
        update:(id)=>(`${Host}/pages/${id}`),
        getOne:(id)=>(`${Host}/pages/${id}`)
    },
    section:{
        view:`${Host}/pagesSections`,
        all:`${Host}/pagesSections/all`,
        add:`${Host}/pagesSections`,
        bulk:`${Host}/pagesSections/bulkDelete`,
        update:(id)=>(`${Host}/pagesSections/${id}`),
        getOne:(id)=>(`${Host}/pagesSections/${id}`)
    },
}
