import React ,{Fragment} from 'react';
import { CardHeader, Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../AbstractElements';

function Details_product({id}) {
    return (
    <Fragment> 
    <Breadcrumbs parent="Details" title="List" mainTitle="Products" />
    <Container className='my-2' fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
           <CardHeader>
           Details Product{id}
           </CardHeader>
            <CardBody>
            
            

            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>

  </Fragment>);
}

export default Details_product;