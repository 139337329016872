

const CheckLayout = () => {
 
 

  const handlePageLayputs = (type) => {
 
  };

  return 
};

export default CheckLayout;
