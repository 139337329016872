import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Breadcrumbs, Btn,Spinner } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
//files
import { Link, useNavigate } from 'react-router-dom';

//Form Component

import {  Loader} from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
// Editor Wyswigy 
import { EditorState, convertToRaw,ContentState ,convertFromHTML} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useParams} from "react-router-dom"
import { Switch } from '@mui/material';
import { FaArrowLeft } from 'react-icons/fa';

const Add_Role = ()=>{
    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [box, setBox] = useState({})
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData]  = useState({
        name:"", 
        website:"1",
    })
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [editors_keys, setEditorsKeys] = useState(['description','short_description','specifications']);
    const permissions = JSON.parse(localStorage.getItem('permission'));

    useEffect(() => {
        if (id === ":id" && (!permissions || !permissions.includes('role.add'))) {
            navigate(`/Forbidden`);
        } else {
            if (id !== ":id") {
                get_details();
            }
        }
    }, []);

    const get_details = async ()=>{
        setIsLoadingDetailes(true)
        const {response,message} = await Helper.Get({
            url:api_Routes.role.getOne(id),
            hasToken:true,
            data:{detailed:1}
        })
        if(response){
            var details_languages = {}
            console.log(response.data)
            setPage(response.data)    
            setFormData({
                name:response.data.name,
                website:response.data.website,
                  
            })
              
            response.data.detailed_translations.forEach(ele=>{
                ele.forEach(dt=>{
                    if (!details_languages[dt.language_id])
                        details_languages[dt.language_id] = {}

                    if(editors_keys.includes(dt.text_type))
                    {
                        details_languages[dt.language_id][dt.text_type] = {
                            value:dt.value ?
                                EditorState.createWithContent(
                                    ContentState.createFromBlockArray(
                                        convertFromHTML(dt.value)
                                    )
                                ) : EditorState.createEmpty(),
                            id:dt.id
                        }

                    }else
                        details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                })
            })
           
    
            
            setDataToEditorState(details_languages)

            setIsLoadingDetailes(false)
        }else{
            if(typeof message =="string"){
                toast.error(message)
            }else{
                Object.keys(message).forEach(msg=>{
                    toast.error(`${message[msg]}:${msg}`)
                })
            }
        }
    }
    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
                if(editors_keys.includes(key))
                    valueTemp = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value)))
                else
                    valueTemp  = value
                
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };

        const handleSubmit = async () => {

            console.log(formData);
            setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''
            console.log(updatedFormData)

            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                    if(editors_keys.includes(subKey))
                        valueTemp = draftToHtml(convertToRaw(dataToEditorState[key][subKey].value.getCurrentContent()))
                    else
                        valueTemp  = dataToEditorState[key][subKey].value ? dataToEditorState[key][subKey].value : null

                    if(id === ":id"){
                        form_data.append(`languages[${key}][${subKey}]`, valueTemp);
                    }
                    else
                        form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
                })
            })
            Object.keys(updatedFormData).forEach((key) => {
                if (key === "file")
                    form_data.append("file", updatedFormData.file);
                else
                    form_data.append(key, updatedFormData[key]);
            });

            if (id === ":id") {
                form_data.append("_method", "PUT");
            }

            const { response, message } = await Helper.Post({
                url: id === ":id" ? api_Routes.role.add : api_Routes.role.update(id),
                data: form_data,
                hasToken: true
            });

            if (response) {
                if (id === ":id") {
                    toast.success("Successfully added !!");
                    navigate(`/role-list/:id/order`);
                  } else {
                    toast.success("Successfully edited !!");
                    navigate(`/role-list/:id/order`);
                  }
                setIsLoading(false);
            } else {
                if (typeof message === "string") {
                    toast.error(message);
                } else {
                    Object.keys(message).forEach((i) => {
                        const errorMessage = message[i][0] || "";
                        toast.error(`${i} : ${errorMessage}`);
                    });
                }
                setIsLoading(false);
            }
            };  
            const handleGoBack = ()=>{
                navigate(`/role-list/:id/order`)
            }
            return (
                <Fragment>
                    <Breadcrumbs mainTitle="Add Role" parent="Role" title="Add" />
                    {
                        isLoadingDetiales?<div className="loader-box">
                        <Spinner attrSpinner={{ className: "loader-26" }} />
                    </div>:<>
                    {/* Basic Info */}
                    <Container  className='mb-3' fluid={true}>
                   
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <Row>
                                        <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                                            <HeaderCard title="basic Info" />
                                        </Col>
                                        {(permissions.includes('role.edit')) && ( 
                                           <Col className='d-flex justify-content-center align-items-center ' lg={2} xl={2}  md={2} sm={2} xs={2}>
                                           <Btn attrBtn={{  className: "h-50", color: 'danger', type: "button", onClick: () => !isLoading && handleSubmit() }} 
                                            disabled={isLoading}
                                           >
                                           {isLoading ? <Loader /> : <i className='fa fa-save'></i>}
                                           </Btn>
                                          </Col>
                                        )}
                                    </Row>
                                    
                                    <CardBody className="add-post">
        
                                        {/* Basic Info */}
                                        <Form className="row needs-validation">
                                                <Col sm="12">
                                                <FormGroup>
                                                    <Label for="validationCustom01">Role Name</Label>
                                                    <Input value={formData.name} onChange={(e)=>{handleChange("name",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="name" required="" />   
                                                </FormGroup>                                
                                                </Col>
                                                <Col xs={12} sm={4} md={4} lg={4} xl={4} >
                                                    <Label for="validationCustom01">Website</Label>
                                                    <div> 
                                                        <Switch checked={formData.website=="1"?true:false} onChange={(e)=>{handleChange("website",e.target.checked?"1":"0",null)}}  />
                                                     </div>
                                                </Col>
                                            </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>    
                    </Container>
                    </>
                    }
                </Fragment>
            );

}
export default Add_Role