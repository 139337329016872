import React, { Fragment,useEffect,useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row,Table } from 'reactstrap';
import { Breadcrumbs,H3,H5, Btn, P, Image, UL, LI, H6, Spinner } from '../../AbstractElements';
import { CheckCircle } from 'react-feather';
//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
//format date
import dateFormat from "dateformat";
import { toast, ToastContainer } from 'react-toastify';
import { Loader } from 'rsuite';
import { useNavigate } from 'react-router';
import { FaArrowLeft } from 'react-icons/fa';


function Details_Shakes({id}) {
  const navigate = useNavigate()
    const [detailsInfo, setDetailsInfo] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [isLoading_Type, setIsLoading_Type] = useState(false)
     useEffect(()=>{
        get_Details()
    },[])

    const handleMenuItemClick = (path) => {
        navigate(path);
      };
    const get_Details = async ()=>{

        const {response, message} = await Helper.Get({
            url:api_Routes.shakes.getOne(id),
            hasToken:true,
        })
        if(response){
            console.log(response.data);
            setDetailsInfo(response.data)
               
           
            setIsLoading(false)
        }else{
            console.log(message);
        }
    }
    const handleChangeType = async ()=>{
        setIsLoading_Type(true)
        const {response, message} = await Helper.Post({
            url:api_Routes.shakes.update(id),
            hasToken:true,
            data:{result_type:"4"}
        })
        if(response){
            console.log(response.data);
            setDetailsInfo(response.data)   
            toast.success("Successfully change it to delivered")
            setIsLoading_Type(false)
        }else{
            console.log(message);
        }

    }
   
    return ( <Fragment>
        <Breadcrumbs mainTitle='Details' parent='details' title='Shake' />
        {isLoading?<div className="loader-box">
                                            <Spinner attrSpinner={{ className: "loader-26" }} />
                                        </div>:<>
        
               
        <Container fluid={true} className='email-wrap bookmark-wrap todo-wrap'>
        <Row className='d-flex justify-content-around'>
            {/* User Info */}
            <Col xl='12' >
                <Card>
                    <CardBody style={{ borderBottom: "1px solid #e9e9e9" }}>
                    <div className='email-app-sidebar left-bookmark custom-scrollbar'>
                        <div className='d-flex align-items-center' >
                        <div className='media-size-email'>
                            <Image attrImage={{ className: 'me-3 img-40 rounded-circle', src:detailsInfo?.user_photo ? detailsInfo.user_photo:"" , alt: '' }} />
                        </div>
                        <div className='flex-grow-1'>
                            <H6 attrH6={{ className: 'f-w-600' }}>
                                 <a 
                                    href={`/user-list/Shake?id=${detailsInfo.user_id}`}>{`${detailsInfo.user_name} (${detailsInfo.user_username})`}
                                 </a> 
                            </H6>                       
                         </div>
                        <div>
                        <Btn 
                            attrBtn={{
                                color: 'primary',
                                className: 'badge-light justify-content-start text-white d-flex align-items-center btn-mail w-40',
                                style: { padding: '10px 20px' },
                                onClick:()=>handleMenuItemClick(`/user-list/Shake?id=${detailsInfo.user_id}`)
                            }}
                        >
                            view
                        </Btn>
                        </div>
                        </div>
                        {detailsInfo.user_phone &&
                        <UL attrUL={{ className: 'simple-list nav main-menu' }}>
                        <LI attrLI={{ className: 'border-0 nav-item mt-3' }}>
                            <i style={{color:"#D80621"}} className={`fa fa-phone`}></i>
                            <span className='title ms-2'>{detailsInfo?.user_phone}</span>
                        </LI>
                     
                        </UL>
                        }
                    </div>
                    </CardBody>
                    <CardBody>
                    <div className='email-app-sidebar left-bookmark custom-scrollbar'>
                        <div className='d-flex align-items-center'>
                        <div className='media-size-email'>
                            <Image attrImage={{ className: 'me-3 img-40 rounded-circle', src:detailsInfo?.category_photo ? detailsInfo.category_photo:"" , alt: '' }} />
                        </div>
                        <div className='flex-grow-1'>
                            <H6 attrH6={{ className: 'f-w-600' }}> <a href={`/box-list/Shake?id=${detailsInfo.box_id}`}>{detailsInfo?.category_translations["en"]?.name}</a> </H6> 
                            <P>{detailsInfo?.category_price}</P>
                        </div> 
                        <div>
                        <Btn attrBtn={{ color: 'primary', className: 'badge-light justify-content-start text-white d-flex align-items-center btn-mail w-40 ', style: { padding: '10px 20px' } ,
                        onClick:()=>handleMenuItemClick(`/box-list/Shake?id=${detailsInfo.box_id}`)}}>  
                                view
                            </Btn>
                        </div>
                        </div>
                        <UL attrUL={{ className: 'simple-list nav main-menu' }}>
                        <LI attrLI={{ className: 'border-0 nav-item mt-4' }}>
                            <i style={{color:"#D80621"}} className={`fa fa-calendar-o`}></i>
                            <span className='title ms-2'>
                                { dateFormat(detailsInfo?.category_created_at,"dddd, mmmm dS, yyyy")}
                            </span>
                        </LI>
                        

                        </UL>
                    </div>
                    </CardBody>
                </Card>
            </Col>
            {/* Category Info */}
            {/* <Col xl='5'>
            <Card>
                    <CardBody>
                    <div className='email-app-sidebar left-bookmark custom-scrollbar'>
                        <div className='d-flex align-items-center'>
                        <div className='media-size-email'>
                            <Image attrImage={{ className: 'me-3 img-40 rounded-circle', src:detailsInfo?.category_photo ? detailsInfo.category_photo:"" , alt: '' }} />
                        </div>
                        <div className='flex-grow-1'>
                            <H6 attrH6={{ className: 'f-w-600' }}>{detailsInfo?.category_translations["en"]?.name}</H6>
                            <P>{detailsInfo?.category_price}</P>
                        </div> 
                        <div>
                        <Btn attrBtn={{ color: 'primary', className: 'badge-light justify-content-start text-white d-flex align-items-center btn-mail w-40 ', style: { padding: '10px 20px' } ,
                        onClick:()=>handleMenuItemClick(`/box-list/Shake?id=${id}`)}}>  
                                view
                            </Btn>
                        </div>
                        </div>
                        <UL attrUL={{ className: 'simple-list nav main-menu' }}>
                        <LI attrLI={{ className: 'border-0 nav-item mt-4' }}>
                            <i className={`fa fa-calendar-o`}></i>
                            <span className='title ms-2'>
                                { dateFormat(detailsInfo?.category_created_at,"dddd, mmmm dS, yyyy")}
                            </span>
                        </LI>
                        

                        </UL>
                    </div>
                    </CardBody>
                </Card>
            </Col> */}
            </Row>
            
            <Row>
            {/* Details Shake */}
            <Col xl='12' >
                <Card>
                <CardHeader>
                    <div className='d-flex align-items-center justify-content-between'>
                        <H5>Details Shake</H5>
                        {detailsInfo.result_type == 3 &&
                   
                        <Btn attrBtn={{ color: 'primary', className: 'badge-light justify-content-start text-white d-flex align-items-center btn-mail w-40',onClick:()=>handleChangeType() }}>
                        <CheckCircle className='me-2' />
                            {isLoading_Type?<Loader/>:"Marke as Deilivered"}
                        </Btn>
                    
                    }
                    </div>  
                    </CardHeader>
                    <CardBody>
                        {detailsInfo.result_type == 1?<span className={`badge badge-light-primary p-3 mb-2 `} style={{ fontSize: '15px' }}>Pendding</span>:
                                        (detailsInfo.result_type==2?<span className={`badge badge-light-primary p-3 mb-2`} style={{ fontSize: '15px' }}>opened</span>:
                                        (detailsInfo.result_type==3?<span style={{background:"#7366ff",color:"white",
                                        fontSize: "10px",
                                        padding: "1px 5px",
                                        borderRadius: "7px"}} >delivery</span>:
                                        (detailsInfo.result_type==4?<span className={`badge badge-light-success`}>delivered</span>:
                                        <span className={`badge badge-light-success`}>sold</span>)))}
                        
                 
                    <div className='p-2 d-flex'>
                                    <H6>Opened : </H6>
                                    <p style={{marginLeft:"10px"}}>{detailsInfo.opened==0?"No":"Yes"}</p>
                                </div>
                    <div className='p-2 d-flex'>
                        <H6>Deliveried  : </H6>
                        <p style={{marginLeft:"10px"}}>{detailsInfo.delivered==0?"No":"Yes"}</p>
                    </div>
                    <div className='p-2 d-flex'>
                        <H6>Delivery Date  : </H6>
                        <p style={{marginLeft:"10px"}}>{dateFormat(detailsInfo.delivery_date,"dddd, mmmm dS, yyyy")}</p>
                    </div>
                    <div className='p-2 d-flex'>
                        <H6>Created At  : </H6>
                        <p style={{marginLeft:"10px"}}>{dateFormat(detailsInfo.created_at,"dddd, mmmm dS, yyyy")}</p>
                    </div>
                    
        
                    
                    

                    </CardBody>
                </Card>
            </Col>
            {/* Address  */}
            {(detailsInfo.result_type == 5 || detailsInfo.result_type == 1  ) ? <div></div> :
           <Col xl='12' >
           <Card>
             <CardHeader>
                   <H5>Address</H5>
             </CardHeader>
               <CardBody>
                   <Row>
                      
                      {detailsInfo.user_addresses_city?
                           <Col lg={4} sm={6} xs={12} md={4}>
                               <div className='p-2 d-flex'>
                                   
                                       <p>
                                           <P>City : {detailsInfo.user_addresses_city}</P>
                                       </p>
                               </div>
                           </Col>
                            :""    
                            }
                            {detailsInfo.user_addresses_floor ?
                           <Col lg={4} sm={6} xs={12} md={4}>
                               <div className='p-2 d-flex'>
                                   
                                       <p>
                                           <P>Floor : {detailsInfo.user_addresses_floor}</P>
                                       </p>
                               </div>
                           </Col>
                        :""    
                        }
                        {detailsInfo.user_addresses_zipcode?
                           <Col lg={4} sm={6} xs={12} md={4}>
                               <div className='p-2 d-flex'>
                                   
                                       <p>
                                           <P>Zipcode : {detailsInfo.user_addresses_zipcode}</P>
                                       </p>
                               </div>
                           </Col>
                        :""    
                        }
                        {detailsInfo.user_addresses_street?
                           <Col lg={4} sm={6} xs={12} md={4}>
                               <div className='p-2 d-flex'>
                                   
                                       <p>
                                           <P>Street : {detailsInfo.user_addresses_street}</P>
                                       </p>
                               </div>
                           </Col>
                        :""    
                        }
                        {detailsInfo.user_addresses_area?
                           <Col lg={4} sm={6} xs={12} md={4}>
                               <div className='p-2 d-flex'>
                                   
                                       <p>
                                           <P>Area : {detailsInfo.user_addresses_area}</P>
                                       </p>
                               </div>
                           </Col>
                        :""    
                        }
                        {detailsInfo.user_addresses_building?
                           <Col lg={4} sm={6} xs={12} md={4}>
                               <div className='p-2 d-flex'>
                                   
                                       <p>
                                           <P>Building : {detailsInfo.user_addresses_building}</P>
                                       </p>
                               </div>
                           </Col>
                        :""    
                        }
                                                           
                   </Row>
                                           
               </CardBody>
            </Card>
         </Col>
                
}
            
            {/* Shipping Option */}
            {detailsInfo.result_type != 5 &&
            <Col xl='12' >
                <Card>
                    <CardHeader>
                        <H5>Shipping Option</H5>
                    </CardHeader>
                    <CardBody>
                    
                       <Row>
                        
                       {
                            
                         <Col lg={4} sm={6} xs={12} md={4}>
                                <div className='p-2 d-flex'>
                                    <H6>Shipping Option : </H6>
                                    <p style={{marginLeft:"10px"}}>{detailsInfo.shipping_options_name}</p>
                                </div>

                            </Col>
                            }
                           {detailsInfo.shippingOption_company_id ?  <Col lg={4} sm={6} xs={12} md={4}>
                                <div className='p-2 d-flex'>
                                    <H6>Company : </H6>
                                    <p style={{marginLeft:"10px"}}>{detailsInfo.shippingOption_company_id   }</p>
                                </div>

                            </Col>
                            :""
                            }
                           {  <Col lg={4} sm={6} xs={12} md={4}>
                                <div className='p-2 d-flex'>
                                    <H6>price : </H6>
                                    <p style={{marginLeft:"10px"}}>{detailsInfo.shipping_options_charge_price}</p>
                                </div>

                            </Col>
                            }
                           
                       </Row>
                       
                    </CardBody>
                </Card>
            </Col>
}
            {/* Activities */}
            <Col xl='12' >
                <Card>
                    <CardHeader>
                        <H5>Activities</H5>
                    </CardHeader>
                    <CardBody>
                    
                       <Row>
                        
                       
                       {detailsInfo.activities.map((item,index) => (
                            <React.Fragment key={item.id}>
                                <div style={{marginBottom : "15px"}}>
                                <Col lg={5} sm={12} xs={12} md={5}>
                                    <div className='p-2 d-flex'>
                                        <H6>Activity : </H6>
                                        <p style={{marginLeft:"10px"}}>{item.translations["en"]}</p>
                                    </div>
                                </Col>

                                <Col lg={5} sm={12} xs={12} md={5}>
                                    <div className='p-2 d-flex'>
                                        <H6>Date : </H6>
                                        <p style={{marginLeft:"10px"}}> {item.date}</p>
                                    </div>
                                </Col>

                                <Col lg={2} sm={12} xs={12} md={2}>
                                <div className={`p-2 d-flex `}>
                                        <H6>Amount : </H6>
                                        <p style={{marginLeft:"10px"}}>{item.amount}</p>
                                    </div>
                                </Col>

                                    <div style={{borderBottom: index !== detailsInfo.activities.length - 1 ? '1px solid #e9e9e9' : '' , marginTop:"15px"}}></div>
                             </div>
                            </React.Fragment>
                        ))}
                           
                       </Row>
                       
                    </CardBody>
                </Card>
            </Col>
            
            
        {/* products info */}
        <Col xl="12">
                <Card>
                    <CardHeader>
                        
                        <H3>Products</H3>
                        {/* <span>{"A"} <code>{".table-responsive"}</code>, <code>{'.table-light'}</code> {"inside table element."}</span> */}
                    </CardHeader>
                    <div className="card-block row">
                        <Col sm="12" lg="12" xl="12">
                            <div className="table-responsive">
                                <Table className='table-light'>
                                    <thead>
                                        <tr>
                                            <th scope="col">Photo</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Sell Price</th>
                                            <th scope="col">Origin Price</th>
                                        </tr>
                                    </thead>
                                       <tbody>
                                            
                                                    {detailsInfo.products.map((item, index) => (
                                                    <tr key={index}>
                                                    <td>
                                                        <img className='me-3 img-40 rounded-circle' src={item.photo} alt='' />
                                                    </td>
                                                    <td>{item.product_translations["en"]?.name}</td>
                                                    <td>{item.sellprice}</td>
                                                    <td>{item.originprice}</td>
                                                    </tr>
                                                ))}
                                            
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </div>
                </Card>
            </Col>


          </Row>
          <ToastContainer/>
        </Container>
        </>
        }
      </Fragment>);
}

export default Details_Shakes;
