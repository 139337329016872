import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
import { useNavigate } from 'react-router-dom';
import { Loader} from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
// Editor Wyswigy 
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Setting = () =>{
    const navigate = useNavigate()
    const [formData, setFormData]  = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const permissions = JSON.parse(localStorage.getItem('permission'));


    useEffect(() => {
        console.log('Component rendered');

        if (!permissions || !permissions.includes('setting.get')) {
            navigate(`/Forbidden`)
        } else {
            get_Setting();
        }
    }, []);

const get_Setting = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.setting.view,
            hasToken: true,
        });

        if (response) {
            console.log(response.data);
            setFormData(response.data);
        } else {
            if (typeof message === "string") {
                toast.error(message);
            } else {
                Object.keys(message).forEach(msg => {
                    toast.error(`${message[msg]}:${msg}`);
                });
            }
        }
    }

        const handleChange = (id, value) => {
            const newData = formData.map(item => {
                if (item.id === id) {
                    return { ...item, value: value };
                }
                return item;
            });
            setFormData(newData);
        }

    const formatData = () => {
        const formattedData = {};
        formData.forEach(item => {
            formattedData[item.id.toString()] = item.value;
        });
        return formattedData;
        }

   const handleSubmit = async() =>{
        const formattedData = formatData();
            const {response, message} = await Helper.Post({
            url : api_Routes.setting.update,
            data:formattedData,
            hasToken :true
          })

         if(response){

            toast.success("Successfully added !!")
            setIsLoading(false)
          }else{
            if(typeof(message) === "string"){
              toast.error(message)
            }else{
              Object.keys(message).map((i)=>{
                toast.error(`${i} : ${message[i][0]}`)
              })}
            setIsLoading(false)
          }

    }

    return (
        <Fragment>
          <Breadcrumbs mainTitle="Settings" parent="settings" title="edit" />
        <Container className='mb-3' fluid={true}>
            <Row>
                <Col sm="12">
                 <Card>
                    <Row>
                        <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                            <HeaderCard title="Settings"/>
                        </Col>
                        
                  {permissions && permissions.includes('setting.edit') && (
                        <Col className='d-flex justify-content-center align-items-center ' lg={2} xl={2}  md={2} sm={2} xs={2}>
                          <Btn attrBtn={{ className: "h-50", color: 'danger', type: "button", onClick: () => handleSubmit() }} >
                                 {isLoading?<Loader/>:<i className='fa fa-save'></i>}
                          </Btn>
                        </Col>
                  )}
                                    

                        <CardBody className="add-post">
                            <Form className="row needs-validation">
                                   {formData.map(({ id, key, value }) => (
                                        <Col sm="12" key={id}>
                                            <FormGroup>
                                                <Row>
                                                    <Col style={{marginLeft:"12px"}} xs={6} sm={6} lg={4} md={4}>
                                                        <Label for={`validationCustom${id}`}>{key}</Label>
                                                    </Col>
                                                    <Col xs={6} sm={6} lg={4} md={4}>
                                                       <Input 
                                                            style={{ width: "90%" }} 
                                                            value={value} 
                                                            onChange={(e) => { handleChange(id, e.target.value) }} 
                                                            className="form-control" 
                                                            id={`validationCustom${id}`} 
                                                            type="text" 
                                                            placeholder="Value" 
                                                            required="" 
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    ))}
                            </Form>
                        </CardBody>
                    </Row>
                 </Card>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}
export default Setting;