import React, { Fragment,useEffect,useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs, Btn, UL, LI, H6, Spinner } from '../../AbstractElements';
//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
//format date
import dateFormat from "dateformat";
import { toast } from 'react-toastify';
import CommonModal from '../../Components/Modal';
import {  useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const style2 = { width: 60, fontSize: 14, padding: 4 };


function Details_Redeem({id}){
    const [detailsInfo, setDetailsInfo] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [modall, setModall] = useState(false);
    const togglee = () => setModall(!modall);

    const [showButton, setShowButton] = useState(false);
    const permissions = JSON.parse(localStorage.getItem('permission'));

       


    useEffect(()=>{
        if (!permissions || !permissions.includes('redeem_request.get-all')) {
            navigate(`/Forbidden`)
        } else {
            get_Details()
        }
       
    },[])

    const get_Details = async()=>{
            const {response, message} = await Helper.Get({
            url:api_Routes.redeemRequest.getOne(id),
            hasToken:true,
        })
        console.log(response.data.status)

         if(response.data.status==1){
                setShowButton(true);
            }
        if(response){
            console.log(response.data);
            setDetailsInfo(response.data)         
            setIsLoading(false)
           
        }
        else{
            console.log(message);
        }
    }
    const handleaccept = async()=>{
        

        const{response,message}=await Helper.Post({
            url: api_Routes.redeemRequest.accept(id),
            hasToken:true,
            
        })
        if (response) {
            
            console.log('Redeem Accepted successfully');
            toast.success("Redeem Accepted successfully")
            navigate(`/redeem-request/:id/order`)

            setShowButton(false)
        } else {
            console.error('Error Accepted redeem:', message);
        }
    }
    const handlereject = async()=>{

        const{response,message}=await Helper.Post({
            url: api_Routes.redeemRequest.reject(id),
            hasToken:true,
            

        })
            if (response) {
            
            console.log('Redeem rejected successfully');
            toast.success("Redeem rejected successfully")
            navigate(`/redeem-request/:id/order`)
            setShowButton(false)
        } else {
            console.error('Error rejected redeem:', message);
        }

      }
      const handleGoBack = ()=>{
        navigate(`/redeemRequests/:id/Shake`)
    }
return(
        <Fragment>
            <CommonModal isOpen={modal} title="Confirm Accept" handleConfirm={handleaccept} toggler={toggle} >
                Are you sure Accept ?
            </CommonModal>
            <CommonModal isOpen={modall} title="Confirm Reject" handleConfirm={handlereject} toggler={togglee} >
                Are you sure Reject ?
            </CommonModal>
            
          <Breadcrumbs mainTitle='Details' parent='details' title='Payment Method' />
       { isLoading?
       <div className="loader-box">
          <Spinner attrSpinner={{ className: "loader-26" }} />
       </div>:
        <Container fluid={true} className='email-wrap bookmark-wrap todo-wrap'>
          
            <Row>
                {/* user_info */}
                <Col xl='12'>
                    <Card>
<CardBody>
    <div className='email-app-sidebar left-bookmark custom-scrollbar'>
        <div className='d-flex align-items-center'>
            <div className='flex-grow-1'>
            <H6 attrH6={{ className: 'f-w-600' }}>
                                 <a 
                                    href={`/user-list/Shake?id=${detailsInfo.user_id}`}>{`${detailsInfo.user.name} (${detailsInfo.user.username})`}
                                 </a> 
                            </H6> 
            </div>
        </div>
        <UL attrUL={{ className: 'simple-list nav main-menu' }}>
            <LI attrLI={{ className: 'border-0 nav-item pt-4 d-flex align-items-center' }}>
            <i style={{color:"#D80621"}} className={`fa fa-balance-scale`}></i>
                <span className='title ms-2'>Amount : {detailsInfo.amount}</span>
            </LI>
            <LI attrLI={{ className: 'border-0 nav-item d-flex align-items-center' }}>
            <i style={{color:"#D80621"}} className={`fa fa-balance-scale`}></i>
                <span className='title ms-2'>Balance When Request Sent : {detailsInfo.balance}</span>
            </LI>
            <LI attrLI={{ className: 'border-0 nav-item d-flex align-items-center' }}>
            <i style={{color:"#D80621"}} className={`fa fa-balance-scale`}></i>
                <span className='title ms-2'>Current User Balance : {detailsInfo.user_current_balance}</span>
            </LI>
            <LI attrLI={{ className: 'border-0 nav-item d-flex align-items-center' }}>
                <i style={{color:"#D80621"}} className={`fa fa-calendar`}></i>
                <span className='title ms-2'>CreatedAt : {dateFormat(detailsInfo.Created_at, "dddd, mmmm dS, yyyy")}</span>
            </LI>
            <LI attrLI={{ className: 'border-0 nav-item d-flex align-items-center' }}>
            <i style={{color:"#D80621"}} className={`fa fa-credit-card`}></i>
                <span className='title ms-2'>Payment Methode : {detailsInfo.payment_method.name}</span>
            </LI>

            <LI attrLI={{ className: 'border-0 nav-item d-flex align-items-center' }}>
              {permissions && permissions.includes('redeem_request.edit') && showButton && (
             <>
                 <Button style={{padding:"9px 28px" }} onClick={() =>togglee() } className=' w-40 m-4   ' > Reject </Button>
                 <Button style={{padding:"9px 28px" }}  onClick={() =>toggle() } className='  w-40 m-4 btn btn-danger ' > Accept </Button>
             </>
                 )}

                    </LI>
             </UL>
                 </div>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
       }
       
        </Fragment>
    )


}
export default Details_Redeem;