import React, { Fragment,useEffect,useState } from 'react';
import { Container, Row, Col, Card, CardBody,Media } from 'reactstrap';
import { Breadcrumbs ,Btn,Spinner } from '../../AbstractElements';

import { useParams } from 'react-router-dom';

// rsuite
import { Input, Whisper, Tooltip } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// data table
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router';
import CommonModal from 'Components/Modal';
import { toast } from 'react-toastify';

const style2 = { width: 60, fontSize: 14, padding: 6 , marginLeft : 5 };


function MailList (){
    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [ totalItems, setTotalItems ] = useState(0)
    const toggle = () => setModal(!modal);
    const [modal, setModal] = useState(false);
    const [refreshDelete, setRefreshDelete] = useState(false);
    const [item, setItem] = useState("");
    const permissions = JSON.parse(localStorage.getItem('permission'));

    useEffect(()=>{
      console.log('Component rendered');

        if (!permissions || !permissions.includes('mail_list.get')) {
           navigate(`/Forbidden`)
       } else {
        get_mail()
      }
    },[values_Filter,page,perPage,refreshDelete])

      const get_mail = async()=>{

        setIsLoading(true)
            const {response, message} = await Helper.Get({
              
            url: api_Routes.maillist.view,
              hasToken:true,
                data:{results:perPage,
                      page:page,
                      keywords:values_Filter.keywords, }
            })
            if(response){

              setData_Table([])
                setTotalItems(response.meta.total)
    
                response.data.forEach(elem => {
                    setData_Table(prev=>[...prev,{
                      email:elem.email,
                      sent:elem.sent,
                                 action: (
                                    <div>
                                           <span>
                                               <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>navigate(`/AddMail/${elem.id}/shake`)}}> Edit </Btn>
                                           </span>
                                       {permissions && permissions.includes('mail_list.delete') && (
                                          <span >
                                            <Btn  attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button' ,onClick:()=>{
                                              toggle()
                                              setItem(elem.id)
                                            }}}>delete </Btn>
                                          </span>
                                          )}
                                    </div>
                                  ),
                            }])
                    
                });
                setCol_Table([
                  {
                        name: 'Email',
                        selector: row => row['email'],
                        sortable: true,
                        width:"30%",
                        center: false,
                    },
                  {
                        name: 'Sent',
                        selector: row => row['sent'],
                        sortable: true,
                        width:"40%",
                        center: false,
                    },
                    {
                        name: 'Action',
                        selector: (row) => row.action,
                        sortable: true,
                        center: true,
                        width: '25%',
                      },
                    
              ])
              setIsLoading(false)
            }else{
                console.log(message);
            }
      }

      const handleDelete = async() => {
        let path,ids={};
        if(item.length != ""){
         
            path=api_Routes.maillist.update(item)
        }else{
            selectedRows.forEach((ele,i)=>{
              ids[`ids[${i}]`] = ele
            })
            path=api_Routes.maillist.bulkDelete
    
        }
    
        const {response, message} = await Helper.Delete({
          url:path,
          hasToken:true,
          data:selectedRows.length>0?ids:{}
        })
        if(response){
          toast.success(message)
          setItem("")
          setSelectedRows([])
          setRefreshDelete(!refreshDelete)
        }else{
          toast.error(message)
          setItem("")
          setSelectedRows([])
        }
      };

      const handleChangePage = (value)=>{
        setPerPage(value)
    }
     const handlePage =(e)=>{
        setPage(e)
     }
     const handleChange = (key,value)=>{
        setValues(prev=>({...prev,[key]:value}))
      }
     const handleFilter = ()=>{
      setFilter_Values(values)
    }

    return (
            
      <Fragment>
            <Breadcrumbs parent="Mails" title="List" mainTitle="Mails List" />
            <CommonModal isOpen={modal} title="Confirm Delete" handleConfirm={handleDelete} toggler={toggle} >
             Are you sure delete ?
           </CommonModal>
            <Container className='my-2' fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                 
                  <CardBody>
                  <Row  >
                  <Col className='d-flex justify-content-center p-2' xs={8} sm={8} lg={10} md={9} xl={10}>
                        <Whisper trigger="focus" speaker={<Tooltip>Keywords</Tooltip>}>
                        <Input onChange={(e)=>{handleChange("keywords",e)}} 
                                style={{ width: "90%" }} placeholder="Keywords" />
                        </Whisper>

                    </Col>  
                    
                    <Col className='d-flex align-items-center justify-content-center p-2' xs={4} sm={4}  xl={2} lg={2} md={2} >
                        <div onClick={()=>{handleFilter()}}>
                        <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                        </div>
                    </Col>
                  </Row>
    
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>  
            <Container fluid={true}>
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody>
                    <Fragment>
                        
                        <DataTable
                            // data={data}
                            // columns={tableColumns}
                            data={data_table}
                            columns={col_table}
                            striped={true}
                            center={true}
                            progressPending={isLoading}
                            progressComponent={<div className="loader-box">
                                            <Spinner attrSpinner={{ className: "loader-26" }} />
                                        </div>}
                            pagination
                            paginationServer
                            paginationPerPage={perPage}
                            paginationRowsPerPageOptions={[10,20,30,50,100]}
                            responsive
                            selectableRows
                            paginationTotalRows={totalItems}
                            paginationServerOptions={()=>{}}
                            onChangePage={(e)=>{handlePage(e)}}
                            onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                            onSelectedRowsChange={(e)=>{
                              setSelectedRows([])
                              e.selectedRows.forEach(ele =>{
                                setSelectedRows(prev=>[...prev,ele.id])
                              })
                            }}
                            clearSelectedRows={toggleDelet}
                            
                        />
                    </Fragment>
      
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          
        </Fragment>
           
          );

}
export default  MailList