import React, { Fragment, useEffect, useState,useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Breadcrumbs, Btn,Image,Spinner } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
//files
import Files from 'react-files';
//Form Component

import { SelectPicker  , Loader} from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
// Editor Wyswigy 
import { EditorState, convertToRaw,ContentState ,convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useParams} from "react-router-dom"
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { FaArrowLeft } from 'react-icons/fa';


const Add_Section= () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoadingSelected, setIsLoadingSelected] = useState({
        page:false,
    })
    const [section,setSection] = useState({})
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [pages, setPages] = useState([])
    const [langauges, setLangauges] = useState([])
    const controllerRef = useRef(null)

    const [formData, setFormData]  = useState({
        name:"",
        page_id:"",

        button_label1:"",
        button_link1:"",

        button_label2:"",
        button_link2:"",

        button_label3:"",
        button_link3:"",
    })
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [editors_keys, setEditorsKeys] = useState(['description']); 
    const permissions = JSON.parse(localStorage.getItem('permission'));

    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        if (id === ":id" && (!permissions || !permissions.includes('page_section.add'))) {
            navigate(`/Forbidden`);
        } else {
                get_pages(signal)
            if(id != ":id"){
                get_details(signal)
            }
        }
        
        if(localStorage.hasOwnProperty("lang")){
            let array = JSON.parse(localStorage.getItem("lang"))
            setLangauges(array)
            if(id==":id"){
                setDataToEditorState(prev=>{
                    array.map((e)=>{
                      prev[e.id]= {
                        description:{id:0,value:EditorState.createEmpty()},
                        name:{id:0,value:""},
                        // meta_title:{id:0,value:""},
                        // meta_description:{id:0,value:""},
                      }
                      return prev[e.id]
                    })
                    return prev
                  })
            }
           }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        return ()=>{
            controller.abort()
        }
    },[])
    const get_pages= async (signal)=>{
        setIsLoadingSelected(prev=>({...prev,["page"]:true}))
        const {response,message} = await Helper.Get_Abort({
            url:api_Routes.page.view,
            hasToken:true,
            signal:signal,
            data:{results:21}
        })
        if(response){
            // response.data.forEach(ele=>{
            //     setPages(prev=>[...prev,{
            //         label:ele.translations["en"]?ele.translations["en"].name:"",
            //         value:ele.id
            //     }])
            // })
            setPages(response.data)
            setIsLoadingSelected(prev=>({...prev,["page"]:false}))
        }
    }
    const get_details = async (signal)=>{
        setIsLoadingDetailes(true)
        const {response,message} = await Helper.Get_Abort({
            url:api_Routes.section.getOne(id),
            hasToken:true,
            signal:signal,
            data:{detailed:1}
        })
        if(response){
            var details_languages = {}
            setSection(response.data)    
            setFormData({
                name:response.data.name,
                page_id:response.data.page_id,

                button_label1:response.data.button_label1,
                button_link1:response.data.button_link1,

                button_label2:response.data.button_label2,
                button_link2:response.data.button_link2,

                button_label3:response.data.button_label3,
                button_link3:response.data.button_link3,
        
            })
            setFiles(response.data.photo?[{
                preview: {type: 'image', url: response.data.photo}
            }]:"")
           response.data.detailed_translations.forEach(ele=>{
                ele.forEach(dt=>{
                    if (!details_languages[dt.language_id])
                        details_languages[dt.language_id] = {}

                    if(editors_keys.includes(dt.text_type))
                    {
                        details_languages[dt.language_id][dt.text_type] = {
                            value:dt.value ?
                                EditorState.createWithContent(
                                    ContentState.createFromBlockArray(
                                        convertFromHTML(dt.value)
                                    )
                                ) : EditorState.createEmpty(),
                            id:dt.id
                        }

                    }else
                        details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                })
            })
            
            setDataToEditorState(details_languages)

            setIsLoadingDetailes(false)
        }
    }


    const handleChange = (key, value, id_lang, id, sort) => {
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
                if(editors_keys.includes(key))
                    valueTemp = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value)))
                else
                    valueTemp  = value
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };


    const handleSubmit = async() =>{
         let exit = false
        
           if (!formData.name || !formData.page_id ) {
                toast.error("Please fill in all required fields (Name, Page ).");
                setIsLoading(false);
                return;
            }
           setIsLoading(true)
            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''

            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                    if(editors_keys.includes(subKey))
                        valueTemp = draftToHtml(convertToRaw(dataToEditorState[key][subKey].value.getCurrentContent()))
                        else{
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            } 
                            else {
                                 exit = true
                            }
                        }
                    if(id === ":id")
                        form_data.append(`languages[${key}][${subKey}]`, valueTemp);
                    else
                        form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
                })
            })
            Object.keys(updatedFormData).forEach((key) => {
                if (key === "file")
                    form_data.append("file", updatedFormData.file);
                else
                    form_data.append(key, updatedFormData[key]);
            });

          if(id==":id"){
            form_data.append("_method","PUT")
          }
          if(exit){
            toast.error("You should add the name , description ");
            setIsLoading(false)
            return;
        }
          const {response, message} = await Helper.Post({
            url : id === ":id"? api_Routes.section.add:api_Routes.section.update(id),
            data :form_data,
            hasToken :true
          })
          if(response){

            if (id === ":id") {
                toast.success("Successfully added !!");
                navigate(`/section-list/order`);
              } else {
                toast.success("Successfully edited !!");
                navigate(`/section-list/order`);
              }            setIsLoading(false)
          }else{
            if(typeof(message) === "string"){
              toast.error(message)
            }else{
              Object.keys(message).map((i)=>{
                toast.error(`${i} : ${message[i][0]}`)
              })}
              setIsLoading(false)
          }
    }
    const onSearch = async (e,key)=>{

            setIsLoadingSelected(prev=>({...prev,["page"]:true}))
        
        
        if(controllerRef.current){
            controllerRef.current.abort()
        }
        controllerRef.current = new AbortController()
        const signal = controllerRef.current.signal
        const {response,message} = await Helper.Get_Abort({
            url: api_Routes.page.view,
            signal:signal,
            data:{keywords:e,results:21},
            hasToken:true
        })
        if(response){
                setPages(response.data)
                setIsLoadingSelected(prev=>({...prev,["page"]:false}))
        }
    }
    
    const [files, setFiles] = useState([]);

    function deleteFile(e) {
        setFiles([]);
        setFormData(prev=>({...prev,["file"]:""}))
        return files

    }
    const onFilesChange = (files) => {
        setFiles(files)
        setFormData(prev=>({...prev,["file"]:files[0]}))
        console.log(files);
    }
    const onFilesError = (error, file) => {
        setFormData(prev=>({...prev,["file"]:""}))
        setFiles(file)
    }
    const handleGoBack = ()=>{
        navigate(`/section-list/order`)
    }
    return (
        <Fragment>
            <Breadcrumbs mainTitle={id==":id"?"Add Section":"Edit Section"} parent="Section" title={id==":id"?"Add":"Edit"} />
            {
                isLoadingDetiales?<div className="loader-box">
                <Spinner attrSpinner={{ className: "loader-26" }} />
            </div>:<>
            {/* Basic Info */}
            <Container  className='mb-3' fluid={true}>
            {/* <Btn
            attrBtn={{
                className: 'btn btn-primary justify-content-start text-white d-flex align-items-center   btn-mail w-40',
                style: { padding: '10px 20px', width:"fit-content",position:"fixed" , zIndex:"2" , bottom:"30px", right:"30px"},
                onClick: handleGoBack
            }}
            >
            <FaArrowLeft /> Back
            </Btn> */}
                <Row>
                    <Col sm="12">
                        <Card>
                            <Row>
                                <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                                    <HeaderCard title="basic Info" />
                                </Col>
                       {(permissions.includes('page_section.edit')) && ( 
                                <Col className='d-flex justify-content-center align-items-center ' lg={2} xl={2}  md={2} sm={2} xs={2}>
                                    <Btn attrBtn={{ className: "h-50", color: 'danger', type: "button", onClick: () => handleSubmit(),disabled:isLoading }} >
                                           {isLoading? <Loader />:<i className='fa fa-save'></i>} 
                                        </Btn>
                                </Col>
                       )}
                            </Row>
                            
                            <CardBody className="add-post">

                                {/* Basic Info */}
                                <Form className="row needs-validation">
                                    <Row>
                                        <Col lg={6} xl={6}  md={6} sm={12} xs={12}>
                                        <FormGroup>
                                            <Label for="validationCustom01">Name</Label>
                                            <Input value={formData.name} onChange={(e)=>{handleChange("name",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="name" required="" />
                                            
                                        </FormGroup>
                                        </Col>
                                        <Col lg={6} xl={6}  md={6} sm={12} xs={12}>
                                            <div>
                                                Page
                                            </div>
                                        <FormGroup>
                                            <SelectPicker className='mt-1' style={{width:"100%",height:"40px"}}
                                            value={parseInt(formData.page_id)?parseInt(formData.page_id):pages.name} 
                                            onChange={(e)=>{handleChange("page_id",e,null)}}
                                             //  data={pages}
                                            data={ pages.map((ele)=>({label:ele.translations["en"]?ele.translations["en"].name:"",value:ele.id}))}
                                            onSearch={(e)=>{
                                                if(e!=""){ onSearch(e,"page") }
                                                }}
                                            placeholder="page"
                                            loading={isLoadingSelected.page}  />
                                        </FormGroup>
                                        </Col>
                                    </Row>

                                </Form>
                                    
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
            {/* languages */}
            {
    Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang, i) => (
        <Container key={i} className='mb-3' fluid={true}>
            <Row>
                <Col sm="12">
                    <Card>
                        <HeaderCard title={`${lang.name} information`} />
                        <CardBody className="add-post">
                            <Form className="row needs-validation">
                                <Col sm="12">
                                        <FormGroup>
                                            <Label for="validationCustom01">Name</Label>
                                            <Input value={id==":id"?dataToEditorState[JSON.stringify(lang.id)].name.value:dataToEditorState[JSON.stringify(lang.id)].name.value} 
                                                    onChange={(e)=>{handleChange("name",e.target.value,lang.id,id==":id"?null:dataToEditorState[JSON.stringify(lang.id)].name.id,0)}} className="form-control" id="validationCustom01" type="text" placeholder={`${lang.name} name`} required="" />
                                            <div className="valid-feedback">{"Looks good!"}</div>
                                        </FormGroup>

                                    <div className="email-wrapper">
                                        <div className="theme-form">
                                            <FormGroup>
                                                <Label> Description:</Label>
                                                <div>
                                                <Editor
                                                    apiKey='9tbaoxukhbd476wzx0cpnz5yr23n39igi9t4ds4jxp0nyfmk'
                                                    init={{
                                                        // TinyMCE editor initialization configuration
                                                        plugins: 'fullpage preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help charmap emoticons codesample code',
                                                        toolbar: 'undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  bullist numlist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile template link anchor codesample | ltr rtl | visualchars visualblocks nonbreaking table tableinsertdialog tablecellprops tableprops help codesample code',
                                                        height: 300,
                                                        branding: false,
                                                        setup: editor => {
                                                            editor.on('Change', function (e) {
                                                                handleChange("description", editor.getContent(), lang.id, id === ":id" ? null : dataToEditorState[lang.id].description.id, 1);
                                                            });
                                                        }
                                                    }}
                                                    initialValue={id === ":id" ? '' : draftToHtml(convertToRaw(dataToEditorState[lang.id].description.value.getCurrentContent()))}
                                                />
                                                    <textarea
                                                        style={{ visibility: "hidden" }}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    ))
}

 <Container className="mb-3" fluid>
    <Row>
      <Col md="12">    
         <Card mb-3  >
            <HeaderCard title="Button " />
              <CardBody>
                 <Row>
                    <Col md="4">
                        <FormGroup>
                            <Label for="validationCustom01">Button 1 Label</Label>
                            <Input
                                value={formData.button_label1}
                                onChange={(e) => { handleChange("button_label1", e.target.value, null) }}
                                className="form-control"
                                id="validationCustom01"
                                type="text"
                                placeholder="Button Label"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="validationCustom01">Button 1 Link</Label>
                            <Input
                                value={formData.button_link1}
                                onChange={(e) => { handleChange("button_link1", e.target.value, null) }}
                                className="form-control"
                                id="validationCustom01"
                                type="text"
                                placeholder="Button Link"
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Label for="validationCustom02">Button 2 Label</Label>
                            <Input
                                value={formData.button_label2}
                                onChange={(e) => { handleChange("button_label2", e.target.value, null) }}
                                className="form-control"
                                id="validationCustom02"
                                type="text"
                                placeholder="Button Label"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="validationCustom02">Button 2 Link</Label>
                            <Input
                                value={formData.button_link2}
                                onChange={(e) => { handleChange("button_link2", e.target.value, null) }}
                                className="form-control"
                                id="validationCustom02"
                                type="text"
                                placeholder="Button Link"
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Label for="validationCustom03">Button 3 Label</Label>
                            <Input
                                value={formData.button_label3}
                                onChange={(e) => { handleChange("button_label3", e.target.value, null) }}
                                className="form-control"
                                id="validationCustom03"
                                type="text"
                                placeholder="Button Label"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="validationCustom03">Button 3 Link</Label>
                            <Input
                                value={formData.button_link3}
                                onChange={(e) => { handleChange("button_link3", e.target.value, null) }}
                                className="form-control"
                                id="validationCustom03"
                                type="text"
                                placeholder="Button Link"
                                required
                            />
                         </FormGroup>
                       </Col>
                    </Row>
                 </CardBody>
             </Card>
          </Col>
        </Row>
    </Container>
             {/* upload image */}
            <Container  className='mb-3' fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={`upload image`} />
                            <CardBody className="fileUploader">
                                <Files
                                    className='files-dropzone fileContainer'
                                    onChange={onFilesChange}
                                    onError={onFilesError}
                                    accepts={['image/*']}
                                    multiple={false}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    {
                                        files.length > 0
                                            ? <div className='files-gallery'>
                                                {files.map((file, index) =>
                                                    <div key={index}>
                                                        <Image attrImage={{ className: 'files-gallery-item', alt: "img", src: `${file.preview.url}` }} />
                                                    </div>
                                                )}
                                            </div>

                                            : <div className="d-flex justify-content-center">
                                                <Btn attrBtn={{ className: "mt-2", type: "button", color: 'primary' }} >Upload Image</Btn>
                                            </div>
                                    }
                                </Files>
                                {files.length > 0 ?
                                    <div className="d-flex justify-content-center">
                                        <Btn attrBtn={{ className: "mt-2", color: 'danger', type: "button", onClick: () => deleteFile(files) }} >
                                            Delete
                                        </Btn></div> : ''}

                        </CardBody>
                           
                        </Card>
                    </Col>
                </Row>
                
            </Container>
            
            </>
            }
            
            
        </Fragment>
    );
};

export default Add_Section;