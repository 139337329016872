import React, { Fragment, useEffect, useState ,useRef} from 'react';
import { Container, Row, Col, Card, CardBody, Form ,Label} from 'reactstrap';
import { Breadcrumbs,Btn} from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
import Dropzone from 'react-dropzone-uploader';



import { SelectPicker,Loader} from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';

import {  useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';


function Add_video (){
    const [detailsInfo, setDetailsInfo] = useState({});
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [formData, setFormData] = useState({});
    const [records, setRecords] = useState([])
    const [selectedType, setSelectedType] = useState(null);
    const controllerRef = useRef(null)

    const [statuss, setstatuss] = useState([
        { label: "Product",  id: "product"  , value: "App\\Models\\Product"  ,"api" : api_Routes.product.view},
     ]);
    
     const permissions = JSON.parse(localStorage.getItem('permission'));

     useEffect(() => {
        if (!permissions || !permissions.includes('video.add')) {
            navigate(`/Forbidden`)
        } 
    }, []);

     const fill_records_value = async(api)=>{
        const {response, message} = await Helper.Get({
          
        url: api,
            hasToken:true,
            data:{}
        })
        if(response){
            setRecords([])
            console.log(records)
            
            // response.data.forEach(ele=>{
            //     setRecords(prev => [...prev, {
            //         label:ele.product_translations["en"].name, // Convert user_id to integer
            //         value: ele.id   // Convert user_id to integer
            //     }])
            // })
            setRecords(response.data)

        }else{
            
            console.log(message);
        }

    }
    const onSearch = async (e,key)=>{
        
        
        if(controllerRef.current){
            controllerRef.current.abort()
        }
        controllerRef.current = new AbortController()
        const signal = controllerRef.current.signal
        const {response,message} = await Helper.Get_Abort({
            url: api_Routes.product.view ,
            signal:signal,
            data:{keywords:e,results:20},
            hasToken:true
        })
        if(response){
            
                setRecords(response.data)            

        }

    }

    const handleSubmit = async () => {
        setIsLoading(true);
        // if (!formData.status || !formData.record || files.length === 0) {
        //     toast.error("Please select type, record, and upload at least one video.");
        //     setIsLoading(false);
        //     return;
        // }

        const form_data = new FormData();

        form_data.append("type", formData.status);
        form_data.append("type_id", formData.record);
       // form_data.append("type_id", 2);

        files.forEach((file, index) => {
            form_data.append("videos[]", file)
        });

        // files.forEach((file, index) => {
        //     form_data.append(`photo[${index}]`, file);
        // });

        form_data.append("visible", 1);
        form_data.append("_method", "put");



      

        const { response, message } = await Helper.Post({
            url: api_Routes.videos.bulk,
            data: form_data,
            hasToken: true
        });

        if (response) {
            setIsLoading(false);
            toast.success("Video uploaded successfully.");
            navigate(`/video-list/:id/order`)
        } else {
            if (typeof message === "string") {
                toast.error(message);
            } else {
                Object.keys(message).forEach((i) => {
                    const errorMessage = message[i][0] || "";
                    toast.error(`${i} : ${errorMessage}`);
                });
            }
            setIsLoading(false);
        }
        };  

        const handleChange = (key, value) => {
            setFormData(prev => ({ ...prev, [key]: value }));
        
            if (key === 'status') {
                const selectedStatus = statuss.find(status => status.value === value);
                if (selectedStatus) {
                    setSelectedType(selectedStatus.value);
                    fill_records_value(selectedStatus.api);
                }
            }
            
        }

        const deleteFile = (index) => {
            const updatedFiles = [...files];
            updatedFiles.splice(index, 1);
            setFiles(updatedFiles);
        };
        
    
        // const onFilesChange = (files) => {
        //     setFiles(files);
        //     setFormData({ ...formData, file: files[0] });
        // };

        const onFilesChange = (newFiles) => {
            setFiles([...files, ...newFiles]);
        };
    
        const onFilesError = (error, file) => {
            console.error('File error:', error);
        };
        const getUploadParams = ({ meta,file }) => {
      
        
            return { url: 'https://httpbin.org/post' };
        };
        
          const handleChangeStatus = ({ meta, file }, status) => {

        if(status=="done"){
            setFiles(prev=>[...prev,file])
        }else if(status=="removed"){
            setFiles(prev=>{
                let array = prev
                let result =array.filter(i => i.name!=file.name)
                return result
            })
        }
    };
    

        return (
            <Fragment>
              <Breadcrumbs parent="Add Video" title="List" mainTitle="Media" />
                <Container fluid={true}>
              
                    <Row>
                        <Col xl="12">
                            <Card>
                                 <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                     <HeaderCard title={`Basic Info`} />
                                     <Col className="d-flex justify-content-end" style={{marginRight:"15px"}}> 
                                            <div>
                                            <Btn attrBtn={{ className: "mt-2", color: 'danger', type: "button",onClick: () =>!isLoading &&  handleSubmit() }} disabled={isLoading}>
                                                    {isLoading ? <Loader /> : <i className='fa fa-save'></i>}</Btn>
                                            </div> 
                                         </Col>
                                 </div>
                                 <div class="container m-4">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Type   </label>
                                                        <SelectPicker
                                                            placeholder="Enter the Type"
                                                            onChange={(e) => { handleChange("status", e) }}
                                                            style={{ width: "80%" }}
                                                            data={statuss}
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Record   </label>
                                                        <SelectPicker
                                                            placeholder="Enter the Record"
                                                            onChange={(e) => { handleChange("record", e) }}
                                                            style={{ width: "80%" }}
                                                            onSearch={(e)=>{
                                                                if( e != "")
                                                                    onSearch(e,"category")
                                                            }}
                                                            data={ records.map((ele)=>({label:ele.product_translations["en"]?ele.product_translations["en"].name:"",value:ele.id}))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
    
                <Container fluid={true}>
                <Row>
                    {/* <Col sm="12">
                        <Card>
                            <CardBody className="fileUploader">
                                <Files
                                    className='files-dropzone fileContainer'
                                    onChange={onFilesChange}
                                    onError={onFilesError}
                                    accepts={['image/*']}
                                    multiple={true}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    <div className="d-flex justify-content-center">
                                        <Btn attrBtn={{ className: "mt-2", type: "button", color: 'primary' }} >Add Image</Btn>
                                    </div>
                                </Files>
                                <div className='files-gallery'>
                                    {files.map((file, index) => (
                                        <div key={index}>
                                            <Image attrImage={{ className: 'files-gallery-item', alt: "img", src: `${file.preview.url}` }} />
                                            <div className="d-flex justify-content-center">
                                                <Btn attrBtn={{ className: "mt-2", color: 'danger', type: "button", onClick: () => deleteFile(index) }} >
                                                    Delete
                                                </Btn>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </CardBody>
                        </Card>
                    </Col> */}
                         <Card>
                            <HeaderCard title={`upload videos`} />
                              <CardBody>
                                <Form>
                                    {/* <ToastContainer /> */}
                                    <div className="dz-message needsclick">
                                        <Dropzone
                                            getUploadParams={getUploadParams}
                                            onChangeStatus={handleChangeStatus}
                                            // onSubmit={handleSubmit}
                                            accept=" video/*"
                                        />
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                </Row>
                {/* <Row>
                    <Col sm="12">
                        <div className="d-flex justify-content-center">
                            <Btn attrBtn={{ className: "mt-2", color: 'info', type: "button", onClick: handleSubmit }}>Submit</Btn>
                        </div>
                    </Col>
                </Row> */}
            </Container>
            </Fragment>
        );

}
export default Add_video;
