import React from 'react';
import { Col, Row } from 'reactstrap';

import DiscoverCard from './DiscoverCard';
import MonthlyProfits from './Shakepercategory';
import OrderOverview from './OrderOverview';
import RecentOrders from './RecentOrders';
import ValuableCustomer from './ValuableCustomer';
import VisitorsCard from './VisitorsCard';
import WidgetsGrid from './WidgetsGrid';
import Shakepercategory from './Shakepercategory';
import Orderperstatus from './orderperstatus';
import Shakeoverview from './shakeoverview';
import OrderThisMonth from './OrderThisMonth';
import Vendor from './Vendor';
import Ordermonth from './ordermoth';
import Fin from './fin';
import TotalBalance from './TotalBalance';
const LeftGrid = () => {
  return (
    <Col >

      <Row className='row'>

        <Col  xs={12} sm={12} md={7} lg={7} xl={7} >
        <TotalBalance />
        </Col>
      <Col xs={12} sm={12} md={5} lg={5} xl={5} >
          <ValuableCustomer />
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Shakeoverview />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <OrderOverview />
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <OrderThisMonth />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Vendor />
        </Col>

        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Orderperstatus />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Shakepercategory />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Fin />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Ordermonth />
        </Col>

      </Row>
    </Col>
  );
};

export default LeftGrid;
