import React, { Fragment,useEffect,useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs ,Btn,Spinner } from '../../AbstractElements';
import {  useNavigate } from 'react-router-dom';
//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// rsuite
import { Input, Whisper, Tooltip,SelectPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
// data table
import DataTable from 'react-data-table-component';
// date  format
import { useLocation } from 'react-router-dom';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const style2 = { width: 60, fontSize: 14, padding: 4 };

function Accounting(){
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [users, setusers] = useState([])
    const [values_Filter, setFilter_Values] = useState({})
    const [ totalItems, setTotalItems ] = useState(0)
    const [selectedfDate, setSelectedfDate] = useState(null)
    const [selectedtDate, setSelectedtDate] = useState(null)
    const location = useLocation();
     const permissions = JSON.parse(localStorage.getItem('permission'));
     const BaseApi=api_Routes.financials.view;


     useEffect(()=>{
        if (!permissions || !permissions.includes('order.get-all')) {
          navigate(`/Forbidden`)
      }else{
        get_user()
        get_Account()
      }
      },[values_Filter,page,perPage])

      const get_user = async()=>{
        const {response, message} = await Helper.Get({
            url:api_Routes.user.view,
            hasToken:true,
        })
        if(response){
          console.log("ana");
            console.log(response.data);
            setusers([])
            response.data.forEach(ele=>{
            setusers(prev => [...prev, {
                label:ele.name, // Convert user_id to integer
                value: ele.id   // Convert user_id to integer
            }])
            })
        }else{
            console.log(message);
        }
        }

      const get_Account = async ()=>{
        let url = BaseApi + '?1=1';
        if (values_Filter.id ) {
            url = url + `&in_ids=${values_Filter.id}`;
          }
          if ( values_Filter.from_date  ) {
            url = url + `&from_date=${values_Filter.from_date}`;
          }
          if ( values_Filter.to_date  ) {
            url = url + `&to_date=${values_Filter.to_date}`;
          }
        const {response, message} = await Helper.Get({
            url:url,
            hasToken:true,
            data:{results:perPage,page:page,
              keywords:values_Filter.keywords,
            }
        })
        if(response){
            
            setData_Table([])
            //   setTotalItems(response.meta.total)
            
            response.data.forEach(elem => {
                setData_Table(prev => [...prev, {
                    name: elem.name,
                    description: elem.description,
                    amount: elem.amount,
                   
                }]);
            });
            
              setCol_Table([{
                      name: 'Name',
                      selector: row => row['name'],
                      sortable: true,
                      width:"33%",
                      center: false,
                  },
                  {
                      name: 'Description',
                      selector: row => row['description'],
                      sortable: true,
                      width:"33%",
                      center: true,
                  },
                  {
                      name: 'Amount',
                      selector: row => row['amount'],
                      sortable: true,
                      width:"25%",
                      center: true,
                  },
                
                  
            ])
            setIsLoading(false)
          }else{
              console.log(message);
          }
  
      }

      const handleChangePage = (value)=>{
        console.log("per_pages",value);
        setPerPage(value)
    }
    const handlePage =(e)=>{
        setPage(e)
    }
   const handleChange = (key, value) => {
    setValues(prev => ({ ...prev, [key]: parseInt(value) }));
     }
  
    const handleFilter = ()=>{
    setFilter_Values(values)
    }

    const handleChanged = (key, value) => {
        const formattedDate = value ? new Date(value)
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ') 
        : null;  
        setValues(prev => ({ ...prev, [key]: formattedDate }));
       }


  return(
    <>
     <Breadcrumbs parent="Accounting" title="List" mainTitle="Accounting" />
        <Container className='my-2' fluid={true}>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardBody>
                            <Row>
              <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                <Whisper trigger="focus" speaker={<Tooltip>Keywords</Tooltip>}>
                  <Input onChange={(e)=>{handleChange("keywords",e)}} 
                        style={{ width: "100%" }} placeholder="Keywords" />
                </Whisper>

              </Col>  

             

              <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                <SelectPicker placeholder="Search By Name" 
                 onChange={(e)=>{handleChange("id",e)}}
                        style={{ width: "100%"}}
                          data={users} 
                        //  loading={isLoading.boxes} 
                         />
              </Col>
                            
            <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                    <DatePicker
                        placeholderText="Search By From Date"
                        selected={selectedfDate}
                        onChange={(date) => handleChanged("from_date", date)}
                        dateFormat="yyyy-M-d"
                        onSelect={(date) => setSelectedfDate(date)}
                        sx={{height:'2px'}}

                    />
                </DemoContainer>
               </LocalizationProvider>

               </Col>


              <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
             <DatePicker
                placeholderText="Search By To Date"
                selected={selectedtDate}
                onChange={(date) => handleChanged("to_date", date)}
                dateFormat="yyyy-M-d"
                onSelect={(date) => setSelectedtDate(date)}
                sx={{height:'2px'}}

              />
              </DemoContainer>
               </LocalizationProvider>
                </Col>

                <Col className='d-flex align-items-center justify-content-center p-2' sm={12} xs={12} lg={2} md={2} >
                <div onClick={()=>{handleFilter()}}>
                  <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                </div>
              </Col>
                
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            
                
            </Row>
        </Container>
        <Container fluid={true}>
            <Row>
                <Col sm="12">
                    <Card>
                        <CardBody>
                            <Fragment>
                                <DataTable
                                        data={data_table}
                                        columns={col_table}
                                        striped={true}
                                        center={true}
                                        progressPending={isLoading}
                                        progressComponent={<div className="loader-box">
                                                            <Spinner attrSpinner={{ className: "loader-26" }} />
                                                        </div>}
                                        pagination
                                        paginationServer
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[10,20,30,50,100]}
                                        responsive
                                        selectableRows
                                        paginationTotalRows={totalItems}
                                        paginationServerOptions={()=>{}}
                                        onChangePage={(e)=>{handlePage(e)}}
                                        onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                                />
                            </Fragment>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    </>
  )
}
export default Accounting;