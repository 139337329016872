import React, { Fragment,useEffect,useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../AbstractElements';
import HeaderCard from '../../Common/Component/HeaderCard';
import DataTableComponent from './DataTableComponent';
import { Helper } from '../../../tools/helper';
import { api_Routes } from '../../../api_Routes';
import Loader from '../../../Layout/Loader';

const DataTables = () => {
  useEffect(()=>{
    get_products()
  },[])
  const [isLoadding, setIsLoading] = useState(false)
  const [response_api, setResponse_Api] = useState([])
  const get_products = async()=>{
    setIsLoading(true)
    const {response, message} = await Helper.Get({
      url:api_Routes.product.view,
      hasToken:true
    })
    if(response){
      setResponse_Api(response.data)
      setIsLoading(false)
    }else{
      console.log(message);
    }
  }
  return (isLoadding?<Loader/>:
    <Fragment>
      <Breadcrumbs parent="Table" title="Data Tables" mainTitle="Data Tables" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Select Multiple and Delete Single Data" />
              <CardBody>
                  {response_api.length>0 ? <DataTableComponent data_api={response_api} />:""}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default DataTables;