
const initialValues = {
    name: "",
    photo:null,
    google_play: "",
    app_store:"",
    conversion_rate:"",
    open_box_fee:"",
    lang:"en",
    charge_fee:"",
    search :"",
    number:0,
    notification:{},
    conversation_id: 0,
    toggleBoolean:true,
    message:{},
    url:"",
    nextUrl:"",
    isOpen:false,
    cart:{
        products:[]
    }
}

export const reducerRoot = (state=initialValues,action) =>{
    switch(action.type){
        case "LOGIN-USER" : 
            return{
                ...state,
                name: action.name,
                photo:action.photo
            }
        case "LANGUAGE":
            return{
                ...state,
                lang: action.lang
            }
        break;
        case "TOGGEL-STATUS":
            return{
                ...state,
                toggleBoolean: action.toggleBoolean
            }
        break;
        case "ECHO_NOTIFICATIONS":
            return{
                ...state,
                notification:action.notification,
                conversation_id: action.conversation_id,
            }
        break;
        case "ECHO_MESSAGE":
            return{
                ...state,
                message:action.message
            }
        break;

        default :
            return{...state}
            break;
    }
}