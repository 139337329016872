
const style = {
  width: 40,
  height: 40,
};
const style2 = { width: 60, fontSize: 14, padding: 4 };
export const productData = [
  
];
export const productColumns = [
 
];
