import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import "./assets/style.css"
import "./assets/overrideStyles.css"
const Root = () => {
  return (
    <div className="App">
      <HelmetProvider>
        <Helmet>
          <title>Dashboard Shake</title>
          <script defer type="application/json" src="http://localhost:3000/static/js/bundle.js"></script>
        </Helmet>
        <App />
      </HelmetProvider>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

// ReactDOM.createRoot(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
