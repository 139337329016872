import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Breadcrumbs, Btn,Image,Spinner } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
//files
import Files from 'react-files';
//Form Component

import { Loader} from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
// Editor Wyswigy 
import { EditorState, convertToRaw,ContentState ,convertFromHTML  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Pick_list from './components/picklist';
import {useParams} from "react-router-dom"
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { FaArrowLeft } from 'react-icons/fa';

const Add_shipping =()=>{
    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [langauges, setLangauges] = useState([])
    const [formData, setFormData]  = useState({
        name:"",
        username:"",
        phone_code:"",
        password:"",
        password_confirmation:"",
        phone:"",
    })
    const [countriess, setcountriess] = useState([])
    const [selectedCountries, setselectedCountries] = useState([])
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [editors_keys, setEditorsKeys] = useState(['description','short_description','specifications']);

    const permissions = JSON.parse(localStorage.getItem('permission'));

    useEffect(() => {
        if (id === ":id" && (!permissions || !permissions.includes('shipping_company.add')))  {
            navigate(`/Forbidden`)
        } else {
            if (id !== ":id") {
                get_details();
            }
            
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);
            if (id === ":id") {
                setDataToEditorState(prev => {
                    array.forEach((e) => {
                        prev[e.id] = {
                            description: { id: 0, value: EditorState.createEmpty() }, // Ensure value is initialized correctly
                            name: { id: 0, value: "" },
                            meta_title: { id: 0, value: "" },
                            meta_description: { id: 0, value: "" },
                        };
                    });
                    return prev;
                });
            }
        }
    }, []);
    
    

     const get_details = async ()=>{
        setIsLoadingDetailes(true)
        const {response,message} = await Helper.Get({
            url:api_Routes.shipping.getOne(id),
            hasToken:true,
            data:{detailed:1}
        })
        if(response){
            var details_languages = {}
            console.log(response.data)
            setPage(response.data)    
            
            setFormData({
                name:response.data.name,
                username:response.data.user?.username,
                phone_code:response.data.user?.phone_code,
                phone:response.data.user?.phone,
                
            })
            setFiles(response.data.user_photo?[{
                preview: {type: 'image', url: response.data.user_photo}
            }]:"")
              response.data.countries.forEach(ele=>{
                setcountriess(prev=>[...prev,{
                            id:ele.id,
                            name:ele.name,
                            code:ele.code,
                            phone_code:ele.phone_code,
                }])
            })
            response.data.detailed_translations.forEach(ele=>{
                ele.forEach(dt=>{
                    if (!details_languages[dt.language_id])
                        details_languages[dt.language_id] = {}

                    if(editors_keys.includes(dt.text_type))
                    {
                        details_languages[dt.language_id][dt.text_type] = {
                            value:dt.value ?
                                EditorState.createWithContent(
                                    ContentState.createFromBlockArray(
                                        convertFromHTML(dt.value)
                                    )
                                ) : EditorState.createEmpty(),
                            id:dt.id
                        }

                    }else
                        details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                })
            })
           
    
            
            setDataToEditorState(details_languages)

            setIsLoadingDetailes(false)
        }
        else{
            if(typeof message =="string"){
                toast.error(message)
            }else{
                Object.keys(message).forEach(msg=>{
                    toast.error(`${message[msg]}:${msg}`)
                })
            }
        }
    }

    const handleChange = (key, value, id_lang, id, sort) => {
            console.log(value)
                if (id_lang) {
                    let translations = { ...dataToEditorState };
                    let valueTemp = ''
                    if(editors_keys.includes(key))
                        valueTemp = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value)))
                    else
                        valueTemp  = value
                    
                    translations[id_lang][key] = { id:id, value:valueTemp};
                    setDataToEditorState(translations);
                } else {
                    setFormData(prev => ({ ...prev, [key]: value }));
                }
            };

    //     if (id_lang) {
    //         let translations = { ...dataToEditorState };
    //         translations[id_lang][key] = { id:id, value:value };
    //         console.log(translations)
    //         setDataToEditorState(translations);
    //     } else {
    //         setFormData(prev => ({ ...prev, [key]: value }));
    //     }
    // };
      

        const handleSubmit = async () => {
            let exit = false

            if(id!=':id'){
                if (!formData.name ) {
                    toast.error("Please fill in all required fields (Name).");
                    setIsLoading(false);
                    return;
            }
        }
            else if (!formData.name || !formData.phone || !formData.phone_code || !formData.username    ) {
        toast.error("Please fill in all required fields (Name, Phone, Phone Code, UserName , Country).");
        setIsLoading(false);
        return;
    }

            console.log(formData);
            setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''
            
            console.log(updatedFormData)
            console.log(selectedCountries)

            selectedCountries.forEach((value,key) => {
                form_data.append(`country_ids[]`, value);

            })
         // form_data.append("country_ids", JSON.stringify(formData.country_ids));


            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                    if(editors_keys.includes(subKey))
                        valueTemp = draftToHtml(convertToRaw(dataToEditorState[key][subKey].value.getCurrentContent()))
                        else {
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            } else {
                                 exit = true
                            }
                        }
                    if(id === ":id"){
                        form_data.append(`languages[${key}][${subKey}]`, valueTemp);
                    }
                    else
                        form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
                })
               // const editorContent = draftToHtml(convertToRaw(dataToEditorState[key].description.value.getCurrentContent()));

                // Append the editor content to the form data
                // form_data.append('editor_content', editorContent);
            })


            Object.keys(updatedFormData).forEach((key) => {
                if (key === "file")
                    form_data.append("file", updatedFormData.file);
                else
                    form_data.append(key, updatedFormData[key]);
            });

            if (id === ":id") {
                form_data.append("_method", "PUT");
            }
            if(exit){
                toast.error("You should add the name , description  , meta_title , meta_description");
                setIsLoading(false)
                return;
            }

            const { response, message } = await Helper.Post({
                url: id === ":id" ? api_Routes.shipping.add : api_Routes.shipping.update(id),
                data: form_data,
                hasToken: true
            });

            if (response) {
                if (id === ":id") {
                    toast.success("Successfully added !!");
                    navigate(`/companies-list/order`);
                  } else {
                    toast.success("Successfully edited !!");
                    navigate(`/companies-list/order`);
                  }               
                   setIsLoading(false);
            } else {
                if (typeof message === "string") {
                    toast.error(message);
                } else {
                    Object.keys(message).forEach((i) => {
                        const errorMessage = message[i][0] || "";
                        toast.error(`${i} : ${errorMessage}`);
                    });
                }
                setIsLoading(false);
            }
            };  


        
      

    
        const [files, setFiles] = useState([]);


    function deleteFile(e) {
        setFiles([]);
        setFormData(prev=>({...prev,["file"]:""}))
        return files

    }
    const onFilesChange = (files) => {
        setFiles(files)
        setFormData(prev=>({...prev,["file"]:files[0]}))
        console.log(files);
    }
    const onFilesError = (error, file) => {
        setFormData(prev=>({...prev,["file"]:""}))
        setFiles(file)
    }
    

     return (
        <Fragment>
            <Breadcrumbs mainTitle={id==":id"?"Add Shipping Company":"Edit Shipping Company"} parent="Shipping Company" title={id==":id"?"Add ":"Edit "}/>
            {
                isLoadingDetiales?<div className="loader-box">
                <Spinner attrSpinner={{ className: "loader-26" }} />
            </div>:<>
            {/* Basic Info */}
            <Container  className='mb-3' fluid={true}>
            
                <Row>
                    <Col sm="12">
                        <Card>
                            <Row>
                                <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                                    <HeaderCard title="basic Info" />
                                </Col>

                                { ( permissions.includes('shipping_company.edit')) && ( 
                               <Col className='d-flex justify-content-center align-items-center ' lg={2} xl={2}  md={2} sm={2} xs={2}>
                               <Btn attrBtn={{  className: "h-50", color: 'danger', type: "button", onClick: () => !isLoading && handleSubmit() }} 
                               disabled={isLoading}
                               >
                               {isLoading ? <Loader /> : <i className='fa fa-save'></i>}
                               </Btn>
                              </Col>
                                )}

                            </Row>
                            
                            <CardBody className="add-post">

                                {/* Basic Info */}
                                <Form className="row needs-validation">
                                        <Col sm="12">

                                            <FormGroup className="row">
                                                <Col sm="6">
                                                    <Label for="validationCustom01">Name</Label>
                                                    <Input value={formData.name} onChange={(e)=>{handleChange("name",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="name" required="" />   
                                                </Col>
                                                <Col sm="6">
                                                    <Label for="validationCustom01">User Name</Label>
                                                    <Input value={formData.username} onChange={(e)=>{handleChange("username",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="user name" required="" />     
                                                </Col>
                                            </FormGroup>

                                            <FormGroup className="row">
                                                <Col sm="6">
                                                    <Label for="validationCustom01">Phone</Label>
                                                    <Input value={formData.phone} onChange={(e)=>{handleChange("phone",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="phone" required="" />     
                                                </Col>
                                                <Col sm="6">
                                                    <Label for="validationCustom01">Phone Code</Label>
                                                    <Input value={formData.phone_code} onChange={(e)=>{handleChange("phone_code",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="phone code" required="" />     
                                                </Col>
                                            </FormGroup>
                                            {id!=":id" ? "" :
                                            <FormGroup className="row">
                                                <Col sm="6">
                                                    <Label for="validationCustom01">Password</Label>
                                                    <Input value={formData.password} onChange={(e)=>{handleChange("password",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="password" required="" />     
                                                </Col>
                                                <Col sm="6">
                                                    <Label for="validationCustom01">Password Confirmation</Label>
                                                    <Input value={formData.password_confirmation} onChange={(e)=>{handleChange("password_confirmation",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="password confirm" required="" />     
                                                </Col>
                                            </FormGroup>  
                                            }                                     
                                        </Col>
                                    </Form>                                    
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
            {/* languages */}
            {
                Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang,i)=>(<Container className='mb-3' fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={`${lang.name} information`} />
                            <CardBody className="add-post">

                      
                                <Form className="row needs-validation">
                                        <Col sm="12">
                                        <FormGroup>
                                            <Label for="validationCustom01">Name</Label>
                                            <Input value={id==":id"?dataToEditorState[JSON.stringify(lang.id)].name.value:dataToEditorState[JSON.stringify(lang.id)].name.value} 
                                                    onChange={(e)=>{handleChange("name",e.target.value,lang.id,id==":id"?null:dataToEditorState[JSON.stringify(lang.id)].name.id,0)}} className="form-control" id="validationCustom01" type="text" placeholder={`${lang.name} name`} required="" />
                                            <div className="valid-feedback">{"Looks good!"}</div>
                                        </FormGroup>
                                    

                                        
                                        <div className="email-wrapper">
                                            <div className="theme-form">
                                            {/* <FormGroup>
                                                <Label > Description:</Label>
                                                <div>
                                                    <Editor
                                                        editorState={dataToEditorState[lang.id]["description"]["value"]}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={(e)=>{handleChange("description",e,lang.id,id===":id"?null:dataToEditorState[JSON.stringify(lang.id)].description.id,1)}}
                                                    />
                                                    <textarea style={{visibility:"hidden"}}
                                                        disabled={true}
                                                        // value={draftToHtml(convertToRaw(dataToEditorState[lang.id]["description"].getCurrentContent()))}
                                                    />
                                                </div>    
                                            </FormGroup> */}

                                            <FormGroup>
                                                <Label> Description </Label>
                                                <div>
                                                <Editor
                                                    apiKey='9tbaoxukhbd476wzx0cpnz5yr23n39igi9t4ds4jxp0nyfmk'
                                                    init={{
                                                        // TinyMCE editor initialization configuration
                                                        plugins: 'fullpage preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help charmap emoticons codesample code',
                                                        toolbar: 'undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  bullist numlist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile template link anchor codesample | ltr rtl | visualchars visualblocks nonbreaking table tableinsertdialog tablecellprops tableprops help codesample code',
                                                        height: 300,
                                                        branding: false,
                                                        setup: editor => {
                                                            editor.on('Change', function (e) {
                                                                handleChange("description", editor.getContent(), lang.id, id === ":id" ? null : dataToEditorState[lang.id].description.id, 1);
                                                            });
                                                        }
                                                    }}
                                                    initialValue={id === ":id" ? '' : draftToHtml(convertToRaw(dataToEditorState[lang.id].description.value.getCurrentContent()))}
                                                />
                                                </div>
                                            </FormGroup>

                                        <FormGroup>
                                            <Label for="validationCustom01">Meta Title</Label>
                                            <Input value={id==":id"?dataToEditorState[JSON.stringify(lang.id)].meta_title.value:dataToEditorState[JSON.stringify(lang.id)].meta_title.value} 
                                                    onChange={(e)=>{handleChange("meta_title",e.target.value,lang.id,id==":id"?null:dataToEditorState[JSON.stringify(lang.id)].meta_title.id,2)}} className="form-control" id="validationCustom01" type="text" placeholder={`${lang.name} meta_title`} required="" />
                                            <div className="valid-feedback">{"Looks good!"}</div>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="validationCustom01">Meta Description </Label>
                                            <textarea value={id==":id"?dataToEditorState[JSON.stringify(lang.id)].meta_description.value:dataToEditorState[JSON.stringify(lang.id)].meta_description.value} 
                                                    onChange={(e)=>{handleChange("meta_description",e.target.value,lang.id,id==":id"?null:dataToEditorState[JSON.stringify(lang.id)].meta_description.id,3)}} className="form-control" id="validationCustom01" type="text" placeholder={`${lang.name} meta_description`} required="" />
                                            <div className="valid-feedback">{"Looks good!"}</div>
                                        </FormGroup>

                                            </div>
                                        </div>
    
                                        </Col>
                                    </Form>
                               
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>))
            }
            {/* selected items */}
            <Container  className='mb-3' fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Row>
                                <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                                    <HeaderCard title="select items" />
                                </Col>
                                
                            </Row>
                            
                            <CardBody className="add-post">

                                <Pick_list data={countriess.length>0 ? countriess:[]}
                                 returnedItems={(res)=>{
                                        setselectedCountries(res)
                                }}  
                                 /> 
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
             {/* upload image */}
            <Container  className='mb-3' fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={`upload image`} />
                            <CardBody className="fileUploader">
                                 <Files
                                    className='files-dropzone fileContainer'
                                    onChange={onFilesChange}
                                    onError={onFilesError}
                                    accepts={['image/*']}
                                    multiple={false}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    {
                                        files.length > 0
                                            ? <div className='files-gallery'>
                                                {files.map((file, index) =>
                                                    <div key={index}>
                                                        <Image attrImage={{ className: 'files-gallery-item', alt: "img", src: `${file.preview.url}` }} />
                                                    </div>
                                                )}
                                            </div>

                                            : <div className="d-flex justify-content-center">
                                                <Btn attrBtn={{ className: "mt-2", type: "button", color: 'primary' }} >Upload Image</Btn>
                                            </div>
                                    }
                                </Files>
                                {files.length > 0 ?
                                    <div className="d-flex justify-content-center">
                                        <Btn attrBtn={{ className: "mt-2", color: 'danger', type: "button", onClick: () => deleteFile(files) }} >
                                            Delete
                                        </Btn></div> : ''}

                        </CardBody>
                           
                        </Card>
                    </Col>
                    
                </Row>
                
            </Container>
            </>
            }
            
            
        </Fragment>
    );

}
export default Add_shipping;