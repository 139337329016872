export const MENUITEMS = [
  {
    menutitle: "Control Panel",
    menucontent: "Dashboards,Widgets",
    Items: [
          { path: `${process.env.PUBLIC_URL}/dashboard`,permission : "financial.get" , icon: "home", title: "Dashboard", type: "link" },
          { path: `${process.env.PUBLIC_URL}/settings/list`,permission : "setting.get" , icon: "settings", title: "Setting", type: "link" },
         


{
            title: "Countries",
            icon: "globe",
            type: "sub",
            badge: "badge badge-light-primary",
            active: false,
            permission : "country.get-all",
            children: [
           
              { path: `${process.env.PUBLIC_URL}/country-list/:id`, permission : "country.get-all", title: "Country List", type: "link" },
              { path: `${process.env.PUBLIC_URL}/add-country/:id`, permission : "country.add", title: "Add Country", type: "link" },
            ],
          },
          
          // {
          //   title: "Mails",
          //   icon: "mail",
          //   type: "sub",
          //   badge: "badge badge-light-primary",
          //   active: false,
          //   permission : "role.get",
          //   children: [
          //     { path: `${process.env.PUBLIC_URL}/MailList/:id`,permission : "conversation.get-all" , icon: "box", title: "MailList", type: "link" },
          //     { path: `${process.env.PUBLIC_URL}/AddMail/:id`,permission : "conversation.get-all" , icon: "box", title: "Add_Mail", type: "link" },
          //   ],
          // },
          {
            title: "Notifications Text",
            icon: "bell",
            type: "sub",
            badge: "badge badge-light-primary",
            active: false,
            permission : "role.get",
            children: [
              { path: `${process.env.PUBLIC_URL}/notification-list/:id`,permission : "conversation.get-all" , icon: "box", title: "Notification List", type: "link" },
              { path: `${process.env.PUBLIC_URL}/add-notification/:id`,permission : "conversation.get-all" , icon: "box", title: "Add Notification", type: "link" },
            ],
          },

          
          
          
          {
            title: "Media",
            icon: "image",
            type: "sub",
            badge: "badge badge-light-primary",
            active: false,
            permission : "photo.get-all",
            children: [
           
              { path: `${process.env.PUBLIC_URL}/photo-list/:id`, permission : "photo.get-all", title: "Photo List", type: "link" },
              { path: `${process.env.PUBLIC_URL}/add-photo/:id`, permission : "photo.add", title: "Add Photo", type: "link" },
              { path: `${process.env.PUBLIC_URL}/video-list/:id`,  permission : "video.get-all", title: "Video List", type: "link" },
              { path: `${process.env.PUBLIC_URL}/add-video/:id`,  permission : "video.add", title: "Add Video", type: "link" },
            ],
          },
        
    ],
  },
// // Shake shake
{
  menutitle: "Shakes ",
  menucontent: "Products,Brands,Categories",
  Items: [

    { path: `${process.env.PUBLIC_URL}/shakes/:id`,permission : "user_shake.get-all" , icon: "gift", title: "Shakes", type: "link" },
       
    { path: `${process.env.PUBLIC_URL}/orders/:id`,permission : "order.get-all" , icon: "shopping-cart", title: "Orders", type: "link" },
    
    { path: `${process.env.PUBLIC_URL}/redeem-request/:id`,permission : "redeem_request.get-all", icon: "credit-card", title: "Redeem Request", type: "link" },

    {
      title: "Balances",
      icon: "dollar-sign",
      type: "sub",
      badge: "badge badge-light-primary",
      active: false,
      permission : "balance.get-all",
      children: [
     
        { path: `${process.env.PUBLIC_URL}/balance-list/:id`, permission : "balance.get-all", title: "Balance List", type: "link" },
        { path: `${process.env.PUBLIC_URL}/charge-balance/:id`, permission : "balance.add", title: "Charge Balance", type: "link" },
      ],
    },
   
  ],
},
{
  menutitle: "Users ",
  menucontent: "Products,Brands,Categories",
  Items: [
    {
      title: "Users",
      icon: "users",
      type: "sub",
      active: false,
      permission:"user_shake.get-all",
      children: [
        { path: `${process.env.PUBLIC_URL}/user-list`,permission:"user_shake.get-all", title: "User List", type: "link" },
        { path: `${process.env.PUBLIC_URL}/add-user/:id`,permission:"user_shake.add", title: "Add User", type: "link" },
      ],
    },
    { path: `${process.env.PUBLIC_URL}/user-addresses/:id`,permission : "user_address.get-all" , icon: "map-pin", title: "User Address", type: "link" },
    {
      title: "Roles",
      icon: "shield",
      type: "sub",
      badge: "badge badge-light-primary",
      active: false,
      permission : "role.get",
      children: [
     
        { path: `${process.env.PUBLIC_URL}/role-list/:id`,permission : "role.get",icon: "box", title: "Role list", type: "link" },
        { path: `${process.env.PUBLIC_URL}/add-role/:id`,permission : "role.add", title: "Add Role", type: "link" },
      ],
    },
  ],
},
{
  menutitle: "Shipping ",
  menucontent: "Products,Brands,Categories",
  Items: [

    {
      title: "Shipping Companies",
      icon: "truck",
      type: "sub",
      badge: "badge badge-light-primary",
      active: false,
      permission : "shipping_company.get",
      children: [
        { path: `${process.env.PUBLIC_URL}/companies-list`,permission : "shipping_company.get", title: "Companies List", type: "link" },
        { path: `${process.env.PUBLIC_URL}/add-company/:id`,permission : "shipping_company.add", title: "Add Company", type: "link" },
      ],
    },
    {
        title: "Shipping Option",
        icon: "calendar",
        type: "sub",
        badge: "badge badge-light-primary",
        active: false,
        permission : "shipping_option.get-all", 
        children: [
          { path: `${process.env.PUBLIC_URL}/options-list`,permission : "shipping_option.get-all", title: "Options List", type: "link" },
          { path: `${process.env.PUBLIC_URL}/add-option/:id`,permission : "shipping_option.add", title: "Add Option", type: "link" },
        ],
      },
   
  ],
},
// {
//   menutitle: "Statistics ",
//   menucontent: "Products,Brands,Categories",
//   Items: [
//     { path: `${process.env.PUBLIC_URL}/Statisitics`,permission : "setting.get" , icon: "bar-chart", title: "Statisitics", type: "link" },
//     { path: `${process.env.PUBLIC_URL}/Accounting`,permission : "setting.get" , icon: "dollar-sign", title: "Financials", type: "link" },
//   ],
// },
{
  menutitle: "Conversations ",
  menucontent: "Products,Brands,Categories",
  Items: [
    
    { path: `${process.env.PUBLIC_URL}/conversations/:id`,permission : "conversation.get-all" , icon: "message-circle", title: "Conversations", type: "link" },
        
    { path: `${process.env.PUBLIC_URL}/support/:id`,permission : "message.get-all" , icon: "help-circle", title: "Support", type: "link" },
    
   
  ],
},

{
  menutitle: "Products ",
  menucontent: "Products,Brands,Categories",
  Items: [
    {
      title: "Products",
      icon: "archive",
      type: "sub",
      badge: "badge badge-light-primary",
      active: false,
      permission:"product.get-all",
      children: [
     
        { path: `${process.env.PUBLIC_URL}/product-list/:id`, permission:"product.get-all", title: "Product List", type: "link" },
        { path: `${process.env.PUBLIC_URL}/add-product/:id`, permission:"product.add", title: "Add Product", type: "link" },
      ],
    },
    {
      title: "Categories",
      icon: "grid",
      type: "sub",
      active: false,
      permission:"category_rating.get-all",
      children: [
        { path: `${process.env.PUBLIC_URL}/category-list`,permission:"category_rating.get-all", title: "Category List", type: "link" },
        { path: `${process.env.PUBLIC_URL}/add-category/:id`,permission:"category_rating.add", title: "Add Category", type: "link" },
      
      ],
    },

    // {
    //   title: "Brands",
    //   icon: "tag",
    //   type: "sub",
    //   active: false,
    //   permission:"brand.get",
    //   children: [
    //     { path: `${process.env.PUBLIC_URL}/brand-list`,permission:"brand.get", title: "Brands-List", type: "link" },
    //     { path: `${process.env.PUBLIC_URL}/add-brand/:id`,permission:"brand.add", title: "Add-Brand", type: "link" },
    //   ],
    // },
  ],
},
{
  menutitle: "Boxes ",
  menucontent: "Boxes,Categories_Of_Boxes",
  Items: [
    {
      title: "Boxes",
      icon: "box",
      type: "sub",
      active: false,
      permission:"box.get-all",
      children: [
        { path: `${process.env.PUBLIC_URL}/box-list`,permission:"box.get-all", title: "Box List", type: "link" },
        { path: `${process.env.PUBLIC_URL}/add-box/:id`,permission:"box.add", title: "Add Box", type: "link" },
      ],
    },
    {
      title: "Category Of Box",
      icon: "grid",
      type: "sub",
      active: false,
      permission:"box_category.get-all",
      children: [
        { path: `${process.env.PUBLIC_URL}/cat-box`,permission:"box_category.get-all", title: "Cat Box", type: "link" },
        { path: `${process.env.PUBLIC_URL}/add-cat/:id`,permission:"box_category.add", title: "Add Cat", type: "link" },
      ],
    },

   
  ],
},


{
  menutitle: "Pages ",
  menucontent: "Products,Brands,Categories",
  Items: [
    
    {
      title: "Pages",
      icon: "layout",
      type: "sub",
      active: false,
      permission:"page.get-all",
      children: [
        { path: `${process.env.PUBLIC_URL}/page-list`,permission:"page.get-all", title: "Page List", type: "link" },
        { path: `${process.env.PUBLIC_URL}/add-page/:id`,permission:"page.add", title: "Add Page", type: "link" },
      ],
    },
    {
      title: "Sections",
      icon: "clipboard",
      type: "sub",
      active: false,
      permission:"page_section.get-all",
      children: [
        { path: `${process.env.PUBLIC_URL}/section-list`,permission:"page_section.get-all", title: "Section List", type: "link" },
        { path: `${process.env.PUBLIC_URL}/add-section/:id`,permission:"page_section.add", title: "Add Section", type: "link" },
      ],
    },
   
  ],
},

 
];
