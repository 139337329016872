
import React, { useState, useEffect } from 'react';
import { PickList } from 'primereact/picklist';
import "primereact/resources/themes/tailwind-light/theme.css";
//api request
import { api_Routes } from '../../../api_Routes';
import { Helper } from '../../../tools/helper';
import { toast } from 'react-toastify';
import { Loader } from 'rsuite';
import { useParams } from 'react-router';

export default function Pick_list({returnedItems,data}) {
    const {id} = useParams()
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [products,setProducts] = useState([])
    const [name, setName] = useState("")
    const [nameFilter, setNameFilter] = useState("")
    useEffect(() => {
        const controller = new AbortController()
        const signal = controller.signal
       get_products(signal)
       return()=>{
        controller.abort()
       }
    },[nameFilter]);
    useEffect(()=>{
        setTarget(data)
    },[])
    // useEffect(()=>{
    //     if(nameFilter.length>=3){
    //         setName(nameFilter.toLocaleLowerCase())
    //     }else(
    //         setName("")
    //     )
    // },[nameFilter])
    const get_products = async (signal)=>{
        setIsLoading(true)
        setSource([]) 
        const {response,message} = await Helper.Get_Abort({
            url:api_Routes.product.availableBoxesForBoxes,
            hasToken:true,
            signal:signal,
            data:nameFilter.length>3?{name:nameFilter,results:50}:{results:50}
        })
        if(response){

            setSource([])
            let itemsTarget = []
            if(data.length>0){
                target.forEach(ele=>{
                    itemsTarget.push(ele.name)     
                 })
            }
            response.data.forEach(ele=>{
                
                if(id!=":id"){
                    if(!itemsTarget.includes(ele.translations["en"]?ele.translations["en"].name:"")){
                        setSource(prev=>[...prev,{
                            name:ele.translations["en"]?ele.translations["en"].name:"",
                            photo:ele.photo,
                            price:ele.sellprice,
                            category:ele.translations["en"]?ele.translations["en"].name:"",
                            id:ele.id
                        }])
                    } 
                }else{
                    setSource(prev=>[...prev,{
                        name:ele.translations["en"]?ele.translations["en"].name:"",
                        photo:ele.photo,
                        price:ele.sellprice,
                        category:ele.translations["en"]?ele.translations["en"].name:"",
                        id:ele.id
                    }])
                }
                   
                
            })
        
            setIsLoading(false)
        }else{
          console.log(message);
        }
    }
    const onChange = (event) => {
        setSource(event.source);
        setTarget(event.target);
        let array = []
        event.target.forEach(ele=>{
            array.push(ele.id)
        })
        returnedItems(array)
    };

    const itemTemplate = (item) => {
        return (
            <div className="d-flex flex-wrap p-2 align-items-center gap-3">
            <img className=" shadow-2 flex-shrink-0 border-round"style={{width:"50px",objectFit:"cover"}} src={`${item.photo}`} alt={item.name} />
            <div className="flex-1 flex flex-column gap-2">
                <span className="font-bold">{item.name.substring(15,0)} ...</span>
                <div className="flex align-items-center gap-2">
                    <i className="pi pi-tag text-sm"></i>
                    <span>{item.category}</span>
                </div>
            </div>
            <span style={{fontWeight:"600"}} className="font-bold text-900 ">${item.price}</span>
        </div>
           
        );
    };
    const itemTemplateTarget = (item) => {
        return (
            <div className="d-flex flex-wrap p-2 align-items-center gap-3">
            <img className=" shadow-2 flex-shrink-0 border-round"style={{width:"50px",objectFit:"cover"}} src={`${item.photo}`} alt={item.name} />
            <div className="flex-1 flex flex-column gap-2">
                <span className="font-bold">{item.name.substring(15,0)} ...</span>
                <div className="flex align-items-center gap-2">
                    <i className="pi pi-tag text-sm"></i>
                    <span>{item.category}</span>
                </div>
            </div>
            <span style={{fontWeight:"600"}} className="font-bold text-900 ">${item.price}</span>
        </div>
           
        );
    };

    return (
        <div className="card">
            <PickList onSourceFilterChange={(e)=>{
                    if(e.originalEvent.target.value.length>3){
                        setNameFilter(e.originalEvent.target.value)
                    }else{
                        setNameFilter("")
                    }
                }} dataKey="id" source={source} target={target} onChange={onChange} 
                targetItemTemplate={target.length>0?itemTemplateTarget:<>No item yet</>}
                sourceItemTemplate={isLoading?<Loader/>:itemTemplate} breakpoint="1280px"
                sourceHeader="Available" targetHeader="Selected" filter filterBy="name" targetFilterPlaceholder='Search' sourceFilterPlaceholder="Search by name"
                sourceStyle={{ height: '24rem' }} targetStyle={{ height: '24rem' }} />
        </div>
    );
}
