import React, { useContext, useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import { FileText, LogIn, Mail, User } from "react-feather";
import man from "../../../assets/images/dashboard/profile.png";

import { LI, UL, Image, P, H1, H6 } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import { Account, Admin, Inbox, LogOut, Taskboard } from "../../../Constant";
import { Style } from "@mui/icons-material";

const UserHeader = () => {
  const history = useNavigate();
  const [profile, setProfile] = useState("");
  const { layoutURL } = useContext(CustomizerContext);
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL") || man);
  }, []);

  const Logout = () => {
    localStorage.removeItem("Name");
    localStorage.removeItem("profileURL");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("verified");
    localStorage.removeItem("auth0_profile");
    localStorage.removeItem("pusherTransportTLS");
    localStorage.removeItem("i18nextLng");
    localStorage.removeItem("permission");
    localStorage.setItem("authenticated", false);
    localStorage.setItem("login", false);
    localStorage.setItem("permission", JSON.stringify(["login"]));
    history(`${process.env.PUBLIC_URL}/login`);
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media">
      <img
          className="b-r-10 m-0"
          style={{ width: "50px", height: "35px" }}
          src={JSON.parse(localStorage.getItem("user"))?.userphoto || ""}
          alt=""
        />
        <div className="media-body">
          <p attrPara={{ className: "mb-0 font-roboto" }}>
          {authenticated ? "" : JSON.parse(localStorage.getItem("user"))?.username  } <i className="middle fa fa-angle-down"></i>
          </p>
        </div>
      </div>
      <UL attrUL={{ className: "simple-list profile-dropdown onhover-show-div" }}>
        {/* <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/profile/${layoutURL}`),
          }}>
          <User />
          <span>{Account} </span>
        </LI>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/email-app/${layoutURL}`),
          }}>
          <Mail />
          <span>{Inbox}</span>
        </LI>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/todo-app/todo/${layoutURL}`),
          }}>
          <FileText />
          <span>{Taskboard}</span>
        </LI> */}
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
