import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label  } from 'reactstrap';
import { Breadcrumbs, Btn,Spinner } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
//files
import Files from 'react-files';
//Form Component
import {  useNavigate } from 'react-router-dom';


import {  Loader} from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
// Editor Wyswigy 
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useParams } from 'react-router';
import { Switch } from '@mui/material';
import { FaArrowLeft } from 'react-icons/fa';


const Permissions = () =>{
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const {id} = useParams()
    const [formData, setFormData]  = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const permissions = JSON.parse(localStorage.getItem('permission'));



    useEffect (() => {
        if (!permissions || !permissions.includes('permission.get')) {
            navigate(`/Forbidden`)
        } else{
             get_permission()
        }
    },[])

    const get_permission = async () =>{
        setIsLoadingDetailes(true)
        const { response, message } = await Helper.Get({
            url: api_Routes.permission.getpermissionsbyrole(id),
            hasToken: true,
        });

        if (response) {
            console.log(response.data);
            setFormData(response.data);
            setIsLoadingDetailes(false)

        } else {
            if (typeof message === "string") {
                toast.error(message);
            } else {
                Object.keys(message).forEach(msg => {
                    toast.error(`${message[msg]}:${msg}`);
                });
            }
        }
    }

    const handleChange = (id, value) => {
        const newData = formData.map(item => {
            if (item.id === id) {
                return { ...item, has_permission: value };
            }
            return item;
        });
        setFormData(newData);
    }
    

// const formatData = () => {
//     const formattedData = {};
//     formData.forEach(item => {
//         formattedData[item.id.toString()] = item.value;
//     });
//     return formattedData;
//     }

    const handleSubmit = async () => {
        // Filter out the IDs of checkboxes with has_permission set to 1
        const checkedIds = formData.filter(item => item.has_permission === 1).map(item => item.id);
    
        const { response, message } = await Helper.Post({
            url: api_Routes.permission.add,
            data: { ids: checkedIds, id: id }, // Include both sets of data in the request
            hasToken: true
        });
    
        if (response) {
            if (id === ":id") {
                toast.success("Successfully added !!");
                navigate(`/role-list/:id/order`);
              } else {
                toast.success("Successfully edited !!");
                navigate(`/role-list/:id/order`);
              }         
                 setIsLoading(false);
        } else {
            if (typeof (message) === "string") {
                toast.error(message);
            } else {
                Object.keys(message).map((i) => {
                    toast.error(`${i} : ${message[i][0]}`);
                });
            }
            setIsLoading(false);
        }
    }
    const handleGoBack = ()=>{
        navigate(`/role-list/:id/order`)
    }
    const handleCheckAllSwitches = () => {
        const updatedFormData = formData.map(item => ({
            ...item,
            has_permission: 1
        }));
        setFormData(updatedFormData);
    }
    

        return (
            <Fragment>
            <Breadcrumbs mainTitle="Permissions" parent="permission" title="edit" />
            {
                isLoadingDetiales?<div className="loader-box">
                <Spinner attrSpinner={{ className: "loader-26" }} />
            </div>:<>
            <Container className='mb-3' fluid={true}>
            
                <Row>
                    <Col sm="12">
                    <Card>
                        <Row>
                            <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                                <HeaderCard title="Permission"/>
                            </Col>

                            {permissions && permissions.includes('permission.edit') && (
                            <Col className='d-flex justify-content-center align-items-center ' lg={2} xl={2}  md={2} sm={2} xs={2}>
                            <Btn attrBtn={{ className: "h-50", color: 'danger', type: "button", onClick: () => handleSubmit() }} >
                                    {isLoading?<Loader/>:<i className='fa fa-save'></i>}
                            </Btn>
                            
                            </Col>
                            )}

                            <Col style={{paddingLeft:"43px"}} className='d-flex justify-content-start align-items-center' lg={3} xl={3} md={3} sm={3} xs={3}>
                                    <Btn 
                                        attrBtn={{
                                            className: "h-50 ",
                                            color:"#1976d2",
                                            style: { width: "fit-content", display: "flex", justifyContent: "center", alignItems: "center", padding: "21px 15px",   backgroundColor: "#1976d2", color: "#fff" },
                                            onClick: handleCheckAllSwitches
                                        }}
                                    >
                                        Check All 
                                    </Btn>
                                </Col>

                            <CardBody className="add-post">
                                <Form className="row needs-validation">
                                {formData.map(({ id, name, has_permission }, index) => (
                                        (index % 3 === 0) && (
                                            <Row key={id} className="mb-3">
                                                {formData.slice(index, index + 3).map(({ id, name, has_permission }) => (
                                                    <Col xs={12} sm={4} key={id}>
                                                        <FormGroup>
                                                            <Switch
                                                                checked={has_permission === 1}
                                                                onChange={(e) => {handleChange(id, e.target.checked ? 1 : 0); console.log(e.target) } }
                                                            />
                                                            <Label>{name}</Label>
                                                        </FormGroup>
                                                    </Col>
                                                ))}
                                            </Row>
                                        )
                                    ))}
                                </Form>
                            </CardBody>
                        </Row>
                    </Card>
                    </Col>
                </Row>
            </Container>
            </>
            }
            
            </Fragment>
        )
}
export default Permissions;