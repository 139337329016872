import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Breadcrumbs, Btn,Spinner } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
//files
import Files from 'react-files';
//Form Component

import { SelectPicker  , Loader} from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
// Editor Wyswigy 
import { EditorState, convertToRaw,ContentState ,convertFromHTML  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useParams} from "react-router-dom"
import {  useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';


const Add_shipping_option = () => {
    const {id} = useParams() 
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [langauges, setLangauges] = useState([])
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [company, setcompany] = useState([]);
    const [editors_keys, setEditorsKeys] = useState(['description','short_description','specifications']);
    const [formData, setFormData]  = useState({
        name:"",
        company_id:"",
        days:"",
        charge_price:"",
        charge_percentage:"",
    })
    const permissions = JSON.parse(localStorage.getItem('permission'));


          useEffect(()=>{ 
            if (id === ":id" && (!permissions || !permissions.includes('shipping_option.add'))) {
                navigate(`/Forbidden`);
            } else {
                get_company()

                if(id != ":id"){
                    get_details()
                }
            }
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

                if(localStorage.hasOwnProperty("lang")){
            let array = JSON.parse(localStorage.getItem("lang"))
            setLangauges(array)
            if(id==":id"){
                setDataToEditorState(prev=>{
                    array.map((e)=>{
                      prev[e.id]= {  
                        name:{id:0,value:""},    
                      }
                      return prev[e.id]
                    })
                    return prev
                  })
            }
           }
            },[])

                const get_company = async ()=>{
       // setIsLoadingSelected(prev=>({...prev,["category"]:true}))
        
        const {response,message} = await Helper.Get({
            url:api_Routes.shipping.view,
            hasToken:true,

        })
        if(response){
            console.log(company)
            console.log(response.data)
              setcompany([])
            response.data.forEach(ele=>{
                setcompany(prev=>[...prev,{
                    label:ele.name,
                    value:ele.id
                }])
            })
           // setIsLoadingSelected(prev=>({...prev,["category"]:false}))
        }else{
            if(typeof message =="string"){
                toast.error(message)
            }else{
                Object.keys(message).forEach(msg=>{
                    toast.error(`${message[msg]}:${msg}`)
                })
            }
        }
    }


        const get_details = async ()=>{
         setIsLoadingDetailes(true)
        const {response,message} = await Helper.Get({
            url:api_Routes.shippingoption.getOne(id),
            hasToken:true,
            data:{detailed:1}
        })
         if(response){
           var details_languages = {}
            console.log(response.data)
            setPage(response.data)    
          
            setFormData({
                name:response.data.name,
                company_id:response.data.company_id,
                days:response.data.days,
                charge_price:response.data.charge_price,
                charge_percentage:response.data.charge_percentage,     
            })      
             response.data.detailed_translations.forEach(ele=>{
                ele.forEach(dt=>{
                    if (!details_languages[dt.language_id])
                        details_languages[dt.language_id] = {}

                    if(editors_keys.includes(dt.text_type))
                    {
                        details_languages[dt.language_id][dt.text_type] = {
                            value:dt.value ?
                                EditorState.createWithContent(
                                    ContentState.createFromBlockArray(
                                        convertFromHTML(dt.value)
                                    )
                                ) : EditorState.createEmpty(),
                            id:dt.id
                        }

                    }else
                        details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                })
            })
            
            setDataToEditorState(details_languages)      

            setIsLoadingDetailes(false)
        }else{
            if(typeof message =="string"){
                toast.error(message)
            }else{
                Object.keys(message).forEach(msg=>{
                    toast.error(`${message[msg]}:${msg}`)
                })
            }
        }

        }
                const handleChange = (key, value, id_lang, id, sort) => {

            if (id_lang) {
                let translations = { ...dataToEditorState };
                translations[id_lang][key] = { id:id, value:value };
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };

        const handleSubmit = async () => {
            let exit = false

            if (!formData.name || !formData.company_id || !formData.days || !formData.charge_price || !formData.charge_percentage  ) {
        toast.error("Please fill in all required fields (Name, Company, Days, Charge Price, Charge Percentage).");
        setIsLoading(false);
        return;
    }
            console.log(formData);
            setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''
            console.log(updatedFormData)

            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                    if(editors_keys.includes(subKey))
                        valueTemp = draftToHtml(convertToRaw(dataToEditorState[key][subKey].value.getCurrentContent()))
                        else {
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            } else {
                                 exit = true
                            }
                        }
                    if(id === ":id"){
                        form_data.append(`languages[${key}][${subKey}]`, valueTemp);
                    }
                    else
                        form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
                })
            })
            Object.keys(updatedFormData).forEach((key) => {
                if (key === "file")
                    form_data.append("file", updatedFormData.file);
                else
                    form_data.append(key, updatedFormData[key]);
            });

            if (id === ":id") {
                form_data.append("_method", "PUT");
            }
            if(exit){
                toast.error("You should add the Name ");
                setIsLoading(false)
                return;
            }

            const { response, message } = await Helper.Post({
                url: id === ":id" ? api_Routes.shippingoption.add : api_Routes.shippingoption.update(id),
                data: form_data,
                hasToken: true
            });

            if (response) {
                if (id === ":id") {
                    toast.success("Successfully added !!");
                    navigate(`/options-list/order`);
                  } else {
                    toast.success("Successfully edited !!");
                    navigate(`/options-list/order`);
                  }
                    setIsLoading(false);
            } else {
                if (typeof message === "string") {
                    toast.error(message);
                } else {
                    Object.keys(message).forEach((i) => {
                        const errorMessage = message[i][0] || "";
                        toast.error(`${i} : ${errorMessage}`);
                    });
                }
                setIsLoading(false);
            }
            };  
            
            const [files, setFiles] = useState([]);


            function deleteFile(e) {
                setFiles([]);
                setFormData(prev=>({...prev,["file"]:""}))
                return files

            }
            const onFilesChange = (files) => {
                setFiles(files)
                setFormData(prev=>({...prev,["file"]:files[0]}))
                console.log(files);
            }
            const onFilesError = (error, file) => {
                setFormData(prev=>({...prev,["file"]:""}))
                setFiles(file)
            }
            const handleGoBack = ()=>{
                navigate(`/shippingOptions/order`)
            }

              return (
        <Fragment>
            
            <Breadcrumbs mainTitle={id==":id"?"Add Shipping Option":"Edit Shipping Option"} parent="Shipping Option" title={id==":id"?"Add ":"Edit "}/>
            {
                isLoadingDetiales?<div className="loader-box">
                <Spinner attrSpinner={{ className: "loader-26" }} />
            </div>:<>
            {/* Basic Info */}
            <Container  className='mb-3' fluid={true}>
           
                <Row>
                    <Col sm="12">
                        <Card>
                            <Row>
                                <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                                    <HeaderCard title="basic Info" />
                                </Col>
                                {(permissions.includes('shipping_option.edit')) && ( 
                                <Col className='d-flex justify-content-center align-items-center ' lg={2} xl={2}  md={2} sm={2} xs={2}>
                                        <Btn attrBtn={{  className: "h-50", color: 'danger', type: "button", onClick: () => !isLoading && handleSubmit() }} 
                                        disabled={isLoading}
                                        >
                                        {isLoading ? <Loader /> : <i className='fa fa-save'></i>}
                                        </Btn>
                                </Col>
                                )}
                            </Row>
                            
                            <CardBody className="add-post">

                                {/* Basic Info */}
                                <Form className="row needs-validation">
                                       <Row>
                                       <Col xs={6} sm={4} md={4} lg={4} xl={4} >
                                        <FormGroup>
                                            <Label for="validationCustom01">Name</Label>
                                            <Input style={{width:"100%"}}  value={formData.name} onChange={(e)=>{handleChange("name",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="name" required="" />   
                                        </FormGroup>
                                        </Col>
                                        <Col style={{marginTop:"25px",marginLeft:"7px"}} xs={4} sm={4} md={4} lg={4} xl={4}>
                                        <FormGroup>
                                            <SelectPicker style={{width:"100%"}}  value={formData.company_id} onChange={(e)=>{handleChange("company_id",e,null)}} data={company} placeholder="Companies"   />
                                        </FormGroup>
                                        </Col>
                                        </Row>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} >
                                        <FormGroup>
                                            <Label for="validationCustom01">Days</Label>
                                            <Input value={formData.days} onChange={(e)=>{handleChange("days",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="days" required="" />     
                                        </FormGroup>
                                    </Col>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4} >
                                        <FormGroup>
                                            <Label for="validationCustom01">Charge Price</Label>
                                            <Input value={formData.charge_price} onChange={(e)=>{handleChange("charge_price",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="charge_price" required="" />     
                                        </FormGroup>        
                                     </Col>
                                     <Col xs={4} sm={4} md={4} lg={4} xl={4} >
                                        <FormGroup>
                                            <Label for="validationCustom01">Charge percentage </Label>
                                            <Input value={formData.charge_percentage} onChange={(e)=>{handleChange("charge_percentage",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder=" charge_percentage" required="" />     
                                        </FormGroup>                                         
                                     </Col>
                                    </Form>
                                    
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
            {/* languages */}
            {
                Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang,i)=>(<Container className='mb-3' fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={`${lang.name} information`} />
                            <CardBody className="add-post">

                      
                                <Form className="row needs-validation">
                                        <Col sm="12">
                                        <FormGroup>
                                            <Label for="validationCustom01">Name</Label>
                                            <Input value={id==":id"?dataToEditorState[JSON.stringify(lang.id)].name.value:dataToEditorState[JSON.stringify(lang.id)].name.value} 
                                                    onChange={(e)=>{handleChange("name",e.target.value,lang.id,id==":id"?null:dataToEditorState[JSON.stringify(lang.id)].name.id,0)}} className="form-control" id="validationCustom01" type="text" placeholder={`${lang.name} name`} required="" />
                                            <div className="valid-feedback">{"Looks good!"}</div>
                                        </FormGroup>
    
                                        </Col>
                                    </Form>
                               
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>))
            }          
            </>
            }
            
            
        </Fragment>
    );

}
export default Add_shipping_option;