import React, { Fragment, useCallback, useState ,useEffect} from 'react'
import DataTable from 'react-data-table-component';
import { Btn, H4 ,Spinner,Image} from '../../../AbstractElements';
import { Media } from 'reactstrap';
import { dummytabledata, tableColumns } from '../../../Data/Table/Defaultdata';
import { Row, Col } from 'reactstrap'
import CommonModal from '../../../Components/Modal';
//api request
import { Helper } from '../../../tools/helper';
import { api_Routes } from '../../../api_Routes';
import { useNavigate } from 'react-router';

import { toast,ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';


const style2 = { width: 60, fontSize: 14, padding: 4 };
const DataTableComponent = ({values}) => {
    console.log(values);
    const navigate= useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [data, setData] = useState(dummytabledata);
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [ totalItems, setTotalItems ] = useState(0)
    const [isLoadding, setIsLoading] = useState(false)
    const [modal, setModal] = useState(false);
    const [refreshDelete, setRefreshDelete] = useState(false);
    const [item, setItem] = useState("");
    const toggle = () => setModal(!modal);
    const permissions = JSON.parse(localStorage.getItem('permission'));

        const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('user_id');

    const BaseApi = api_Routes.product.all;

    useEffect(()=>{
        get_products()
      },[perPage,page,values,refreshDelete])
    
    const get_products = async()=>{

        let url = BaseApi + '?1+1';
        if(userId){
            url = url + `&user_id=${userId}`;
        }
        
        setData_Table([])
        setIsLoading(true)
        const data = {page:page,results:perPage}
        if(values.categories && values.categories.length>0)
            data["in_categories"] = values.categories.toString()
        if(values.keywords && values.keywords !="")
            data["keywords"] = values.keywords
        if(values.is_box)
            data["is_box"] = values.is_box
        const {response, message} = await Helper.Get({
            url:url,
            hasToken:true,
            data:data
        })
        if(response){ 

            setTotalItems(response.meta.total)
            response.data.forEach(elem =>{
            // proccess for stars
            let empty_stars =[]
            let fill_stars =[]
            let half_stars = []
            let fill_num = 0
            if(elem.ratings==0){
                fill_num =0
            }else if(elem.ratings==5){
                fill_num =5
            }else{
                fill_num = Math.floor(elem.ratings)
            }
            for(let n=0 ; n < fill_num ; n++){
                fill_stars.push(n)
            }
            if(elem.ratings > Math.floor(elem.ratings)){
                half_stars.push(1)
                let remain_num = 5-(fill_num+1)
                for(let i =0 ; i<remain_num ; i++){
                    empty_stars.push(i)
                }
                
            }else{
                let remain_num = 5-(fill_num)
                for(let i =0 ; i<remain_num ; i++){
                    empty_stars.push(i)
                }
            }
            const isStockElem = elem.is_stock == 1 ? (
        <span key="stockBadge" className='badge badge-light-success'>is Stock</span>) : (
        <span key="noStockBadge" className='badge badge-light-danger'>isn't stock</span>);

            const bodyRating = (
            <div key="bodyRating" className="d-flex gap-1">
                {fill_stars.map((e, index) => {
                    return <i key={`fill_star_${index}`} style={{color:"#e3a404"}} className={`fa fa-star`}></i> 
                })}
                {half_stars.map((e, index) => {
                    return <i key={`half_star_${index}`} style={{color:"#e3a404"}} className={`fa fa-star-half-empty`}></i>
                })}
                {empty_stars.map((e, index) => {
                    return <i key={`empty_star_${index}`} style={{color:"#e3a404"}} className={`fa fa-star-o`}></i> 
                })}
            </div>
            );    
            setData_Table(prev=> [...prev,{
                id:elem.id,
                name:<Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${elem.photo?elem.photo:""}`, alt:`${elem.name?elem.name:""}` }} />
                    <Media body className="align-self-center">
                        <div>{elem.product_translations["en"]?elem.product_translations["en"].name:""}</div>
                    </Media>
                </Media>,
                category:elem.category_translations["en"]?.name,
                brand:elem.brand?elem.brand.name:"-", 
                sellprice:elem.sellprice,
                is_stock:isStockElem,
                is_box:elem.is_box == 0 ? "marketplace" : "boxes" ,
                quantity:elem.quantity,
                id:elem.id,
                // ratings:elem.ratings,
                ratings:bodyRating,
                action: (
                    <div>
                    <span className='mx-2'>
                        <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>navigate(`/add-product/${elem.id}/Shake`)}}>Edit </Btn>
                    </span>
                    {permissions && permissions.includes('product.delete') && (
                    <span >
                        <Btn  attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button' ,onClick:()=>{
                        toggle()
                        setItem(elem.id)
                        }}}>Delete </Btn>
                    </span>
                    )}
                    </div>
                ),
                    }])
            })
            setCol_Table([
            {
                name: 'Id',
                selector: row => row['id'],
                sortable: true,
                width:"8%",
                center: false,
            },
            {
                name: 'Name',
                selector: row => row['name'],
                sortable: true,
                width:"31%",
                center: false,
            },
            {
                name: 'Category',
                selector: row => `${row.category}`,
                sortable: true,
                width:"8%",
                center: true,
            },
            {
                name: 'Is Box',
                selector: row => row["is_box"],
                sortable: true,
                width:"8%",
                center: true,
            },
            {
                name: 'Is Stock',
                selector: row => row["is_stock"],
                sortable: true,
                width:"6%",
                center: true,
            },
            {
                name: 'Price',
                selector: row => `${row.sellprice}`,
                sortable: true,
                width:"8%",
                center: true,
            },
            {
                name: 'Rating',
                selector: row =>row["ratings"],
                sortable: true,
                width:"8%",
                center: true,
            },{
                name: 'Action',
                selector: (row) => row.action,
                sortable: true,
                center: true,
                Width: '21%',
            },])
            
            setIsLoading(false)
        }else{
            console.log(message);
        }
    }

    const handleRowSelected = useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const handleDelete = async() => {
        let path,ids={};
        if(item.length !==""){
         
            path=api_Routes.product.update(item)
        }else{
            selectedRows.forEach((ele,i)=>{
              ids[`ids[${i}]`] = ele
            })
            path=api_Routes.product.bulkDelete
    
        }
    
        const {response, message} = await Helper.Delete({
          url:path,
          hasToken:true,
          data:selectedRows.length>0?ids:{}
        })
        if(response){
          toast.success(message)
          setItem("")
          setSelectedRows([])
          setRefreshDelete(!refreshDelete)
        }else{
          toast.error(message)
          setItem("")
          setSelectedRows([])
        }
        // if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
        //     setToggleDelet(!toggleDelet);
    
        //     setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
        //     setSelectedRows('');
        // }
      };
    /// Change Pages To Api
    const handleChangePage = (value)=>{
        setPerPage(value)
    }
    const handlePage =(e)=>{
        setPage(e)
    }
    return (
        <Fragment>
            <CommonModal isOpen={modal} title="Confirm Delete" handleConfirm={handleDelete} toggler={toggle} >
                Are you sure delete ?
            </CommonModal>
            {(selectedRows.length !== 0) &&
                <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                    <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
                    <Btn attrBtn={{ color: 'danger', onClick: () =>  toggle() }}>Delete</Btn>
                </div>
            }
            <DataTable
                // data={data}
                // columns={tableColumns}
                data={data_table}
                columns={col_table}
                striped={true}
                center={true}
                progressPending={isLoadding}
                progressComponent={<div className="loader-box">
                                        <Spinner attrSpinner={{ className: "loader-26" }} />
                                    </div>}
                pagination
                paginationServer
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={[10,20,30,50,100]}
                responsive
                // selectableRows
                paginationTotalRows={totalItems}
                onSelectedRowsChange={(e)=>{
                    setSelectedRows([])
                    e.selectedRows.forEach(ele =>{
                      setSelectedRows(prev=>[...prev,ele.id])
                    })
                  }}
                onChangePage={(e)=>{handlePage(e)}}
                onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                clearSelectedRows={toggleDelet}
            />
            <ToastContainer/>
        </Fragment>
    )
}
export default DataTableComponent
