

export const login = (name,photo)=>{
    return {
        type:"LOGIN-USER",
        name: name,
        photo:photo
    }

}
export const toggle = (toggleBoolean)=>{
    return {
        type:"TOGGEL-STATUS",
        toggleBoolean: toggleBoolean,
    }
}
export const Language = (lang)=>{
    return{
        type:"LANGUAGE",
        lang:lang
    }
}

export const echo_info = (notification,conversation_id)=>{
    return{
        type:"ECHO_NOTIFICATIONS",
        notification : notification,
        conversation_id : conversation_id,
    }
}
export const echo_message= (message)=>{
    return{
        type:"ECHO_MESSAGE",
        message:message
    }
}