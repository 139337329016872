import React, { Fragment,useEffect,useState } from 'react';
import { Container, Row, Col, Card, CardBody,Media } from 'reactstrap';
import { Breadcrumbs ,Btn, H4,Image,Spinner } from '../../AbstractElements';


// rsuite
import { Input, Whisper, Tooltip,SelectPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// data table
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router';
import CommonModal from 'Components/Modal';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

const BaseApi = api_Routes.box.view
const style2 = { width: 60, fontSize: 14, padding: 4 };
function Box_Table() {
    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading_Category, setIsLoading_Category] = useState(false)
    const [categories, setCategories] = useState([])
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [ totalItems, setTotalItems ] = useState(0)
    const [modal, setModal] = useState(false);
    const [refreshDelete, setRefreshDelete] = useState(false);
    const [item, setItem] = useState("");
    const toggle = () => setModal(!modal);
    const permissions = JSON.parse(localStorage.getItem('permission'));
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('id');

    useEffect(()=>{
      if (!permissions || !permissions.includes('box.get-all')) {
        navigate(`/Forbidden`)
    } else {
      get_boxes()
      get_categories()
    }
      
    },[values_Filter,page,perPage,refreshDelete])

    const get_boxes = async()=>{
      let url = BaseApi + '?1+1';

      if(userId){
        url = url + `&id=${userId}`;
    }
        setIsLoading(true)
        setData_Table([])
        const {response, message} = await Helper.Get({
            url:url,
            hasToken:true,
            data:{results:perPage,
              page:page,
              keywords:values_Filter.keywords,
              category_id:values_Filter.category_id
            }
        })
        if(response){
            setTotalItems(response.meta.total)
            response.data.forEach(elem =>{   
                setData_Table(prev=> [...prev,{
                    name:<Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${elem.photo}`, alt:`${elem.name}` }} />
                        <Media body className="align-self-center">
                            <div>{elem.translations["en"]?.name}</div>
                        </Media>
                    </Media>,
                    category:elem.category?elem.category.name:"",
                    products:elem.products.length,
                    id:elem.id,
                    action: (
                      <div>
                        <span className='mx-2'>
                          <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>
                          navigate(`/add-box/${elem.id}/Shake`)}}>Edit </Btn>
                        </span>
                        {permissions && permissions.includes('box.delete') && (
                        <span >
                          <Btn  attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button' ,onClick:()=>{
                            toggle()
                            setItem(elem.id)
                          }}}>Delete </Btn>
                        </span>
                        )}
                      </div>
                    ),
                   
                        }])
                })
                setCol_Table([{
                    name: 'Name',
                    selector: row => row['name'],
                    sortable: true,
                    width:"25%",
                    center: false,
                },
                {
                    name: 'Category',
                    selector: row => `${row.category}`,
                    sortable: true,
                    width:"25%",
                    center: true,
                },
                {
                    name: 'Products',
                    selector: row => `${row.products}`,
                    sortable: true,
                    width:"25%",
                    center: true,
                },
                {
                  name: 'Action',
                  selector: (row) => row.action,
                  sortable: true,
                  center: true,
                  Width: '20%',
                },
          ])
            
               
           
            setIsLoading(false)
        }else{
            console.log(message);
        }
        }
    const get_categories = async()=>{
      setIsLoading_Category(true)

      const {response, message} = await Helper.Get({
          url:api_Routes.category_box.view,
          hasToken:true,
          data:{results:perPage,
            page:page,
            keywords:values_Filter.keywords,
            category_id:values_Filter.category_id
          }
      })
      if(response){
          setCategories(response.data)
          // response.data.forEach(elem =>{   
          //     setCategories(prev=>[...prev,{
          //       label:elem.translations["en"]?elem.translations["en"].name:"",
          //       value:elem.id
          //     }])
          //     })
          
          
              
          
          setIsLoading_Category(false)
      }else{
          console.log(message);
      }
      }
    const handleDelete = async() => {
        let path,ids={};
        if(item.length != ""){
         
            path=api_Routes.box.update(item)
        }else{
            selectedRows.forEach((ele,i)=>{
              ids[`ids[${i}]`] = ele
            })
            path=api_Routes.box.bulk
    
        }
    
        const {response, message} = await Helper.Delete({
          url:path,
          hasToken:true,
          data:selectedRows.length>0?ids:{}
        })
        if(response){
          toast.success(message)
          setItem("")
          setSelectedRows([])
          setRefreshDelete(!refreshDelete)
        }else{
          toast.error(message)
          setItem("")
          setSelectedRows([])
        }
        // if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
        //     setToggleDelet(!toggleDelet);
    
        //     setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
        //     setSelectedRows('');
        // }
      };
    /// Change Pages To Api
    const handleChangePage = (value)=>{
        setPerPage(value)
    }
    const handlePage =(e)=>{
        setPage(e)
    }
    const handleChange = (key,value)=>{
        setValues(prev=>({...prev,[key]:value}))
      }
    const handleFilter = ()=>{
      setFilter_Values(values)
    }

    return ( <Fragment>
        <Breadcrumbs parent="List" title="Box List" mainTitle="Box " />
        <CommonModal isOpen={modal} title="Confirm Delete" handleConfirm={handleDelete} toggler={toggle} >
          Are you sure delete ?
        </CommonModal>
        <Container className='my-2' fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
             
              <CardBody>
              <Row>
                <Col className='d-flex justify-content-center p-2' sm={12} lg={6} md={6} xl={6}>
                  <Whisper trigger="focus" speaker={<Tooltip>Name</Tooltip>}>
                    <Input onChange={(e)=>{handleChange("keywords",e)}} 
                          style={{ width: "100%" }} placeholder="Search By Name" />
                  </Whisper>
                </Col>  
                
                <Col className='d-flex justify-content-center p-2' sm={12} lg={5} md={5} xl={5}>
                  <SelectPicker size="md" placeholder="Search By Category" onChange={(e)=>{handleChange("category_id",e)}}
                          style={{ width: "100%" }} data={categories.map((elem)=>({
                            label:elem.translations["en"]?elem.translations["en"].name:"",
                            value:elem.id
                          }))} loading={isLoading_Category}  />
               
                </Col>
               
                <Col className='d-flex align-items-center justify-content-center p-2' sm={12} xs={12} lg={1} md={1} >
                  <div onClick={()=>{handleFilter()}}>
                    <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                  </div>
                </Col>
              </Row>
              

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                <Fragment>
                    {(selectedRows.length !== 0) &&
                        <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                            <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
                            <Btn attrBtn={{ color: 'danger', onClick: () => toggle() }}>Delete</Btn>
                        </div>
                    }
                    <DataTable
                        // data={data}
                        // columns={tableColumns}
                        data={data_table}
                        columns={col_table}
                        striped={true}
                        center={true}
                        progressPending={isLoading}
                        progressComponent={<div className="loader-box">
                                              <Spinner attrSpinner={{ className: "loader-26" }} />
                                          </div>}
                        pagination
                        paginationServer
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10,20,30,50,100]}
                        responsive
                        // selectableRows
                        paginationTotalRows={totalItems}
                        paginationServerOptions={()=>{}}
                        onChangePage={(e)=>{handlePage(e)}}
                        onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                        clearSelectedRows={toggleDelet}
                    />
                </Fragment>
  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>);
}

export default Box_Table;
