import React, { Fragment,useEffect,useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs ,Btn, H4,Spinner } from '../../AbstractElements';


// rsuite
import { Input, Whisper, Tooltip } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// data table
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router';
import CommonModal from 'Components/Modal';
import { toast } from 'react-toastify';


const style2 = { width: 60, fontSize: 14, padding: 4 };

function Shipping_option(){
        const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [ totalItems, setTotalItems ] = useState(0)
    const [modal, setModal] = useState(false);
    const [refreshDelete, setRefreshDelete] = useState(false);
    const [item, setItem] = useState("");
    const toggle = () => setModal(!modal);
    const permissions = JSON.parse(localStorage.getItem('permission'));


        useEffect(()=>{
          if (!permissions || !permissions.includes('shipping_option.get-all')) {
            navigate(`/Forbidden`)
        } else {
          get_shipping()
        }
        
    },[values_Filter,page,perPage,refreshDelete])

    const get_shipping = async()=>{
        setIsLoading(true)
        setData_Table([])
        const {response, message} = await Helper.Get({
            url:api_Routes.shippingoption.view,
            hasToken:true,
            data:{results:perPage,
              page:page,
              keywords:values_Filter.keywords,
            }
        })
        if(response){
            console.log(response.data);
            setTotalItems(response.meta.total)
            response.data.forEach(elem =>{   
                setData_Table(prev=> [...prev,{
                    id:elem.id,
                    name:elem.name,
                    company_id:elem.company_id,
                    days:elem.days,
                    charge_percentage:elem.charge_percentage,
                    charge_price:elem.charge_price,
                    action: (
                      <div>
                        <span className='mx-2'>
                          <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>
                          navigate(`/add-option/${elem.id}/Shake`)}}>Edit </Btn>
                        </span>
                        {permissions && permissions.includes('shipping_option.delete') && (
                        <span >
                          <Btn  attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button' ,onClick:()=>{
                            toggle()
                            setItem(elem.id)
                          }}}>delete </Btn>
                        </span>
                        )}
                      </div>
                    ),
                   
                        }])
                })
                setCol_Table([
                {
                    name: 'Id',
                    selector: row => row['id'],
                    sortable: true,
                    width:"10%",
                    center: false,
                },
                {
                    name: 'Name',
                    selector: row => row['name'],
                    sortable: true,
                    width:"18%",
                    center: false,
                },
                {
                    name: 'Company Id',
                    selector: row => row['company_id'],
                    sortable: true,
                    width:"13%",
                    center: false,
                },
                {
                    name: 'Days',
                    selector: row => row['days'],
                    sortable: true,
                    width:"10%",
                    center: false,
                },
                {
                    name: 'Charge Percentage',
                    selector: row => row['charge_percentage'],
                    sortable: true,
                    width:"15%",
                    center: false,
                },
                {
                    name: 'Charge Price',
                    selector: row => row['charge_price'],
                    sortable: true,
                    width:"15%",
                    center: false,
                },
                
                {
                  name: 'Action',
                  selector: (row) => row.action,
                  sortable: true,
                  center: true,
                  Width: '16%',
                },
          ])
            
               
           
            setIsLoading(false)
        }else{
            console.log(message);
        }
    }

         const handleDelete = async() => {
        let path,ids={};
        if(item.length != ""){
         
            path=api_Routes.shippingoption.update(item)
        }else{
            selectedRows.forEach((ele,i)=>{
              ids[`ids[${i}]`] = ele
            })
            path=api_Routes.shippingoption.bulk
    
        }
    
        const {response, message} = await Helper.Delete({
          url:path,
          hasToken:true,
          data:selectedRows.length>0?ids:{}
        })
        if(response){
          toast.success(message)
          setItem("")
          setSelectedRows([])
          setRefreshDelete(!refreshDelete)
        }else{
          toast.error(message)
          setItem("")
          setSelectedRows([])
        }
      };

     const handleChangePage = (value)=>{
        setPerPage(value)
    }
     const handlePage =(e)=>{
        setPage(e)
     }
     const handleChange = (key,value)=>{
        setValues(prev=>({...prev,[key]:value}))
      }
     const handleFilter = ()=>{
      setFilter_Values(values)
    }


    return(
        <Fragment>
           <Breadcrumbs parent="Table" title="Data Tables" mainTitle="Data Tables" />
           <CommonModal isOpen={modal} title="Confirm Delete" handleConfirm={handleDelete} toggler={toggle} >
             Are you sure delete ?
           </CommonModal>
           <Container className='my-2' fluid={true}>
            <Row>
                <Col sm="12">
                <Card>
                    <CardBody>
                        <Row>
                        <Col className='d-flex justify-content-center p-2' xs={8} sm={8} lg={11} md={11} xl={11}>
                        <Whisper trigger="focus" speaker={<Tooltip>Keywords</Tooltip>}>
                        <Input onChange={(e)=>{handleChange("keywords",e)}} 
                               placeholder="Search By Name" />
                        </Whisper>

                    </Col>  
                    
                    <Col className='d-flex align-items-center justify-content-end p-2' xs={4} sm={4}  xl={1} lg={1} md={1} >
                        <div onClick={()=>{handleFilter()}}>
                        <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                        </div>
                    </Col>

                        </Row>
                    </CardBody>
                </Card>
                </Col>
            </Row>
           </Container>
             <Container fluid={true}>
                <Row>
                    <Col>
                     <Card>
                        <CardBody>
                            <Fragment>
                                 {(selectedRows.length !== 0) &&
                                     <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                                     <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
                                     <Btn attrBtn={{ color: 'danger', onClick: () => toggle() }}>Delete</Btn>
                                     </div>
                                }
                                  <DataTable
                                    // data={data}
                                    // columns={tableColumns}
                                    data={data_table}
                                    columns={col_table}
                                    striped={true}
                                    center={true}
                                    progressPending={isLoading}
                                    progressComponent={<div className="loader-box">
                                                        <Spinner attrSpinner={{ className: "loader-26" }} />
                                                      </div>}
                                    pagination
                                    paginationServer
                                    paginationPerPage={perPage}
                                    paginationRowsPerPageOptions={[10,20,30,50,100]}
                                    responsive
                                    paginationTotalRows={totalItems}
                                    paginationServerOptions={()=>{}}
                                    onChangePage={(e)=>{handlePage(e)}}
                                    onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                                    clearSelectedRows={toggleDelet}
                                />
                            </Fragment>
                        </CardBody>
                     </Card>
                    </Col>
                </Row>
           </Container>
        </Fragment>
    )


}
export default Shipping_option;
