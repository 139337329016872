import React, { useState ,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Table } from 'reactstrap';
import { H5, Image,H6 } from '../../../AbstractElements';
import { DailyDropdown, ValuableCustomerTitle } from '../../../Constant';
import DropdownCommon from '../../Common/Dropdown';
import { Helper } from '../../../tools/helper';
import { api_Routes } from '../../../api_Routes';
import { Height } from '@mui/icons-material';
import gold from "../../../assets/images/logo/gold.svg"
import bronze from "../../../assets/images/logo/bronze.svg"
import paltinum from "../../../assets/images/logo/platinum.svg"
import silver from "../../../assets/images/logo/silver.svg"

function ValuableCustomer  () {
  const [vendors,setvendors] = useState([])
  

  useEffect(()=>{
    get_vendor()
  },[])

  const get_vendor = async()=>{
    const {response, message} = await Helper.Get({
      url:api_Routes.user.show ,
      hasToken: true,
      data:{
        results: 3,
        page: 1,
      }
  })
  if(response){
    setvendors(response.data)
    console.log(vendors);
    
  }
  }
  return (
    <div className='appointment'>
      <Card>
        <CardHeader className='card-no-border'>
          <div className='header-top'>
            <H5 className='m-0'>Top Vendors</H5>
            <div className='card-header-right-icon'>
              {/* <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'start' }} options={DailyDropdown} iconName='icon-more-alt' btn={{ tag: 'span' }} /> */}
            </div>
          </div>
        </CardHeader>
        <div className='card-body pt-0'>
          <div className='appointment-table customer-table '>
            <Table borderless responsive>
              <tbody>
                {vendors.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <Image attrImage={{ className: 'img-fluid img-40 rounded-circle me-2', src: item.photo, alt: 'user' }} />
                    </td>
                    <td className='img-content-box'>
                      <Link className='f-w-500' to={`${process.env.PUBLIC_URL}/add-user/${item.id}/Shake`}>
                        {item.name}
                      </Link>
                      <span className='f-light'>{item.email}</span>
                    </td>
                    <td>
                       <img style={{height:"20px",width:"20px"}}  src={item.badge.name =="Platinum Vendor" ? paltinum :item.badge.name =="Gold Vendor" ? gold : item.badge.name =="Bronze Vendor" ? bronze : silver } alt='user' /> 
                    </td>
                    <td>
                      <p style={{fontSize:"15px",fontWeight:'500',fontFamily:"Apple-System,Arial,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,STXihei,sans-serif"}}>{item.badge.name}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ValuableCustomer;
