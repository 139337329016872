import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { H5, UL, LI } from '../../../AbstractElements';
import { MonthlyProfitsGrowth, MonthlyProfitsTitle } from '../../../Constant';
import { MonthlyProfitsChartData } from '../../../Data/Ecommerce/Chart';
import {Box6} from '../../Statistics/chart'

const Shakeoverview = () => {
  return (
    <Card>
          <CardHeader className='card-no-border'>
            <H5 >Shakes Per Category</H5>
          </CardHeader>
          <CardBody className='pt-0'>
            <Row className='m-0 overall-card'>
              <Col xl='9' md='8' sm='7' className='box-col-7 p-0'>
                <div className='chart-right' style={{marginTop:"40px",marginRight:"-30px"}}>
                  <Row>
                    <Col xl='12'>
                      <CardBody className='p-0'>
                      <UL attrUL={{ horizontal: true, className: 'd-flex balance-data' }}>
                        <LI>
                          <span className='f-light ms-1' style={{ color: "#54BA4A" }}>Bronze</span>
                        </LI>
                        <LI>
                          <span className='f-light ms-1' style={{ color: "#7064F5" }}>Silver</span>
                        </LI>
                        <LI>
                          <span className='f-light ms-1' style={{ color: "#FF5733" }}>Golden</span>
                        </LI>
                        <LI>
                          <span className='f-light ms-1' style={{ color: "#AB47BC" }}>Platinum</span>
                        </LI>
                      </UL>
                        <div className='current-sale-container order-container'>
                          <ReactApexChart className='overview-wrapper' type='line' width={"150%"} height={300} options={Box6().options} series={Box6().series} />
                          <div className='back-bar-container'>
                            {/* <ReactApexChart type='bar' height={180} options={OptionsOverViewBarChart.options} series={OptionsOverViewBarChart.series} /> */}
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
  );
};

export default Shakeoverview;
