import { Box, Grid , Avatar, IconButton, Skeleton, CircularProgress , LinearProgress } from '@mui/material';
import React, { useEffect, useRef ,useState,useCallback} from 'react';
import styled, { keyframes } from 'styled-components';
import dateFormat from 'dateformat';
import {  useSnackbar } from 'notistack';
//emoji picker
import Picker from "emoji-picker-react";
//mui 
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
//images

import {api_Routes as apiRoutes} from '../../api_Routes';
import { Helper } from '../../tools/helper';
import { connect } from 'react-redux';
import { Close } from '@mui/icons-material';
import Swiper_Images_With_Videos from './lightCallery';
import noMessage from "../../assets/images/messages.png"
const Input = styled.input`
    width:100%;
    height:100%;
    border-radius:14px;
    outline:unset;
    background:rgb(159 159 159 / 24%);
    border:unset;
    padding:10px
`
const Continer = styled.div`
    padding:40px 0;
    justify-content:center;

& h2{

    font-weight:600;
    font-size:20px;
    text-align:center;
}
& h6{
    color:black;
    font-weight:500;
    font-size:16px;
    text-align:center;
    margin-bottom:10px;
}
& img{
    object-fit:contain;
    height:250px;

}
& span{
    display: flex;
    margin-right: 10px;
}
`

const spin = keyframes`
from { transform: rotate(0deg) }
to { transform: rotate(360deg)}
`
const BoxCustom = styled(Box)`
&  .content .grid_div{
    
    position: relative;
}
& .content .grid_div img{
    width:100%;
    object-fit: contain;
    max-height:200px;
    filter: grayscale(0.8);
}
& .content .grid_div .first-col {
    width: 50%;
}
& .content .grid_div .second-col {
    width: 50%
}
& .content .grid_div .container_loading{
    position: absolute;
    top: 0;
    z-index: 10;
    right: 0;
    left:0;
    bottom: 0;
    background: rgba(255, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
}
& .content .grid_div .one-col{
    width: 100%
}
& .content .grid_div .container_col{
    display: flex;
    background: #7c7575
}
& .content .grid_div .container_loading .loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #d80621;
    width: 50px;
    height: 50px;
    animation: ${spin} 2s linear infinite
    
  }
`
function ChatInterface ({id,message,userOther,returned_new_id,messageDir="",url=""}){
    const { enqueueSnackbar } = useSnackbar();
    const [conversation, setConversation] = useState([])
    const [isLoading_chat, setIsLoading_chat] = useState(true)
    const [isLoading_files, setIsLoading_files] = useState(false)
    const [newConversation_id,setNewConversation_id] = useState("")
    const [value,setValue] = useState("")
    const scrollControll = useRef(null)
    const divScroll = useRef(null)
    const hiddenFileInput = useRef(null);
    const hiddenVideoInput = useRef(null)
    const [viewPicker,setViewPicker] = useState(false)
    const [menuAttatchment, setMenuAttatchment] = useState(false)
    const [files,setFiles] = useState([])
    const [videos,setVideos] = useState([])
    const page = useRef(0)
    const [pageHelp, setPageHelp] = useState(0)
    const [totalPages, setTotalPages] = useState(1)
    const [loadingOldMessages,setLoadingOldMessages] = useState(false)
    const [isLoadingSend, setIsLoadingSend] = useState(false)
    const observerTarget  = useRef(null)
    const [imageClicked, setImagesClicked] = useState("")
    const [imagesBox, setImagesBox] = useState([])
    const [viewBox, setViewBox] = useState(false)
    const [msg,setMsg] = useState("")
    const emoji_div = useRef(null)
    const upload_div = useRef(null)
    const messageDirection = useRef("")
    const urlDirection = useRef("")
    useEffect(()=>{
        // to Close menu when click outside
            function handleClickOutside(event) {
                if (upload_div.current && !upload_div.current.contains(event.target)) {
                setMenuAttatchment(false)
                }
                if (emoji_div.current && !emoji_div.current.contains(event.target)) {
                    setViewPicker(false)
                    }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            };
    
        })
 
    const increase_page = ()=>{
        page.current = page.current+1
        setPageHelp(prev=>prev+1)
    }
    useEffect(() => {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            increase_page()
          }
        },
        { threshold: 1 }
      );
    
      if (observerTarget.current) {
        observer.observe(observerTarget.current);
      }
    
      return () => {
        if (observerTarget.current) {
          observer.unobserve(observerTarget.current);
        }
      };
    }, [observerTarget.current]);
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        setConversation([])
        if(id != ""){
            page.current = 0
            setIsLoading_chat(true)
            setConversation([])
            get_conversation(signal)

            scrollControll.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight });
            });
        }
        else{
            setIsLoading_chat(false)
        }
        return ()=>{
            controller.abort()
        }    
    },[id])
    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        if(id != "" && page.current > 1){
            setLoadingOldMessages(true)
            get_conversation(signal)
            
            scrollControll.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight%2});
            });
            
        }
        return ()=>{
            controller.abort()
        }    
    },[pageHelp])
    // proccess for message from channel 
    useEffect(()=>{
        console.log(message);
        const controller = new AbortController()
        const signal = controller.signal
        if("message" in message){
            if(message.message.receiver == JSON.parse(localStorage.getItem("user")).user_id ){
                if(message.message.attachment){
                    get_attatchment(message.message.message_id)
                }else if(message.message.message.length>0){
                    if(userOther.conversation_id == message.conversation_id){
                        setConversation(prev=>[...prev,{
                            sender:userOther.id,
                            sender_username:userOther.username,
                            sender_photo:userOther.image,
                            receiver: JSON.parse(localStorage.getItem("user")).user_id,
                            receiver_username:JSON.parse(localStorage.getItem("user")).username,
                            receiver_photo:JSON.parse(localStorage.getItem("user")).photo,
                            conversation_id: message.message.conversation_id,
                            message: message.message.message,
                            photos:[],
                            videos:[]
        
                        }])
                    }
                    
                }
            }
            scrollControll.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
           
        }
        return ()=>{
            controller.abort()
        }  

    },[message])
    // proccess for message_dirction from vendor 


    const get_conversation = async (signal)=>{
      
        if((page.current <= totalPages && page.current >1)||(page.current == 1 || page.current == 0)){
            setMsg("")
            const {response, message} = await Helper.Get({
                url:apiRoutes.messages.getByConversation(id),
                hasToken:true,
                data: page.current == 0 ?{page:1,results:20}:{page:page.current,results:20}
            })
            if(response){
                if(page.current == 1 || page.current == 0){
                    setConversation(response.data.reverse())
                    setTotalPages(response.meta.last_page)
                }else{
                        let array = []
                        response.data.forEach(e=>{
                            array.unshift(e)
                        })
                            setConversation(prev=>[...array,...prev])
                        
                    
                        setIsLoading_chat(false)
                        setLoadingOldMessages(false)
                        scrollControll.current.addEventListener('DOMNodeInserted', event => {
                            const { currentTarget: target } = event;
                            target.scroll({ top: 200, behavior: 'smooth' });
                            // target.scrollTop = 100
                        });
                 
                    
                }
                setLoadingOldMessages(false) 
                 setIsLoading_chat(false)
            }
        }else{
            setMsg("No old messages.")
        }
        

  
    }
    const get_attatchment = async(id)=>{
        const {response , message} = await Helper.Get({
            url:apiRoutes.messages.message_getOne(id),
            hasToken:true
        })
        if(response){
            setConversation(prev=>[...prev,{
                sender:userOther.id,
                sender_username:userOther.username,
                sender_photo:userOther.image,
                receiver: JSON.parse(localStorage.getItem("user")).user_id,
                receiver_username:JSON.parse(localStorage.getItem("user")).username,
                receiver_photo:JSON.parse(localStorage.getItem("user")).photo,
                conversation_id: response.data.conversation_id,
                message: response.data.message,
                photos:response.data.photos,
                videos:response.data.videos,
            }])

        }
    }
    function handleChangeFile(e) {
        setIsLoading_files(true)
        Object.keys(e.target.files).map((key,i)=>{
            setFiles(prev=>[...prev,{
                file:e.target.files[i],
                url:URL.createObjectURL(e.target.files[i]),
                type:"image"
            }])
        })
    }
    function handleChangeFileVideo(e) {
        console.log("videos",e);
        setIsLoading_files(true)
        Object.keys(e.target.files).map((key,i)=>{
            setVideos(prev=>[...prev,{
                file:e.target.files[i],
                url:URL.createObjectURL(e.target.files[i]),
                type:"video"
            }])
        })
    }
    const onEmojiClick = (event, emojiObject) => {
        setValue((prevInput) => prevInput + event.emoji);

      };
    const handleChange =(e)=>{
        setValue(e) 
    }
    const handleSend = async()=>{
        if( videos.length>0 ||files.length>0 || value.length>0 || messageDirection.current != "" ){
            scrollControll.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
            setConversation(prev=>[...prev,{
                id:(videos.length>0 ||files.length>0) ? "loading":"",
                receiver:userOther.id,
                receiver_username:userOther.username,
                receiver_photo:userOther.image,
                sender: JSON.parse(localStorage.getItem("user")).user_id,
                sender_username:JSON.parse(localStorage.getItem("user")).username,
                sender_photo:JSON.parse(localStorage.getItem("user")).photo,
                conversation_id: userOther.conversation_id,
                message:(messageDirection.current != "" && urlDirection.current != "")?`<div>${messageDirection.current}<div><a href="${urlDirection.current}">${urlDirection.current}</a></div></div>`:value,
                photos:files,
                videos:videos, 
            }])
            setValue("")   
            setFiles([])
            setVideos([])
          
            let data = new FormData()
            data.append("sender",JSON.parse(localStorage.getItem("user")).user_id)
            if(messageDirection.current != ""){
                data.append("receiver",JSON.parse(localStorage.getItem("userOther")).id)
            }else{
                data.append("receiver",userOther.id)
            }
            
            data.append("message",(messageDirection.current != "" && urlDirection.current != "")?`<div>${messageDirection.current}<div><a  href="${urlDirection.current}">${urlDirection.current}</a></div></div>`:value)
            if(files.length>0){
                files.forEach((e,i)=>{
                    data.append(`photos[]`,e.file)
                })
            }
            if(videos.length>0){
                videos.forEach((e,i)=>{
                    data.append(`videos[]`,e.file)
                })
            }
            data.append("_method","PUT")
            const {response, message,status} = await Helper.Post({
                url :apiRoutes.messages.sendMessage,
                data:data,
                hasToken:true
            })
            if(response){
                if(localStorage.hasOwnProperty("userOther")){
                    returned_new_id(response.data.conversation_id)
                    localStorage.removeItem("userOther")
                }
                if(response.data.photos.length> 0 ){
                    const array = conversation
                    // array.pop()
                    setConversation(prev=>[...array,{
                        receiver:userOther.id,
                        receiver_username:userOther.username,
                        receiver_photo:userOther.image,
                        sender: JSON.parse(localStorage.getItem("user")).user_id,
                        sender_username:JSON.parse(localStorage.getItem("user")).username,
                        sender_photo:JSON.parse(localStorage.getItem("user")).photo,
                       conversation_id: userOther.conversation_id,
                       message: response.data.message,
                       videos:response.data.videos,
                       photos:response.data.photos
            
                    }])
                    setIsLoading_files(false)
                }
                if(response.data.videos.length> 0 ){
                    const array = conversation
                    // array.pop()
                    setConversation(prev=>[...array,{
                       receiver:userOther.id,
                       receiver_username:userOther.username,
                       receiver_photo:userOther.image,
                       sender: JSON.parse(localStorage.getItem("user")).id,
                       sender_username:JSON.parse(localStorage.getItem("user")).username,
                       sender_photo:JSON.parse(localStorage.getItem("user")).photo,
                       sender:JSON.parse(localStorage.getItem("user")).id,
                       conversation_id: userOther.conversation_id,
                       message: response.data.message,
                       videos:response.data.videos,
                       photos:response.data.photos
            
                    }])
                    setIsLoading_files(false)
                } 
                setIsLoadingSend(false)  
                messageDirection.current = ""
                urlDirection.current = ""
            }else{
                
                if(status == "500"){
                    const array = conversation
                    array.pop()
                    setConversation(array)
                }else{
                    const array = conversation
                    setConversation(array)
                }
           
                setIsLoadingSend(false)  
                setIsLoading_files(false)
                enqueueSnackbar("Failed send message, try again please.",{variant:"error",anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                  }}) 
            }
        }else{
            enqueueSnackbar("You can't send an empty message.",{variant:"error",anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
              }}) 
        }
    }
  
    const handleOpenLightBox = (photos,id)=>{
        setViewBox(true)

        setImagesBox(photos)
    }
    return<>
    {viewBox&&
    <Box id="gallery_overlay" sx={{position:"fixed",top:0,left:0,right:0,bottom:0
    ,display:"flex",justifyContent:"center",alignItems:"center",background:"rgb(22 22 22 / 87%)",zIndex:"2000"}}>
        <IconButton onClick={()=>{setViewBox(false)}}>
            <Close sx={{color:"white",position:"fixed",top:"10px",right:"10px",zIndex:"2500"}}/>
        </IconButton>
        <Swiper_Images_With_Videos array={imagesBox}/>
         </Box>
        }
        <Box ref={scrollControll} className="scroll-div" style={{height:window.innerHeight-250}}>
        <Box ref={divScroll} sx={{padding:"0 5px"}}>
            {loadingOldMessages && msg !="No old messages." && [1,2].map((e,index)=>{
                 if(index%2 ==0){
                     return<Box key={Math.random()*1485}>
                     <Box sx={{display:"flex",justifyContent:"end",gap:"10px",alignItems:"center"}}>
             
                         <Skeleton sx={{width:"20%"}}  animation="wave" />
                         <Skeleton variant="circular" width={40} height={40}  animation="wave" />
                     </Box>
                     <Box sx={{
                         display:"flex",justifyContent:"end",
                         padding:"5px 53px 0 0 ",
                         width:'100%',
                         "& p":{
                             border:"1px solid inherit",
                             borderRadius:" 16px 0px 16px 16px",
                             background:"rgba(216, 6, 33, 1)",
                             color:"black",
                             marginTop:"-13px",
                             width:"50%",
                             color:"white",
                             padding:"20px"
                         }
                     }}>
                         <Box sx={{width:"50%"}} >
                             <Skeleton sx={{width:"100%"}} animation="wave" />
                         </Box>
                     </Box>
                 </Box>
                 }else{
                     return<Box key={Math.random()*1385} sx={{width:"100%"}} >
                     <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
                         <Skeleton variant="circular" width={40} height={40}  animation="wave" />
                         <Skeleton sx={{width:"20%"}} animation="wave" />
                     </Box>
                     <Box sx={{
                         padding:"5px 0 0 53px ",
                         "& p":{
                             border:"1px solid grey",
                             borderRadius:"0px 16px 16px 16px",
                             background:"white",
                             color:"black",
                             marginTop:"-13px",
                             width:"50%",
                             padding:"20px"
                         }
                     }}>
                         <Box sx={{width:"100%"}} >
                             <Skeleton sx={{width:"50%"}} animation="wave" />
                         </Box>
                     </Box>
                 </Box>

                 }
             })}

          <div ref={observerTarget}  style={{height:"50px",display:"flex",justifyContent:"center",alignItems:"center"}} >
                {msg}</div>
             
             {/* <div ref={observerTarget}></div> */}
         {/* other  */}
         {
             isLoading_chat?[1,2,3,4].map((e,index)=>{
                 
                if(index%2 ==0){
                    return<Box key={Math.random()*1241}>
                    <Box sx={{display:"flex",justifyContent:"end",gap:"10px",alignItems:"center"}}>
            
                        <Skeleton sx={{width:"20%"}}  animation="wave" />
                        <Skeleton variant="circular" width={40} height={40}  animation="wave" />
                    </Box>
                    <Box sx={{
                        display:"flex",justifyContent:"end",
                        padding:"5px 53px 0 0 ",
                        width:'100%',
                        "& p":{
                            border:"1px solid inherit",
                            borderRadius:" 16px 0px 16px 16px",
                            background:"rgba(216, 6, 33, 1)",
                            color:"black",
                            marginTop:"-13px",
                            width:"50%",
                            color:"white",
                            padding:"20px"
                        }
                    }}>
                        <Box sx={{width:"50%"}} >
                            <Skeleton sx={{width:"100%"}} animation="wave" />
                        </Box>
                    </Box>
                </Box>
                }else{
                    return<Box key={Math.random()*1741} sx={{width:"100%"}} >
                    <Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
                        <Skeleton variant="circular" width={40} height={40}  animation="wave" />
                        <Skeleton sx={{width:"20%"}} animation="wave" />
                    </Box>
                    <Box sx={{
                        padding:"5px 0 0 53px ",
                        "& p":{
                            border:"1px solid grey",
                            borderRadius:"0px 16px 16px 16px",
                            background:"white",
                            color:"black",
                            marginTop:"-13px",
                            width:"50%",
                            padding:"20px"
                        }
                    }}>
                        <Box sx={{width:"100%"}} >
                            <Skeleton sx={{width:"50%"}} animation="wave" />
                        </Box>
                    </Box>
                </Box>

                }

                 
             }):<>
             {conversation.length==0 ?<Box>
         <Continer>
         <Box sx={{display:"flex",justifyContent:"center"}}>
            

         </Box>
         <Box>
            <img  style={{width:"100%",height:"300px",objectFit:"contain"}} src={noMessage} alt="no message" />
             <h2>No messages  yet</h2>
             
         </Box>
         </Continer>
                 </Box>:conversation.map((e,index)=>{
                        const array = []
                        if(e.photos.length>0)
                            e.photos.forEach((photo)=>{array.push({src:photo.url,type:"image"})})
                        if(e.videos.length>0)
                            e.videos.forEach((video)=>{array.push({src:video.url,type:"video"})})
                        if(e.sender == JSON.parse(localStorage.getItem("user")).user_id &&( e.message !="" || array.length !=0 )){
                            return <Box>
                                {(index>1 && conversation[index-1].sender == e.sender ) ? "":<Box sx={{display:"flex",justifyContent:"end",gap:"10px",alignItems:"center",
                                        "& h6":{
                                            fontSize: "15px !important"
                                        }}}>
                                        <span style={{textTransform:"uppercase",fontSize:"12px"}}>{dateFormat(e.created_at,"hh:mm TT")}</span>
                                        <h6 >{e.sender_username?e.sender_username:""}</h6>
                                        <Avatar alt={e.sender_username?e.sender_username:""} sx={{width:45,height:45}} src={e.sender_photo?e.sender_photo:''}/>
                                </Box>}   
                            <BoxCustom sx={{
                                display:"flex",
                                justifyContent:"end",
                                padding:"5px 53px 0 0 ",
                                "& .content":{
                                    border:"1px solid inherit",
                                    borderRadius:" 16px 0px 16px 16px",
                                    background:"rgba(216, 6, 33, 1)",
                                    // backgroundColor:"rgb(223 223 223)",
                                    wordWrap: "break-word",
                                    color:"white",
                                    marginTop:"-13px",
                                    maxWidth:"70%",
                                    width:"fit-content",
                                    padding:"10px",
                                    marginBottom:"10px"
                                },
                                "& .content img":{
                                    cursor:"pointer"
                                },
                                "& .content video":{
                                    cursor:"pointer"
                                },
                                "& .content a":{
                                    textDecoration: "underline !important",
                                    color:" #cfc7c7 !important"
                                },
                                
               
                            }}>
                                <div className='content'>
                                    
                                    {e.message != "" && <div style={{textWrap:"br"}} dangerouslySetInnerHTML={{__html:e.message}}></div>}
                                    {array.length>0 && array.length > 4 &&  <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                               {array[0].type == "image"?<img alt={`img_${Math.random()*1230}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6}  xs={6}>
                                           <Box  onClick={()=>{handleOpenLightBox(array,1)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[0].type == "image"?<img alt={`img_${Math.random()*1200}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[1].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[1].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6}  xs={6}>
                                           <Box  onClick={()=>{handleOpenLightBox(array,2)}} sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                           {array[2].type == "image"?<img alt={`img_${Math.random()*1030}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[2].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[2].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid   sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           
                                           <Box onClick={()=>{handleOpenLightBox(array,3)}}  sx={{background: "white",borderRadius: "16px",overflow: "hidden",height:"200px",width:"100%",position:"relative"}}>
                                               <Box sx={{display:"flex",justifyContent:"center",cursor:"pointer",alignItems:"center" ,position:"absolute",left:0,right:0,bottom:0,top:0,zIndex:10,background:"rgb(33 32 32 / 30%)"}}>
                                               <span style={{color:"white",fontSize:"24px"}}>+{array.length-4}</span>
                                               </Box>
                                               {array[3].type == "image"?<img alt={`img_${Math.random()*1300}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[3].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[3].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    {array.length>0 && array.length == 4 && <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}   sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                           {array[0].type == "image"?<img alt={`img_${Math.random()*2230}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,1)}} sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[1].type == "image"?<img alt={`img_${Math.random()*4530}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[1].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[1].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,2)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                                {array[2].type == "image"?<img alt={`img_${Math.random()*1730}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[2].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[2].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,3)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[3].type == "image"?<img alt={`img_${Math.random()*10230}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[3].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[3].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    {array.length>0 && array.length == 3 && <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                           {array[0].type == "image"?<img alt={`img_${Math.random()*330}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,1)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                                {array[1].type == "image"?<img alt={`img_${Math.random()*2130}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[1].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[1].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,2)}}   sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                           {array[2].type == "image"?<img alt={`img_${Math.random()*5030}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[2].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    { array.length>0 && array.length == 2 &&  <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[0].type == "image"?<img alt={`img_${Math.random()*1237}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,1)}}   sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                                {array[1].type == "image"?<img alt={`img_${Math.random()*1210}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[1].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[1].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    {array.length>0 && array.length == 1 &&  <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={12} md={12}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[0].type == "image"?<img alt={`img_${Math.random()*1470}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    {e.id =="loading"  && <Box>
                                    <LinearProgress sx={{borderRadius:"8px"}} color="secondary"/>
                                        
                                    </Box>}
                                    <Box sx={{display:"flex",justifyContent:"end"}}>
                                        <span style={{textTransform:"uppercase",fontSize:"10px",color:"#c2b5b5"}}>{dateFormat(e.created_at,"hh:mm TT")}</span>
                                    </Box>
                                </div>
                                    
                                 
                                 
                                 
                                 
                                    
                                 
                                
                            </BoxCustom>
                        </Box>
                        }else if(e.sender != JSON.parse(localStorage.getItem("user")).user_id &&( e.message !="" || array.length !=0 )){
                            return<Box >
                                {(index>1 && conversation[index-1].receiver == e.receiver )?"":<Box sx={{display:"flex",gap:"10px",alignItems:"center"}}>
                                <Avatar alt={e.sender_username?e.sender_username:""} sx={{width:45,height:45,background:"white"}} src={e.sender_photo?e.sender_photo:""}/>
                                <h6 style={{fontSize: "15px"}}>{e.sender_username?e.sender_username:""}</h6>
                                <span style={{textTransform:"uppercase",fontSize:"12px"}}>{dateFormat(e.created_at,"hh:mm TT")}</span>
                            </Box> }
                            
                            <BoxCustom sx={{
                                padding:"5px 0 0 53px ",
                                display:"flex",
                                "& .content":{
                                    borderRadius:"0px 16px 16px 16px",
                                    background:"white",
                                    color:"black",
                                    // display:"flex",
                                    wordWrap: "break-word",
                                    marginTop:"-13px",
                                    maxWidth:"70%",
                                    width:"fit-content",
                                    padding:"10px",
                                    marginBottom:"10px"
                                },
                            
                                "& .content img":{
                                    cursor:"pointer"
                                },
                                "& .content video":{
                                    cursor:"pointer"
                                },
                                "& .content a":{
                                    textDecoration: "underline !important",
                                    color:" #d80621 !important"
                                },
                            }}>
                               <div className='content'>
                                {e.message != "" && <div style={{textWrap:"br"}} dangerouslySetInnerHTML={{__html:e.message}}></div>}
                                    {array.length>0 && array.length > 4 &&  <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                               {array[0].type == "image"?<img alt={`img_${Math.random()*1230}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6}  xs={6}>
                                           <Box  onClick={()=>{handleOpenLightBox(array,1)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[0].type == "image"?<img alt={`img_${Math.random()*1200}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[1].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[1].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6}  xs={6}>
                                           <Box  onClick={()=>{handleOpenLightBox(array,2)}} sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                           {array[2].type == "image"?<img alt={`img_${Math.random()*1030}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[2].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[2].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid   sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           
                                           <Box onClick={()=>{handleOpenLightBox(array,3)}}  sx={{background: "white",borderRadius: "16px",overflow: "hidden",height:"200px",width:"100%",position:"relative"}}>
                                               <Box sx={{display:"flex",justifyContent:"center",cursor:"pointer",alignItems:"center" ,position:"absolute",left:0,right:0,bottom:0,top:0,zIndex:10,background:"rgb(33 32 32 / 30%)"}}>
                                               <span style={{color:"white",fontSize:"24px"}}>+{array.length-4}</span>
                                               </Box>
                                               {array[3].type == "image"?<img alt={`img_${Math.random()*1300}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[3].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[3].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    {array.length>0 && array.length == 4 && <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}   sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                           {array[0].type == "image"?<img alt={`img_${Math.random()*2230}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,1)}} sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[1].type == "image"?<img alt={`img_${Math.random()*4530}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[1].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[1].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,2)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                                {array[2].type == "image"?<img alt={`img_${Math.random()*1730}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[2].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[2].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,3)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[3].type == "image"?<img alt={`img_${Math.random()*10230}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[3].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[3].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    {array.length>0 && array.length == 3 && <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                           {array[0].type == "image"?<img alt={`img_${Math.random()*330}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,1)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                                {array[1].type == "image"?<img alt={`img_${Math.random()*2130}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[1].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[1].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,2)}}   sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                           {array[2].type == "image"?<img alt={`img_${Math.random()*5030}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[2].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    { array.length>0 && array.length == 2 &&  <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[0].type == "image"?<img alt={`img_${Math.random()*1237}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={6} md={6} sm={6} xs={6}>
                                           <Box onClick={()=>{handleOpenLightBox(array,1)}}   sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                                {array[1].type == "image"?<img alt={`img_${Math.random()*1210}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[1].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[1].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    {array.length>0 && array.length == 1 &&  <Grid  container >
                                       <Grid sx={{padding:"3px",display:"flex",justifyContent:"center",alignItems:'center'}} lg={12} md={12}>
                                           <Box onClick={()=>{handleOpenLightBox(array,0)}}  sx={{background: "white",width:"100%",borderRadius: "16px",overflow: "hidden",height:"200px"}}>
                                            {array[0].type == "image"?<img alt={`img_${Math.random()*1470}`} style={{width:"100%",height:"100%",objectFit:"contain"}} src={array[0].src} />
                                                :<video style={{width:"100%",height:"100%",objectFit:"contain"}}  width="320" height="240" controls>
                                                <source src={array[0].src} />
                                            </video>}
                                           </Box>
                                       </Grid>
                                    </Grid>}
                                    <Box sx={{display:"flex",justifyContent:"start"}}>
                                        <span style={{textTransform:"uppercase",fontSize:"10px",color:"grey"}}>{dateFormat(e.created_at,"hh:mm TT")}</span>
                                    </Box>
                                </div>
                             
                            </BoxCustom>
                        </Box>
                        }
                    
                 
             })}
             </>
         }
   
     </Box>
     
    </Box>
    
    {userOther.id && <Box sx={{width:"100%",display:"flex",justifyContent:"space-between",background:"white",padding:"10px"}}>
    <Box sx={{display:"flex",height:"100%",width:"70px"}}>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}} >
            <IconButton onClick={()=>{
                setMenuAttatchment(false)
                setViewPicker(!viewPicker)}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40 40" fill="none">
                <g clip-path="url(#clip0_1874_30692)">
                <path d="M20 37.5C15.3587 37.5 10.9075 35.6563 7.62563 32.3744C4.34374 29.0925 2.5 24.6413 2.5 20C2.5 15.3587 4.34374 10.9075 7.62563 7.62563C10.9075 4.34374 15.3587 2.5 20 2.5C24.6413 2.5 29.0925 4.34374 32.3744 7.62563C35.6563 10.9075 37.5 15.3587 37.5 20C37.5 24.6413 35.6563 29.0925 32.3744 32.3744C29.0925 35.6563 24.6413 37.5 20 37.5ZM20 40C25.3043 40 30.3914 37.8929 34.1421 34.1421C37.8929 30.3914 40 25.3043 40 20C40 14.6957 37.8929 9.60859 34.1421 5.85786C30.3914 2.10714 25.3043 0 20 0C14.6957 0 9.60859 2.10714 5.85786 5.85786C2.10714 9.60859 0 14.6957 0 20C0 25.3043 2.10714 30.3914 5.85786 34.1421C9.60859 37.8929 14.6957 40 20 40Z" fill="#606060"/>
                <path d="M10.7129 23.9175C11 23.7517 11.3411 23.7068 11.6614 23.7926C11.9816 23.8784 12.2546 24.0879 12.4204 24.375C13.1885 25.7055 14.2935 26.8103 15.6241 27.5783C16.9547 28.3463 18.464 28.7504 20.0004 28.75C21.5367 28.7504 23.046 28.3463 24.3766 27.5783C25.7073 26.8103 26.8122 25.7055 27.5804 24.375C27.6618 24.2317 27.7709 24.1059 27.9011 24.0049C28.0314 23.9039 28.1804 23.8297 28.3395 23.7865C28.4986 23.7433 28.6646 23.732 28.8281 23.7532C28.9916 23.7745 29.1493 23.8279 29.292 23.9103C29.4348 23.9927 29.5598 24.1026 29.66 24.2335C29.7601 24.3645 29.8333 24.5139 29.8755 24.6733C29.9176 24.8327 29.9278 24.9988 29.9055 25.1622C29.8831 25.3255 29.8287 25.4828 29.7454 25.625C28.7578 27.3355 27.3373 28.7559 25.6266 29.7433C23.916 30.7308 21.9755 31.2504 20.0004 31.25C18.0252 31.2504 16.0847 30.7308 14.3741 29.7433C12.6635 28.7559 11.2429 27.3355 10.2554 25.625C10.0896 25.3379 10.0447 24.9967 10.1305 24.6765C10.2163 24.3563 10.4258 24.0833 10.7129 23.9175ZM17.5004 16.25C17.5004 18.32 16.3804 20 15.0004 20C13.6204 20 12.5004 18.32 12.5004 16.25C12.5004 14.18 13.6204 12.5 15.0004 12.5C16.3804 12.5 17.5004 14.18 17.5004 16.25ZM27.5004 16.25C27.5004 18.32 26.3804 20 25.0004 20C23.6204 20 22.5004 18.32 22.5004 16.25C22.5004 14.18 23.6204 12.5 25.0004 12.5C26.3804 12.5 27.5004 14.18 27.5004 16.25Z" fill="#606060"/>
                </g>
                <defs>
                <clipPath id="clip0_1874_30692">
                <rect width="40" height="40" fill="white"/>
                </clipPath>
                </defs>
            </svg>
            </IconButton>
            <Box ref={emoji_div} sx={{position:"absolute",left:"0",bottom:"50px",zIndex:"100"}}>
                {viewPicker && (
                    <Picker pickerStyle={{ width: "100%" }} onEmojiClick={(e)=>{
                        onEmojiClick(e)}} />
                )}
            </Box>
        </Box>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",position:"relative"}} >
            <IconButton onClick={()=>{
                setMenuAttatchment(!menuAttatchment)
                setViewPicker(false)
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="28" viewBox="0 0 27 41" fill="none">
                    <path d="M2.25 12.8312V28C2.25 30.9837 3.43526 33.8452 5.54505 35.9549C7.65483 38.0647 10.5163 39.25 13.5 39.25C16.4837 39.25 19.3452 38.0647 21.455 35.9549C23.5647 33.8452 24.75 30.9837 24.75 28V9.25C24.75 7.26088 23.9598 5.35322 22.5533 3.9467C21.1468 2.54018 19.2391 1.75 17.25 1.75C15.2609 1.75 13.3532 2.54018 11.9467 3.9467C10.5402 5.35322 9.75 7.26088 9.75 9.25V26.4662C9.75 26.9587 9.847 27.4463 10.0355 27.9013C10.2239 28.3563 10.5001 28.7697 10.8484 29.1179C11.1966 29.4661 11.61 29.7423 12.0649 29.9308C12.5199 30.1192 13.0075 30.2162 13.5 30.2162C14.4946 30.2162 15.4484 29.8212 16.1516 29.1179C16.8549 28.4146 17.25 27.4608 17.25 26.4662V13" stroke="#606060" stroke-width="2.6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </IconButton>
            <input accept="image/*" ref={hiddenFileInput}  id="select" multiple  type="file" onChange={handleChangeFile} hidden />
            <input accept="video/*" ref={hiddenVideoInput}  id="select" multiple  type="file" onChange={handleChangeFileVideo} hidden />
            {menuAttatchment && <Box ref={upload_div} sx={{position:"absolute",left:"0",bottom:"50px",zIndex:"100"}}>
                <List
                    sx={{ width: '100%', maxWidth: 460, bgcolor: 'background.paper' }}
                    aria-label="contacts"
                    >
                    <ListItem disablePadding>
                        <ListItemButton onClick={()=>{
                            hiddenFileInput.current.click()
                            setMenuAttatchment(false)}}>
                        <ListItemIcon>
                            <AddAPhotoIcon />
                        </ListItemIcon>
                        <ListItemText primary="Photos" />
                        </ListItemButton>
                     
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={()=>{
                            hiddenVideoInput.current.click()
                            setMenuAttatchment(false)}}>
                        <ListItemIcon>
                            <VideoCameraBackIcon />
                        </ListItemIcon>
                        <ListItemText  primary="Videos" />
                        </ListItemButton>
                        
                    </ListItem>
                </List>
            </Box>}
        </Box>
    </Box>
    <Box sx={{flex:"1",height:"100%",justifyContent:"center",alignItems:"center"}}>

        <Input  onKeyDown={(e)=>{
            if(e.key=="Enter"){handleSend()}}} onChange={(e)=>{handleChange(e.target.value)}} value={value} placeholder='type a message' />
          
            {files.length>0 && <Grid  sx={{paddingTop:"5px"}} container>
            {files.map((e,index)=>{
                return <Grid key={e.file.name} sx={{position:"relative",padding:"5px 20px",borderRadius:"20px",background:"rgba(19, 51, 107, 0.05)",overflow:"hidden"}} lg={3} md={3} sm={2} xs={2}>
                        {e.file.name.substring(0,10)}
                        <Box onClick={()=>{
                            let temp = files
                            const new_values = temp.filter((ele)=>( ele.file.name != e.file.name))
        
                            setFiles(new_values)
                        }} sx={{position:"absolute",right:"5px",display:"flex",
                        justifyContent:"center",alignItems:"center",top:"50%",transform:"translateY(-50%)",
                        "&:hover":{
                            cursor:"pointer"
                        }}}>
                            <Close  sx={{color:"black",fontSize:"12px"}} />
                        </Box>
                    </Grid>
            })}
            </Grid>}
        {videos.length>0 && <Box sx={{display:"flex",gap:"10px",paddingTop:"5px"}}>
            {videos.map((e,index)=>{
            return <Box key={e.file.name} sx={{position:"relative",padding:"5px 20px",borderRadius:"20px",background:"rgba(19, 51, 107, 0.05)",}}>
                    {e.file.name.substring(0,10)}
                    <Box onClick={()=>{
                        let temp = videos
                        const new_values = temp.filter((ele)=>( ele.file.name != e.file.name))
    
                        setVideos(new_values)
                    }} sx={{position:"absolute",right:"5px",display:"flex",
                    justifyContent:"center",alignItems:"center",top:"50%",transform:"translateY(-50%)",
                    "&:hover":{
                        cursor:"pointer"
                    }}}>
                        <Close  sx={{color:"black",fontSize:"12px"}} />
                    </Box>
                </Box>
        })}
            </Box>}
    </Box>
    <Box sx={{display:"flex",justifyContent:"end",alignItems:"start"}}>
        <IconButton onClick={handleSend}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 43 37" fill="none">
          <path d="M40.0976 0.243284L2.00783 15.3131C-0.591634 16.3843 -0.576595 17.8722 1.53091 18.5356L11.3101 21.6655L33.9362 7.01887C35.006 6.35101 35.9835 6.71029 35.1801 7.44207L16.8484 24.4163H16.8441L16.8484 24.4185L16.1739 34.7604C17.1621 34.7604 17.5982 34.2954 18.1525 33.7465L22.9024 29.0076L32.7825 36.4951C34.6043 37.5244 35.9126 36.9954 36.3659 34.7648L42.8517 3.40405C43.5155 0.673095 41.8356 -0.563439 40.0976 0.243284Z" fill="#606060"/>
        </svg>
        </IconButton>
    </Box>
   

</Box>}
</>

}

const mapStateToprops = (state)=>{
    return {
        message_redux:state.message,
        conversation_id_redux:state.conversation_id,
        notification_redux : state.notification,
        message_redux : state.message
    }
}

export default connect(mapStateToprops,null)(ChatInterface);
