import React, { Fragment, useEffect, useState } from 'react';
import { useParams , useNavigate} from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Breadcrumbs, Image, H6, P,Btn, Spinner } from '../../AbstractElements';
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
import { FaArrowLeft } from 'react-icons/fa';

function Details_Address() {

    const { id } = useParams();
    const [detailsInfo, setDetailsInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate()
    const permissions = JSON.parse(localStorage.getItem('permission'));

    useEffect(() => {
        if (!permissions || !permissions.includes('user_address.get-all')) {
            navigate(`/Forbidden`)
        } else {
        get_Details();
        }
    }, []);

    const get_Details = async () => {
        const { response, message } = await Helper.Get({
            url: api_Routes.address.getOne(id),
            hasToken: true,
        });

        if (response) {
            console.log(response.data);
            setDetailsInfo(response.data);
            setIsLoading(false);
        } else {
            console.log(message);
        }
    }
    const handleGoBack = ()=>{
        navigate(`/Address/:id/order`)
    }
    return (
        <Fragment>
            <Breadcrumbs mainTitle='Details' parent='details' title='Address' />
            {isLoading ?
                <div className="loader-box">
                    <Spinner attrSpinner={{ className: "loader-26" }} />
                </div> :
                <Container fluid={true} className='email-wrap bookmark-wrap todo-wrap'>
                 
                    <Row>
                        <Col xl='12'>
                            <Card>
                                <CardBody>
                                    <div className='email-app-sidebar left-bookmark custom-scrollbar'>
                                        <div className='d-flex align-items-center'>
                                            <div className='flex-grow-1' >
                                                <div className='d-flex align-items-center justify-content-start ' >
                                                    <Image attrImage={{ className: 'me-3 img-50 rounded-circle', style: { marginBottom: '20px' }, src: (detailsInfo?.user.photo) ? (detailsInfo.user.photo) : "", alt: '' }} />
                                                    <H6 attrH6={{ className: 'f-w-600' }}>
                                                        <a 
                                                            href={`/user-list/Shake?id=${detailsInfo.user_id}`}>{`${detailsInfo.user.name} (${detailsInfo.user.username})`}
                                                        </a> 
                                                    </H6>
                                                </div>

                                                <Row>
                           
                                <Col lg={4} sm={6} xs={12} md={4}>
                                    <div className='p-2 d-flex'>
                                        
                                            <p>
                                            <P>City : {detailsInfo.city}</P>
                                            </p>
                                    </div>
                                </Col>
                                <Col lg={4} sm={6} xs={12} md={4}>
                                    <div className='p-2 d-flex'>
                                        
                                            <p>
                                            <P>Floor : {detailsInfo.floor}</P>
                                            </p>
                                    </div>
                                </Col>
                                <Col lg={4} sm={6} xs={12} md={4}>
                                    <div className='p-2 d-flex'>
                                        
                                            <p>
                                            <P>Zipcode : {detailsInfo.zipcode}</P>
                                            </p>
                                    </div>
                                </Col>
                                <Col lg={4} sm={6} xs={12} md={4}>
                                    <div className='p-2 d-flex'>
                                        
                                            <p>
                                            <P>Street : {detailsInfo.street}</P>
                                            </p>
                                    </div>
                                </Col>
                                <Col lg={4} sm={6} xs={12} md={4}>
                                    <div className='p-2 d-flex'>
                                        
                                            <p>
                                            <P>Area : {detailsInfo.area}</P>
                                            </p>
                                    </div>
                                </Col>
                                <Col lg={4} sm={6} xs={12} md={4}>
                                    <div className='p-2 d-flex'>
                                        
                                            <p>
                                            <P>Building : {detailsInfo.building}</P>
                                            </p>
                                    </div>
                                </Col>
                                                                
                        </Row>
                                                
                                                {/* <P>City : {detailsInfo.city}</P>
                                                <P>Floor : {detailsInfo.floor}</P>
                                                <P>Zipcode : {detailsInfo.zipcode}</P>
                                                <P>Building : {detailsInfo.building}</P>
                                                <P>Street : {detailsInfo.street}</P>
                                                <P>Area : {detailsInfo.area}</P> */}
                                            </div>
                                        </div>
                                        {/* <UL attrUL={{ className: 'simple-list nav main-menu' }}>
                                            <LI attrLI={{ className: 'border-0 nav-item pt-4' }}>
                                                <i className={`fa fa-phone`}></i>
                                                <span className='title ms-2'>{detailsInfo.user.phone}</span>
                                            </LI>
                                            <LI attrLI={{ className: 'border-0 nav-item' }}>
                                                <Btn attrBtn={{ color: 'primary', className: 'badge-light justify-content-start text-white d-flex align-items-center btn-mail w-40' }}>
                                                    <CheckCircle className='me-2' />
                                                    View
                                                </Btn>
                                            </LI>
                                        </UL> */}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }

        </Fragment>
    )
}

export default Details_Address;
