import React, { useState, useEffect } from 'react';
import { PickList } from 'primereact/picklist';
import "primereact/resources/themes/tailwind-light/theme.css";
//api request
import { api_Routes } from '../../../api_Routes';
import { Helper } from '../../../tools/helper';
import { toast } from 'react-toastify';
import { Loader } from 'rsuite';
import { useParams } from 'react-router';

export default function Pick_list({returnedItems,data}){
    const {id} = useParams()
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [products,setProducts] = useState([])
    const [name, setName] = useState("")
    const [nameFilter, setNameFilter] = useState("")
    useEffect(() => {
       get_products()
    }, [nameFilter]);
    useEffect(()=>{
        setTarget(data)
    },[])

        const get_products = async ()=>{
        setIsLoading(true)
        setSource([]) 
        const {response,message} = await Helper.Get({
            url:api_Routes.country.view,
            hasToken:true,
            data:nameFilter.length>3?{name:nameFilter,results:1000}:{results:1000}
        })
        if(response){
            console.log(data);
            setSource([])
            let itemsTarget = []
            if(data.length>0){
                target.forEach(ele=>{
                    itemsTarget.push(ele.name)     
                 })
                 console.log(itemsTarget)
                 console.log(source)
            }
            response.data.forEach(ele=>{
                
                if(id!=":id"){
                    console.log("edit");
                    if(!itemsTarget.includes(ele.name)){
                       console.log("in edit")
                        setSource(prev=>[...prev,{
                            id:ele.id,
                            name:ele.name,
                            code:ele.code,
                            phone_code:ele.phone_code
                        }])
                    }
                    
                }else{
                    console.log("no edit");
                    setSource(prev=>[...prev,{
                        id:ele.id,
                        name:ele.name,
                        code:ele.code,
                        phone_code:ele.phone_code
                    }])
                }        
            })
        
            setIsLoading(false)
        }else{
            if(typeof message =="string"){
                toast.error(message)
            }else{
                Object.keys(message).forEach(msg=>{
                    toast.error(`${message[msg]}:${msg}`)
                })
            }
        }
    }
    const onChange = (event) => {
        setSource(event.source);
        setTarget(event.target);
        let array = []
        event.target.forEach(ele=>{
            array.push(ele.id)
        })
        returnedItems(array)
    };

    const itemTemplate = (item) => {
        return (
            <div className="grid grid-cols-2 gap-3 p-2 items-center">
                <div className="flex flex-col gap-2">
                    <span className="font-bold">{item.name}</span>
                </div>
            </div>
           
        );
    };
    const itemTemplateTarget = (item) => {
        return (
            <div className="grid grid-cols-2 gap-3 p-2 items-center">
                <div className="flex flex-col gap-2">
                    <span className="font-bold">{item.name}</span>
                </div>
            </div>
           
        );
    };

    return (
        <div className="card">
            <PickList onSourceFilterChange={(e)=>{
                    if(e.originalEvent.target.value.length>3){
                        setNameFilter(e.originalEvent.target.value)
                    }else{
                        setNameFilter("")
                    }
                }} dataKey="id" source={source} target={target} onChange={onChange} 
                targetItemTemplate={target.length>0?itemTemplateTarget:<>No item yet</>}
                sourceItemTemplate={isLoading?<Loader/>:itemTemplate} breakpoint="1280px"
                sourceHeader="Available" targetHeader="Selected" filter filterBy="name" targetFilterPlaceholder='Search' sourceFilterPlaceholder="Search by name"
                sourceStyle={{ height: '24rem' }} targetStyle={{ height: '24rem' }} />
        </div>
    );

}