import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Breadcrumbs, Btn,Image,Spinner } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
//files
import Files from 'react-files';
//Form Component

import {  Loader} from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
// Editor Wyswigy 
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useParams} from "react-router-dom"

import { Editor } from '@tinymce/tinymce-react';
import { ContentBlock } from 'draft-js';

import {  useNavigate } from 'react-router-dom';

import { FaArrowLeft } from 'react-icons/fa';



const Add_Page = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [categories, setCategories] = useState([])
    const [brands, setBrands] = useState([])
    const [page, setPage] = useState({})
    const [productsImg,setProductsImg] = useState([])
    const [langauges, setLangauges] = useState([])
    const [formData, setFormData]  = useState({
        name:"",
        url:""
    })
    const [dataToEditorState, setDataToEditorState] = useState({});
    const permissions = JSON.parse(localStorage.getItem('permission'));
    const [editors_keys, setEditorsKeys] = useState(['description','short_description','specifications']);

    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        if (id === ":id" && (!permissions || !permissions.includes('page.add'))) {
            navigate(`/Forbidden`);
        } else {
            if(id != ":id"){
                get_details(signal)
            }
        }
        


        if(localStorage.hasOwnProperty("lang")){
            let array = JSON.parse(localStorage.getItem("lang"))
            setLangauges(array)
            if(id==":id"){
                setDataToEditorState(prev=>{
                    array.map((e)=>{
                      prev[e.id]= {
                        description:{id:0,value:EditorState.createEmpty()},
                        name:{id:0,value:""},
                        meta_title:{id:0,value:""},
                        meta_description:{id:0,value:""},
                      }
                      return prev[e.id]
                    })
                    return prev
                  })
            }
           }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        return()=>{
            controller.abort()
        }
    },[])


    const get_details = async (signal)=>{
        setIsLoadingDetailes(true)
        const {response,message} = await Helper.Get_Abort({
            url:api_Routes.page.getOne(id),
            hasToken:true,
            signal:signal,
            data:{detailed:1}
        })
        if(response){
            var details_languages = {}
            setPage(response.data)    
            setFormData({
                name:response.data.name,
                url:response.data.url
            })
            response.data.detailed_translations.forEach(ele=>{
                ele.forEach(dt=>{
                    if (!details_languages[dt.language_id])
                        details_languages[dt.language_id] = {}

                    if(editors_keys.includes(dt.text_type))
                    {
                        details_languages[dt.language_id][dt.text_type] = {
                            value:dt.value ?
                                EditorState.createWithContent(
                                    ContentState.createFromBlockArray(
                                        convertFromHTML(dt.value)
                                    )
                                ) : EditorState.createEmpty(),
                            id:dt.id
                        }

                    }else
                        details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                })
            })
            setFiles(response.data.photo?[{
                preview: {type: 'image', url: response.data.photo}
            }]:"")
            setDataToEditorState(details_languages)

            setIsLoadingDetailes(false)
        }
    }
    const convertFromHTML = (html) => {
        const blocksFromHTML = htmlToDraft(html);
        const contentBlocks = blocksFromHTML.contentBlocks.map(block => {
            if (block.text.trim() === '') {
                // Empty blocks should be converted to null to avoid rendering issues
                return null;
            } else {
                // Preserve inline styles for all elements
                const element = document.createElement('div');
                element.innerHTML = block.text;
                const elementsWithStyles = element.querySelectorAll('*[style]');
                elementsWithStyles.forEach(el => {
                    el.outerHTML = el.outerHTML; // Retain inline styles for all elements
                });
    
                // Create a new ContentBlock with the modified text
                const newBlock = new ContentBlock({
                    key: block.key,
                    type: block.type,
                    text: element.innerHTML,
                    characterList: block.characterList
                });
    
                return newBlock;
            }
        }).filter(block => block !== null);
    
        return contentBlocks;
    }

    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
                if(editors_keys.includes(key))
                    valueTemp = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value)))
                else
                    valueTemp  = value
                
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };
                
        const handleSubmit = async () => {
            let exit = false

            if (!formData.name ) {
        toast.error("Please fill in all required fields (Name).");
        setIsLoading(false);
        return;
    }
            setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''

            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                    if(editors_keys.includes(subKey))
                        valueTemp = draftToHtml(convertToRaw(dataToEditorState[key][subKey].value.getCurrentContent()))
                        else {
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            } else {
                                 exit = true
                            }
                        }
                    if(id === ":id")
                        form_data.append(`languages[${key}][${subKey}]`, valueTemp);
                    else
                        form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
                })
            })
            Object.keys(updatedFormData).forEach((key) => {
                if (key === "file")
                    form_data.append("file", updatedFormData.file);
                else
                    form_data.append(key, updatedFormData[key]);
            });

            if (id === ":id") {
                form_data.append("_method", "PUT");
            }
            if(exit){
                toast.error("You should add the name , description  , meta_title , meta_description");
                setIsLoading(false)
                return;
            }

            const { response, message } = await Helper.Post({
                url: id === ":id" ? api_Routes.page.add : api_Routes.page.update(id),
                data: form_data,
                hasToken: true
            });

            if (response) {
                if (id === ":id") {
                    toast.success("Successfully added !!");
                    navigate(`/page-list/Shake`);
                  } else {
                    toast.success("Successfully edited !!");
                    navigate(`/page-list/Shake`);
                  }               
                   setIsLoading(false);
            } else {
                if (typeof message === "string") {
                    toast.error(message);
                } else {
                    Object.keys(message).forEach((i) => {
                        const errorMessage = message[i][0] || "";
                        toast.error(`${i} : ${errorMessage}`);
                    });
                }
                setIsLoading(false);
            }
            };      


    const [files, setFiles] = useState([]);
    
    const handleGoBack = ()=>{
        navigate(`/page-list/Shake`)
    }

    function deleteFile(e) {
        setFiles([]);
        setFormData(prev=>({...prev,["file"]:""}))
        return files

    }
    const onFilesChange = (files) => {
        setFiles(files)
        setFormData(prev=>({...prev,["file"]:files[0]}))
        console.log(files);
    }
    const onFilesError = (error, file) => {
        setFormData(prev=>({...prev,["file"]:""}))
        setFiles(file)
    }

    return (
        <Fragment>
            <Breadcrumbs mainTitle={id==":id"?"Add Page":"Edit Page"} parent="Page" title={id==":id"?"Add":"Edit"}  />
            {
                isLoadingDetiales?<div className="loader-box">
                <Spinner attrSpinner={{ className: "loader-26" }} />
            </div>:<>
            {/* Basic Info */}
            <Container  className='mb-3' fluid={true}>
            {/* <Btn 
            attrBtn={{
                className: 'btn btn-primary justify-content-start text-white d-flex align-items-center   btn-mail w-40',
                style: { padding: '10px 20px', width:"fit-content",position:"fixed" , zIndex:"200" , bottom:"30px", right:"30px"},
                onClick: handleGoBack
            }}
            >
            <FaArrowLeft /> Back
            </Btn> */}
                <Row>
                    <Col sm="12">
                        <Card>
                            <Row>
                                <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                                    <HeaderCard title="basic Info" />
                                </Col>
                           {(permissions.includes('page.edit')) && ( 
                                <Col className='d-flex justify-content-center align-items-center ' lg={2} xl={2}  md={2} sm={2} xs={2}>
                                        <Btn attrBtn={{ className: "h-50", color: 'danger', type: "button", onClick: () => handleSubmit() ,disabled:isLoading}} >
                                            {isLoading?<Loader/>:<i className='fa fa-save'></i>}
                                        </Btn>
                                </Col>
                                )}
                            </Row>
                            
                            <CardBody className="add-post">

                                {/* Basic Info */}
                                <Form className="row needs-validation">
                                    <Row>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                                        <FormGroup>
                                            <Label for="validationCustom01">Name</Label>
                                            <Input value={formData.name} onChange={(e)=>{handleChange("name",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="name" required="" />
                                            
                                        </FormGroup>
                                        </Col>
                                        <Col  xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <FormGroup>
                                            <Label for="validationCustom01">Url</Label>
                                            <Input value={formData.url} onChange={(e)=>{handleChange("url",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="name" required="" />
                                            
                                        </FormGroup>

                                        </Col>
                                    </Row>
                                        
                                    </Form>
                                    
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
            {/* languages */}
            {
                Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang,i)=>(<Container className='mb-3' fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={`${lang.name} information`} />
                            <CardBody className="add-post">

                      
                                <Form className="row needs-validation">
                                        <Col sm="12">
                                        <FormGroup>
                                            <Label for="validationCustom01">Name</Label>
                                            <Input value={id==":id"?dataToEditorState[JSON.stringify(lang.id)].name.value:dataToEditorState[JSON.stringify(lang.id)].name.value} 
                                                    onChange={(e)=>{handleChange("name",e.target.value,lang.id,id==":id"?null:dataToEditorState[JSON.stringify(lang.id)].name.id,0)}} className="form-control" id="validationCustom01" type="text" placeholder={`${lang.name} name`} required="" />
                                            <div className="valid-feedback">{"Looks good!"}</div>
                                        </FormGroup>
                                    

                                        
                                        <div className="email-wrapper">
                                            <div className="theme-form">
                                            <FormGroup>
                                                <Label > Description:</Label>
                                                <div>
                                                <Editor
                                                    apiKey='ime80vsvlhk1vw6zpyla5ls253smuovbrmqu2ucv1bksnhex'
                                                    init={{
                                                        // TinyMCE editor initialization configuration
                                                        plugins: 'fullpage preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help charmap emoticons codesample code',
                                                        toolbar: 'undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  bullist numlist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile template link anchor codesample | ltr rtl | visualchars visualblocks nonbreaking table tableinsertdialog tablecellprops tableprops help codesample code',
                                                        height: 300,
                                                        branding: false,
                                                        setup: editor => {
                                                            editor.on('Change', function (e) {
                                                                handleChange("description", editor.getContent(), lang.id, id === ":id" ? null : dataToEditorState[lang.id].description.id, 1);
                                                            });
                                                        }
                                                    }}
                                                    initialValue={id === ":id" ? '' : draftToHtml(convertToRaw(dataToEditorState[lang.id].description.value.getCurrentContent()))}
                                                />
                                                    {/* <textarea style={{visibility:"hidden"}}
                                                        disabled={true}
                                                        // value={draftToHtml(convertToRaw(dataToEditorState[lang.id]["description"].getCurrentContent()))}
                                                    /> */}
                                                </div>    
                                            </FormGroup>

                                        <FormGroup>
                                            <Label for="validationCustom01">Meta Title</Label>
                                            <Input value={id==":id"?dataToEditorState[JSON.stringify(lang.id)].meta_title.value:dataToEditorState[JSON.stringify(lang.id)].meta_title.value} 
                                                    onChange={(e)=>{handleChange("meta_title",e.target.value,lang.id,id==":id"?null:dataToEditorState[JSON.stringify(lang.id)].meta_title.id,2)}} className="form-control" id="validationCustom01" type="text" placeholder={`${lang.name} meta_title`} required="" />
                                            <div className="valid-feedback">{"Looks good!"}</div>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="validationCustom01">Meta Description </Label>
                                            <textarea value={id==":id"?dataToEditorState[JSON.stringify(lang.id)].meta_description.value:dataToEditorState[JSON.stringify(lang.id)].meta_description.value} 
                                                    onChange={(e)=>{handleChange("meta_description",e.target.value,lang.id,id==":id"?null:dataToEditorState[JSON.stringify(lang.id)].meta_description.id,3)}} className="form-control" id="validationCustom01" type="text" placeholder={`${lang.name} meta_description`} required="" />
                                            <div className="valid-feedback">{"Looks good!"}</div>
                                        </FormGroup>

                                            </div>
                                        </div>
    
                                        </Col>
                                    </Form>
                               
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>))
            }
             {/* upload image */}
            <Container  className='mb-3' fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={`upload image`} />
                            <CardBody className="fileUploader">
                                 <Files
                                    className='files-dropzone fileContainer'
                                    onChange={onFilesChange}
                                    onError={onFilesError}
                                    accepts={['image/*']}
                                    multiple={false}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    {
                                        files.length > 0
                                            ? <div className='files-gallery'>
                                                {files.map((file, index) =>
                                                    <div key={index}>
                                                        <Image attrImage={{ className: 'files-gallery-item', alt: "img", src: `${file.preview.url}` }} />
                                                    </div>
                                                )}
                                            </div>

                                            : <div className="d-flex justify-content-center">
                                                <Btn attrBtn={{ className: "mt-2", type: "button", color: 'primary' }} >Upload Image</Btn>
                                            </div>
                                    }
                                </Files>
                                {files.length > 0 ?
                                    <div className="d-flex justify-content-center">
                                        <Btn attrBtn={{ className: "mt-2", color: 'danger', type: "button", onClick: () => deleteFile(files) }} >
                                            Delete
                                        </Btn></div> : ''}

                        </CardBody>
                           
                        </Card>
                    </Col>
                    
                </Row>
                
            </Container>
            </>
            }
            
            
        </Fragment>
    );
};

export default Add_Page;