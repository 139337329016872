import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Breadcrumbs, Btn,Spinner } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
//files
//Form Component

import { Loader} from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
// Editor Wyswigy 

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useParams} from "react-router-dom"
import {  useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { Switch } from '@mui/material';

const Add_Mail = () =>{
    const {id} = useParams() 
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [langauges, setLangauges] = useState([])
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [editors_keys, setEditorsKeys] = useState(['description','short_description','specifications']);
    const [formData, setFormData]  = useState({
        email:"",
        sent:"1"

    })
    const permissions = JSON.parse(localStorage.getItem('permission'));

    useEffect(()=>{ 
        if (id === ":id" && (!permissions || !permissions.includes('mail_list.add'))) {
            navigate(`/Forbidden`);
        } else {

            if(id != ":id"){
                get_mail()
            }
        }

            if(localStorage.hasOwnProperty("lang")){
        let array = JSON.parse(localStorage.getItem("lang"))
        setLangauges(array)
        if(id==":id"){
            setDataToEditorState(prev=>{
                array.map((e)=>{
                  prev[e.id]= {  
                    name:{id:0,value:""},    
                  }
                  return prev[e.id]
                })
                return prev
              })
        }
       }
        },[])

        const get_mail = async ()=>{
            setIsLoadingDetailes(true)
           const {response,message} = await Helper.Get({
               url:api_Routes.maillist.getOne(id),
               hasToken:true,
               data:{detailed:1}
           })
            if(response){
               console.log(response.data)
               setPage(response.data)    
             
               setFormData({
                   email:response.data.email,
                   sent:response.data.sent,  

               })      
            
   
               setIsLoadingDetailes(false)
           }else{
               if(typeof message =="string"){
                   toast.error(message)
               }else{
                   Object.keys(message).forEach(msg=>{
                       toast.error(`${message[msg]}:${msg}`)
                   })
               }
           }
      }
                   const handleChange = (key, value, id_lang, id, sort) => {
   
               if (id_lang) {
                   let translations = { ...dataToEditorState };
                   translations[id_lang][key] = { id:id, value:value };
                   setDataToEditorState(translations);
               } else {
                   setFormData(prev => ({ ...prev, [key]: value }));
               }
           };

           const handleSubmit = async () => {

            console.log(formData);
            setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };

            Object.keys(updatedFormData).forEach((key) => {

                    form_data.append(key, updatedFormData[key]);
            });

            if (id === ":id") {
                form_data.append("_method", "PUT");
            }

            const { response, message } = await Helper.Post({
                url: id === ":id" ? api_Routes.maillist.add : api_Routes.maillist.update(id),
                data: form_data,
                hasToken: true
            });

            if (response) {
                if (id === ":id") {
                    toast.success("Successfully added !!");
                    navigate(`/MailList/:id/order`);
                  } else {
                    toast.success("Successfully edited !!");
                    navigate(`/MailList/:id/order`);
                  }
                    setIsLoading(false);
            } else {
                if (typeof message === "string") {
                    toast.error(message);
                } else {
                    Object.keys(message).forEach((i) => {
                        const errorMessage = message[i][0] || "";
                        toast.error(`${i} : ${errorMessage}`);
                    });
                }
                setIsLoading(false);
            }
            };  
            const handleGoBack = ()=>{
                navigate(`/MailList/:id/order`)
            }
             return (
        <Fragment>
            <Breadcrumbs mainTitle="Add Page" parent="Page" title="Add" />
            {
                isLoadingDetiales?<div className="loader-box">
                <Spinner attrSpinner={{ className: "loader-26" }} />
            </div>:<>
            {/* Basic Info */}
            <Container  className='mb-3' fluid={true}>
            <Btn 
            attrBtn={{
                className: 'btn btn-primary justify-content-start text-white d-flex align-items-center   btn-mail w-40',
                style: { padding: '10px 20px', width:"fit-content",position:"fixed" , zIndex:"2" , bottom:"30px", right:"30px"},
                onClick: handleGoBack
            }}
            >
            <FaArrowLeft /> Back
            </Btn>
                <Row>
                    <Col sm="12">
                        <Card>
                            <Row>
                                <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                                    <HeaderCard title="basic Info" />
                                </Col>
                                {( permissions.includes('mail_list.edit')) && ( 
                                <Col className='d-flex justify-content-center align-items-center ' lg={2} xl={2}  md={2} sm={2} xs={2}>
                                        <Btn attrBtn={{ className: "h-50", color: 'danger', type: "button", onClick: () => handleSubmit() }} >
                                            {isLoading?<Loader/>:<i className='fa fa-save'></i>}
                                        </Btn>
                                </Col>
                                )}
                                
                            </Row>
                            
                            <CardBody className="add-post">

                                {/* Basic Info */}
                                <Form className="row needs-validation">
                                        <Col sm="12">

                                        <FormGroup>
                                            <Label for="validationCustom01">Email</Label>
                                            <Input value={formData.email} onChange={(e)=>{handleChange("email",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="email" required="" />   
                                        </FormGroup>

                                        <Col xs={12} sm={4} md={4} lg={4} xl={4} >
                                                    <Label for="validationCustom01">is_Sent</Label>
                                                    <div> 
                                                        <Switch checked={formData.sent=="1"?true:false} onChange={(e)=>{handleChange("sent",e.target.checked?"1":"0",null)}}  />
                                                     </div>
                                                </Col> 
        
                                        </Col>
                                    </Form>
                                    
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
          
           
                      </>
            }
            
            
        </Fragment>
    );

}
export default Add_Mail