import React, { Fragment,useEffect,useState } from 'react';
import { Container, Row, Col, Card, CardBody,Media } from 'reactstrap';
import { Breadcrumbs ,Btn, H4,Image,Spinner } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';


// rsuite
import { CheckPicker,Input, Whisper, Tooltip,SelectPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// data table
import DataTable from 'react-data-table-component';
import { dummytabledata, tableColumns } from '../../Data/Table/Defaultdata';
import { useNavigate } from 'react-router';
import CommonModal from 'Components/Modal';
import { toast ,ToastContainer} from 'react-toastify';

const style2 = { width: 60, fontSize: 14, padding: 4 };


function Notifi (){
    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [ totalItems, setTotalItems ] = useState(0)
    const [modal, setModal] = useState(false);
    const [refreshDelete, setRefreshDelete] = useState(false);
    const toggle = () => setModal(!modal);
    const permissions = JSON.parse(localStorage.getItem('permission'));

    useEffect(()=>{
        if (!permissions || !permissions.includes('notification.get-all')) {
          navigate(`/Forbidden`)
      } else {
        get_notification()
      }
          
      },[values_Filter,page,perPage,refreshDelete])

      const get_notification = async ()=>{
        setIsLoading(true)
        setData_Table([])
        const {response, message} = await Helper.Get({
            url:api_Routes.notification.view,
            hasToken:true,
            data:{results:perPage,
              page:page,
              keywords:values_Filter.keywords,
            }
        })
        if(response){
            console.log(response.data);
            setTotalItems(response.meta.total)
            response.data.forEach(elem =>{   
                setData_Table(prev=> [...prev,{
                    id:elem.id,
                    name:elem.name,
                    action: (
                      <div>
                        <span className='mx-2'>
                          <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>
                          navigate(`/add-notification/${elem.id}/Shake`)}}>Edit </Btn>
                        </span>
                      </div>
                    ),
                   
                        }])
                })
                setCol_Table([
                {
                    name: 'Id',
                    selector: row => row['id'],
                    sortable: true,
                    width:"15%",
                    center: false,
                },
                {
                    name: 'Name',
                    selector: row => row['name'],
                    sortable: true,
                    width:"65%",
                    center: false,
                },
                {
                  name: 'Action',
                  selector: (row) => row.action,
                  sortable: true,
                  center: true,
                  Width: '25%',
                },
          ])
            
               
           
            setIsLoading(false)
        }else{
            console.log(message);
        }

      }
      
     const handleChangePage = (value)=>{
        setPerPage(value)
    }
     const handlePage =(e)=>{
        setPage(e)
     }
     const handleChange = (key,value)=>{
        setValues(prev=>({...prev,[key]:value}))
      }
     const handleFilter = ()=>{
      setFilter_Values(values)
    }

    return(
        <Fragment>
          <Breadcrumbs parent="Table" title="Notification Text" mainTitle="Notifications Text" />
           <Container className='my-2' fluid={true}>
            <Row>
                <Col sm="12">
                <Card>
                    <CardBody>
                        <Row>

                        <Col className='d-flex justify-content-center p-2' xs={8} sm={8} lg={11} md={11} xl={11}>
                        <Whisper trigger="focus" speaker={<Tooltip>Keywords</Tooltip>}>
                        <Input onChange={(e)=>{handleChange("keywords",e)}} 
                                placeholder="Search By Notification Name" />
                        </Whisper>

                    </Col>  
                    
                    <Col className='d-flex align-items-center justify-content-end p-2' xs={4} sm={4}  xl={1} lg={1} md={1} >
                        <div onClick={()=>{handleFilter()}}>
                        <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                        </div>
                    </Col>

                        </Row>
                    </CardBody>
                </Card>
                </Col>
            </Row>
           </Container>
           <Container fluid={true}>
                <Row>
                    <Col>
                     <Card>
                        <CardBody>
                            <Fragment>
                                 {(selectedRows.length !== 0) &&
                                     <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                                     <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
                                     <Btn attrBtn={{ color: 'danger', onClick: () => toggle() }}>Delete</Btn>
                                     </div>
                                }
                                  <DataTable
                                    // data={data}
                                    // columns={tableColumns}
                                    data={data_table}
                                    columns={col_table}
                                    striped={true}
                                    center={true}
                                    progressPending={isLoading}
                                    progressComponent={<div className="loader-box">
                                                        <Spinner attrSpinner={{ className: "loader-26" }} />
                                                      </div>}
                                    pagination
                                    paginationServer
                                    paginationPerPage={perPage}
                                    paginationRowsPerPageOptions={[10,20,30,50,100]}
                                    responsive
                                    paginationTotalRows={totalItems}
                                    paginationServerOptions={()=>{}}
                                    onChangePage={(e)=>{handlePage(e)}}
                                    onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                                    clearSelectedRows={toggleDelet}
                                />
                            </Fragment>
                        </CardBody>
                     </Card>
                    </Col>
                </Row>
           </Container>
        </Fragment>
    )
  
}
export default Notifi;
