import React, { Fragment ,useRef } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { H5, UL, LI ,Breadcrumbs ,Btn } from '../../AbstractElements';
import { User} from './chart'
import {Box} from './chart'
import {Box1} from './chart'
import {Box2} from './chart'
import {Box3} from './chart'
import {Box4} from './chart'
import {Box5} from './chart'
import {Box6} from './chart'
import {Box7} from './chart'
import {Product} from './chart'
import ReactApexChart from 'react-apexcharts';


function Statisitics  () {
   
    // const vendorRef = useRef(null);
    // const userShakesRef = useRef(null);

    // // Function to scroll to vendors section
    // const scrollToVendors = () => {
    //   vendorRef.current.scrollIntoView({ behavior: 'smooth' });
    // };

    // // Function to scroll to user shakes section
    // const scrollToUserShakes = () => {
    //   userShakesRef.current.scrollIntoView({ behavior: 'smooth' });
    // };

    return (<>
    <Fragment>
       <Breadcrumbs mainTitle="Statistics" parent="Statistic-List"  />
    
        <Card>
          <CardHeader className='card-no-border'>
            <H5>Orders</H5>
          </CardHeader>
          <CardBody className='pt-0'>
            <Row className='m-0 overall-card'>
              <Col xl='9' md='8' sm='7' className='box-col-7 p-0'>
                <div className='chart-right'>
                  <Row>
                    <Col xl='12'>
                      <CardBody className='p-0'>
                        <UL attrUL={{ horizontal: true, className: 'd-flex balance-data' }}>
                          <LI>
                            <span className='circle bg-secondary' />
                            <span className='f-light ms-1'>Order (weekly)</span>
                          </LI>
                        </UL>
                        <div className='current-sale-container order-container'>
                          <ReactApexChart className='overview-wrapper' type='line' width={"150%"} height={300} options={Box().options} series={Box().series} />
                          <div className='back-bar-container'>
                            {/* <ReactApexChart type='bar' height={180} options={OptionsOverViewBarChart.options} series={OptionsOverViewBarChart.series} /> */}
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className='card-no-border'>
            <H5 >Vendors</H5>
          </CardHeader>
          <CardBody className='pt-0'>
            <Row className='m-0 overall-card'>
              <Col xl='9' md='8' sm='7' className='box-col-7 p-0'>
                <div className='chart-right'>
                  <Row>
                    <Col xl='12'>
                      <CardBody className='p-0'>
                        <UL attrUL={{ horizontal: true, className: 'd-flex balance-data' }}>
                          <LI>
                            <span className='circle bg-secondary' />
                            <span className='f-light ms-1'>Vendor (weekly)</span>
                          </LI>
                        </UL>
                        <div className='current-sale-container order-container'>
                          <ReactApexChart className='overview-wrapper' type='line' width={"150%"} height={300} options={Box1().options} series={Box1().series} />
                          <div className='back-bar-container'>
                            {/* <ReactApexChart type='bar' height={180} options={OptionsOverViewBarChart.options} series={OptionsOverViewBarChart.series} /> */}
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className='card-no-border'>
            <H5>Vendors</H5>
          </CardHeader>
          <CardBody className='pt-0'>
            <Row className='m-0 overall-card'>
              <Col xl='9' md='8' sm='7' className='box-col-7 p-0'>
                <div className='chart-right'>
                  <Row>
                    <Col xl='12'>
                      <CardBody className='p-0'>
                        <UL attrUL={{ horizontal: true, className: 'd-flex balance-data' }}>
                          <LI>
                            <span className='circle bg-secondary' />
                            <span className='f-light ms-1'>Vendor (monthly)</span>
                          </LI>
                        </UL>
                        <div className='current-sale-container order-container'>
                          <ReactApexChart className='overview-wrapper' type='line' width={"150%"} height={300} options={Box2().options} series={Box2().series} />
                          <div className='back-bar-container'>
                            {/* <ReactApexChart type='bar' height={180} options={OptionsOverViewBarChart.options} series={OptionsOverViewBarChart.series} /> */}
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className='card-no-border'>
            <H5 >User Shakes</H5>
          </CardHeader>
          <CardBody className='pt-0'>
            <Row className='m-0 overall-card'>
              <Col xl='9' md='8' sm='7' className='box-col-7 p-0'>
                <div className='chart-right'>
                  <Row>
                    <Col xl='12'>
                      <CardBody className='p-0'>
                        <UL attrUL={{ horizontal: true, className: 'd-flex balance-data' }}>
                          <LI>
                            <span className='circle bg-secondary' />
                            <span className='f-light ms-1'>User Shakes (weakly)</span>
                          </LI>
                        </UL>
                        <div className='current-sale-container order-container'>
                          <ReactApexChart className='overview-wrapper' type='line' width={"150%"} height={300} options={Box3().options} series={Box3().series} />
                          <div className='back-bar-container'>
                            {/* <ReactApexChart type='bar' height={180} options={OptionsOverViewBarChart.options} series={OptionsOverViewBarChart.series} /> */}
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardHeader className='card-no-border'>
            <H5>User Shakes</H5>
          </CardHeader>
          <CardBody className='pt-0'>
            <Row className='m-0 overall-card'>
              <Col xl='9' md='8' sm='7' className='box-col-7 p-0'>
                <div className='chart-right'>
                  <Row>
                    <Col xl='12'>
                      <CardBody className='p-0'>
                        <UL attrUL={{ horizontal: true, className: 'd-flex balance-data' }}>
                          <LI>
                            <span className='circle bg-secondary' />
                            <span className='f-light ms-1'>User Shakes (monthly)</span>
                          </LI>
                        </UL>
                        <div className='current-sale-container order-container'>
                          <ReactApexChart className='overview-wrapper' type='line' width={"150%"} height={300} options={Box4().options} series={Box4().series} />
                          <div className='back-bar-container'>
                            {/* <ReactApexChart type='bar' height={180} options={OptionsOverViewBarChart.options} series={OptionsOverViewBarChart.series} /> */}
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
                <Card>
            <CardHeader className='card-no-border'>
                <H5>Shake Per Category</H5>
            </CardHeader>
            <CardBody className='pt-0'>
                <div className='monthly-profit'>
                <ReactApexChart type='donut' height={300} series={Box5().series} options={Box5().options} />
                </div>
            </CardBody>
            </Card>

            <Row>
            <Col>
            <Card>
          <CardHeader className='card-no-border'>
            <H5 >Shakes</H5>
          </CardHeader>
          <CardBody className='pt-0'>
            <Row className='m-0 overall-card'>
              <Col xl='9' md='8' sm='7' className='box-col-7 p-0'>
                <div className='chart-right'>
                  <Row>
                    <Col xl='12'>
                      <CardBody className='p-0'>
                        <UL attrUL={{ horizontal: true, className: 'd-flex balance-data' }}>
                          <LI>
                            <span className='f-light ms-1'style={{color:"#54BA4A"}}>Bronze</span>
                          </LI>
                          <LI>
                            <span className='f-light ms-1'style={{color:"#7064F5"}}>Silver</span>
                          </LI>
                          <LI>
                            <span className='f-light ms-1'style={{color:"#FF5733"}}>Golden</span>
                          </LI>
                          <LI>
                            <span className='f-light ms-1'style={{color:"#AB47BC"}}>Platinum </span>
                          </LI>
                        </UL>
                        <div className='current-sale-container order-container'>
                          <ReactApexChart className='overview-wrapper' type='line' width={"150%"} height={300} options={Box6().options} series={Box6().series} />
                          <div className='back-bar-container'>
                            {/* <ReactApexChart type='bar' height={180} options={OptionsOverViewBarChart.options} series={OptionsOverViewBarChart.series} /> */}
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

            <Card>
          <CardHeader className='card-no-border'>
            <H5 >Order</H5>
          </CardHeader>
          <CardBody className='pt-0'>
            <Row className='m-0 overall-card'>
              <Col xl='9' md='8' sm='7' className='box-col-7 p-0'>
                <div className='chart-right'>
                  <Row>
                    <Col xl='12'>
                      <CardBody className='p-0'>
                        <UL attrUL={{ horizontal: true, className: 'd-flex balance-data' }}>
                          <LI>
                            <span className='f-light ms-1'style={{color:"#54BA4A"}}>Pending</span>
                          </LI>
                          <LI>
                            <span className='f-light ms-1'style={{color:"#7064F5"}}>Delivery </span>
                          </LI>
                          <LI>
                            <span className='f-light ms-1'style={{color:"#FF5733"}}>Delivered</span>
                          </LI>
                          <LI>
                            <span className='f-light ms-1'style={{color:"#AB47BC"}}>Rejected</span>
                          </LI>
                          <LI>
                            <span className='f-light ms-1'style={{color:"#F9A825"}}>Canceled</span>
                          </LI>
                          <LI>
                            <span className='f-light ms-1'style={{color:"#3E2723"}}>Sold</span>
                          </LI>
                        </UL>
                        <div className='current-sale-container order-container'>
                          <ReactApexChart className='overview-wrapper' type='line' width={"150%"} height={300} options={Box7().options} series={Box7().series} />
                          <div className='back-bar-container'>
                            {/* <ReactApexChart type='bar' height={180} options={OptionsOverViewBarChart.options} series={OptionsOverViewBarChart.series} /> */}
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        </Col>
        </Row>
                    <Card className='visitor-card'>
                        <CardHeader className='card-no-border'>
                            <div className='header-top'>
                            <H5 attrH5={{ className: 'm-0' }}>
                                Products
                                <span className='f-14 font-primary f-w-500 ms-1'>
                                (+2.8)
                                </span>
                            </H5>
                            <div className='card-header-right-icon'>
                                {/* <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'start' }} options={DailyDropdown} iconName='icon-more-alt' btn={{ tag: 'span' }} /> */}
                            </div>
                            </div>
                        </CardHeader>
                        <CardBody className='pt-0'>
                            <div className='visitors-container'>
                            <ReactApexChart height={270} type='bar' options={Product.options} series={Product.series} />
                            </div>
                        </CardBody>
                     </Card>
                     </Fragment>
        </>);
}
export default Statisitics;
