import React, { Fragment,useEffect,useState ,useRef} from 'react';
import { Container, Row, Col, Card, CardBody,Media  } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { Btn, H4 ,Spinner} from '../../AbstractElements';

// api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// data table
import DataTable from 'react-data-table-component';
import { dummytabledata } from '../../Data/Table/Defaultdata';
// rsuite
import { Input, Whisper, Tooltip , SelectPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { useNavigate, useParams } from 'react-router';
import CommonModal from 'Components/Modal';
import { toast } from 'react-toastify';
import Details_Balance from './details';
import dateFormat from "dateformat";
import { useLocation } from 'react-router-dom';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MarginBottom } from 'Constant';

    

const BaseApi=api_Routes.balance.view;


const style2 = { width: 60, fontSize: 14, padding: 4 };
const Balances = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [data_table, setData_Table] = useState([])
  const [selectedfDate, setSelectedfDate] = useState(null)
  const [users, setusers] = useState([])
  const [col_table, setCol_Table] = useState([])
  const [ perPage, setPerPage ] = useState(10)
  const [ page, setPage ] = useState(1)
  const [values,setValues] = useState({})
  const [values_Filter, setFilter_Values] = useState({})
  const [ totalItems, setTotalItems ] = useState(0)
  const [modal, setModal] = useState(false);
  const [refreshDelete, setRefreshDelete] = useState(false);
  const [item, setItem] = useState("");
  const toggle = () => setModal(!modal);
  const [statuss, setstatuss] = useState([]);
  const controllerRef = useRef(null)


  const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('user_id');
    const permissions = JSON.parse(localStorage.getItem('permission'));

    
  useEffect(()=>{
    if (id === ":id" && (!permissions || !permissions.includes('balance.get-all'))) {
      navigate(`/Forbidden`);
  }else{
    if(id == ":id"){
        get_balances()
        get_user()
        get_status()
    }}
  },[values_Filter,page,perPage,refreshDelete])

  const get_status = () => {
    const response = { data: [
       { name: "charge", id: 1 },
       { name: "Redeem", id: 2 },
       { name: "shake fee", id: 3 },
       { name: "payment", id: 4 },
       { name: "transfer", id: 5 },
       { name: "open box fee", id: 6 },
       { name: "delivered", id: 7 }
     ]};
       setstatuss([]);
     response.data.forEach(ele => {
     
       setstatuss(prev => [...prev, {
         label: ele.name,
         value: ele.id
       }]);
      
     });}

  const get_user = async()=>{
    const {response, message} = await Helper.Get({
        url:api_Routes.user.view,
        hasToken:true,
    })
    if(response){
      console.log("ana");
        console.log(response.data);
        // setusers([])
        // response.data.forEach(ele=>{
        // setusers(prev => [...prev, {
        //     label:ele.name, // Convert user_id to integer
        //     value: ele.id ,  // Convert user_id to integer
        // }])
        // })
        setusers(response.data)
    }else{
        console.log(message);
    }
    }
  
  const get_balances = async()=>{

    let url = BaseApi + '?1+1';
    
    if(userId){
      url = url + `&user_id=${userId}`;
    }
    if (values_Filter.user_id ) {
      url = url + `&user_id=${values_Filter.user_id}`;
    }
    if (values_Filter.user_id1 ) {
      url = url + `&to_user_id=${values_Filter.user_id1}`;
    }
    if ( values_Filter.date  ) {
      url = url + `&updated_at=${values_Filter.date}`;
    }
    if ( values_Filter.amount ) {
      url = url + `&amount=${values_Filter.amount}`;
    }
    if ( values_Filter.type ) {
      url = url + `&balance_type=${values_Filter.type}`;
    }

    setData_Table([])
    setIsLoading(true)
    const {response, message} = await Helper.Get({
      url:url,
      hasToken:true,
      data:{page:page,results:perPage,keywords:values_Filter.keywords}
    })
    if(response){
      setTotalItems(response.meta.total)
      response.data.forEach(elem=>{
        setData_Table(prev=>[...prev,{
          user:elem.user&&<Media className='d-flex'>
                <Media body className="align-self-center">
                  <div>{elem.user.name}</div>
              </Media>
              </Media>
          ,
          to_user:elem.to_user?<Media className='d-flex'>
                    <Media body className="align-self-center">
                        <div>{elem.to_user.name}</div>
                    </Media>
                </Media>:"-",
          amount:elem.amount,
          type_name:elem.type.name,
          date:dateFormat(elem.created_at,"dddd, mmmm dS, yyyy"),
          id:elem.id,
          active:elem.active == 1 ?<span className={`iconbg badge-light-success p-3`}>Active</span>
                    :<span className={`iconbg badge-light-danger p-3`}>Not Active</span>,
          action: (
            <div>
              {permissions && permissions.includes('balance.get-all') && (
              <span className='mx-2'>
                <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>navigate(`/balance-list/${elem.id}/Shake`)}}> Details </Btn>
              </span>
              )}
              {/* {permissions && permissions.includes('balance.delete') && (
              <span >
                <Btn  attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button' ,onClick:()=>{
                  toggle()
                  setItem(elem.id)
                }}}>delete </Btn>
              </span>
              )} */}
            </div>
          ),


      }])
      })
      setCol_Table([{
        name: 'User From',
        selector: row => row['user'],
        sortable: true,
        width:"14%",
        center: false,
    },
    {
        name: 'User To',
        selector: row => row['to_user'],
        sortable: true,
        center: true,
        width:"12%",
    },

    {
        name: 'Type',
        selector: row => `${row.type_name}`,
        sortable: true,
        width:"14%",
        center: true,
    },
    {
        name: 'Amount',
        selector: row =>  `${row.amount}`,
        sortable: true,
        width:"13%",
        center: true,
    },
    {
        name: 'Date',
        selector: row => row['date'] ,
        sortable: true,
        width:"25%",
        center: true,
    }, 
    {
        name: 'Active',
        selector: row => row['active'] ,
        sortable: true,
        width:"10%",
        center: true,
    },
   
    {
      name: 'Action',
      selector: (row) => row.action,
      sortable: true,
      center: true,
      Width: '15%',
    }])
      setIsLoading(false)
    }else{
      console.log(message);
    }
  }
  
  const handleDelete = async() => {
    let path,ids={};
    if(item.length != ""){
     
        path=api_Routes.balance.update(item)
    }else{
        selectedRows.forEach((ele,i)=>{
          ids[`ids[${i}]`] = ele
        })
        path=api_Routes.balance.bulk

    }

    const {response, message} = await Helper.Delete({
      url:path,
      hasToken:true,
      data:selectedRows.length>0?ids:{}
    })
    if(response){
      toast.success(message)
      setItem("")
      setSelectedRows([])
      setRefreshDelete(!refreshDelete)
    }else{
      toast.error(message)
      setItem("")
      setSelectedRows([])
    }
    // if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
    //     setToggleDelet(!toggleDelet);

    //     setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
    //     setSelectedRows('');
    // }
  };
    /// Change Pages To Api
    const handleChangePage = (value)=>{
      setPerPage(value)
    }
    const handlePage =(e)=>{
        setPage(e)
    }
    const handleChange = (key,value)=>{
        setValues(prev=>({...prev,[key]:value}))
      }
    const handleFilter = ()=>{
    setFilter_Values(values)
    }
    const handleChanged = (key, value) => {
      const formattedDate = value ? new Date(value)
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ') 
      : null;  
      setValues(prev => ({ ...prev, [key]: formattedDate }));
     }

     const onSearch1 = async (e,key)=>{

      if(controllerRef.current){
        controllerRef.current.abort()
      }
      controllerRef.current = new AbortController()
      const signal = controllerRef.current.signal
      const {response,message} = await Helper.Get_Abort({
        url:  api_Routes.user.view ,
        signal:signal,
        data:{keywords:e,results:20},
        hasToken:true
      })
      if(response){
            setusers(response.data)
      }
      
      }
    

  return (
    id== ":id"?<Fragment>
    <CommonModal isOpen={modal} title="Confirm Delete" handleConfirm={handleDelete} toggler={toggle} >
      Are you sure delete ?
    </CommonModal>
    <Breadcrumbs parent="Balance" title="List" mainTitle="Balance" />
    <Container className='my-2' fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
           
            <CardBody>
            <Row  >
              <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={4}>
                <Whisper trigger="focus" speaker={<Tooltip>Keywords</Tooltip>}>
                  <Input onChange={(e)=>{handleChange("keywords",e)}} 
                         placeholder="Search By UserName ,To User ,From User ,Amount" />
                </Whisper>
              </Col>  

              <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={4}>
                <SelectPicker placeholder="Search By user" 
                 onChange={(e)=>{handleChange("user_id",e)}}
                        style={{ width: "100%" }}
                        data={ users.map((ele)=>({label:ele.name?ele.name:"",value:ele.id}))} 
                        onSearch={(e)=>{
                          if(e!="")
                           onSearch1(e,"user1")
                      }}
                          
                        //  loading={isLoading.boxes} 
                         />
              </Col>
              <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={4}>
                <SelectPicker placeholder="Search By to-user" 
                 onChange={(e)=>{handleChange("user_id1",e)}}
                        style={{ width: "100%" }}
                        data={ users.map((ele)=>({label:ele.name?ele.name:"",value:ele.id}))} 
                        onSearch={(e)=>{
                          if(e!="")
                           onSearch1(e,"user1")
                      }}
                          
                        //  loading={isLoading.boxes} 
                         />
              </Col>
              <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={4}>
                <SelectPicker placeholder="Search By Type" 
                 onChange={(e)=>{handleChange("type",e)}}
                        style={{ width: "100%" , marginTop:'15px' }}
                          data={statuss} 
                          
                        //  loading={isLoading.boxes} 
                         />
              </Col>
              <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={4}>
                <Input placeholder="Search By Amount" 
                onChange={(e)=>{handleChange("amount",e)}}
                        style={{ width: "100%" , height:'35px' , marginTop:'15px',borderRadius : '5px', border:'1px solid #e5e7eb', paddingLeft:'11px' }}
                           
                        //  loading={isLoading.boxes} 
                         />
              </Col>

              <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3} >
              <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DemoContainer components={['DatePicker']} >
                    <DatePicker
                    className='date'
                        placeholderText="Search By  Date"
                        selected={selectedfDate}
                        onChange={(date) => handleChanged("date", date)}
                       // dateFormat="yyyy-M-d"
                        onSelect={(date) => setSelectedfDate(date)}
                        sx={{height:'70%'  , overFlow:'hidden' , WebkitFlexDirection: 'row', display: 'contents' , borderRadius : '5px' , border: ' 1px solid  #e5e7eb' }}
                    />
                </DemoContainer>
               </LocalizationProvider>

               </Col>

             
              <Col className='d-flex align-items-center justify-content-end p-2 mt-3' sm={12} xs={1} lg={1} md={1} xl={1} >
                <div onClick={()=>{handleFilter()}}>
                  <Btn  className='info outline Info Button' > <i className='fa fa-search'></i></Btn>
                </div>
              </Col>
            </Row>
            

            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
            <Fragment>
                  {(selectedRows.length !== 0) &&
                      <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                          <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
                          <Btn attrBtn={{ color: 'danger', onClick: () => toggle() }}>Delete</Btn>
                      </div>
                  }
                  <DataTable
                      // data={data}
                      // columns={tableColumns}
                      data={data_table}
                      columns={col_table}
                      striped={true}
                      center={true}
                      progressPending={isLoading}
                      progressComponent={<div className="loader-box">
                                          <Spinner attrSpinner={{ className: "loader-26" }} />
                                      </div>}
                      pagination
                      paginationServer
                      paginationPerPage={perPage}
                      paginationRowsPerPageOptions={[10,20,30,50,100]}
                      responsive
                      paginationTotalRows={totalItems}
                      paginationServerOptions={()=>{}}
                      onSelectedRowsChange={(e)=>{
                        setSelectedRows([])
                        e.selectedRows.forEach(ele =>{
                          setSelectedRows(prev=>[...prev,ele.id])
                        })
                        

                      }}
                      onChangePage={(e)=>{handlePage(e)}}
                      onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                      clearSelectedRows={toggleDelet}
                  />
              </Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </Fragment>:<Details_Balance id={id}/>
  );

};

export default Balances;