import React, { Fragment,useEffect,useState , useContext} from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Breadcrumbs ,Btn,Spinner } from '../../AbstractElements';
import {  useNavigate, useParams } from 'react-router-dom';
import CustomizerContext from '../../_helper/Customizer';
//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// rsuite
import { Input, Whisper, Tooltip } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
// data table
import DataTable from 'react-data-table-component';
import { dummytabledata } from '../../Data/Table/Defaultdata';
// date  format

import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router-dom';
import CommonModal from 'Components/Modal';
import { toast } from 'react-toastify';


const style2 = { width: 60, fontSize: 14, padding: 4 };
const style3 = { width: 85, fontSize: 14, padding: '6px' }; // Adjust the padding values as needed


function Countries(){
    const {id} = useParams()
    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [ totalItems, setTotalItems ] = useState(0)
    const toggle = () => setModal(!modal);
    const [modal, setModal] = useState(false);
    const [item, setItem] = useState("");
    const [refreshDelete, setRefreshDelete] = useState(false);
    const location = useLocation();
     const permissions = JSON.parse(localStorage.getItem('permission'));


     useEffect(()=>{
        console.log('Component rendered');

        if (!permissions || !permissions.includes('country.get-all')) {
            navigate(`/Forbidden`)
        } else{
        get_countries()
          }
      },[values_Filter,page,perPage,refreshDelete])

     const get_countries = async()=>{
        setIsLoading(true)
        const {response, message} = await Helper.Get({
          
        url: api_Routes.country.view,
          hasToken:true,
            data:{results:perPage,page:page,
              keywords:values_Filter.keywords,
            }
        })
        if(response){

            setData_Table([])
              setTotalItems(response.meta.total)
  
              response.data.forEach(elem => {
                  setData_Table(prev=>[...prev,{
                      id:elem.id,
                      name:elem.name,
                      code:elem.code,
                      phone_code: elem.phone_code,
                      is_active:elem.is_active,
                      action: (
                             <div>
                                {permissions && permissions.includes('country.edit') && (
                               <span>
                                 <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>navigate(`/add-country/${elem.id}/order`)}}> Edit </Btn>
                               </span>
                                )}
                                 {permissions.includes('country.delete') && (
                                <span>
                                <Btn  attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs m-2', type: 'button' ,onClick:()=>{
                                toggle()
                                setItem(elem.id)
                                }}}>Delete </Btn>
                            </span>
                                  )}
                             </div>
                            ),
                      }])
                  
              });
              setCol_Table([{
                      name: 'id',
                      selector: row => row['id'],
                      sortable: true,
                      width:"15%",
                      center: false,
                  },
                  {
                      name: 'Country',
                      selector: row => row['name'],
                      sortable: true,
                      width:"20%",
                      center: true,
                  },
                  {
                      name: 'Code',
                      selector: row => row['code'],
                      sortable: true,
                      width:"10%",
                      center: true,
                  },
                  {
                      name: 'Phone Code',
                      selector: row => row['phone_code'],
                      sortable: true,
                      width:"20%",
                      center: false,
                  },
                  {
                      name: 'Is Active',
                      selector: row => row['is_active'],
                      sortable: true,
                      width:"15%",
                      center: false,
                  },
                 
                  {
                      name: 'Action',
                      selector: (row) => row.action,
                      sortable: true,
                      center: true,
                      width: '20%',
                    },
                  
            ])
            setIsLoading(false)
          }else{
              console.log(message);
          }
  
      }

      const handleChangePage = (value)=>{
        console.log("per_pages",value);
        setPerPage(value)
    }
    const handlePage =(e)=>{
        setPage(e)
    }
   const handleChange = (key, value) => {
    setValues(prev => ({ ...prev, [key]:value }));
     }

    const handleFilter = ()=>{
    setFilter_Values(values)
    }
    const handleDelete = async() => {
        let path,ids={};
        if(item.length != ""){
         
            path=api_Routes.country.update(item)
        }else{
            selectedRows.forEach((ele,i)=>{
              ids[`ids[${i}]`] = ele
            })
            path=api_Routes.country.bulkDelete
    
        }
    
        const {response, message} = await Helper.Delete({
          url:path,
          hasToken:true,
          data:selectedRows.length>0?ids:{}
        })
        if(response){
          toast.success(message)
          setItem("")
          setSelectedRows([])
          setRefreshDelete(!refreshDelete)
        }else{
          toast.error(message)
          setItem("")
          setSelectedRows([])
        }
        // if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
        //     setToggleDelet(!toggleDelet);
    
        //     setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
        //     setSelectedRows('');
        // }
      };

     return(<>
        <CommonModal isOpen={modal} title="Confirm Delete" handleConfirm={handleDelete} toggler={toggle} >
                  Are you sure delete ?
          </CommonModal>
         <Breadcrumbs parent="country" title="List" mainTitle="Countries" />
                <Container className='my-2' fluid={true}>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                    <Col className='d-flex justify-content-center p-2' xs={8} sm={8} lg={11} md={11} xl={11}>
                        <Whisper trigger="focus" speaker={<Tooltip>Keywords</Tooltip>}>
                        <Input onChange={(e)=>{handleChange("keywords",e)}} 
                                placeholder="Search By Country Name" />
                        </Whisper>

                    </Col>  
                    
                    <Col className='d-flex align-items-center justify-content-center p-2' xs={4} sm={4}  xl={1} lg={1} md={1} >
                        <div onClick={()=>{handleFilter()}}>
                        <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                        </div>
                    </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardBody>
                                    <Fragment>
                                        <DataTable
                                                data={data_table}
                                                columns={col_table}
                                                striped={true}
                                                center={true}
                                                progressPending={isLoading}
                                                progressComponent={<div className="loader-box">
                                                                    <Spinner attrSpinner={{ className: "loader-26" }} />
                                                                </div>}
                                                pagination
                                                paginationServer
                                                paginationPerPage={perPage}
                                                paginationRowsPerPageOptions={[10,20,30,50,100]}
                                                responsive
                                                paginationTotalRows={totalItems}
                                                paginationServerOptions={()=>{}}
                                                onChangePage={(e)=>{handlePage(e)}}
                                                onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                                        />
                                    </Fragment>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
            );

}
export default Countries;
