import React, { Fragment,useEffect,useState } from 'react';
import { Container, Row, Col, Card, CardBody,Media } from 'reactstrap';
import { Breadcrumbs ,Btn, H4,Image ,Spinner} from '../../AbstractElements';


// rsuite
import { Input, Whisper, Tooltip } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// data table
import DataTable from 'react-data-table-component';
import { dummytabledata } from '../../Data/Table/Defaultdata';
import { useNavigate } from 'react-router';
import CommonModal from 'Components/Modal';
import { toast } from 'react-toastify';

const style2 = { width: 60, fontSize: 14, padding: 4 };
function Section_Table() {
    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [modal, setModal] = useState(false);
    const [ totalItems, setTotalItems ] = useState(0)
    const [refreshDelete, setRefreshDelete] = useState(false);
    const [item, setItem] = useState("");
    const toggle = () => setModal(!modal);
    const permissions = JSON.parse(localStorage.getItem('permission'));


    useEffect(()=>{
      const controller = new AbortController()
      const signal = controller.signal
      if (!permissions || !permissions.includes('page_section.get-all')) {
        navigate(`/Forbidden`)
    } else {
      get_pages(signal)
    }
    return()=>{
      controller.abort()
    }
      
    },[values_Filter,page,perPage,refreshDelete])

    const get_pages = async(signal)=>{
        setData_Table([])
        setIsLoading(true)
        const {response, message} = await Helper.Get_Abort({
            url:api_Routes.section.view,
            hasToken:true,
            data:{results:perPage,page:page,
              signal:signal,
              keywords:values_Filter.keywords}
        })
        if(response){
            console.log(response.data);
            setTotalItems(response.meta.total)
            response.data.forEach(elem =>{   
                setData_Table(prev=> [...prev,{
                    name:<Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${elem.photo}`, alt:`${elem.name}` }} />
                        <Media body className="align-self-center">
                            <div>{elem.translations["en"]?elem.translations["en"].name:""}</div>
                        </Media>
                    </Media>,
                    id:elem.id,
                    action: (
                      <div>
                        <span className='mx-2'>
                          <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>navigate(`/add-section/${elem.id}/Shake`)}}>Edit </Btn>
                        </span>
                  {permissions && permissions.includes('page_section.delete') && (
                        <span >
                          <Btn  attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs', type: 'button' ,onClick:()=>{
                            toggle()
                            setItem(elem.id)
                          }}}>Delete </Btn>
                        </span>
                  )}
                      </div>
                    ),
                        }])
                })
                setCol_Table([{
                    name: 'Name',
                    selector: row => row['name'],
                    sortable: true,
                    width:"75%",
                    center: false,
                },
                {
                  name: 'Action',
                  selector: (row) => row.action,
                  sortable: true,
                  center: true,
                  Width: '30%',
                }
                
          ])
            
               
           
            setIsLoading(false)
        }else{
            console.log(message);
        }
        }
    const handleDelete = async() => {
          let path,ids={};
          if(item.length != ""){
           
              path=api_Routes.section.update(item)
          }else{
              selectedRows.forEach((ele,i)=>{
                ids[`ids[${i}]`] = ele
              })
              path=api_Routes.section.bulk
      
          }
      
          const {response, message} = await Helper.Delete({
            url:path,
            hasToken:true,
            data:selectedRows.length>0?ids:{}
          })
          if(response){
            toast.success(message)
            setItem("")
            setSelectedRows([])
            setRefreshDelete(!refreshDelete)
          }else{
            toast.error(message)
            setItem("")
            setSelectedRows([])
          }
          // if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
          //     setToggleDelet(!toggleDelet);
      
          //     setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
          //     setSelectedRows('');
          // }
        };
    /// Change Pages To Api
    const handleChangePage = (value)=>{
        console.log("per_pages",value);
        setPerPage(value)
    }
    const handlePage =(e)=>{
        setPage(e)
    }
    const handleChange = (key,value)=>{
        setValues(prev=>({...prev,[key]:value}))
      }
    const handleFilter = ()=>{
    setFilter_Values(values)
    }
    return ( <Fragment>
      <CommonModal isOpen={modal} title="Confirm Delete" handleConfirm={handleDelete} toggler={toggle} >
        Are you sure delete ?
      </CommonModal>
        <Breadcrumbs parent="Section" title="List" mainTitle="Sections" />
        <Container className='my-2' fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
             
              <CardBody>
              <Row  >
              <Col className='d-flex justify-content-center p-2' xs={8} sm={8} lg={11} md={11} xl={11}>
                        <Whisper trigger="focus" speaker={<Tooltip>Keywords</Tooltip>}>
                        <Input onChange={(e)=>{handleChange("keywords",e)}} 
                                style={{ width: "100%" }} placeholder="Search By Section Name" />
                        </Whisper>

                    </Col>  
                    
                    <Col className='d-flex align-items-center justify-content-center p-2' xs={4} sm={4}  xl={1} lg={1} md={1} >
                        <div onClick={()=>{handleFilter()}}>
                        <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                        </div>
                    </Col>
              </Row>
              

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                <Fragment>
                    {(selectedRows.length !== 0) &&
                        <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                            <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
                            <Btn attrBtn={{ color: 'danger', onClick: () => toggle() }}>Delete</Btn>
                        </div>
                    }
                    <DataTable
                        // data={data}
                        // columns={tableColumns}
                        data={data_table}
                        columns={col_table}
                        striped={true}
                        center={true}
                        progressPending={isLoading}
                        progressComponent={<div className="loader-box">
                                            <Spinner attrSpinner={{ className: "loader-26" }} />
                                        </div>}
                        pagination
                        paginationServer
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10,20,30,50,100]}
                        responsive
                        // selectableRows
                        paginationTotalRows={totalItems}
                        onSelectedRowsChange={(e)=>{
                          setSelectedRows([])
                          e.selectedRows.forEach(ele =>{
                            setSelectedRows(prev=>[...prev,ele.id])
                          })
                        }}
                        paginationServerOptions={()=>{}}
                        onChangePage={(e)=>{handlePage(e)}}
                        onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                        clearSelectedRows={toggleDelet}
                    />
                </Fragment>
  
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>);
}

export default Section_Table;
