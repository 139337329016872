
import React, { useEffect, useState } from 'react';
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';



export const SmallWidgetsData = () => {
  const [detailsInfo, setDetailsInfo] = useState({});

  useEffect(() => {
    fetchDetailsInfo();
  }, []);  
  
  const fetchDetailsInfo = async () => {
    
      const { response,message } = await Helper.Get({
        url: api_Routes.statistics.view1,
        hasToken: true,
      });
      if (response) {

        setDetailsInfo(response.data);
        console.log(detailsInfo)
      }
      else {
        console.log(message);
      }
    
  };

  return [
    {
      title: 'Total Orders',
      color: 'primary',
      total: detailsInfo.orders_total ,
      gros: 50,
      icon: 'new-order',
    },
    {
      title: 'Total Users',
      color: 'warning',
      total: detailsInfo.users_total,
      gros: 20,
      icon: 'customers',
    },
    {
      title: 'Total User Shakes',
      color: 'secondary',
      total: detailsInfo.userShakes_total ,
      gros: 10,
      prefix: '$',
      icon: 'sale',
      suffix: 'k',
    },
    {
      title: 'Total Products',
      color: 'success',
      total: detailsInfo.products_total ,
      gros: 80,
      prefix: '$',
      icon: 'profit',
    },
    {
      title: 'Total Boxes',
      color: 'success',
      total: detailsInfo.boxes_total,
      gros: 80,
      prefix: '$',
      icon: 'profit',
    },
    {
      title: 'Total Boxes',
      color: 'success',
      total: 156.0,
      gros: 80,
      prefix: '$',
      icon: 'profit',
    },
  ]
};



// ValuableCustomer Dara //
export const ValuableCustomerData = [
  {
    name: 'Jane Cooper',
    image: '1.jpg',
    email: 'alma.lawson@gmail.com',
  },
  {
    name: ' Cameron Willia',
    image: '2.jpg',
    email: 'tim.jennings@gmail.com',
  },
  {
    name: 'Floyd Miles',
    image: '9.jpg',
    email: 'kenzi.lawson@gmail.com',
  },
  {
    name: 'Dianne Russell',
    image: '5.jpg',
    email: 'curtis.weaver@gmail.com',
  },
  {
    name: 'Guy Hawkins',
    image: '3.jpg',
    email: 'curtis.weaver@gmail.com',
  },
];

// Recent Order Table //
export const RecentOrderTable = [
  [
    {
      image: '4.png',
      title: 'T-shirt',
      id: '#CFDE-2163',
      qty: 'X1',
      status: 'Verified',
      statusCode: 'success',
      price: 56.0,
      total: 100.0,
      iconName: '24-hour',
    },
    {
      image: '3.png',
      title: 'Pink T-shirt',
      id: '#CFDE-2780',
      qty: 'X2',
      status: 'Rejected',
      statusCode: 'danger',
      price: 156.0,
      total: 870.0,
      iconName: '24-hour',
    },
  ],
  [
    {
      image: '5.png',
      title: 'Sony',
      id: '#CFDE-2163',
      qty: 'X1',
      status: 'Rejected',
      statusCode: 'danger',
      price: 56.0,
      total: 390.0,
      iconName: '24-hour',
    },
    {
      image: '6.png',
      title: 'Samsung',
      id: '#CFDE-2780',
      qty: 'X2',
      status: 'Verified',
      statusCode: 'success',
      price: 100.0,
      total: 870.0,
      iconName: '24-hour',
    },
  ],
  [
    {
      image: '1.png',
      title: 'Sony',
      id: '#CFDE-2163',
      qty: 'X1',
      status: 'Verified',
      statusCode: 'success',
      price: 56.0,
      total: 100.0,
      iconName: '24-hour',
    },
    {
      image: '2.png',
      title: 'Sennheiser',
      id: '#CFDE-2780',
      qty: 'X2',
      status: 'Rejected',
      statusCode: 'danger',
      price: 156.0,
      total: 100.0,
      iconName: '24-hour',
    },
  ],
  [
    {
      image: '7.png',
      title: 'Chair',
      id: '#CFDE-2163',
      qty: 'X1',
      status: 'Verified',
      statusCode: 'success',
      price: 48.0,
      total: 50.0,
      iconName: '24-hour',
    },
    {
      image: '8.png',
      title: 'Office Chair',
      id: '#CFDE-2780',
      qty: 'X2',
      status: 'Rejected',
      statusCode: 'danger',
      price: 73.0,
      total: 75.0,
      iconName: '24-hour',
    },
  ],
  [
    {
      image: '9.png',
      title: 'Lamp',
      id: '#CFDE-2163',
      qty: 'X1',
      status: 'Verified',
      statusCode: 'success',
      price: 20.0,
      total: 25.0,
      iconName: '24-hour',
    },
    {
      image: '10.png',
      title: 'Bedside lamp',
      id: '#CFDE-2780',
      qty: 'X2',
      status: 'Rejected',
      statusCode: 'danger',
      price: 70.0,
      total: 88.0,
      iconName: '24-hour',
    },
  ],
];

export const TopCategoriesData = [
  {
    image: '1.png',
    title: 'Food & Drinks',
    subTitle: '12,200',
  },
  {
    image: '2.png',
    title: 'Furniture',
    subTitle: '7,510',
  },
  {
    image: '3.png',
    title: 'Grocery',
    subTitle: '15,475',
  },
  {
    image: '4.png',
    title: 'Electronics',
    subTitle: '27,840',
  },
  {
    image: '5.png',
    title: 'Toys & Games',
    subTitle: '8,788',
  },
  {
    image: '6.png',
    title: 'Desktop',
    subTitle: '10,673',
  },
  {
    image: '7.png',
    title: 'Mobile & Accessories',
    subTitle: '5,129',
  },
];

export const RecentActivityData = [
  {
    title: ' Added Bew Items',
    date: '8th March, 2022',
    subTitle: 'Quisque a consequat ante sit amet magna...',
    image: ['1', '2', '3'],
    status: 'primary',
  },
  {
    title: 'Anamika Comments this Poducts',
    date: '2nd Sep, Today',
    subTitle: 'Quisque a consequat ante sit amet magna...',
    status: 'warning',
  },
  {
    title: 'Jacksion Purchase ',
    date: '3nd Sep, 2022',
    subTitle: 'Quisque a consequat ante sit amet magna...',
    status: 'secondary',
  },
  {
    title: 'Anamika Comments this Poducts',
    date: '2nd Sep, Today',
    subTitle: 'Quisque a consequat ante sit amet magna...',
    status: 'success',
  },
];
