
import React, { Fragment,useEffect,useState , useContext,useRef} from 'react';
import { Container, Row, Col, Card, CardBody,Media } from 'reactstrap';
import { Breadcrumbs ,Btn, H4,Image,Spinner } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';

import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomizerContext from '../../_helper/Customizer';
// rsuite
import { CheckPicker,Input, Whisper, Tooltip , SelectPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// data table
import DataTable from 'react-data-table-component';
import { dummytabledata, tableColumns } from '../../Data/Table/Defaultdata';
// date  format
import dateFormat from "dateformat";
import Details_Shakes from './details';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router-dom';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const style2 = { width: 60, fontSize: 14, padding: 4 };

const BaseApi=api_Routes.shakes.view;

function User_Shakes() {
    const {id} = useParams()
    const navigate = useNavigate()
    const { layoutURL } = useContext(CustomizerContext);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [data, setData] = useState(dummytabledata);
    const [isLoading, setIsLoading] = useState(false)
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [ totalItems, setTotalItems ] = useState(0)
    const [statuss, setstatuss] = useState([]);
    const [users, setusers] = useState([])
    const [box, setbox] = useState([])
    const [open, setopen] = useState([])
    const [selectedfDate, setSelectedfDate] = useState(null)
    const [selectedtDate, setSelectedtDate] = useState(null)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('user_id');
    const permissions = JSON.parse(localStorage.getItem('permission'));
    const controllerRef = useRef(null)



    useEffect(()=>{
      if (!permissions || !permissions.includes('user_shake.get-all')) {
        navigate(`/Forbidden`)
    }else{
      get_open()
      get_box() 
      get_user()
      get_status()
      get_Shakes()
    }
    },[values_Filter,page,perPage])

     const get_user = async()=>{
       const {response, message} = await Helper.Get({
        url:api_Routes.user.view,
        hasToken:true,
    })
    if(response){
      console.log("ana");
        console.log(response.data);
        // setusers([])
        // response.data.forEach(ele=>{
        // setusers(prev => [...prev, {
        //     label:ele.name , 
        //     value: ele.id, 
        // }])
        setusers(response.data)
        // })
    }else{
        console.log(message);
    }
    }

      const get_box = async()=>{
       const {response, message} = await Helper.Get({
        url:api_Routes.category_box.view,
        hasToken:true,
    })
    if(response){
      console.log("ana");
        console.log(response.data);
        setbox([])
        response.data.forEach(ele=>{
        setbox(prev => [...prev, {
            label:ele.name, 
            value: ele.id, 
        }])
        })
    }else{
        console.log(message);
    }
    }

   const get_status = () => {
      const response = { data: [
      { name: "Pending", id: 1 },
      { name: "Opend", id: 2 },
      { name: "Delivery", id: 3 },
      { name: "Delivered", id: 4 },
      { name: "Sold", id: 5 }
    ]};
      setstatuss([]);
    response.data.forEach(ele => {
    
      setstatuss(prev => [...prev, {
        label: ele.name,
        value: ele.id
      }]);
     
    });}

       const get_open = () => {
      const response = { data: [
      { name: "All", id: "" },
      { name: "Opend", id: 1 },
      { name: "Close", id: 0 },
    ]};
      setopen([]);
    response.data.forEach(ele => {
    
      setopen(prev => [...prev, {
        label: ele.name,
        value: ele.id
      }]);
     
    });}

    const get_Shakes = async()=>{

    let url = BaseApi + '?1+1';

    if(userId){
        url = url + `&user_id=${userId}`;
    }
    if ( values_Filter.status  ) {
        url = url + `&result_type=${values_Filter.status}`;
      }
    if ( values_Filter.openn  ) {
        url = url + `&opened=${values_Filter.openn}`;
      }
        if (values_Filter.user_id ) {
        url = url + `&user_id=${values_Filter.user_id}`;
      }
        if (values_Filter.box_category ) {
        url = url + `&category_id=${values_Filter.box_category}`;
      }
       if ( values_Filter.from_date  ) {
        url = url + `&from_date=${values_Filter.from_date}`;
      }
      if ( values_Filter.to_date  ) {
        url = url + `&to_date=${values_Filter.to_date}`;
      }
      if (values_Filter.price ) {
        url = url + `&price=${values_Filter.price}`;
      }

        setIsLoading(true)
        const {response, message} = await Helper.Get({
            url:url,
            hasToken:true,
            data:{results:perPage,page:page,
              keywords:values_Filter.keywords}
        })
        if(response){
          
           setData_Table([])
          console.log(response.data);
          setTotalItems(response.meta.total)
            response.data.forEach(elem =>{   
                setData_Table(prev=> [...prev,{
                  photo: <img className='theme-text user-photo ' src={elem.user_photo} alt='User Photo' />,
                    user_name: elem.user_name,
                    phone:elem.user_phone,
                    category : elem.category_translations["en"]?.name,
                    box: elem.box_translations["en"]?.name,
                    price:elem.price,
                    date:dateFormat(elem.created_at,"dddd, mmmm dS, yyyy"),
                    result_type:elem.result_type == 1?
                    <span className={`badge badge-light-primary`} style={{
                      padding: '12px',
                      fontSize: '12px',
                      fontWeight: "bold"
                    }}>Pendding</span>:
                            (elem.result_type==2?
                            <span className={`badge badge-light-primary`}style={{
                              padding: '10px  18px',
                              fontSize: '12px',
                              fontWeight: "bold"
                            }}>Opened</span>:
                            (elem.result_type==3?
                            <span  className={`badge badge-light-primary`} style={{
                              padding: '10px 15px',
                              fontSize: '13px',
                              fontWeight: "bold"
                            }} >Delivery</span>:
                            (elem.result_type==4?
                            <span className={`badge badge-light-success`}style={{
                              padding: '10px',
                              fontSize: '13px',
                              fontWeight: "bold"
                            }}>Delivered</span>:
                            <span className={`badge badge-light-success`}style={{
                              padding: '8px 26px',
                              fontSize: '13px',
                              fontWeight: "bold"
                            }}>Sold</span>))),
                            action: (
                                <div>
                                  <span>
                                    <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>navigate(`/shakes/${elem.id}/Shake`)}}> Details </Btn>
                                  </span>
                                </div>
                              ),
                        }])
                       
                    
                })
                setCol_Table([
                  {
                    name: 'Photo',
                    selector: row => row['photo'],
                    sortable: true,
                    width:"10%",
                    center: false,
                },
                  {
                    name: 'User',
                    selector: row => row['user_name'],
                    sortable: true,
                    width:"14%",
                    center: false,
                },
                
                {
                    name: 'Category',
                    selector: row => row['category'],
                    sortable: true,
                    width:"12%",
                    center: false,
                },
                {
                    name: 'Box',
                    selector: row => row['box'],
                    sortable: true,
                    width:"12%",
                    center: false,
                },
                {
                    name: 'Price',
                    selector: row => row['price'],
                    sortable: true,
                    width:"8%",
                    center: true,
                },
                {
                    name: 'Created Date',
                    selector: row => row['date'],
                    sortable: true,
                    width:"20%",
                    center: false,
                },
                {
                    name: 'Status',
                    selector: row => row['result_type'],
                    sortable: true,
                    width:"13%",
                    center: false,
                },
                {
                    name: 'Action',
                    selector: (row) => row.action,
                    sortable: true,
                    center: true,
                    maxWidth: '16%',
                  },
                
          ])
            
               
           
            setIsLoading(false)
        }else{
            console.log(message);
        }
        }
    const handleDelete = () => {
        if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
            setToggleDelet(!toggleDelet);

            setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
            setSelectedRows('');
        }
    };
    /// Change Pages To Api
    const handleChangePage = (value)=>{
        console.log("per_pages",value);
        setPerPage(value)
    }
    const handlePage =(e)=>{
        setPage(e)
    }
    const handleChange = (key,value)=>{
        setValues(prev=>({...prev,[key]:value}))
      }

    const handleChanged = (key, value) => {
        const formattedDate = value ? new Date(value)
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ') 
        : null;  
        setValues(prev => ({ ...prev, [key]: formattedDate }));
       }

    const handleFilter = ()=>{
    setFilter_Values(values)
    }
    
    const onSearch = async (e,key)=>{
  
  if(controllerRef.current){
      controllerRef.current.abort()
  }
  controllerRef.current = new AbortController()
  const signal = controllerRef.current.signal
  const {response,message} = await Helper.Get_Abort({
      url:  api_Routes.user.view ,
      signal:signal,
      data:{keywords:e,results:20},
      hasToken:true
  })
  if(response){
         setusers(response.data)
  }

}
    return ( id==":id"?<>
    <Breadcrumbs parent="Shakes" title="List" mainTitle="Shakes Users" />
    <Container className='my-2' fluid={true}>
    <Row>
      <Col sm="12">
        <Card>
         
          <CardBody>
          <Row  >
            <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
              <Whisper trigger="focus" speaker={<Tooltip>Keywords</Tooltip>}>
                <Input onChange={(e)=>{handleChange("keywords",e)}} 
                      style={{ width: "100%" }} placeholder="Search By Name - Category - Box - Price" />
              </Whisper>
            </Col>  

                          <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                <SelectPicker placeholder="Search By Status" 
                 onChange={(e)=>{handleChange("status",e)}}
                        style={{ width: "100%" }}
                          data={statuss} 
                        //  loading={isLoading.boxes} 
                         />
              </Col>
              <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                <Input placeholder="Search By  Price" 
                onChange={(e)=>{handleChange("price",e)}}
                        style={{ width: "100%" , height:'35px' ,borderRadius : '5px', border:'1px solid #e5e7eb', paddingLeft:'11px' }}
                           />
              </Col>

             <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                <SelectPicker placeholder="Search By User Name" 
                 onChange={(e)=>{handleChange("user_id",e)}}
                        style={{ width: "100%" }}
                        data={ users.map((ele)=>({label:ele.name?ele.name:"",value:ele.id}))} 
                          onSearch={(e)=>{
                            if(e!="")
                             onSearch(e,"user_id")
                        }}
                        //  loading={isLoading.boxes} 
                         />
              </Col>


             <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={3}>
                <SelectPicker placeholder="Search By Box Category" 
                 onChange={(e)=>{handleChange("box_category",e)}}
                        style={{ width: "100%"   , marginTop:'15px' }}
                          data={box} 
                        //  loading={isLoading.boxes} 
                         />
              </Col>

             <Col className='d-flex justify-content-center p-2' sm={12} lg={4} md={4} xl={3}>
                <SelectPicker placeholder="Search By Status Box" 
                 onChange={(e)=>{handleChange("openn",e)}}
                        style={{ width: "100%"  , marginTop:'15px' }}
                          data={open} 
                        //  loading={isLoading.boxes} 
                         />
              </Col>

              <Col className='d-flex justify-content-center p-2' sm={6} lg={4} md={4} xl={3} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DemoContainer components={['DatePicker']} >
                    <DatePicker
                        placeholderText="Search By From Date"
                        selected={selectedfDate}
                        onChange={(date) => handleChanged("from_date", date)}
                        dateFormat="yyyy-M-d"
                        onSelect={(date) => setSelectedfDate(date)}
                       sx={{height:'2px'}}
                    />
                </DemoContainer>
               </LocalizationProvider>

               </Col>


               <Col className='d-flex justify-content-center p-2' sm={6} lg={4} md={4} xl={3} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
             <DatePicker
                placeholderText="Search By To Date"
                selected={selectedtDate}
                onChange={(date) => handleChanged("to_date", date)}
                dateFormat="yyyy-M-d"
                onSelect={(date) => setSelectedtDate(date)}
              />
              </DemoContainer>
               </LocalizationProvider>
                </Col>
           
            <Col className='d-flex align-items-center justify-content-end p-2' sm={12} xs={12} lg={12} md={12} >
              <div onClick={()=>{handleFilter()}}>
                <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
              </div>
            </Col>
          </Row>
          

          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
            <Fragment>
                {(selectedRows.length !== 0) &&
                    <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                        <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
                        <Btn attrBtn={{ color: 'danger', onClick: () => handleDelete() }}>Delete</Btn>
                    </div>
                }
                <DataTable
                    // data={data}
                    // columns={tableColumns}
                    data={data_table}
                    columns={col_table}
                    striped={true}
                    center={true}
                    progressPending={isLoading}
                    progressComponent={<div className="loader-box">
                                        <Spinner attrSpinner={{ className: "loader-26" }} />
                                    </div>}
                    pagination
                    paginationServer
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[10,20,30,50,100]}
                    responsive
                    paginationTotalRows={totalItems}
                    paginationServerOptions={()=>{}}
                    onChangePage={(e)=>{handlePage(e)}}
                    onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                    clearSelectedRows={toggleDelet}
                />
            </Fragment>

            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </>:<Details_Shakes id={id}/>);
}

export default User_Shakes;