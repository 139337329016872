import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
//files
import Files from 'react-files';
//Form Component
import {  useNavigate } from 'react-router-dom';

import { SelectPicker , Loader} from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
// Editor Wyswigy 
import {  EditorState,ContentState ,convertFromHTML } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useParams } from 'react-router';
import { FaArrowLeft } from 'react-icons/fa';


const Add_Balance= () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [brand, setBrand] = useState({})
    const [langauges, setLangauges] = useState([])
    const [isLoadingDetailes,setIsLoadingDetailes] = useState(true)
    const [isLoadingSelect,setIsLoadingSelect] = useState(true)
    const [formData, setFormData]  = useState({
        user_id:"",
        amount:""
    })
    const [dataToEditorState, setDataToEditorState] = useState({});
    const permissions = JSON.parse(localStorage.getItem('permission'));

    useEffect(()=>{
        if (!permissions || !permissions.includes('balance.add')) {
            navigate(`/Forbidden`)
        } else {
            get_users()
        }
            
        
        if(localStorage.hasOwnProperty("lang")){
            let array = JSON.parse(localStorage.getItem("lang"))
            setLangauges(array)
            
           }
    },[])
    const get_details = async ()=>{
        setIsLoadingDetailes(true)
        const {response,message} = await Helper.Get({
            url : api_Routes.brand.getOne(id),
            hasToken:true,
            data:{detailed:1}

        })
        if(response){
            const details_languages = {}
            setFormData(()=>({
                name:response.data.name,
            }))
            response.data.detailed_translations.forEach(ele=>{
                let array= []
                let title_lang =""
                ele.forEach(dt=>{
                    title_lang = dt.language_id
                    array.push({[dt.text_type]:dt.value,
                                id:dt.id})
                    setBrand(prev=>({...prev,[title_lang]:array}))
                    details_languages[title_lang]=array
                })
            })
            setDataToEditorState(prev=>{
                Object.keys(details_languages).map((e)=>{
                  prev[e]= [{
                    name:details_languages[e][0].name,
                    id:details_languages[e][0].id
                  },
                  {
                    description:EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                        convertFromHTML(details_languages[e][1].description)
                        )),
                    id:details_languages[e][1].id}
                ]
                  return prev[e]
                })
                return prev
              })
            
              setIsLoadingDetailes(false)
        }else{
            if(typeof message =="string"){
                toast.error(message)
            }else{
                Object.keys(message).forEach(msg=>{
                    toast.error(`${message[msg]}:${msg}`)
                })
            }
        }
    }
    const handleChange = (key,value) =>{
        setFormData(prev=>({...prev,[key]:value}))
       
        
       
    }
    const get_users = async ()=>{
        setIsLoadingSelect(true)
        const {response,message} = await Helper.Get({
            url : api_Routes.user.view,
            hasToken:true,
            data:{
                results:1000
                }

        })
        if(response){
            
            response.data.forEach(ele=>{
                setUsers(prev=>[...prev,{
                    label:ele.name,
                    value:ele.id
                }])
            })
              setIsLoadingSelect(false)
        }else{
            if(typeof message =="string"){
                toast.error(message)
            }else{
                Object.keys(message).forEach(msg=>{
                    toast.error(`${message[msg]}:${msg}`)
                })
            }
        }
    }
    const handleSubmit = async() =>{
        console.log(formData);
        setIsLoading(true)
        var form_data = new FormData();
        form_data.append("move_type","1")
        form_data.append("user_id",formData.user_id)
        form_data.append("balance_type","5")
        form_data.append("active","1")
        form_data.append("amount",formData.amount)
        form_data.append("_method","PUT")
          const {response, message} = await Helper.Post({
            url : id==":id"?api_Routes.balance.store:api_Routes.balance.getOne(id),
            data :form_data,
            hasToken :true
          })
          if(response){

            toast.success(id==":id"?"Successfully Add Balance !!":"Successfully Edit Balace !!")
            navigate(`/balance-list/:id/order`)
            setIsLoading(false)
          }else{
            if(typeof(message) === "string"){
              toast.error(message)
            }else{
              Object.keys(message).map((i)=>{
                toast.error(`${i} : ${message[i][0]}`)
              })}
            setIsLoading(false)
          }
    }
    

    return (
        <Fragment>
            <Breadcrumbs mainTitle="Charge Balance" parent="Balance" title="Charge" />
            {/* Basic Info */}
            <Container  className='mb-3' fluid={true}>
           
                <Row>
                    <Col sm="12">
                        <Card>
                            <Row>
                                <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                                    <HeaderCard title="Charge" />
                                </Col>

                                {( permissions.includes('balance.edit')) && ( 
                                <Col className='d-flex justify-content-center align-items-center ' lg={2} xl={2}  md={2} sm={2} xs={2}>
                                        <Btn attrBtn={{  className: "h-50", color: 'danger', type: "button", onClick: () => !isLoading && handleSubmit() }} 
                                        disabled={isLoading}
                                        >
                                        {isLoading ? <Loader /> : <i className='fa fa-save'></i>}
                                        </Btn>
                                </Col>
                                )}
                            </Row>
                            
                            <CardBody className="add-post">

                                {/* Basic Info */}
                                <Form className="row needs-validation">
                                        <Col sm="12">
                                            
                                        <FormGroup>
                                            <Row>
                                                <Col xs={6} sm={6} lg={1} md={1} >
                                                    <Label style={{marginTop:"5px"}} for="validationCustom01">User :</Label>
                                                </Col>
                                                <Col xs={6} sm={6} lg={5} md={5} >
                                                    <SelectPicker style={{width:"80%"}} value={formData.user_id} onChange={(e)=>{handleChange("user_id",e,null)}}   placeholder="user to"  data={users} isLoading={isLoadingSelect} />
                                                </Col>
                                                <Col xs={6} sm={6} lg={1} md={1} >
                                                    <Label style={{marginTop:"5px"}} for="validationCustom01">Amount:</Label>
                                                </Col>
                                                <Col xs={6} sm={6} lg={5} md={5} >
                                                    <Input style={{width:"80%"}} value={formData.amount} onChange={(e)=>{handleChange("amount",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="amount" required="" />
                                                </Col>
                                            </Row>
                                        </FormGroup>

                                        <FormGroup>
                                            <Row>
                                            </Row>
                                            
                                            
                                            
                                        </FormGroup>
                                    
                                        
                                       
                                        </Col>
                                    </Form>
                                    
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
            
        </Fragment>
    );
};

export default Add_Balance;