import  { useEffect, useState } from 'react';
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';

export const Box = () => {
  const [detailsInfo, setDetailsInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
   
    const { response, message } = await Helper.Get({
      url: api_Routes.statistics.view,
      hasToken: true,
    });
    if (response) {
      setDetailsInfo(response.data);
      setIsLoading(false);
    } else {
      console.log(message);
    }
  };

  return {
    series: [
      {
        name: "Order",
        type: "area",
        data: detailsInfo.series,
      },
      
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          color: "#000",
          opacity: 0.08,
        },
      },
      stroke: {
        width: [2, 2, 2],
        curve: "smooth",
      },
      grid: {
        show: true,
        borderColor: "var(--chart-border)",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 20,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: [ "#54BA4A"],
      // ,"#7064F5", "#54BA4A",
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      labels: detailsInfo.options?.xaxis?.categories || [],
      markers: {
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 2,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 2,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 2,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 5,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 5,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 9,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 9,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 9,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
        ],
        hover: {
          size: 5,
          sizeOffset: 0,
        },
      },
      xaxis: {
        type: "category",
        tickAmount: 4,
        tickPlacement: "between",
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          color: "var(--chart-border)",
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      yaxis: {
        min: 0,
        tickAmount: 6,
        tickPlacement: "between",
      },
      tooltip: {
        shared: false,
        intersect: false,
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 350,
            },
          },
        },
      ],
    },
  };
};
export const Box0 = () => {
  const [detailsInfo0, setDetailsInfo0] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
   
    const { response, message } = await Helper.Get({
      url: api_Routes.statistics.view0,
      hasToken: true,
    });
    if (response) {
      setDetailsInfo0(response.data);
      setIsLoading(false);
    } else {
      console.log(message);
    }
  };

  return {
    series: [
      {
        name: "Order",
        type: "area",
        data: detailsInfo0.series,
      },
      
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          color: "#000",
          opacity: 0.08,
        },
      },
      stroke: {
        width: [2, 2, 2],
        curve: "smooth",
      },
      grid: {
        show: true,
        borderColor: "var(--chart-border)",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 20,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: [ "#54BA4A"],
      // ,"#7064F5", "#54BA4A",
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      labels:  ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
      markers: {
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 2,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 2,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 2,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 5,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 5,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 9,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 9,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 9,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
        ],
        hover: {
          size: 5,
          sizeOffset: 0,
        },
      },
      xaxis: {
        type: "category",
        tickAmount: 4,
        tickPlacement: "between",
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          color: "var(--chart-border)",
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      yaxis: {
        min: 0,
        tickAmount: 6,
        tickPlacement: "between",
      },
      tooltip: {
        shared: false,
        intersect: false,
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 350,
            },
          },
        },
      ],
    },
  };
};
export const Box1 = () => {
  const [detailsInfo1, setDetailsInfo1] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
   
    const { response, message } = await Helper.Get({
      url: api_Routes.statistics.view2,
      hasToken: true,
    });
    if (response) {
      setDetailsInfo1(response.data);
      setIsLoading(false);
    } else {
      console.log(message);
    }
  };

  return {
    series: [
      {
        name: "Vendor",
        type: "area",
        data: detailsInfo1.series,
      },
      
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          color: "#000",
          opacity: 0.08,
        },
      },
      stroke: {
        width: [2, 2, 2],
        curve: "smooth",
      },
      grid: {
        show: true,
        borderColor: "var(--chart-border)",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 20,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: [ "#7064F5"],
      // ,"#7064F5", "#54BA4A",
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      labels: detailsInfo1.options?.xaxis?.categories || [],
      markers: {
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 2,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 2,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 2,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 5,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 5,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 9,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 9,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 9,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
        ],
        hover: {
          size: 5,
          sizeOffset: 0,
        },
      },
      xaxis: {
        type: "category",
        tickAmount: 4,
        tickPlacement: "between",
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          color: "var(--chart-border)",
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      yaxis: {
        min: 0,
        tickAmount: 6,
        tickPlacement: "between",
      },
      tooltip: {
        shared: false,
        intersect: false,
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 350,
            },
          },
        },
      ],
    },
  };
};
export const Box2 = () => {
  const [detailsInfo2, setDetailsInfo2] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
   
    const { response, message } = await Helper.Get({
      url: api_Routes.statistics.view3,
      hasToken: true,
    });
    if (response) {
      setDetailsInfo2(response.data);
      setIsLoading(false);
    } else {
      console.log(message);
    }
  };

  return {
    series: [
      {
        name: "Vendor",
        type: "area",
        data: detailsInfo2.series,
      },
      
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          color: "#000",
          opacity: 0.08,
        },
      },
      stroke: {
        width: [2, 2, 2],
        curve: "smooth",
      },
      grid: {
        show: true,
        borderColor: "var(--chart-border)",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 20,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: [ "#7064F5"],
      // ,"#7064F5", "#54BA4A",
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
       markers: {
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 2,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 2,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 2,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 5,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 5,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 9,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 9,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 9,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
        ],
        hover: {
          size: 5,
          sizeOffset: 0,
        },
      },
      xaxis: {
        type: "category",
        tickAmount: 12,
        tickPlacement: "between",
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          color: "var(--chart-border)",
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      yaxis: {
        min: 0,
        tickAmount: 6,
        tickPlacement: "between",
      },
      tooltip: {
        shared: false,
        intersect: false,
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 350,
            },
          },
        },
      ],
    },
  };
};
export const Box3 = () => {
  const [detailsInfo3, setDetailsInfo3] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
   
    const { response, message } = await Helper.Get({
      url: api_Routes.statistics.view4,
      hasToken: true,
    });
    if (response) {
      setDetailsInfo3(response.data);
      setIsLoading(false);
    } else {
      console.log(message);
    }
  };

  return {
    series: [
      {
        name: "Vendor",
        type: "area",
        data: detailsInfo3.series,
      },
      
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          color: "#000",
          opacity: 0.08,
        },
      },
      stroke: {
        width: [2, 2, 2],
        curve: "smooth",
      },
      grid: {
        show: true,
        borderColor: "var(--chart-border)",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 20,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: [ "#FF3364"],
      // ,"#7064F5", "#54BA4A",
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      labels: detailsInfo3.options?.xaxis?.categories || [],
      markers: {
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 2,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 2,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 2,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 5,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 5,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 9,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 9,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 9,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
        ],
        hover: {
          size: 5,
          sizeOffset: 0,
        },
      },
      xaxis: {
        type: "category",
        tickAmount: 4,
        tickPlacement: "between",
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          color: "var(--chart-border)",
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      yaxis: {
        min: 0,
        tickAmount: 6,
        tickPlacement: "between",
      },
      tooltip: {
        shared: false,
        intersect: false,
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 350,
            },
          },
        },
      ],
    },
  };
};
export const Box4 = () => {
  const [detailsInfo4, setDetailsInfo4] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
   
    const { response, message } = await Helper.Get({
      url: api_Routes.statistics.view5,
      hasToken: true,
    });
    if (response) {
      setDetailsInfo4(response.data);
      setIsLoading(false);
    } else {
      console.log(message);
    }
  };

  return {
    series: [
      {
        name: "Vendor",
        type: "area",
        data: detailsInfo4.series,
      },
      
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          color: "#000",
          opacity: 0.08,
        },
      },
      stroke: {
        width: [2, 2, 2],
        curve: "smooth",
      },
      grid: {
        show: true,
        borderColor: "var(--chart-border)",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 20,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: [ "#FF3364"],
      // ,"#7064F5", "#54BA4A",
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
        markers: {
        discrete: [
          {
            seriesIndex: 9,
            dataPointIndex: 2,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 7,
            dataPointIndex: 2,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 2,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 5,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 5,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 9,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 9,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 9,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
        ],
        hover: {
          size: 5,
          sizeOffset: 0,
        },
      },
      xaxis: {
        type: "category",
        tickAmount: 12,
        tickPlacement: "between",
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          color: "var(--chart-border)",
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      yaxis: {
        min: 0,
        tickAmount: 6,
        tickPlacement: "between",
      },
      tooltip: {
        shared: false,
        intersect: false,
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 350,
            },
          },
        },
      ],
    },
  };
};
export const Box5 = () => {
  const [detailsInfo5, setDetailsInfo5] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
   
    const { response, message } = await Helper.Get({
      url: api_Routes.statistics.view6,
      hasToken: true,
    });
    if (response) {
      console.log(response.data)
      setDetailsInfo5(response.data);
      console.log(detailsInfo5)
      setIsLoading(false);
    } else {
      console.log(message);
    }
  };

  return {
    series:detailsInfo5.series,
    options: {
      labels: ["Bronze Box",
      "Silver Box",
      "Golden Box",
      "Platinum Box"],
      chart: {
        type: "donut",
        height: 300,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        fontSize: "14px",
        fontFamily: "Rubik, sans-serif",
        fontWeight: 500,
        labels: {
          colors: ["var(--chart-text-color)"],
        },
        markers: {
          width: 6,
          height: 6,
        },
        itemMargin: {
          horizontal: 7,
          vertical: 0,
        },
      },
      stroke: {
        width: 10,
        colors: ["var(--light2)"],
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "83%",
            labels: {
              show: true,
              name: {
                offsetY: 4,
              },
              total: {
                show: true,
                fontSize: "20px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: 500,
                label: "Shake  ",
              },
            },
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
          },
        },
      },
      colors: ["#54BA4A", "var(--theme-default)", "#FFA941","#4285F4",],
      responsive: [
        {
          breakpoint: 1630,
          options: {
            chart: {
              height: 360,
            },
          },
        },
        {
          breakpoint: 1584,
          options: {
            chart: {
              height: 400,
            },
          },
        },
        {
          breakpoint: 1473,
          options: {
            chart: {
              height: 250,
            },
          },
        },
        {
          breakpoint: 1425,
          options: {
            chart: {
              height: 270,
            },
          },
        },
        {
          breakpoint: 1400,
          options: {
            chart: {
              height: 320,
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 250,
            },
          },
        },
      ],
    },
  };
};
export const Box6 = () => {
  const [detailsInfo6, setDetailsInfo6] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [apiResponseData, setapiResponseData] = useState([]);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
   
    const { response, message } = await Helper.Get({
      url: api_Routes.statistics.view7,
      hasToken: true,
    });
    if (response) {
      setDetailsInfo6(response.data);
      setapiResponseData(response.data.series)

      setIsLoading(false);
    } else {
      console.log(message);
    }
  };



  return {
    series: [
      {
        name: "Bronze Box",
        type: "area",
        data: apiResponseData.find(item => item.name === "Bronze Box")?.data
      },
      {
        name: "Silver Box",
        type: "area",
        data: apiResponseData.find(item => item.name === "Silver Box")?.data
      },
      {
        name: "Golden Box",
        type: "area",
        data: apiResponseData.find(item => item.name === "Golden Box")?.data
      },
      {
        name: "Platinum Box",
        type: "area",
        data: apiResponseData.find(item => item.name === "Platinum Box")?.data
      }
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          color: "#000",
          opacity: 0.08,
        },
      },
      stroke: {
        width: [2, 2, 2],
        curve: "smooth",
      },
      grid: {
        show: true,
        borderColor: "var(--chart-border)",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 20,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: [ "#54BA4A","#7064F5", "#FF5733","#AB47BC"],
      // ,"#7064F5", "#54BA4A",
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
      markers: {
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 2,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 2,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 2,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 5,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 5,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 9,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 9,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 9,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
        ],
        hover: {
          size: 5,
          sizeOffset: 0,
        },
      },
      xaxis: {
        type: "category",
        tickAmount: 4,
        tickPlacement: "between",
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          color: "var(--chart-border)",
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      yaxis: {
        min: 0,
        tickAmount: 6,
        tickPlacement: "between",
      },
      tooltip: {
        shared: false,
        intersect: false,
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 350,
            },
          },
        },
      ],
    },
  };
};
export const Box7 = () => {
  const [detailsInfo6, setDetailsInfo7] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [apiResponseData1, setapiResponseData1] = useState([]);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
   
    const { response, message } = await Helper.Get({
      url: api_Routes.statistics.view8,
      hasToken: true,
    });
    if (response) {
      setDetailsInfo7(response.data);
      setapiResponseData1(response.data.series)

      setIsLoading(false);
    } else {
      console.log(message);
    }
  };



  return {
    series: [
      {
        name: "Pending",
        type: "area",
        data: apiResponseData1.find(item => item.name === "Pending")?.data
      },
      {
        name: "Delivery",
        type: "area",
        data: apiResponseData1.find(item => item.name === "Delivery")?.data
      },
      {
        name: "Delivered",
        type: "area",
        data: apiResponseData1.find(item => item.name === "Delivered")?.data
      },
      {
        name: "Rejected",
        type: "area",
        data: apiResponseData1.find(item => item.name === "Rejected")?.data
      },
      {
        name: "Canceled",
        type: "area",
        data: apiResponseData1.find(item => item.name === "Canceled")?.data
      },
      {
        name: "Sold",
        type: "area",
        data: apiResponseData1.find(item => item.name === "Sold")?.data
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          color: "#000",
          opacity: 0.08,
        },
      },
      stroke: {
        width: [2, 2, 2],
        curve: "smooth",
      },
      grid: {
        show: true,
        borderColor: "var(--chart-border)",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 20,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      colors: [ "#54BA4A","#7064F5", "#FF5733","#AB47BC","#F9A825","#0077FF"],
      // ,"#7064F5", "#54BA4A",
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
      markers: {
        discrete: [
          {
            seriesIndex: 0,
            dataPointIndex: 2,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 2,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 2,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 5,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 5,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 5,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 0,
            dataPointIndex: 9,
            fillColor: "#7064F5",
            strokeColor: "var(--white)",
            size: 5,
            sizeOffset: 3,
          },
          {
            seriesIndex: 1,
            dataPointIndex: 9,
            fillColor: "#54BA4A",
            strokeColor: "var(--white)",
            size: 5,
          },
          {
            seriesIndex: 2,
            dataPointIndex: 9,
            fillColor: "#FF3364",
            strokeColor: "var(--white)",
            size: 5,
          },
        ],
        hover: {
          size: 5,
          sizeOffset: 0,
        },
      },
      xaxis: {
        type: "category",
        tickAmount: 4,
        tickPlacement: "between",
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          color: "var(--chart-border)",
        },
        axisTicks: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      yaxis: {
        min: 0,
        tickAmount: 6,
        tickPlacement: "between",
      },
      tooltip: {
        shared: false,
        intersect: false,
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 350,
            },
          },
        },
      ],
    },
  };
};
export const Box8 = () => {
  const [detailsInfo8, setDetailsInfo8] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
   
    const { response, message } = await Helper.Get({
      url: api_Routes.statistics.view9,
      hasToken: true,
    });
    if (response) {
      setDetailsInfo8(response.data);
      setIsLoading(false);
    } else {
      console.log(message);
    }
  };

  return {
    series:detailsInfo8.series,
    options: {
      labels: [ "Pending",
      "Delivery",
      "Delivered",
      "Rejected",
      "Canceled",
      "Sold"],
      chart: {
        type: "donut",
        height: 300,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        fontSize: "14px",
        fontFamily: "Rubik, sans-serif",
        fontWeight: 500,
        labels: {
          colors: ["var(--chart-text-color)"],
        },
        markers: {
          width: 6,
          height: 6,
        },
        itemMargin: {
          horizontal: 7,
          vertical: 0,
        },
      },
      stroke: {
        width: 10,
        colors: ["var(--light2)"],
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "83%",
            labels: {
              show: true,
              name: {
                offsetY: 4,
              },
              total: {
                show: true,
                fontSize: "20px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: 500,
                label: "Order  ",
              },
            },
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
          },
        },
      },
      colors: ["#54BA4A","#7064F5", "#FF5733","#AB47BC","#F9A825","#FF4081"],
      responsive: [
        {
          breakpoint: 1630,
          options: {
            chart: {
              height: 360,
            },
          },
        },
        {
          breakpoint: 1584,
          options: {
            chart: {
              height: 400,
            },
          },
        },
        {
          breakpoint: 1473,
          options: {
            chart: {
              height: 250,
            },
          },
        },
        {
          breakpoint: 1425,
          options: {
            chart: {
              height: 270,
            },
          },
        },
        {
          breakpoint: 1400,
          options: {
            chart: {
              height: 320,
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 250,
            },
          },
        },
      ],
    },
  };
};
export const Box9 = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [apiResponseData2, setapiResponseData2] = useState([]);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
   
    const { response, message } = await Helper.Get({
      url: api_Routes.financials.view,
      hasToken: true,
    });
    if (response) {
      setapiResponseData2(response.data)
      setIsLoading(false);
    } else {
      console.log(message);
    }
  };

  return {
    series:[ apiResponseData2.find(item => item.name === "Delivery Cost")?.amount ,
             apiResponseData2.find(item => item.name === "Transfer Balance")?.amount ,
             apiResponseData2.find(item => item.name === "Payment")?.amount ,
             apiResponseData2.find(item => item.name === "Shake Fee")?.amount,
             apiResponseData2.find(item => item.name === "Redeem")?.amount ,
             apiResponseData2.find(item => item.name === "Charge")?.amount
            ] ,
    options: {
      labels: [ "Delivery Cost",
      "Transfer Balance",
      "Payment",
      "Shake Fee",
      "Redeem",
      "Charge"],
      chart: {
        type: "donut",
        height: 300,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        fontSize: "14px",
        fontFamily: "Rubik, sans-serif",
        fontWeight: 500,
        labels: {
          colors: ["var(--chart-text-color)"],
        },
        markers: {
          width: 6,
          height: 6,
        },
        itemMargin: {
          horizontal: 7,
          vertical: 0,
        },
      },
      stroke: {
        width: 10,
        colors: ["var(--light2)"],
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "83%",
            labels: {
              show: true,
              name: {
                offsetY: 4,
              },
              total: {
                show: true,
                fontSize: "20px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: 500,
                label: "Financials ",
              },
            },
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
          },
        },
      },
      colors: ["#54BA4A","#7064F5", "#FF5733","#AB47BC","#F9A825","#FF4081"],
      responsive: [
        {
          breakpoint: 1630,
          options: {
            chart: {
              height: 360,
            },
          },
        },
        {
          breakpoint: 1584,
          options: {
            chart: {
              height: 400,
            },
          },
        },
        {
          breakpoint: 1473,
          options: {
            chart: {
              height: 250,
            },
          },
        },
        {
          breakpoint: 1425,
          options: {
            chart: {
              height: 270,
            },
          },
        },
        {
          breakpoint: 1400,
          options: {
            chart: {
              height: 320,
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 250,
            },
          },
        },
      ],
    },
  };
};

  // Over Chart Bar Option
  
  export const User = {
    series: [350, 500],
    options: {
      labels: ["Register", "Login"],
      chart: {
        type: "donut",
        height: 300,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        fontSize: "14px",
        fontFamily: "Rubik, sans-serif",
        fontWeight: 500,
        labels: {
          colors: ["var(--chart-text-color)"],
        },
        markers: {
          width: 6,
          height: 6,
        },
        itemMargin: {
          horizontal: 7,
          vertical: 0,
        },
      },
      stroke: {
        width: 10,
        colors: ["var(--light2)"],
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "83%",
            labels: {
              show: true,
              name: {
                offsetY: 4,
              },
              total: {
                show: true,
                fontSize: "20px",
                fontFamily: "Rubik, sans-serif",
                fontWeight: 500,
                label: "Online : 120",
                formatter: () => "Total User",
              },
            },
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
          },
        },
      },
      colors: ["#54BA4A", "var(--theme-default)", "#FFA941"],
      responsive: [
        {
          breakpoint: 1630,
          options: {
            chart: {
              height: 360,
            },
          },
        },
        {
          breakpoint: 1584,
          options: {
            chart: {
              height: 400,
            },
          },
        },
        {
          breakpoint: 1473,
          options: {
            chart: {
              height: 250,
            },
          },
        },
        {
          breakpoint: 1425,
          options: {
            chart: {
              height: 270,
            },
          },
        },
        {
          breakpoint: 1400,
          options: {
            chart: {
              height: 320,
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 250,
            },
          },
        },
      ],
    },
  };

  export const Product = {
    series: [
      {
        name: "Brand X",
        data: [18, 10, 65, 18, 28, 10],
      },
      {
        name: "Brand Y",
        data: [25, 50, 30, 30, 25, 45],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 270,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 6,
        colors: ["transparent"],
      },
      grid: {
        show: true,
        borderColor: "var(--chart-border)",
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      colors: ["#FFA941", "var(--theme-default)"],
      xaxis: {
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        tickAmount: 4,
        tickPlacement: "between",
        labels: {
          style: {
            fontFamily: "Rubik, sans-serif",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 5,
        tickPlacement: "between",
        labels: {
          style: {
            fontFamily: "Rubik, sans-serif",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        fontFamily: "Rubik, sans-serif",
        fontSize: "14px",
        fontWeight: 500,
        labels: {
          colors: "var(--chart-text-color)",
        },
        markers: {
          width: 6,
          height: 6,
          radius: 12,
        },
        itemMargin: {
          horizontal: 10,
        },
      },
      responsive: [
        {
          breakpoint: 1366,
          options: {
            plotOptions: {
              bar: {
                columnWidth: "80%",
              },
            },
            grid: {
              padding: {
                right: 0,
              },
            },
          },
        },
        {
          breakpoint: 992,
          options: {
            plotOptions: {
              bar: {
                columnWidth: "70%",
              },
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            plotOptions: {
              bar: {
                columnWidth: "60%",
              },
            },
            grid: {
              padding: {
                right: 5,
              },
            },
          },
        },
      ],
    },
  };
