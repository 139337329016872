import React, { Fragment,useEffect,useState } from 'react';
import { Container, Row, Col, Card, CardBody,Media } from 'reactstrap';
import { Breadcrumbs ,Btn,Image,Spinner } from '../../AbstractElements';
import { useNavigate, useParams } from 'react-router-dom';
//api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// rsuite
import { Input, Whisper, Tooltip,SelectPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
// data table
import DataTable from 'react-data-table-component';
// date  format

import 'react-datepicker/dist/react-datepicker.css';
import EditPhoto from './edit_photo';


import { toast } from 'react-toastify';
import CommonModal from 'Components/Modal';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const BaseApi=api_Routes.photos.view;
const style2 = { width: 60, fontSize: 14, padding: 4 };
const style3 = { width: 60, fontSize: 14, padding: 4, backgroundColor: '#FF8C00	' , marginLeft : 10 };


function Photos(){
    const {id} = useParams()
    const navigate = useNavigate()
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleDelet, setToggleDelet] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [data_table, setData_Table] = useState([])
    const [col_table, setCol_Table] = useState([])
    const [ perPage, setPerPage ] = useState(10)
    const [ page, setPage ] = useState(1)
    const [values,setValues] = useState({})
    const [values_Filter, setFilter_Values] = useState({})
    const [ totalItems, setTotalItems ] = useState(0)
    const [statuss, setstatuss] = useState([
        { label: "Category", id: "category" , value: "App\\Models\\Category","api" : api_Routes.category.view},
        { label: "Page", id: "page" , value: "App\\Models\\Page","api" : api_Routes.page.view},
        { label: "Product", id: "product" , value: "App\\Models\\Product","api" : api_Routes.product.view},
        { label: "Brand", id: "brand" , value: "App\\Models\\Brand","api" : api_Routes.brand.view},
     ]);
    const [records, setRecords] = useState([])
    const [selectedType, setSelectedType] = useState(null);
    const [selectedPhotoUrl, setSelectedPhotoUrl] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [ ty,setty ] = useState("");
    const permissions = JSON.parse(localStorage.getItem('permission'));
    const [item, setItem] = useState("");
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [refreshDelete, setRefreshDelete] = useState(false);



    useEffect(()=>{
         if (!permissions || !permissions.includes('photo.get-all')) {
            navigate(`/Forbidden`)
        } else {
             get_photo()
       }
    
    
  },[values_Filter,page,perPage,refreshDelete])


     const get_photo = async()=>{
                
        let url = BaseApi + '?1=1';

        if (selectedType) {
            url = url + `&type=${selectedType}`
            // + `&type_id=${}`;
        }

        setIsLoading(true)
        const {response, message} = await Helper.Get({
          
        url: url,
          hasToken:true,
            data:{results:perPage,
                  page:page,
                  keywords:values_Filter.keywords, }
        })

        if(response){

            setData_Table([])
              setTotalItems(response.meta.total)
  
              response.data.forEach(elem => {
                const typeName = elem.type.split('\\').pop();
                  setData_Table(prev=>[...prev,{
                    id:<Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-40 me-3', src: `${elem.url}`, alt:`${elem.id}` }} />
                    </Media>,
                    Type: typeName,
  
                               action: (
                                  <div>
                                  {permissions && permissions.includes('photo.edit') && (
                                    <span>
                                      <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>navigate(`/photo-list/${elem.id}/order`)}}>
                                         Edit
                                      </Btn>
                                    </span>
                                  )}

                                    <span>
                                      <Btn attrBtn={{ style: style3, className : ' info outline Info Button ' , type: 'button' ,onClick:()=>handlePreview(elem.url)}}>
                                          Preview
                                      </Btn>
                                    </span>

                                    {permissions.includes('role.delete') && (
                               <span>
                                    <Btn  attrBtn={{ style: style2, color: 'danger', className: 'btn btn-xs m-2', type: 'button' ,onClick:()=>{
                                    toggle()
                                    setItem(elem.id)
                                    }}}>Delete </Btn>
                                </span>
                                  )}

                                  </div>
                                ),
                          }])
                  
              });
              setCol_Table([{
                      name: 'Photo',
                      selector: row => row['id'],
                      sortable: true,
                      width:"25%",
                      center: false,
                  },
                  {
                    name: 'Type',
                    selector: row => row['Type'],
                    sortable: true,
                    width:"45%",
                    center: false,
                },
                  {
                      name: 'Action',
                      selector: (row) => row.action,
                      sortable: true,
                      center: true,
                      width: '25%',
                    },
                  
            ])
            setIsLoading(false)
          }else{
            
              console.log(message);
          }

     }

    
  
        const fill_records_value = async(api)=>{
            const {response, message} = await Helper.Get({
              
            url: api,
                hasToken:true,
                data:{}
            })
            if(response){
              setRecords([])
                
                response.data.forEach(ele=>{
                    setRecords(prev => [...prev, {
                        label:ele.translations["en"].name, // Convert user_id to integer
                        value: ele.id   // Convert user_id to integer
                    }])
                })
            }else{
                
                console.log(message);
            }

        }
        const handleChangePage = (value)=>{
            console.log("per_pages",value);
            setPerPage(value)
        }
        const handlePage =(e)=>{
            setPage(e)
        }


        const handleChange = (key, value) => {
         
            setValues(prev => ({ ...prev, [key]: value }));
        
            if (key === 'status') {
                const selectedStatus = statuss.find(status => status.value === value);
                if (selectedStatus) {
                    setSelectedType(selectedStatus.value);
                    fill_records_value(selectedStatus.api);
                }
            }
            if(key === 'record'){
              setty()

            }
            
        }

        const handleDelete = async() => {
          let path,ids={};
          if(item.length != ""){
           
              path=api_Routes.photos.update(item)
          }else{
              selectedRows.forEach((ele,i)=>{
                ids[`ids[${i}]`] = ele
              })
              path=api_Routes.photos.bulk
      
          }
      
          const {response, message} = await Helper.Delete({
            url:path,
            hasToken:true,
            data:selectedRows.length>0?ids:{}
          })
          if(response){
            toast.success(message)
            setItem("")
            setSelectedRows([])
            setRefreshDelete(!refreshDelete)
          }else{
            toast.error(message)
            setItem("")
            setSelectedRows([])
          }
          // if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.map(r => r.title)}?`)) {
          //     setToggleDelet(!toggleDelet);
      
          //     setData(data.filter((item) => selectedRows.filter((elem) => elem.id === item.id).length > 0 ? false : true));
          //     setSelectedRows('');
          // }
        };
        
        
        const handleFilter = ()=>{
        setFilter_Values(values)
        }

        const handlePreview = (photoUrl) => {
          setSelectedPhotoUrl(photoUrl);
          setIsOpen(true);
      };

        return ( id==":id" ? <>
        
             <CommonModal isOpen={modal} title="Confirm Delete" handleConfirm={handleDelete} toggler={toggle} >
              Are you sure you want to delete this photo ?
            </CommonModal> 
              <Breadcrumbs parent="photo" title="List" mainTitle="Media" />
              
              <Container className='my-2' fluid={true}>
              <Row>
                <Col sm="12">
                  <Card>
                   
                    <CardBody>
                    <Row  >
                      <Col className='d-flex justify-content-center p-2' sm={12} lg={11} md={11} xl={11}>
                        <Whisper trigger="focus" speaker={<Tooltip>Keywords</Tooltip>}>
                          <Input onChange={(e)=>{handleChange("keywords",e)}} 
                                placeholder="Search By Type" />
                        </Whisper>
                      </Col>  
                     
                      
                      {/* <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                            <SelectPicker placeholder="Search By Type" 
                            onChange={(e)=>{handleChange("status",e)}}
                            style={{ width: "100%" }}
                            data={statuss} 
                            //  loading={isLoading.boxes} 
                       />
                      </Col> */}

                      <Col className='d-flex align-items-center justify-content-end p-2' sm={12} xs={12} lg={1} md={1} xl={1} >
                        <div onClick={()=>{handleFilter()}}>
                          <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                        </div>
                      </Col>
                      {isOpen && (
                         <Lightbox 
                         mainSrc={selectedPhotoUrl}
                         onCloseRequest={() => setIsOpen(false)}
                         >
                         </Lightbox>
                       )}


                      {/* <Col className='d-flex justify-content-center p-2' sm={12} lg={3} md={3} xl={3}>
                            <SelectPicker placeholder="Search By Record" 
                            onChange={(e)=>{handleChange("record",e)}}
                            style={{ width: "100%" }}
                            data={records} 
                            //  loading={isLoading.boxes} 
                         />
                      </Col> */}

                    </Row>
                    
      
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>  
              <Container fluid={true}>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                      <Fragment>
                          
                          <DataTable
                              // data={data}
                              // columns={tableColumns}
                              data={data_table}
                              columns={col_table}
                              striped={true}
                              center={true}
                              progressPending={isLoading}
                              progressComponent={<div className="loader-box">
                                              <Spinner attrSpinner={{ className: "loader-26" }} />
                                          </div>}
                              pagination
                              paginationServer
                              paginationPerPage={perPage}
                              paginationRowsPerPageOptions={[10,20,30,50,100]}
                              responsive
                              paginationTotalRows={totalItems}
                              paginationServerOptions={()=>{}}
                              onChangePage={(e)=>{handlePage(e)}}
                              onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                              onSelectedRowsChange={(e)=>{
                                setSelectedRows([])
                                e.selectedRows.forEach(ele =>{
                                  setSelectedRows(prev=>[...prev,ele.id])
                                })
                              }}
                              clearSelectedRows={toggleDelet}
                              
                          />
                      </Fragment>
        
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            

            </> : <EditPhoto id={id} />

            );
                              


    }
    export default Photos;
