import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Breadcrumbs, Btn,Image,Spinner } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
//files
import Files from 'react-files';
//Form Component
import { Loader} from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
// Editor Wyswigy 
import { EditorState, convertToRaw,ContentState ,convertFromHTML  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useParams} from "react-router-dom"
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { FaArrowLeft } from 'react-icons/fa';

const Add_Notification = ()=>{
    const {id} = useParams()
    const navigate = useNavigate()
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [page, setPage] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [langauges, setLangauges] = useState([])
    const [formData, setFormData]  = useState({
        name:"",

    })
  
    const [dataToEditorState, setDataToEditorState] = useState({});
    // const [editors_keys, setEditorsKeys] = useState(['description','short_description','specifications']);

    const permissions = JSON.parse(localStorage.getItem('permission'));

    useEffect(() => {
        if (id === ":id" && (!permissions || !permissions.includes('notification.add')))  {
            navigate(`/Forbidden`)
        } else {
            if (id !== ":id") {
                get_notification();
            }
        }
        
    
        if (localStorage.hasOwnProperty("lang")) {
            let array = JSON.parse(localStorage.getItem("lang"));
            setLangauges(array);
            if (id === ":id") {
                setDataToEditorState(prev => {
                    array.forEach((e) => {
                        prev[e.id] = {
                            description: { id: 0, value: ""}, // Ensure value is initialized correctly
                            name: { id: 0, value: "" },
                        };
                    });
                    return prev;
                });
            }
        }
    }, []);

    const get_notification = async ()=>{
        setIsLoadingDetailes(true)
        const {response,message} = await Helper.Get({
            url:api_Routes.notification.getOne(id),
            hasToken:true,
            data:{detailed:1}
        })
        if(response){
            var details_languages = {}
            console.log(response.data)
            setPage(response.data)    
            setFormData({
                name:response.data.name,                
            })
            setFiles(response.data.photo?[{
                preview: {type: 'image', url: response.data.photo}
            }]:"")
            response.data.detailed_translations.forEach(ele=>{
                ele.forEach(dt=>{
                    if (!details_languages[dt.language_id])
                        details_languages[dt.language_id] = {}

                   
                        details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                })
            })
           
        
            
            setDataToEditorState(details_languages)

            setIsLoadingDetailes(false)
        }
        else{
            if(typeof message =="string"){
                toast.error(message)
            }else{
                Object.keys(message).forEach(msg=>{
                    toast.error(`${message[msg]}:${msg}`)
                })
            }
        }
    }

    const handleChange = (key, value, id_lang, id, sort) => {
        console.log(value)
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
              
                    valueTemp  = value
                
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };

        const handleSubmit = async () => {
            let exit = false

            if (!formData.name  ) {
        toast.error("Please fill in all required fields (Name).");
        setIsLoading(false);
        return;
    }

            console.log(formData);
            setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''
            console.log(updatedFormData)
          
         // form_data.append("country_ids", JSON.stringify(formData.country_ids));


            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                   
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            } else {
                                 exit = true
                            }
                        
                    if(id === ":id"){
                        form_data.append(`languages[${key}][${subKey}]`, valueTemp);
                    }
                    else
                        form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);
                })
   
            })


            Object.keys(updatedFormData).forEach((key) => {
                if (key === "file")
                    form_data.append("file", updatedFormData.file);
                else
                    form_data.append(key, updatedFormData[key]);
            });

            if (id === ":id") {
                form_data.append("_method", "PUT");
            }
            if(exit){
                toast.error("You should add the name , description ");
                setIsLoading(false)
                return;
            }

            const { response, message } = await Helper.Post({
                url: id === ":id" ? api_Routes.notification.add : api_Routes.notification.update(id),
                data: form_data,
                hasToken: true
            });

            if (response) {
                if (id === ":id") {
                    toast.success("Successfully added !!");
                    navigate(`/notification-list/:id/order`);
                  } else {
                    toast.success("Successfully edited !!");
                    navigate(`/notification-list/:id/order`);
                  }              
                    setIsLoading(false);
            } else {
                if (typeof message === "string") {
                    toast.error(message);
                } else {
                    Object.keys(message).forEach((i) => {
                        const errorMessage = message[i][0] || "";
                        toast.error(`${i} : ${errorMessage}`);
                    });
                }
                setIsLoading(false);
            }
            };  

            const [files, setFiles] = useState([]);


            function deleteFile(e) {
                setFiles([]);
                setFormData(prev=>({...prev,["file"]:""}))
                return files
        
            }
            const onFilesChange = (files) => {
                setFiles(files)
                setFormData(prev=>({...prev,["file"]:files[0]}))
                console.log(files);
            }
            const onFilesError = (error, file) => {
                setFormData(prev=>({...prev,["file"]:""}))
                setFiles(file)
            }
            const handleGoBack = ()=>{
                navigate(`/Notifications/:id/order`)
            }

            return (
                <Fragment>
                    <Breadcrumbs mainTitle={id==":id"?"Add Notification Text":"Edit Notification Text"} parent="Page" title="Notification Text" />
                    {
                        isLoadingDetiales?<div className="loader-box">
                        <Spinner attrSpinner={{ className: "loader-26" }} />
                    </div>:<>
                    {/* Basic Info */}
                    <Container  className='mb-3' fluid={true}>
             
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <Row>
                                        <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                                            <HeaderCard title="basic Info" />
                                        </Col>
        
                                        {( permissions.includes('notification.edit')) && ( 
                                        <Col className='d-flex justify-content-center align-items-center ' lg={2} xl={2}  md={2} sm={2} xs={2}>
                                                <Btn attrBtn={{ className: "h-50", color: 'danger', type: "button", onClick: () => handleSubmit() }} >
                                                    {isLoading?<Loader/>:<i className='fa fa-save'></i>}
                                                </Btn> 
                                        </Col>
                                        )}
        
                                    </Row>
                                    
                                    <CardBody className="add-post">
        
                                        {/* Basic Info */}
                                        <Form className="row needs-validation">
                                                <Col sm="12">
                                                <FormGroup>
                                                    <Label for="validationCustom01">Name</Label>
                                                    <Input value={formData.name} onChange={(e)=>{handleChange("name",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="name" required="" />   
                                                </FormGroup>                                       
                                                </Col>
                                            </Form>
                                            
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        
                    </Container>
                    {/* languages */}
                    {
                        Object.keys(dataToEditorState).length > 0 && langauges.length > 0 && langauges.map((lang,i)=>(<Container className='mb-3' fluid={true}>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <HeaderCard title={`${lang.name} information`} />
                                    <CardBody className="add-post">
        
                              
                                        <Form className="row needs-validation">
                                                <Col sm="12">
                                                <FormGroup>
                                                    <Label for="validationCustom01">Name</Label>
                                                    <Input value={id==":id"?dataToEditorState[JSON.stringify(lang.id)]?.name.value:dataToEditorState[JSON.stringify(lang.id)]?.name.value} 
                                                            onChange={(e)=>{handleChange("name",e.target.value,lang.id,id==":id"?null:dataToEditorState[JSON.stringify(lang.id)]?.name.id,0)}} className="form-control" id="validationCustom01" type="text" placeholder={`${lang.name} name`} required="" />
                                                    <div className="valid-feedback">{"Looks good!"}</div>
                                                </FormGroup>
                                            
        
                                                
                                                <div className="email-wrapper">
                                                    <div className="theme-form">
        
                                                    <FormGroup>
                                                        <Label> Description </Label>
                                                        <div>
                                                        <Input 
                                                            value={id === ":id" ? dataToEditorState[JSON.stringify(lang.id)]?.description.value : dataToEditorState[JSON.stringify(lang.id)]?.description.value} 
                                                            onChange={(e) => handleChange("description", e.target.value, lang.id, id === ":id" ? null : dataToEditorState[JSON.stringify(lang.id)]?.description.id, 1)} 
                                                            className="form-control" 
                                                            id="validationCustom02" 
                                                            type="text" 
                                                            placeholder={`${lang.description} description`} 
                                                            required="" 
                                                        />
                                                        </div>
                                                    </FormGroup>
        
                                                    </div>
                                                </div>
            
                                                </Col>
                                            </Form>
                                       
                                        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>))
                    }
                   
                     {/* upload image */}
                    <Container  className='mb-3' fluid={true}>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <HeaderCard title={`upload image`} />
                                    <CardBody className="fileUploader">
                                         <Files
                                            className='files-dropzone fileContainer'
                                            onChange={onFilesChange}
                                            onError={onFilesError}
                                            accepts={['image/*']}
                                            multiple={false}
                                            maxFileSize={10000000}
                                            minFileSize={0}
                                            clickable
                                        >
                                            {
                                                files.length > 0
                                                    ? <div className='files-gallery'>
                                                        {files.map((file, index) =>
                                                            <div key={index}>
                                                                <Image attrImage={{ className: 'files-gallery-item', alt: "img", src: `${file.preview.url}` }} />
                                                            </div>
                                                        )}
                                                    </div>
        
                                                    : <div className="d-flex justify-content-center">
                                                        <Btn attrBtn={{ className: "mt-2", type: "button", color: 'primary' }} >Upload Image</Btn>
                                                    </div>
                                            }
                                        </Files>
                                        {files.length > 0 ?
                                            <div className="d-flex justify-content-center">
                                                <Btn attrBtn={{ className: "mt-2", color: 'danger', type: "button", onClick: () => deleteFile(files) }} >
                                                    Delete
                                                </Btn></div> : ''}
        
                                </CardBody>
                                   
                                </Card>
                            </Col>
                            
                        </Row>
                        
                    </Container>
                    </>
                    }
                    
                    
                </Fragment>
            );
}
export default Add_Notification ; 