import React, { Fragment,useEffect,useState } from 'react';
import { Container, Row, Col, Card, CardBody,Media  } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import { Btn, H4 ,Spinner,Image} from '../../AbstractElements';

// api request
import { Helper } from '../../tools/helper';
import { api_Routes } from '../../api_Routes';
// data table
import DataTable from 'react-data-table-component';
import { dummytabledata } from '../../Data/Table/Defaultdata';
// rsuite
import {Input, Whisper, Tooltip } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { useNavigate } from 'react-router';
import CommonModal from 'Components/Modal';
import { toast } from 'react-toastify';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel } from '@mui/material';
import { useLocation } from 'react-router-dom';



const style2 = { width: 60, fontSize: 14, padding: 4 };
const style3 = { width: 60, fontSize: 14, padding: 4 , position:"absolute" , zIndex:2 };
const style4 = { width: 60, fontSize: 14, padding: 4 , position:"absolute" , zIndex:2 ,cursor:"no-drop"};
const BaseApi = api_Routes.user.view;
const User_Table = () => {
  const navigate = useNavigate()
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleDelet, setToggleDelet] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [data_table, setData_Table] = useState([])
  const [col_table, setCol_Table] = useState([])
  const [ perPage, setPerPage ] = useState(10)
  const [ page, setPage ] = useState(1)
  const [values,setValues] = useState({})
  const [values_Filter, setFilter_Values] = useState({})
  const [ totalItems, setTotalItems ] = useState(0)
  const [modal, setModal] = useState(false);
  const [refreshDelete, setRefreshDelete] = useState(false);
  const [item, setItem] = useState("");
  const [refresh, setrefresh] = useState(false)
  const toggle = () => setModal(!modal);
  const permissions = JSON.parse(localStorage.getItem('permission'));
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get('id');

  const handleMenuItemClick = (path) => {
    navigate(path);
  };
  const handleverified = async (id) =>{
      
      const {response, message} = await Helper.Get({
        url : api_Routes.user.updateVeified(id),
        hasToken :true
      })
      if(response){
      setrefresh(true)
        toast.success("Successfully Verified !!");
          
      }else{
        if(typeof(message) === "string"){
          toast.error(message)
        }else{
          Object.keys(message).map((i)=>{
            toast.error(`${i} : ${message[i][0]}`)
          })}
      }
}

  useEffect(()=>{
    if (!permissions || !permissions.includes('user.get-all')) {
      navigate(`/Forbidden`)
  } else {
    get_users()
  }
   
   
  },[values_Filter,page,perPage,refreshDelete,refresh])

  const get_users = async()=>{

    let url = BaseApi + '?1+1';

    if(userId){
      url = url + `&id=${userId}`;
  }
    setData_Table([])
    setIsLoading(true)
    const {response, message} = await Helper.Get({
      url:url,
      hasToken:true,
      data:{page:page,
        results:perPage,
        keywords:values_Filter.keywords,

      }
    })
    if(response){
      setTotalItems(response.meta.total)
      response.data.forEach(elem=>{
        setData_Table(prev=>[...prev,{
          username:<Media className='d-flex'><Image attrImage={{ className: 'rounded-circle  img-40  me-2',style:{
            objectFit: "cover"}, src: `${elem.photo}`, alt:`${elem.name}` }} />
              <Media body className="align-self-center">
                  <div>{elem.username}</div>
              </Media>
          </Media>,
          name:elem.name,
          phone:elem.phone,
         
          id:elem.id,
          active:elem.active == 1 ?<span style={{padding:"15px"}} className={` badge-light-success `}>Active</span>
                    :<span className={`iconbg badge-light-danger p-3`}>Not Active</span>,
          verified:elem.verified == 1 ?<span  style={{padding:"15px"}}  className={` badge-light-success `}>Verified</span>
                    :<span className={`iconbg badge-light-danger p-3`}>Not Verified</span>,
          // verification_code:elem.verification_code,
          balance:elem.balance,
          // referred_by:elem.referred_by,
          action: (
            <div>
              <span className='mx-2'>
                <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>navigate(`/add-user/${elem.id}/Shake`)}}>Edit </Btn>
              </span>
              {elem.verified!=1 ?
              <span className='mx-2'>
                <Btn  attrBtn={{ style: style3,  color: 'primary', className: 'btn btn-danger', type: 'button ' ,onClick:()=>handleverified(elem.id)}}>Verify </Btn>
              </span>
              :
              <span className='mx-2'>
                <Btn  attrBtn={{ style: style4 ,  color: 'secondary', className: 'btn btn-secondary', type: 'button ' }}>Verify </Btn>
              </span>
              }
              {/* {permissions && permissions.includes('user.delete') && (
              <span >
                <Btn  attrBtn={{ style: style2, color: 'success', className: 'btn btn-xs', type: 'button' ,onClick:()=>{
                  toggle()
                  setItem(elem.id)
                }}}>delete </Btn>
              </span>
              )} */}
            </div>
          ),
         Route: (
        //   <div className="dropdown">
        //   <Dropdown>
        //     {/* <Dropdown.Toggle variant="success" id="dropdown-basic">
        //       Click here */}
              
        //       <Dropdown.Toggle variant="success" id="dropdown-basic">

        //       <Dropdown.Item>
        //         <Link to={`/Orders/:id/shakes?user_id=${elem.id}`} className="dropdown-link">Order</Link>
        //       </Dropdown.Item>
        //       <Dropdown.Item>
        //         <Link to={`/Shakes/:id/shakes?user_id=${elem.id}`} className="dropdown-link">Shakes</Link>
        //       </Dropdown.Item>
        //       <Dropdown.Item>
        //         <Link to={`/balance-list/:id/shakes?user_id=${elem.id}`} className="dropdown-link">Balance</Link>
        //       </Dropdown.Item>
        //       <Dropdown.Item>
        //         <Link to={`/product-list/:id/shakes?user_id=${elem.id}`} className="dropdown-link">Products</Link>
        //       </Dropdown.Item>

        //       </Dropdown.Toggle>
              

        //     {/* </Dropdown.Toggle> */}
              

        //   </Dropdown>
        // </div>
              <FormControl  size="small">
              <InputLabel id="demo-simple-select-InputLabel">Options</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Opitions"
                  style={{ width: '100px', backgroundColor: '#f5f5f5', borderRadius: '5px', padding: '0px', border: '1px solid #ccc' }}

                  onChange={handleChange}
                  // placeholder='Options'
                >
                  <MenuItem value={10} onClick={() => handleMenuItemClick(`/orders/:id/shakes?user_id=${elem.id}`)}>Order</MenuItem>
                  <MenuItem value={20} onClick={() => handleMenuItemClick(`/Shakes/:id/shakes?user_id=${elem.id}`)}>Shakes</MenuItem>
                  <MenuItem value={30} onClick={() => handleMenuItemClick(`/balance-list/:id/shakes?user_id=${elem.id}`)}>Balance</MenuItem>
                  <MenuItem value={40} onClick={() => handleMenuItemClick(`/product-list/:id/shakes?user_id=${elem.id}`)}>Products</MenuItem>
                </Select>
              </FormControl>
          ),


      }])
      })
      setCol_Table([{
        name: 'User Name',
        selector: row => row['username'],
        sortable: true,
        width:"17%",
        center: false,
    },
    {
        name: 'Name',
        selector: row => `${row.name}`,
        sortable: true,
        width:"15%",
        center: true,
    },

    {
        name: 'Phone',
        selector: row => `${row.phone}`,
        sortable: true,
        width:"12%",
        center: true,
    },
   
    {
        name: 'Active',
        selector: row => row['active'] ,
        sortable: true,
        width:"10%",
        center: true,
    },
    {
        name: 'Verified',
        selector: row => row['verified'] ,
        sortable: true,
        width:"14%",
        center: true,
    }, 
    //  {
    //     name: 'Balance',
    //     selector: row =>  `${row.balance}`,
    //     sortable: true,
    //     width:"10%",
    //     center: true,
    // },
    // {
    //     name: 'Referred By',
    //     selector: row =>  `${row.referred_by}`,
    //     sortable: true,
    //     width:"150px",
    //     center: true,
    // },
    {
      name: 'Action',
      selector: (row) => row.action,
      sortable: true,
      center: true,
      Width: '10%',
    },
    {
      name: 'Filter',
      selector: (row) => row.Route,
      sortable: true,
      center: true,
      Width: '20%',
      
    }
  
  
  ])
      setIsLoading(false)
    }else{
      console.log(message);
    }
  }
  
  const handleDelete = async() => {
    let path,ids={};
    if(item.length != ""){
     
        path=api_Routes.user.update(item)
    }else{
        selectedRows.forEach((ele,i)=>{
          ids[`ids[${i}]`] = ele
        })
        path=api_Routes.user.bulkDelete

    }

    const {response, message} = await Helper.Delete({
      url:path,
      hasToken:true,
      data:selectedRows.length>0?ids:{}
    })
    if(response){
      toast.success(message)
      setItem("")
      setSelectedRows([])
      setRefreshDelete(!refreshDelete)
    }else{
      toast.error(message)
      setItem("")
      setSelectedRows([])
    }
  
  };
    /// Change Pages To Api
    const handleChangePage = (value)=>{
      setPerPage(value)
    }
    const handlePage =(e)=>{
        setPage(e)
    }
   const handleChange = (key,value)=>{
        setValues(prev=>({...prev,[key]:value}))
      }

    const handleFilter = ()=>{
    setFilter_Values(values)
    }





  return (
    <Fragment>
      <CommonModal isOpen={modal} title="Confirm Delete" handleConfirm={handleDelete} toggler={toggle} >
        Are you sure delete ?
      </CommonModal>
      <Breadcrumbs parent="User" title="List" mainTitle="Users" />
      <Container className='my-2' fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
             
              <CardBody>
              <Row  >
                <Col className='d-flex justify-content-center p-2' sm={12} lg={11} md={11} xl={11}>
                  <Whisper trigger="focus" speaker={<Tooltip>Keywords</Tooltip>}>
                    <Input onChange={(e)=>{handleChange("keywords",e)}} 
                           placeholder="Search By Name , User Name" />
                  </Whisper>
                </Col>  
               
                <Col className='d-flex align-items-center justify-content-center p-2' sm={12} xs={12} lg={1} md={1} >
                  <div onClick={()=>{handleFilter()}}>
                    <Btn  className='info outline Info Button'> <i className='fa fa-search'></i></Btn>
                  </div>
                </Col>

              

              </Row>
              

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <Fragment>
                    {(selectedRows.length !== 0) &&
                        <div className={`d-flex align-items-center justify-content-between bg-light-info p-2`}>
                            <H4 attrH4={{ className: 'text-muted m-0' }}>Delete Selected Data..!</H4>
                            <Btn attrBtn={{ color: 'danger', onClick: () => toggle() }}>Delete</Btn>
                        </div>
                    }
                    {/* <Orders value={values_Filter}/> */}
                    <DataTable
                        // data={data}
                        // columns={tableColumns}
                        data={data_table}
                        columns={col_table}
                        striped={true}
                        center={true}
                        progressPending={isLoading}
                        progressComponent={<div className="loader-box">
                                            <Spinner attrSpinner={{ className: "loader-26" }} />
                                        </div>}
                        pagination
                        paginationServer
                        paginationPerPage={perPage}
                        paginationRowsPerPageOptions={[10,20,30,50,100]}
                        responsive
                        
                        paginationTotalRows={totalItems}
                        paginationServerOptions={()=>{}}
                        onSelectedRowsChange={(e)=>{
                          setSelectedRows([])
                          e.selectedRows.forEach(ele =>{
                            setSelectedRows(prev=>[...prev,ele.id])
                          })
                        }}
                        onChangePage={(e)=>{handlePage(e)}}
                        onChangeRowsPerPage={(e)=>{handleChangePage(e)}}
                        clearSelectedRows={toggleDelet}
                    />
                </Fragment>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};

export default User_Table;