import { Box, Grid , Avatar, IconButton, Skeleton } from '@mui/material';
import React, { useEffect, useRef ,useState} from 'react';
import styled from 'styled-components';
import FsLightbox from "fslightbox-react";
import dateFormat from 'dateformat';

//mui
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { BlockOutlined } from '@mui/icons-material';

//real time 
import Pusher from 'pusher-js';



import {api_Routes as apiRoutes} from '../../api_Routes';
import { Helper } from '../../tools/helper';
import { useBreakpoint } from 'react-use-size';
import axios from 'axios';
import Echo from 'laravel-echo';
import { echo_info } from '../../redux/action';
import { connect } from 'react-redux';
import { listen_message } from '../../echo';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ChatInterface from "./conversation"
import { Helmet } from 'react-helmet-async';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import getDate from './getDate';

const Container = styled.div`
    padding:10px 0;
    @media(max-width:992px){
        padding:10px 10px
    }

`
const Title = styled.h3`
    color:black;
    font-size:18px;
    font-weight:600;

    line-height:unset !important;
    padding:10px 0;
    display:flex;
    align-items:center
`
const SubTitle = styled.h5`
    font-size:15px;
    font-weight:600;

    line-height:unset !important;
    margin-bottom:4px
`
const Paragraph = styled.p`
    color:black;
    font-size:12px;

    line-height:unset !important;
`
const Date = styled.span`
    color:black;
    font-size:10px;
    font-weight:600;

    line-height:unset !important;
`
const Input = styled.input`
    width:100%;
    height:100%;
    border-radius:14px;
    outline:unset;
    background:rgb(159 159 159 / 24%);
    border:unset;
    padding:10px
`
const Continer = styled.div`
    padding:40px 0;
    justify-content:center;

& h2{

    font-weight:600;
    font-size:20px;
    text-align:center;
}
& h6{
    color:black;

    font-weight:500;
    font-size:16px;
    text-align:center;
    margin-bottom:10px;
}
& img{
    object-fit:contain;
    height:250px;

}
& span{
    display: flex;
    margin-right: 10px;
}
`
const InputSearch = styled.input`
    width:100%;
    height:40px;
    border-radius:14px;
    outline:unset;
    border: 1px solid rgb(159 159 159 / 45%);
    background:white;
    margin-bottom:10px ;
    padding:10px

`


function Messages_Page({conversation_id_redux,message_redux,notification_redux}) {
    const { id } = useParams() 
    const [conversations, setConversations] = useState([])
    const [search, setSearch] = useState("")
    const [userOther, setUserOther] = useState({conversation_id:null})
    const [conversation, setConversation] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isLoading_chat, setIsLoading_chat] = useState(true)
    const [source_Photos, setSource_photos] = useState([])
    const [isOpenLightBox, setIsOpenLightBox] = useState(false)
    const [view,setView] = useState(false)
    const navigate = useNavigate()
    const id_conv = useRef("")
    const controllerAbort = useRef(null)
    const [conversation_new_id,setConversation_new_id] = useState("")
    const location = useLocation()
    const [toOpenConversation, setToOpenConversation] = useState("")
    const [viewMore,setViewMore] = useState({})
    const ref_div = useRef(null)
    const [messageDir , setMessageDir] = useState("")
    const [url , setUrl] = useState("")
    const isSmall = useBreakpoint(992)
    // const date = useRef( )
    let date = getDate()
    useEffect(()=>{
        if(localStorage.getItem("view-conv") && window.location.pathname != "/support/:id/Shake"){
            setView(localStorage.getItem("view-conv"))
        }
    },[view])
    useEffect(()=>{
        if(location.state && location.state.message_from_vendor !=""){
            setUrl(location.state.url)
            setMessageDir(location.state.message_from_vendor)
        }else if(location.state){
            setToOpenConversation(location.state.message)
        }
    },[location])
    useEffect(()=>{
        // to Close menu when click outside
        function handleClickOutside(event) {
            if (ref_div.current && !ref_div.current.contains(event.target)) {
            setViewMore(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
        };
    })
  
    useEffect(()=>{  
        const controller = new AbortController()
        const signal = controller.signal
        if(id_conv.current == ""){
            get_conversations(signal)
        }else{
            get_conversations(signal)
            listen_message(id_conv.current)
        }
        return ()=>{
            controller.abort()
        }
         
    },[id])
   
    

    const get_conversations = async (signal,search=null)=>{
        const {response, message} = await Helper.Get_Abort({
            url:apiRoutes.converstaions.getByAthunticated,
            hasToken:true,
            signal:signal,
            data:search?{keywords:search,role_id:4}:{role_id:4}
        })
        if(response){
                setConversations(response.data)
                // if(response.data.length>0){
                    if(id){     
                                   
                       
                        const ids = []
                        response.data.forEach(element => {
                            ids.push(element.first_user)
                            ids.push(element.second_user)
                         });
                        if(ids.includes(parseInt(id))){
                        response.data.forEach(ele=>{
                            setViewMore(prev=>({...prev,[ele.id]:false}))
                            if(ele.first_user == id && ele.first_user != JSON.parse(localStorage.getItem("user")).user_id){
                                setUserOther({
                                    username:ele.first_username,
                                    image:ele.first_photo,
                                    id:ele.first_user,
                                    conversation_id:ele.id,
                                })
                                listen_message(ele.id)
                                id_conv.current = ele.id
                            }else if(ele.second_user == id && ele.second_user != JSON.parse(localStorage.getItem("user")).user_id){
                                setUserOther({
                                    username:ele.second_username,
                                    image:ele.second_photo,
                                    id:ele.second_user,
                                    conversation_id:ele.id,
                                })
                                listen_message(ele.id)
                                id_conv.current = ele.id
                            }
                        })
    
                        }else if (localStorage.getItem("userOther") && (!ids.includes(parseInt(id)))){
                        setUserOther({
                            username:JSON.parse(localStorage.getItem("userOther")).username,
                            image:JSON.parse(localStorage.getItem("userOther")).photo,
                            id:JSON.parse(localStorage.getItem("userOther")).id,
                            // conversation_id:latest_Conversation.id,
                        })
                        const temp = response.data
                        response.data.forEach(element=>{
                            setViewMore(prev=>({...prev,[element.id]:false}))
                        })
                        temp.unshift({
                            id:"" ,
                            first_user:JSON.parse(localStorage.getItem("userOther")).id,
                            first_username:JSON.parse(localStorage.getItem("userOther")).username,
                            first_photo:JSON.parse(localStorage.getItem("userOther")).photo,
                            lastMessage:{
                                message:"-", 
                            },
                            created_at:date,
                    
                        })
                        setConversations(temp)  
                        id_conv.current = ""
                        }
                    }else{
                        
                    }
                // }else{
                //     navigate("/vendors")
                // }

            setIsLoading(false)
        }
       
    }
    const handleDeleteConversation = async(id)=>{
        setViewMore(prev=>({...prev,[id]:false}))
        setIsLoading(true)
        const {response, message} = await Helper.Delete({
            url:apiRoutes.messages.conversation_getOne(id),
            hasToken:true
        })
        if(response){
                setConversations([])
                setConversation([])
                navigate("/support/:id/Shake")
                id_conv.current = ""
                setUserOther({conversation_id:null})
                get_conversations(null,false,null)
               
             
            }
            setIsLoading(false)
            

        }
    const handleBlockConversation = async(id)=>{
        setViewMore(prev=>({...prev,[id]:false}))
        setIsLoading(true)
        const {response, message} = await Helper.Delete({
            url:apiRoutes.vendor.block(id),
            hasToken:true
        })
        if(response){
                setConversations([])
                setConversation([])
                navigate("/support/:id/Shake")
                id_conv.current = ""
                setUserOther({conversation_id:null})
                get_conversations(null,false,null)
               
             
            }
            setIsLoading(false)
            

        }

    
    
    const handleSearch = (e)=>{
        setSearch(e.target.value)
        setIsLoading(true)
        if(controllerAbort.current){
            controllerAbort.current.abort()
        }
        controllerAbort.current = new AbortController()
        const signal = controllerAbort.current.signal
        get_conversations(signal,e.target.value)
    }
    const updateConversationsWithNewUser = (res)=>{
        id_conv.current = res
        get_conversations()
    }

    return ( <Container>
        <Helmet>
        <title>Shake Shake | Chat </title>
        </Helmet>
        <Grid container>
            <Grid sx={{borderRight:"1px solid rgba(26, 155, 227, 0.05)",padding:"0 10px",
            "@media(max-width:900px)":{
                display:view ?"none":"block"
            }}} lg={3} md={4} xl={3} sm={12} xs={12} >
                <Box sx={{display:"flex",justifyContent:"space-between",height:"80px"}}>
                    <Title>Chat</Title>
                </Box>
                <Box>
                    <InputSearch value={search} onChange={(e)=>{handleSearch(e)}} placeholder='Search'/>
                </Box>
                <Box className="scroll-div" style={{height:window.innerHeight-250}}>
                  
                    {isLoading?<Box>
                       {[1,2,3,4,5].map(()=>(<Box key={Math.random()*400} sx={{display:"flex",gap:"10px",padding:"5px",}}>
                            <Box sx={{width:"20%",display:"flex",justifyContent:"start",alignItems:"center"}}>
                                <Skeleton variant="circular" width={40} height={40}  animation="wave" />
                            </Box>
                            <Box sx={{width:"70%"}}>
                                <Skeleton sx={{width:"40%"}} height={30} animation="wave" />
                                <Skeleton sx={{width:"90%"}} height={30} animation="wave" />
                            </Box>

                        </Box>))}

                        </Box>:<>
                        {conversations.length == 0 ?<Box sx={{display:"flex",justifyContent:"center"}}>
                            no conversation yet .

                        </Box>:conversations.map((e,index)=>{
                            const object ={}
                            if(e.first_user != JSON.parse(localStorage.getItem("user")).id){
                                object["image"]=e.first_photo
                                object["username"]=e.first_username
                                object["id"]=e.first_user
                                object["conversation_id"]=e.id 
                                object["message"]=e.lastMessage?e.lastMessage.message:"attatchment"
                                object["date"]=dateFormat(e.created_at,"dd mm yyyy")
                            }else{
                                object["image"]=e.second_photo
                                object["username"]=e.second_username
                                object["id"]=e.second_user
                                object["conversation_id"]=e.id 
                                object["message"]=e.lastMessage?e.lastMessage.message:"attatchment"
                                object["date"]=dateFormat(e.created_at,"dd mm yyyy")
                             
                            }
                           
                        
                        return<Box key={Math.random()*100}  onClick={()=>{
                            setUserOther(object)
                            listen_message(object.conversation_id)
                            id_conv.current = object.conversation_id
                            navigate(`/support/${object.id}/Shake`)                      
                            setView(true)
                            localStorage.setItem("view-conv",true)
                            }} sx={{
                                padding:"15px 10px",
                                borderRadius:"4px",
                                transition:"0.5s",
                                position:"relative",
                                background:object.id == userOther.id?"rgba(19, 51, 107, 0.05)":"",
                                borderBottom:object.id == userOther.id?"":"1px solid rgba(19, 51, 107, 0.05)",
                                "&:hover":{
                                    background:"rgba(19, 51, 107, 0.05)",
                                    cursor:"pointer"
                                },
                                "&:hover hr":{
                                    display:"none"
                                }
                            }}>
                           {/* <IconButton onClick={()=>{
                            setViewMore(prev=>({...prev,[object.conversation_id]:!prev[object.conversation_id]}))
                           }} sx={{position:"absolute", right:"5px",bottom:0,zIndex:"50"}}>
                               <MoreHorizIcon/>
                           </IconButton>
                           {
                            viewMore[object.conversation_id] && <Paper ref={ref_div} sx={(index==conversations.length-1)?{position:"absolute",bottom:"30px",right:"10px", width: 320, maxWidth:150,zIndex:"55",background:"white" }
                            :{position:"absolute",top:"70px",right:"10px", width: 320, maxWidth:150,zIndex:"55",background:"white" }}>
                            <MenuList>
                                <MenuItem onClick={()=>{
                                    handleDeleteConversation(object.conversation_id)}}>
                                <ListItemIcon>
                                <IndeterminateCheckBoxIcon/>
                                </ListItemIcon>
                                <ListItemText>delete</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={()=>{
                                
                                }}>
                                    <ListItemIcon>
                                    
                                        <BlockOutlined/>
                                    </ListItemIcon>
                                    <ListItemText>block</ListItemText>
                                </MenuItem>
                            </MenuList>
                            </Paper>
                           } */}
                        <Box sx={{display:"flex"}}>
                        <Box sx={{display:"flex",justifyContent:"start",alignItems:"center",width:"20%"}}>
                            <Avatar sx={{width:45,height:45,
                            "@media(min-width:992px) and (max-width:1200px)":{
                                width:35,
                                height:35
                            }}} src={object.image} alt={object.username}/>
                        </Box>
                        <Box sx={{width:"80%"}}>
                            <Box sx={{display:"flex",justifyContent:'space-between',padding:"0 5px"}}>
                                <SubTitle>
                                    {object.username}
                                </SubTitle>
                                <Date>
                                    {object.date}
                                </Date>
                            </Box>
                            <Box sx={{padding:"0 5px"}}>
                                <Paragraph style={(id_conv.current == object.conversation_id)?{color:"black",fontWeight:"600"}:{}}>
                                {(JSON.parse(localStorage.getItem("user")).id == e.first_user) && "you: "}
                                {object.message?<span dangerouslySetInnerHTML={{__html:object.message.substring(15,0)+"..."}}></span>:"attachment"}

                                </Paragraph>
                            </Box>

                        </Box>
                        
                    </Box>
    
                     </Box>
                    })}
                        
                        </>}
                </Box>

            </Grid>
          <Grid sx={{background:"rgba(19, 51, 107, 0.05)",
            "@media(max-width:900px)":{
                display:view ?"block":"none"
            }}} lg={9} md={8} xl={9} sm={12} xs={12} >
                
                {("username"in userOther)&& <Box sx={{display:"flex",gap:"10px",background:"white",padding:"10px 15px"}}>
                    <Box>
                        <Link to={`/user-list/Shake?id=${userOther.id}`}>
                            <Avatar alt={userOther.username} sx={{width:50,height:50}} src={userOther.image}/>
                        </Link>
                    </Box>
                    <Title ><Link to={`/user-list/Shake?id=${userOther.id}`}> {userOther.username}</Link></Title>
                </Box>}
                    
			<FsLightbox
				toggler={isOpenLightBox}
				sources={source_Photos}
			/> 
            <Box>
            <ChatInterface url={url} messageDir={messageDir} returned_new_id={(res)=>{
                updateConversationsWithNewUser(res)}} userOther={userOther} message={message_redux} id={id_conv.current} 
               // isLoading_chat={isLoading_chat}
             conversation= {conversation.length>0 ?conversation :[]} />

    
     
 </Box>
               


            </Grid>

        </Grid>
        
    </Container> );
}
const mapStateToprops = (state)=>{
    return {
        message_redux:state.message,
        conversation_id_redux:state.conversation_id,
        notification_redux : state.notification,
        message_redux : state.message
    }
}

export default connect(mapStateToprops,null)(Messages_Page);