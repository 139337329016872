import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input  } from 'reactstrap';
import { Breadcrumbs, Btn,Image,Spinner } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
//files
import Files from 'react-files';
//Form Component

import { Loader, SelectPicker } from 'rsuite';
//api request
import { api_Routes } from 'api_Routes';
import { Helper } from 'tools/helper';
import { toast } from 'react-toastify';
// Editor Wyswigy 
import {EditorState, convertToRaw,ContentState ,convertFromHTML} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {useParams} from "react-router-dom"
import { Chip, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Editor } from '@tinymce/tinymce-react';

import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import { FaArrowLeft } from 'react-icons/fa';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';



const Add_Product= () => {
    const navigate = useNavigate()
    const {id} = useParams()
    const [isLoadingSelected, setIsLoadingSelected] = useState({
        category:false,
        brand:false
    })
    const [value, setValue] = useState(1);
    const [hover, setHover] = useState(-1);
  
    const labels = {
      0.5: 'Useless',
      1: 'Useless+',
      1.5: 'Poor',
      2: 'Poor+',
      2.5: 'Ok',
      3: 'Ok+',
      3.5: 'Good',
      4: 'Good+',
      4.5: 'Excellent',
      5: 'Excellent+',
    };
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingDetiales, setIsLoadingDetailes] = useState(false)
    const [categories, setCategories] = useState([])
    const [brands, setBrands] = useState([])
    const [langauges, setLangauges] = useState([])
    const [product ,setProduct] = useState({})
    const [productsImg,setProductsImg] = useState([])
    const controllerRef = useRef(null)

    const [formData, setFormData]  = useState({
        name:"",
        is_stock:"1",
        visible:"1",
        is_box:"0",
        name:"",
        ratings:"",
        category_id:0,
        condition:"0",
        quantity:1,
        sellprice:0,
        originprice:0,
        accepted : "1",
        
    })
    const [dataToEditorState, setDataToEditorState] = useState({});
    const [editors_keys, setEditorsKeys] = useState(['description','short_description','specifications']);
    const permissions = JSON.parse(localStorage.getItem('permission'));


    useEffect(()=>{
        const controller = new AbortController()
        const signal = controller.signal
        if (!permissions || !permissions.includes('product.get-all')) {
            navigate(`/Forbidden`)
        } else {


            get_categories(signal)
            // get_brands()

        if(id != ":id"){
            get_details(signal)
        }
        }
        
        if(localStorage.hasOwnProperty("lang")){
            let array = JSON.parse(localStorage.getItem("lang"))
            setLangauges(array)
            if(id==":id"){
                setDataToEditorState(prev=>{
                    array.map((e)=>{
                      prev[e.id]= {
                        name:{id:0,value:""},
                        short_description:{id:0,value:EditorState.createEmpty()},
                        description:{id:0,value:EditorState.createEmpty()},
                        specifications:{id:0,value:EditorState.createEmpty()},
                        meta_title:{id:0,value:""},
                        meta_description:{id:0,value:""},
                      }
                      return prev[e.id]
                    })
                    return prev
                  })
            } 
           }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        return ()=>{
            controller.abort()
        }
    },[])
    const get_details = async (signal)=>{
        setIsLoadingDetailes(true)
        const {response,message} = await Helper.Get_Abort({
            url:api_Routes.product.getOne(id),
            signal:signal,
            hasToken:true,
            data:{detailed:1}
        })
        if(response){
            const details_languages = {}
            setProduct(response.data)    
            setFormData({
                name:response.data.name,
                category_id:response.data.category_id,
                ratings:response.data.ratings,
                visible:response.data.visible ,
                is_stock:response.data.is_stock ,
                is_box:response.data.is_box ,
                quantity:response.data.quantity,
                sellprice:response.data.sellprice,
                condition:response.data.condition?response.data.condition:"",
                originprice:response.data.originprice,
            })
            setValue(response.data.ratings)
            if( response.data.category_id){
                onSearch(response.data.category_translations["en"].name,'category')
                handleChange("category_id",response.data.category_id,null)
            }
            setFiles(response.data.photo?[{
                preview: {type: 'image', url: response.data.photo}
            }]:"")
            // setFiles([{preview:{url:response.data.photo}}])
            response.data.detailed_translations.forEach(ele=>{
                ele.forEach(dt=>{
                    if (!details_languages[dt.language_id])
                        details_languages[dt.language_id] = {}

                    if(editors_keys.includes(dt.text_type))
                    {
                        details_languages[dt.language_id][dt.text_type] = {
                            value:dt.value ?
                                EditorState.createWithContent(
                                    ContentState.createFromBlockArray(
                                        convertFromHTML(dt.value)
                                    )
                                ) : EditorState.createEmpty(),
                            id:dt.id
                        }

                    }else
                        details_languages[dt.language_id][dt.text_type] = {value:dt.value,id:dt.id}
                })
            })
            
            setDataToEditorState(details_languages)

            setIsLoadingDetailes(false)
        }
    }


    const get_categories = async (signal)=>{
        setIsLoadingSelected(prev=>({...prev,["category"]:true}))
        const {response,message} = await Helper.Get_Abort({
            url:api_Routes.category.view,
            signal:signal,
            hasToken:true
        })
        if(response){
            setCategories(response.data)
            setIsLoadingSelected(prev=>({...prev,["category"]:false}))
        }
    }
    const get_brands = async ()=>{
        setIsLoadingSelected(prev=>({...prev,["brand"]:true}))
        const {response,message} = await Helper.Get({
            url:api_Routes.brand.view,
            hasToken:true
        })
        if(response){
            setBrands(response.data)
            setIsLoadingSelected(prev=>({...prev,["brand"]:false}))
        }else{
            if(typeof message =="string"){
                toast.error(message)
            }else{
                Object.keys(message).forEach(msg=>{
                    toast.error(`${message[msg]}:${msg}`)
                })
            }
        }
    }
    const handleChange = (key, value, id_lang, id, sort) => {
            if (id_lang) {
                let translations = { ...dataToEditorState };
                let valueTemp = ''
                if(editors_keys.includes(key))
                    valueTemp = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value)))
                else
                    valueTemp  = value
                
                translations[id_lang][key] = { id:id, value:valueTemp};
                setDataToEditorState(translations);
            } else {
                setFormData(prev => ({ ...prev, [key]: value }));
            }
        };

    const onSearch = async (e,key)=>{
        console.log("fun search" , e);
        if(key == "category"){
            setIsLoadingSelected(prev=>({...prev,["category"]:true}))
        }else{
            setIsLoadingSelected(prev=>({...prev,["brand"]:true}))
        }
        
        if(controllerRef.current){
            controllerRef.current.abort()
        }
        controllerRef.current = new AbortController()
        const signal = controllerRef.current.signal
        const {response,message} = await Helper.Get_Abort({
            url: key == "category" ? api_Routes.category.view : api_Routes.brand.view,
            signal:signal,
            data:{keywords:e,results:20},
            hasToken:true
        })
        if(response){
            if (key == "category"){
                setCategories(response.data)
                setIsLoadingSelected(prev=>({...prev,["category"]:false}))
            }else{
                setBrands(response.data)
                setIsLoadingSelected(prev=>({...prev,["brand"]:false}))
            }

        }

    }
    const onChangeTextArea = (value,key,id) => {
        setDataToEditorState(prev=>({...prev,[JSON.stringify(id)]:{...prev[JSON.stringify(id)],[key]:EditorState.createWithContent(
            ContentState.createFromBlockArray(
            convertFromHTML(value)
            ))}}))
            setFormData( prev =>{
                prev["languages"][JSON.stringify(id)]={...prev["languages"][JSON.stringify(id)],[key]:value} 
                return {...prev }
            })
    };
    
    const handleSubmit = async () => {
            let exit = false

            if (!formData.name || !formData.category_id || !formData.ratings || !formData.originprice || !formData.sellprice  ) {
                toast.error("Please fill in all required fields (Name, Category, Ratings, Origin Price, Sell Price, Quantity).");
                setIsLoading(false);
                return;
            }
         
            setIsLoading(true);

            var form_data = new FormData();
            var updatedFormData = { ...formData };
            var valueTemp = ''

            Object.keys(dataToEditorState).forEach((key,value) => {
                Object.keys(dataToEditorState[key]).forEach((subKey) => {
                    if(editors_keys.includes(subKey))
                        valueTemp = draftToHtml(convertToRaw(dataToEditorState[key][subKey].value.getCurrentContent()))
                        else {
                            if (dataToEditorState[key][subKey].value) {
                                valueTemp = dataToEditorState[key][subKey].value;
                            } else {
                                 exit = true
                            }
                        }
                    if(id === ":id")
                        form_data.append(`languages[${key}][${subKey}]`, valueTemp);
                    else
                        form_data.append(`languages[${dataToEditorState[key][subKey].id}][${subKey}]`, valueTemp);

                })
            })
            Object.keys(updatedFormData).forEach((key) => {
                if (key === "file")
                    form_data.append("file", updatedFormData.file);
                else
                    form_data.append(key, updatedFormData[key]);
            });

        if (id === ":id") {
            if(!updatedFormData.file){
                toast.error("The File field is required .");
                setIsLoading(false);
                return;
            }
            form_data.append("_method", "PUT");
        }
        if(exit){
            toast.error("You should add the name , description , short_description , specification , meta_title , meta_description");
            setIsLoading(false)
            return;
        }
        
        const { response, message } = await Helper.Post({
            url: id === ":id" ? api_Routes.product.add : api_Routes.product.update(id),
            data: form_data,
            hasToken: true
        });

        if(response) {
            if (productsImg.length > 0) {
                send_images(id === ":id" ? response.data.id : id);
            } else {
                setIsLoading(false);
                if (id === ":id") {
                    toast.success(" Product Add Successfully  !!");
                    navigate(`/product-list/:id/Shake`);
                  } else {
                    toast.success(" Product Edit Successfully  !!");
                    navigate(`/product-list/:id/Shake`);
                  }            }
        } else {
            if (typeof message === "string") {
                toast.error(message);
            } else {
                Object.keys(message).map((i) => {
                    toast.error(`${i} : ${message[i][0]}`);
                });
            }
            setIsLoading(false);
        }
    } 
    
    const send_images = async (id)=>{
        var form_data = new FormData();
        productsImg.forEach((e,i)=>{
            form_data.append(`photos[${i}]`, e);   
          })
        form_data.append("visible", "1"); 
        form_data.append("type_id", id); 
        form_data.append("type", "App\Models\Product"); 
        form_data.append("_method","PUT")
          
        const {response, message} = await Helper.Post({
            url :api_Routes.photos.bulk,
            data :form_data,
            hasToken :true
          })
          if(response){
            setIsLoading(false)
            toast.success(id==":id"?"Successfully added !!":"Successfully edit !!")

          }else{
            if(typeof(message) === "string"){
              toast.error(message)
            }else{
              Object.keys(message).map((i)=>{
                toast.error(`${i} : ${message[i][0]}`)
              })}
              setIsLoading(false)
          }
    }
    const [files, setFiles] = useState([]);

    function deleteFile(e) {
        setFiles([]);
        return files

    }
    const onFilesChange = (files) => {
        setFiles(files)
        setFormData(prev=>({...prev,["file"]:files[0]}))
    }
    const onFilesError = (error, file) => {
        setFormData(prev=>({...prev,["file"]:""}))
        setFiles(file)
    }
    // drop zones functionality
    const getUploadParams = ({ meta,file }) => {
      
        
        return { url: 'https://httpbin.org/post' };
    };

    const getLabelText = (value) => {
        
        return labels[value]
      };

    const handleChangeStatus = ({ meta, file }, status) => {

        if(status=="done"){
            setProductsImg(prev=>[...prev,file])
        }else if(status=="removed"){
            setProductsImg(prev=>{
                let array = prev
                let result =array.filter(i => i.name!=file.name)
                return result
            })
        }
    };
    const handleGoBack = ()=>{
        navigate(`/product-list/:id/Shake`)
    }
    return (
        <Fragment> 
            <Breadcrumbs  mainTitle={id==":id"?"Add product":"Edit product"} parent="Product" title={id==":id"?"Add":"Edit"} />
            {/* Basic Info */}
                                {isLoadingDetiales ? (
                        <div key="uniqueLoaderKey" className="loader-box">
                            <Spinner attrSpinner={{ className: "loader-26" }} />
                        </div>
                    ) :
                                    <>
                <Container key={formData.productId}  className='mb-3' fluid={true}>
                {/* <Btn 
                    attrBtn={{
                        className: 'btn btn-primary justify-content-start text-white d-flex align-items-center   btn-mail w-40',
                        style: { padding: '10px 20px', width:"fit-content",position:"fixed" , zIndex:"100" , bottom:"30px", right:"30px"},
                        onClick: handleGoBack
                    }}
                    >
                <FaArrowLeft /> Back
                </Btn> */}
                <Row>
                    <Col sm="12">
                        <Card>
                            <Row>
                                <Col lg={10} xl={10}  md={10} sm={10} xs={10}>
                                    <HeaderCard title="basic Info" />
                                    
                                </Col>
                                {( permissions.includes('product.edit')) && ( 

                                <Col className='d-flex justify-content-center align-items-center ' lg={2} xl={2}  md={2} sm={2} xs={2}>
                                        <Btn  attrBtn={{ className: "h-50", color: 'danger', type: "button", onClick: () => handleSubmit() ,disabled:isLoading}} >
                                           {isLoading?<Loader/>: <i className='fa fa-save'></i>}
                                        </Btn>   
                                </Col>
                                )}
                            </Row>
                            
                            <CardBody className="add-post">
                                
                                {/* Basic Info */}
                                <Form className="row needs-validation">
                                        <Col >
                                        <FormGroup>
                                            <Row>
                                          <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <Label for="validationCustom01">Name *</Label>
                                            <Input style={{width:"80%"}}  value={formData.name} onChange={(e)=>{handleChange("name",e.target.value,null)}} className="form-control" id="validationCustom01" type="text" placeholder="name" required="true" />  
                                          </Col>

                                            <Col  xs={12} sm={12} md={6} lg={6} xl={6} >
                                                <div>
                                                <Label for="validationCustom01">Category *</Label>
                                                </div>

                                                 <SelectPicker style={{width:"100%"}} data={ categories.map((ele)=>({label:ele.translations["en"]?ele.translations["en"].name:"",value:ele.id}))} 
                                                 onSearch={(e)=>{
                                                        if( e != "")
                                                            onSearch(e,"category")
                                                    }}
                                                      value={parseInt(formData.category_id)}
                                                     
                                                     onChange={(e)=>{
                                                        handleChange("category_id",e,null)}}  placeholder="category" loading={isLoadingSelected.category}  />
                                                </Col>
                                                </Row>
                                        </FormGroup>
                                    
                                        
                                        
                                        <FormGroup className='my-4' >
                                            <Row >
                                                <Col xs={12} sm={4} md={4} lg={4} xl={4} >
                                                    <Label for="validationCustom01">Origin Price</Label>
                                                    <Input  value={formData.originprice} onChange={(e)=>{
                                                        if(parseFloat(e.target.value) <= 0 ){
                                                            handleChange("originprice",formData.originprice==""?10:formData.originprice,null)
                                                        }else{
                                                            handleChange("originprice",e.target.value,null)
                                                        }

                                                        }} className="form-control" id="validationCustom01" type="number" placeholder="origin price" required="" />
                                                </Col>
                                                <Col xs={12} sm={4} md={4} lg={4} xl={4} >
                                                    <Label for="validationCustom01">sell Price </Label>
                                                    <Input  value={formData.sellprice} onChange={(e)=>{
                                                        if(parseFloat(e.target.value) <= 0 ){
                                                            handleChange("sellprice",formData.sellprice==""?10:formData.sellprice,null)
                                                        }else{
                                                            handleChange("sellprice",e.target.value,null)
                                                        }
                                                        }} className="form-control" id="validationCustom01" type="number" placeholder="sell Price" required="" />
                                                </Col>
                                                <Col xs={12} sm={3} md={4} lg={4} xl={4} >
                                                    {formData.is_stock =="1"?<>
                                                        <Label for="validationCustom01">Quantity</Label>
                                                        <Input style={{width:"100%"}}  value={formData.quantity} onChange={(e)=>{handleChange("quantity",e.target.value,null)}}  className="form-control" id="validationCustom01" type="number" placeholder="quantity" required="" />
                                                    </>:""}
                                                </Col>
                                            </Row>
                                            
                                        </FormGroup>
                                        <FormGroup >
                                            <Row className=''>
                                                
                                               
                                                {/* <Col xs={12} sm={12} md={6} lg={6} xl={4} >
                                                    <SelectPicker value={formData.condition=="new"?"1":"2"} style={{width:"100%"}} onChange={(e)=>{handleChange("condition",e =="1"?"new":"used",null)}} data={[{
                                                        label:"new",
                                                        value:1,
                                                    },{ 
                                                        label:"used",
                                                        value:2,
                                                    }]} placeholder="condition"  />
                                                </Col> */}
                                                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                                                    <FormControl >
                                                    <Label for="validationCustom01">Status </Label>
                                                        
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                                            name="controlled-radio-buttons-group"
                                                            value={formData.condition.toString()} // Convert value to string
                                                            onChange={(e) => handleChange("condition", parseInt(e.target.value))}
                                                            sx={{marginLeft:"15px",
                                                                '& .MuiSvgIcon-root': {
                                                                fontSize: 18,
                                                                }
                                                                ,}}
                                                    >
                                                            <FormControlLabel value="1" control={<Radio />} label="New" />
                                                            <FormControlLabel value="0" control={<Radio />} label="Barely New" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Col>
                                                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                                                    <FormGroup>
                                                    <Row className='gap-1'>
                                                    <Label for="validationCustom01">Rating </Label>
                                                        <Box sx={{display:"flex",gap:"20px"}}>
                                                            <Rating
                                                                    name="hover-feedback"
                                                                    value={value}
                                                                    precision={0.5}
                                                                    getLabelText={getLabelText}
                                                                    onChange={(event, newValue) => {
                                                                    setValue(newValue);
                                                                    handleChange("ratings", newValue)}}
                                                                    onChangeActive={(event, newHover) => {
                                                                        
                                                                    setHover(newHover);
                                                                    }}
                                                                    emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                                                />
                                                                
                                                                {value !== null && (

                                                                            hover !== -1 ? <Box  sx={{ ml: 2 }}>
                                                                            {( hover<="2")?<Chip sx={{background:"#FFD5D5",color:"#D20000",width:"120px"}} label={labels[hover !== -1 ? hover : value]} />
                                                                                        :""}
                                                                            {( hover == "2.5" || hover == "3" ) ?<Chip sx={{background:"#FFDFAF",color:"#faaf00",width:"120px"}} label={labels[hover !== -1 ? hover : value]} />
                                                                            :""}
                                                                            {( hover>="3.5" ) ?<Chip sx={{background:"#A0FFAA",color:"#017A0D",width:"120px"}} label={labels[hover !== -1 ? hover : value]} />
                                                                            :""}</Box> : <Box  sx={{ ml: 2 }}>
                                                                            {( value <= "2")?<Chip sx={{background:"#FFD5D5",color:"#D20000",width:"120px"}} label={labels[hover !== -1 ? hover : value]} />
                                                                                        :""}
                                                                            {( value == "2.5" || value == "3" ) ?<Chip sx={{background:"#FFDFAF",color:"#faaf00",width:"120px"}} label={labels[hover !== -1 ? hover : value]} />
                                                                            :""}
                                                                            {( value>="3.5") ?<Chip sx={{background:"#A0FFAA",color:"#017A0D",width:"120px"}} label={labels[hover !== -1 ? hover : value]} />
                                                                            :""}
                                                                            
                                                                            </Box>
                                                                    
                                                                )}
                                                        </Box>
                                                    </Row>
                                                    
                                                </FormGroup>
                                                </Col>
                                                
                                            </Row>
                                            
                                        </FormGroup> 
                                        <FormGroup className='my-4'>
                                            <Row className=''>
                                                <Col xs={4} sm={4} md={4} lg={4} xl={4} >
                                                    <Label for="validationCustom01">Visible</Label>
                                                    <div>
                                                      
                                                        <Switch checked={formData.visible=="1"?true:false} onChange={(e)=>{handleChange("visible",e.target.checked?"1":"0",null)}}  />
                                                     </div>
                                                </Col>

                                                <Col xs={4} sm={4} md={4} lg={4} xl={4} >
                                                    <Label  for="validationCustom01">is Stock</Label>
                                                    <div>
                                                        <Switch checked={formData.is_stock=="1"?true:false} onChange={(e)=>{handleChange("is_stock",e.target.checked?"1":"0",null)}} />
                                                    </div>
                                                </Col>

                                                <Col xs={4} sm={4} md={4} lg={4} xl={4} >
                                                    <Label  for="validationCustom01">is Box</Label>
                                                    <div>
                                                        <Switch checked={formData.is_box=="1"?true:false} onChange={(e)=>{handleChange("is_box",e.target.checked?"1":"0",null)}} />
                                                    </div>
                                                </Col>

                                                
                                            </Row>
                                            
                                            
                                        </FormGroup>
                                       
                                        </Col>
                                    </Form>
                                    
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </Container>
            {/* languages */}
            {
                Object.keys(dataToEditorState).length > 0 && langauges.length>0  && langauges.map((lang,i)=>(<Container className='mb-3' fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={`${lang.name} information`} />
                            <CardBody className="add-post">

                      
                                <Form className="row needs-validation">
                                        <Col sm="12">
                                       <FormGroup>
                                            <Label for="validationCustom01">Name *</Label>
                                            <Input value={id==":id"?dataToEditorState[JSON.stringify(lang.id)].name.value:dataToEditorState[JSON.stringify(lang.id)].name.value} 
                                                    onChange={(e)=>{handleChange("name",e.target.value,lang.id,id==":id"?null:dataToEditorState[JSON.stringify(lang.id)].name.id,0)}} className="form-control" id="validationCustom01" type="text" placeholder={`${lang.name} name`} required 
                                                    />
                                            <div className="valid-feedback">{"Looks good!"}</div>
                                        </FormGroup>
                                    

                                        <div className="email-wrapper">
                                            <div className="theme-form">
                                            <FormGroup>
                                                <Label>Short Description : *</Label>
                                                {/* short_description */}
                                             <div>
                                             <Editor
                                                    apiKey='9tbaoxukhbd476wzx0cpnz5yr23n39igi9t4ds4jxp0nyfmk'
                                                    init={{
                                                        // TinyMCE editor initialization configuration
                                                        plugins: 'fullpage preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help charmap emoticons codesample code',
                                                        toolbar: 'undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  bullist numlist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile template link anchor codesample | ltr rtl | visualchars visualblocks nonbreaking table tableinsertdialog tablecellprops tableprops help codesample code',
                                                        height: 300,
                                                        branding: false,
                                                        setup: editor => {
                                                            editor.on('Change', function (e) {
                                                                handleChange("short_description", editor.getContent(), lang.id, id === ":id" ? null : dataToEditorState[lang.id].short_description.id, 1);
                                                            });
                                                        }
                                                    }}
                                                    required
                                                    initialValue={id === ":id" ? '' : draftToHtml(convertToRaw(dataToEditorState[lang.id].short_description.value.getCurrentContent()))}
                                                />
                                                      {/* handleChange("short_description", e, lang.id, id == ":id" ? null : dataToEditorState?.[JSON.stringify(lang.id)]?.[1]?.id, 1) */}
                                               {/* <textarea style={{visibility:"hidden"}}
                                                        disabled={true}
                                                         value={id==":id"?draftToHtml(convertToRaw(dataToEditorState[lang.id]["short_description"].getCurrentContent()))
                                                         :draftToHtml(convertToRaw(dataToEditorState[lang.id][1]["short_description"].getCurrentContent()))}
                                                    /> */}
                                            </div>



                                            </FormGroup>
                                            </div>
                                        </div>
                                        <div className="email-wrapper">
                                        <div className="theme-form">
                                          <FormGroup>
                                                <Label > Description: *</Label>
                                                <div>
                                                <Editor
                                                    apiKey='9tbaoxukhbd476wzx0cpnz5yr23n39igi9t4ds4jxp0nyfmk'
                                                    init={{
                                                        // TinyMCE editor initialization configuration
                                                        plugins: 'fullpage preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help charmap emoticons codesample code',
                                                        toolbar: 'undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  bullist numlist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile template link anchor codesample | ltr rtl | visualchars visualblocks nonbreaking table tableinsertdialog tablecellprops tableprops help codesample code',
                                                        height: 300,
                                                        branding: false,
                                                        setup: editor => {
                                                            editor.on('Change', function (e) {
                                                                handleChange("description", editor.getContent(), lang.id, id === ":id" ? null : dataToEditorState[lang.id].description.id, 2);
                                                            });
                                                        }
                                                    }}
                                                    initialValue={id === ":id" ? '' : draftToHtml(convertToRaw(dataToEditorState[lang.id].description.value.getCurrentContent()))}
                                                    required
                                              />
                                                    {/* <textarea style={{visibility:"hidden"}}
                                                        disabled={true}
                                                        // value={draftToHtml(convertToRaw(dataToEditorState[lang.id]["description"].getCurrentContent()))}
                                                    /> */}
                                                </div>    
                                            </FormGroup>
                                            </div>
                                        </div>
                                        <div className="email-wrapper">
                                            <div className="theme-form">
                                            <FormGroup>
                                            <Label >specifications: *</Label>
                                            <div>
                                            <Editor
                                                apiKey='9tbaoxukhbd476wzx0cpnz5yr23n39igi9t4ds4jxp0nyfmk'
                                                init={{
                                                    // TinyMCE editor initialization configuration
                                                    plugins: 'fullpage preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help charmap emoticons codesample code',
                                                    toolbar: 'undo redo | formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  bullist numlist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile template link anchor codesample | ltr rtl | visualchars visualblocks nonbreaking table tableinsertdialog tablecellprops tableprops help codesample code',
                                                    height: 300,
                                                    branding: false,
                                                    setup: editor => {
                                                        editor.on('Change', function (e) {
                                                            handleChange("specifications", editor.getContent(), lang.id, id === ":id" ? null : dataToEditorState[lang.id].specifications.id, 3);
                                                        });
                                                    }
                                                }}
                                                required
                                                initialValue={id === ":id" ? '' : draftToHtml(convertToRaw(dataToEditorState[lang.id].specifications.value.getCurrentContent()))}
                                            />
                                              
                                            </div>    
                                        </FormGroup>
                                        </div>
                                    </div>
                                    <div className="email-wrapper">
                                        <div className="theme-form">
                                            <Label >meta Title *</Label>
                                            <Input value={id==":id"?dataToEditorState[JSON.stringify(lang.id)].meta_title.value:dataToEditorState[JSON.stringify(lang.id)].meta_title.value} 
                                                onChange={(e)=>{handleChange("meta_title",e.target.value,lang.id,id==":id"?null:dataToEditorState[JSON.stringify(lang.id)].meta_title.id,0)}} className="form-control" id="validationCustom01" type="text" placeholder="meta title" required />
                                        </div>
                                    </div>
                                    <div className="email-wrapper">
                                        <div className="theme-form">
                                            <Label>meta Description *</Label>
                                            <Input value={id==":id"?dataToEditorState[JSON.stringify(lang.id)].meta_description.value:dataToEditorState[JSON.stringify(lang.id)].meta_description.value} 
                                            onChange={(e)=>{handleChange("meta_description",e.target.value,lang.id,id==":id"?null:dataToEditorState[JSON.stringify(lang.id)].meta_description.id,0)}} className="form-control" id="validationCustom01" type="text" placeholder="meta description" required />
                                        </div>
                                    </div>
                                    
                                    </Col>
                                </Form>
                            </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>  )
        )}
             {/* upload image */}
            <Container  className='mb-3' fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title={`upload Basic image`} />
                            <CardBody className="fileUploader">
                                <Files
                                    className='files-dropzone fileContainer'
                                    onChange={onFilesChange}
                                    onError={onFilesError}
                                    accepts={['image/*']}
                                    multiple={false}
                                    maxFileSize={10000000}
                                    minFileSize={0}
                                    clickable
                                >
                                    {
                                        files.length > 0
                                            ? <div className='files-gallery'>
                                                {files.map((file, index) =>
                                                    <div key={index}>
                                                        <Image attrImage={{ className: 'files-gallery-item', alt: "img", src: `${file.preview.url}` }} />
                                                    </div>
                                                )}
                                            </div>

                                            : <div className="d-flex justify-content-center">
                                                <Btn attrBtn={{ className: "mt-2", type: "button", color: 'primary' }} >Upload Image</Btn>
                                            </div>
                                    }
                                </Files>
                                {files.length > 0 ?
                                    <div className="d-flex justify-content-center">
                                        <Btn attrBtn={{ className: "mt-2", color: 'danger', type: "button", onClick: () => deleteFile(files) }} >
                                            Delete
                                        </Btn></div> : ''}

                        </CardBody>
                           
                        </Card>
                    </Col>
                    
                    
                </Row>
                
            </Container>
                </>
            }
            
        </Fragment>
    );
};

export default Add_Product;
